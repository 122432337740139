/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { fetchProducts, fetchSubCategoryProducts } from './productsApi';

const initialState = {
  productsWP: [],
  filteredProducts: [],
  subFilteredProducts: [], // отфильтрованные товары из подкатегории категории
  subProducts: [],
  searchQuery: '',
  visibleProductsCount: 10,
  isFromSubCategory: false,
  isLoading: false,
  totalProducts: 0,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,

  reducers: {
    setFromSubCategory: (state, { payload }) => {
      state.isFromSubCategory = payload;
    },

    setSearchQuery: (state, { payload }) => {
      state.searchQuery = payload;

      // Фильтрация товаров
      const lowerCaseQuery = payload.toLowerCase();

      state.subFilteredProducts = state.subProducts.filter(
        (product) =>
          product.name.toLowerCase().includes(lowerCaseQuery) || product.sku.toLowerCase().includes(lowerCaseQuery)
      );

      state.filteredProducts = state.productsWP.filter(
        (product) =>
          product.name.toLowerCase().includes(lowerCaseQuery) || product.sku.toLowerCase().includes(lowerCaseQuery)
      );
    },

    inputClear: (state) => {
      state.searchQuery = '';

      state.subFilteredProducts = state.subProducts;
      state.filteredProducts = state.productsWP;
    },

    loadMoreProducts: (state) => {
      // Увеличиваем количество отображаемых товаров
      const newVisibleCount = state.visibleProductsCount + 10;
      state.visibleProductsCount = newVisibleCount;

      // Устанавливаем `filteredProducts` с учётом нового лимита
      state.subFilteredProducts = state.subProducts.slice(0, newVisibleCount);
      state.filteredProducts = state.productsWP.slice(0, newVisibleCount);
    },

    sortPriceMax: (state) => {
      state.subFilteredProducts = [...state.subProducts].sort((a, b) => b.price - a.price);
      state.filteredProducts = [...state.productsWP].sort((a, b) => b.price - a.price);
    },

    sortPriceMin: (state) => {
      state.subFilteredProducts = [...state.subProducts].sort((a, b) => a.price - b.price);
      state.filteredProducts = [...state.productsWP].sort((a, b) => a.price - b.price);
    },

    sortReset: (state) => {
      state.subFilteredProducts = state.subProducts;
      state.filteredProducts = state.productsWP;
    },

    clearFilteredProducts: (state) => {
      state.productsWP = [];
      state.filteredProducts = [];
      state.subFilteredProducts = [];
      state.subProducts = [];
      state.searchQuery = '';
      state.visibleProductsCount = 10;
      state.isLoading = false;
      state.totalProducts = 0;
    },

    clearSubProducts: (state) => {
      state.subFilteredProducts = [];
      state.subProducts = [];
    },

    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, { payload }) => {
        const prioritizedIds = [70, 88, 96];
        const publish = payload.data.filter((product) => product.status !== 'draft');
        const sale = publish.filter(({ sale_price }) => sale_price !== '').flatMap(({ id }) => id);

        const newProducts = publish.reverse().map((product) => ({
          ...product,
          isPrioritized: prioritizedIds.includes(product.id),
          isSale: sale.includes(product.id),
        }));

        // const updatedProducts = state.productsWP.concat(newProducts);
        // state.productsWP = [
        //   ...updatedProducts.filter((product) => product.isPrioritized),
        //   ...updatedProducts.filter((product) => !product.isPrioritized),
        // ];

        // Сортировка по isSale и isPrioritized
        const sortedProducts = newProducts.sort((a, b) => {
          // Сортировка по распродаже
          if (a.isSale !== b.isSale) {
            return b.isSale - a.isSale;
          }
          // Сортировка по приоритету
          if (a.isPrioritized !== b.isPrioritized) {
            return b.isPrioritized - a.isPrioritized;
          }
          // Сортировка по id (или другой логике)
          return b.id - a.id;
        });

        state.productsWP = sortedProducts.reverse();

        state.filteredProducts = state.productsWP.slice(0, state.visibleProductsCount);

        state.totalProducts = payload.total;
        state.isLoading = false;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchSubCategoryProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubCategoryProducts.fulfilled, (state, { payload }) => {
        const prioritizedIds = [70, 88, 96];
        const publish = payload.data.filter((product) => product.status !== 'draft');
        const sale = publish.filter(({ sale_price }) => sale_price !== '').flatMap(({ id }) => id);

        const newProducts = publish.reverse().map((product) => {
          return {
            ...product,
            isPrioritized: prioritizedIds.includes(product.id),
            isSale: sale.includes(product.id),
          };
        });

        // const updatedProducts = state.subProducts.concat(newProducts);

        // state.subProducts = [
        //   // ...updatedProducts.filter((product) => product.isSale),
        //   // ...updatedProducts.filter((product) => !product.isSale),
        //   ...updatedProducts.filter((product) => product.isPrioritized),
        //   ...updatedProducts.filter((product) => !product.isPrioritized),
        // ];

        // Сортировка по isSale и isPrioritized
        const sortedProducts = newProducts.sort((a, b) => {
          // Сортировка по распродаже
          if (a.isSale !== b.isSale) {
            return b.isSale - a.isSale;
          }
          // Сортировка по приоритету
          if (a.isPrioritized !== b.isPrioritized) {
            return b.isPrioritized - a.isPrioritized;
          }
          // Сортировка по id (или другой логике)
          return b.id - a.id;
        });

        state.subProducts = sortedProducts;

        state.subFilteredProducts = state.subProducts.slice(0, state.visibleProductsCount);

        state.totalProducts = payload.total;
        state.isLoading = false;
      })
      .addCase(fetchSubCategoryProducts.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setSearchQuery,
  inputClear,
  loadMoreProducts,
  sortPriceMax,
  sortPriceMin,
  sortReset,
  clearFilteredProducts,
  setLoading,
  clearSubProducts,
  setFromSubCategory,
} = productsSlice.actions;
export const productsReducer = productsSlice.reducer;

/* ---------------------------- распарсить обьект --------------------------- */
// function decoder(name, value) {
//   const originalObject = JSON.parse(JSON.stringify(value));
//   console.log(name, originalObject);
// }
