import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: localStorage.getItem('leng') || 'uk',
};

const languageSlice = createSlice({
  name: 'language',
  initialState,

  reducers: {
    setCurrentLeng: (state, { payload }) => {
      state.language = payload;
    },
  },
});

export const { setCurrentLeng } = languageSlice.actions;

export const languageReducer = languageSlice.reducer;
