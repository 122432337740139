/* eslint-disable no-undef */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CloseBtn, MessageWrapper, StyledBtn, StyledModal, Title } from './ModalThanksContakt.styled';
import ModalBackdrop from '../../../common/ModalBackdrop/ModalBackdrop';
import { deleteName } from '../../../../redux/basket/basket';

export const ModalThanksContakt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.basket);
  const { t } = useTranslation();

  const goBack = () => navigate('/');

  useEffect(() => {
    fbq('track', 'Contact');
  }, []);

  useEffect(() => {
    return () => {
      dispatch(deleteName());
    };
  }, [dispatch]);

  return (
    <ModalBackdrop>
      <StyledModal
        onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <CloseBtn src="/images/icons/close.svg" alt="Close" onClick={goBack} />
        <Title>
          {name} {t('thanksModal.title1')}
        </Title>
        <MessageWrapper>
          <p>{t('thanksModal.text1')}</p>
        </MessageWrapper>
        <StyledBtn to="/accessories">{t('modalBasket.btn2')}</StyledBtn>
      </StyledModal>
    </ModalBackdrop>
  );
};

export default ModalThanksContakt;
