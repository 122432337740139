import { useDispatch, useSelector } from 'react-redux';

import { routeToModelsByBrand } from '../../redux/filter/filter';
import { transliterate } from '../../utils/formatURL';
import { StyledLink, Grid } from './Catalog.styled';

const CatalogGrid = () => {
  const dispatch = useDispatch();
  const { filteredLogoBrand } = useSelector((state) => state.filter);

  const handleSelectBrand = (value) => {
    dispatch(routeToModelsByBrand(value));
  };

  return (
    <Grid>
      {filteredLogoBrand.map((brand) => {
        const lowerCasePath = brand.name.replace(/\s/g, '').toLowerCase();
        const transLitEn = transliterate(lowerCasePath);

        return (
          <StyledLink to={`/catalog/${transLitEn}`} key={brand.name} onClick={() => handleSelectBrand(transLitEn)}>
            <img src={brand.logo} alt={brand.name} />

            <p>{brand.name}</p>
          </StyledLink>
        );
      })}
    </Grid>
  );
};

export default CatalogGrid;
