import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const StyledModal = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 560px;
  padding: 15px;
  height: 400px;
  background: #272b34;
  border-radius: 12px;
`;

export const CloseBtn = styled.img`
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  width: 20px;
  height: 20px;
  fill: ${(p) => p.theme.colors.white};
  cursor: pointer;
`;

export const Title = styled.h3`
  font-size: 24px;
  margin-bottom: 24px;
`;

export const MessageWrapper = styled.div`
  margin-bottom: 40px;
  font-size: 18px;
  p {
    letter-spacing: 1px;
    transition: transform 0.3s ease;
  }

  p:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const StyledBtn = styled(Link)`
  display: inline-block;
  padding: 15px 25px;
  font-size: ${(p) => p.theme.fontSizes.fs20};
  line-height: ${(p) => p.theme.lineHeights.lh24};
  border: 2px solid ${(p) => p.theme.colors.white};
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;

  &:hover {
    color: ${(p) => p.theme.colors.accentColor};
    border-color: ${(p) => p.theme.colors.accentColor};
  }

  @media screen and (max-width: 768px) {
    font-size: ${(p) => p.theme.fontSizes.fs16};
    padding: 15px 35px;
  }

  @media screen and (max-width: 425px) {
    font-size: ${(p) => p.theme.fontSizes.fs14};
    padding: 5px 15px;
  }
`;
