/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { uid } from 'uid';

const createRugOrder = (data) => {
  return {
    id: uid(),
    type: 'rug',
    imgModel: '',
    title: '',
    salonScheme: [], //записываем схему салона 1 или 2
    typeM: '', //тип материала ромб\сота
    colorRug: '', //ЦВЕТ КОВРИКА
    colorEdge: '', //ЦВЕТ КАНТИКА
    heel: false, //подпятник да или нет
    brandLabel: '', //шильдик да или нет
    typeBoart: '', //ТИП БОРТИКА 5см 10см
    typeRug: '', //ТИП КОВРИКА КЛАСИЧЕСКИЙ ИЛИ 3Д...
    titleKey: '',
    price: 0,

    count: 1,
    totalPrice: 0,
    ...data,
  };
};

const createPlannerOrder = (data) => {
  return {
    id: uid(),
    type: 'accessory',
    imgAcc: '',
    title: '',
    size: '', //размер аксесуара
    typeM: '', //тип материала ева\эко
    color: '', //ЦВЕТ
    price: 0,

    count: 1,
    totalPrice: 0,
    ...data,
  };
};

const createMatOrder = (data) => {
  return {
    id: uid(),
    type: 'mat',
    imgMat: '',
    title: '',
    count: 1, //количество
    typeM: '', //тип материала ромб\сота\капля
    color: '', //ЦВЕТ
    price: 0,

    totalPrice: 0,
    ...data,
  };
};

const createCoverOrder = (data) => {
  return {
    id: uid(),
    type: 'cover',
    imgCover: '',
    title: '',
    typeM: '', //тип материала
    selectedSideСolor: '',
    selectedCenterColor: '',
    selectedInsertColor: '',
    selectedThreadColor: '', //ЦВЕТ
    price: 0,

    count: 1, //количество
    totalPrice: 0,
    ...data,
  };
};

const createGeneralProductCardOrder = (data) => {
  return {
    id: uid(),
    type: 'generalCard',
    sku: '',
    imgGen: '',
    title: '',
    typeM: '', //тип материала
    color: '',
    size: '',
    colorEdge: '',
    selectedSideСolor: '',
    selectedCenterColor: '',
    selectedInsertColor: '',
    selectedThreadColor: '', //ЦВЕТ
    price: 0,
    salePrice: 0,

    count: 1, //количество
    totalPrice: 0,
    ...data,
  };
};

const initialState = {
  orders: [], //суда записивать обьект с данными, для отображения в корзине
  name: '',
  totalPrice: 0,
  wheelOfFortuneShown: false,
  lastSpinDate: null, // Дата последнего запуска колеса
  winnings: '',
  isLoading: false,
  orderCompleted: false,
};

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addRugOrder: (state, { payload }) => {
      const newOrder = createRugOrder(payload);
      newOrder.totalPrice = newOrder.price * newOrder.count;
      state.orders.push(newOrder);
      state.totalPrice = state.orders.reduce((acc, order) => acc + order.totalPrice, 0);
    },

    addPlannerOrder: (state, { payload }) => {
      const newOrder = createPlannerOrder(payload);
      newOrder.totalPrice = newOrder.price * newOrder.count;
      state.orders.push(newOrder);
      state.totalPrice = state.orders.reduce((acc, order) => acc + order.totalPrice, 0);
    },

    addMatOrder: (state, { payload }) => {
      const newOrder = createMatOrder(payload);
      newOrder.totalPrice = newOrder.price;
      state.orders.push(newOrder);
      state.totalPrice = state.orders.reduce((acc, order) => acc + order.totalPrice, 0);
    },

    addCoverOrder: (state, { payload }) => {
      const newOrder = createCoverOrder(payload);
      newOrder.totalPrice = newOrder.price;
      state.orders.push(newOrder);
      state.totalPrice = state.orders.reduce((acc, order) => acc + order.totalPrice, 0);
    },

    addGeneralProductCardOrder: (state, { payload }) => {
      const newOrder = createGeneralProductCardOrder(payload);

      newOrder.totalPrice = newOrder.price;
      state.orders.push(newOrder);
      state.totalPrice = state.orders.reduce((acc, order) => acc + order.totalPrice, 0);
    },

    decrement: (state, { payload }) => {
      const order = state.orders.find((order) => order.id === payload.id);

      if (order && order.count > 1) {
        order.count -= 1;
        order.totalPrice -= order.price;

        state.totalPrice = state.orders.reduce((acc, order) => acc + order.totalPrice, 0);
      }
    },

    increment: (state, { payload }) => {
      const order = state.orders.find((order) => order.id === payload.id);

      if (order) {
        order.count += 1;
        order.totalPrice += order.price;

        state.totalPrice = state.orders.reduce((acc, order) => acc + order.totalPrice, 0);
      }
    },

    deleteOrder: (state, { payload }) => {
      state.orders = state.orders.filter(({ id }) => payload !== id);
      state.totalPrice = state.orders.reduce((acc, order) => {
        return acc + parseFloat(order.price);
      }, 0);
    },

    clearingOrders: (state) => {
      state.orders = [];
      state.winnings = '';
      state.orderCompleted = true;
    },

    getName: (state, { payload }) => {
      state.name = payload;
    },

    deleteName: (state) => {
      state.name = '';
    },

    showWheelOfFortune: (state) => {
      state.wheelOfFortuneShown = true;
    },

    resetWheelOfFortune: (state) => {
      state.wheelOfFortuneShown = false;
    },

    setLastSpinDate: (state, { payload }) => {
      state.lastSpinDate = payload; // Сохранить дату последнего запуска
    },

    setWinnings: (state, { payload }) => {
      state.winnings = payload; // Сохранить выигрыш
    },

    setLoading: (state, { payload }) => {
      state.isLoading = payload; // Сохранить выигрыш
    },

    setOrderCompleted: (state, { payload }) => {
      state.orderCompleted = payload;
    },
  },
});

export const {
  addRugOrder,
  addPlannerOrder,
  addMatOrder,
  addCoverOrder,
  addGeneralProductCardOrder,
  deleteOrder,
  increment,
  decrement,
  clearingOrders,
  getName,
  deleteName,
  showWheelOfFortune,
  resetWheelOfFortune,
  setLastSpinDate,
  setWinnings,
  setLoading,
  setOrderCompleted,
} = basketSlice.actions;

export const basketReducer = basketSlice.reducer;

/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
