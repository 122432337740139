import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledCatalog = styled.section`
  padding: 60px 0 0 0;
  text-align: center;
`;

export const SelectBox = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 50px;

  ::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    fill: red;
    background: url('/images/icons/arrow.svg') no-repeat center/contain;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

// export const StyledSelect = styled.select`
export const StyledReactSelect = styled.div`
  position: relative;

  .react-select__control {
    -webkit-appearance: none;
    -moz-appearance: none;

    width: 620px;
    height: 50px;
    /* padding: 0 12px; */
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;

    border: none;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.modalColor};
    cursor: pointer;
    /* border-color: transparent !important; */

    &:hover,
    &:focus {
      box-shadow: 0 0 5px rgba(255, 0, 0, 0.5) !important;
      outline: none !important;
      border-color: transparent !important;
    }

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .react-select__value-container {
    justify-items: start;
  }
  /* .react-select__indicator, */
  .react-select__dropdown-indicator,
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__clear-indicator {
    margin-right: 20px;
  }

  .react-select__placeholder {
    color: rgb(194, 194, 194);
  }

  .react-select__single-value {
    color: rgb(194, 194, 194);
  }

  .react-select__menu {
    background: ${(p) => p.theme.colors.modalColor} !important;
    border-radius: 8px;
    margin-top: 4px;
    overflow: hidden;
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__option--is-focused {
    background: transparent;
  }

  .react-select__input-container {
    color: rgb(194, 194, 194) !important;
  }

  ::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: url('/images/icons/arrow.svg') no-repeat center/contain;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
`;
export const StyledSelectLink = styled(Link)`
  position: relative;
  text-align: start;
  width: 100%;
  padding: 10px;
  z-index: 10;

  &:hover {
    background: #025ec2 !important;
  }

  &.react-select__option--is-selected {
    background: #025ec2 !important;
    color: white;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-items: center;

  @media screen and (max-width: 1260px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 652px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 0 60px 0;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 190px;
  height: 190px;
  text-align: center;

  :hover {
    img {
      box-shadow: 0px 0px 20px 0px rgba(255, 0, 0, 0.5);
    }
  }

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    background-color: ${(p) => p.theme.colors.modalColor};
    border-radius: 12px;
    transition: box-shadow 0.3s ease;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
