import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import Spinner from './components/common/Spinner/Spinner';
import { persistor, store } from './redux/store';
import { GlobalStyle } from './GlobalStyle';
import { theme } from './theme';
import App from './App';
import './i18n';

const CACHE_BUSTER = process.env.REACT_APP_VERSION || '1.0.0';
const storedVersion = localStorage.getItem('site_version');

// Функция удаления куки для домена и поддоменов
const deleteCookie = (name, domain) => {
  document.cookie = `${name}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // Резервное удаление без домена
};

if (storedVersion !== CACHE_BUSTER) {
  persistor.purge(); // Очищаем redux-persist
  localStorage.removeItem('i18nextLng'); // Очищаем язык

  // Удаляем все куки для домена .evacar.com.ua
  const cookies = document.cookie.split(';');
  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim();
    deleteCookie(cookieName, '.evacar.com.ua');
  });

  // Сохраняем новую версию
  localStorage.setItem('site_version', CACHE_BUSTER);
  window.location.reload(true);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <StyleSheetManager disableVendorPrefixes>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<Spinner />}>
              <App />
            </Suspense>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </StyleSheetManager>
  </BrowserRouter>
);
