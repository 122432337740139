/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import {
  EDGE_COLOR_3D,
  EDGE_COLOR_CLASSIC,
  R_RUG_COLOR_IMG,
  R_RUG_COLOR_IMG_3D,
  S_RUG_COLOR_IMG,
  S_RUG_COLOR_IMG_3D,
} from '../../data/imagesConstructor';
import { EDGE_COLOR, NAMEPLATE_PRICE, RUG_COLOR } from '../../data/optionsConstructor';

const initialState = {
  title: '',
  imgModel: '',
  salonScheme: [], //записываем схему салона 1 или 2
  shamePrices: {},
  shamePricesCar: {},
  typeM: '', //тип материала ромб\сота
  rugSet: null, //вставляем суда масив картинок при открытии страницы
  edgeSet: null, //вставляем суда масив картинок при открытии страницы
  schemeSet: null,
  colorRugSet: null,
  colorEdgeSet: null,
  colorRug: '', //ЦВЕТ КОВРИКА
  colorEdge: '', //ЦВЕТ КАНТИКА
  currentImg: null,
  currentEdge: null,
  heel: '', //подпятник да или нет
  heel3d: '', //подпятник да или нет
  brandLabel: '', //шильдик да или нет
  namePlate: null, //масив шильдиков
  typeBoart: '', //ТИП БОРТИКА 5см 10см
  typeRug: '', //ТИП КОВРИКА КЛАСИЧЕСКИЙ ИЛИ 3Д...
  titleKey: '',
  schemePrice: 0,
  shameValue: '',
  buttonDisabled: false,
  previousPrice: 0,
  pulse: '',

  basePrice: 0, // базовая цена
  extraPrice: 0, // доп цена
  totalPrice: 0, //общяя сумма
};

const constructorRugSlice = createSlice({
  name: 'constructorRug',
  initialState,

  reducers: {
    // заполнение стеита при первом входе в конструктор
    popelateInitialState: (state, { payload }) => {
      const { currentModelImg, currentTitle, titleKey, processedScheme, translatedPrices } = payload;

      state.imgModel = currentModelImg;
      state.title = currentTitle;
      state.salonScheme = [];
      state.typeM = 'romb';
      state.shamePrices = translatedPrices;
      state.shamePricesCar = processedScheme;
      state.schemeSet = state.shamePricesCar;
      state.colorRugSet = RUG_COLOR;
      state.colorEdgeSet = EDGE_COLOR;
      // state.rugSet = R_RUG_COLOR_IMG;
      state.rugSet = R_RUG_COLOR_IMG_3D;
      // state.edgeSet = EDGE_COLOR_CLASSIC;
      state.edgeSet = EDGE_COLOR_3D;
      state.colorRug = 'r_bk';
      state.colorEdge = 'bk';
      state.typeBoart = '';
      state.brandLabel = '';
      state.namePlate = NAMEPLATE_PRICE;
      state.heel = '';
      state.heel3d = '';
      state.shameValue = '';
      state.typeRug = 'Зd'; //З=> русская буква
      state.titleKey = titleKey;
      state.buttonDisabled = false;
      state.previousPrice = 0;
      state.basePrice = 0;
      state.extraPrice = 0;
      state.totalPrice = 0;
      state.pulse = '';

      state.currentImg = state.rugSet?.find(({ value }) => state.colorRug === value);
      state.currentEdge = state.edgeSet?.find(({ value }) => state.colorEdge === value);
    },

    // выбор схемы салона
    handleSalonScheme: (state, { payload }) => {
      const { value, price } = payload;

      state.shameValue = value;
      const index = state.salonScheme.findIndex((item) => item.value === value);

      if (index !== -1) {
        state.salonScheme.splice(index, 1);
        state.basePrice -= price;
        state.typeBoart = '';

        if (state.typeRug === 'Зd') {
          state.schemeSet = state.shamePricesCar;
        }

        state.shameValue = '';
        state.buttonDisabled = false;
      } else {
        state.pulse = '';
        // Если схема не выбрана, добавляем ее
        state.salonScheme.push({ value, price });

        // Обновляем общую цену
        state.basePrice += price;

        // Деактивируем все схемы после выбранной
        if (value) {
          state.pulse = '';
          state.salonScheme = state.salonScheme.filter((item) => item.value);
          state.buttonDisabled = true;
        } else {
          const index = ['k1', 'k2', 'k3', 'k4', 'k5', 'k6', 'k7', 'k8', 'k9', 'k10'].indexOf(value);
          if (index !== -1) {
            state.salonScheme = state.salonScheme.filter((item) => item.value || item.value <= value);
          }
        }
      }
      state.totalPrice = state.basePrice + state.extraPrice;
    },

    // выбор материала ромб или сота
    selectMaterialType: (state, { payload }) => {
      state.typeM = payload;

      if (payload === 'romb') {
        state.colorRug = 'r_bk';
        state.rugSet = state.typeRug === 'classic' ? R_RUG_COLOR_IMG : R_RUG_COLOR_IMG_3D;
      } else {
        state.colorRug = 's_bk';
        state.rugSet = state.typeRug === 'classic' ? S_RUG_COLOR_IMG : S_RUG_COLOR_IMG_3D;
      }

      // заменяет картинку относящююся к форме материала
      state.currentImg = state.rugSet?.find(({ value }) => state.colorRug === value);
    },

    // ВЫБОР ТИПА КОВРИКА
    selecttypeRug: (state, { payload }) => {
      state.typeRug = payload;

      // при смене значения перезаписываются масивы
      if (payload === 'classic' || payload === 'classic3dlapa') {
        if (state.typeM === 'romb') {
          state.rugSet = R_RUG_COLOR_IMG;
        } else {
          state.rugSet = S_RUG_COLOR_IMG;
        }

        if (payload === 'classic') {
          state.typeRug = 'classic';
        } else {
          state.typeRug = 'classic3dlapa';
        }

        state.edgeSet = EDGE_COLOR_CLASSIC;
        state.typeBoart = '';
        state.schemeSet = state.schemeSet.map((scheme, index) => {
          const priceArray = state.shamePrices[payload] || state.shamePrices['classic']; //подмена если classic3dlapa выдаёт андевайн ставим  по усолчанию
          return {
            ...scheme,
            price: priceArray ? priceArray[index] || scheme.price : scheme.price,
          };
        });

        state.salonScheme = [];
        state.shameValue = '';
        state.heel = '';
        state.heel3d = '';
        state.brandLabel = 'item0';
        state.previousPrice = 0;
        state.totalPrice = 0;
        state.basePrice = 0;
        state.extraPrice = 0;
        state.buttonDisabled = false;
        state.pulse = '';
        state.namePlate = NAMEPLATE_PRICE;
      } else {
        if (state.typeM === 'romb') {
          state.rugSet = R_RUG_COLOR_IMG_3D;
        } else {
          state.rugSet = S_RUG_COLOR_IMG_3D;
        }

        state.edgeSet = EDGE_COLOR_3D;
        state.typeRug = 'Зd'; //Зd=> русская буква
        state.schemeSet = state.shamePricesCar;
        state.salonScheme = [];
        state.shameValue = '';
        state.heel = '';
        state.heel3d = '';
        state.brandLabel = 'item0';
        state.previousPrice = 0;
        state.totalPrice = 0;
        state.basePrice = 0;
        state.extraPrice = 0;
        state.buttonDisabled = false;
        state.pulse = '';
        state.namePlate = NAMEPLATE_PRICE;
      }

      state.currentImg = state.rugSet?.find(({ value }) => state.colorRug === value);
      state.currentEdge = state.edgeSet?.find(({ value }) => state.colorEdge === value);
    },

    // выбор размера бортиков
    selectSizeBoart: (state, { payload }) => {
      const { value, checked } = payload;
      if (checked) {
        state.typeBoart = value;
        state.schemeSet = state.schemeSet.map((scheme, index) => {
          return {
            ...scheme,
            price: state.shamePrices[value][index] || scheme.price,
          };
        });

        // Пересчитываем totalPrice
        const currentPrice = state.schemeSet.find((item) => state.shameValue === item.value);

        state.basePrice = currentPrice ? currentPrice.price : state.basePrice;
      } else {
        state.typeBoart = '';
        state.schemeSet = state.shamePricesCar;
        state.pulse = '';

        // Пересчитываем totalPrice для исходных цен
        const currentPrice = state.schemeSet.find((item) => state.shameValue === item.value);

        state.basePrice = currentPrice ? currentPrice.price : state.basePrice;
      }

      // Пересчитываем общую сумму
      state.totalPrice = state.basePrice + state.extraPrice;
    },

    // выбор цвета материала ромб или сота
    selectColorRug: (state, { payload }) => {
      state.colorRug = payload;

      state.currentImg = state.rugSet?.find(({ value }) => value.includes(payload));
    },

    // выбор цвета бортика ромб или сота
    selectColorEdge: (state, { payload }) => {
      state.colorEdge = payload;

      state.currentEdge = state.edgeSet?.find(({ value }) => value.includes(payload));
    },

    // добавить или удалить подпятник
    addHeel: (state, { payload }) => {
      // const { checked, price = 0, priceЗd = 0, key } = payload;
      const { checked, price = 0 } = payload;

      // if (checked && key === 'alum') {
      //   state.heel = 'Так';
      //   state.extraPrice = 0;
      //   state.extraPrice += price;
      // } else {
      //   state.heel = '';
      //   state.extraPrice -= price;
      // }

      // if (checked && key === '3d') {
      //   state.heel3d = '3D';
      //   state.extraPrice = 0;
      //   state.extraPrice += priceЗd;
      // } else {
      //   state.heel3d = '';
      //   state.extraPrice -= priceЗd;
      // }

      if (checked) {
        state.heel = 'Так';
        state.extraPrice += price;
      } else {
        state.heel = '';
        state.extraPrice -= price;
      }

      state.totalPrice = state.basePrice + state.extraPrice;
    },

    // выбор шильдика и стоимость
    addBrandLabel: (state, { payload }) => {
      const { value, price } = payload;

      // Если выбрано пустое значение, сбрасываем brandLabel и уменьшаем totalPrice
      if (value === 'item0') {
        state.extraPrice -= state.previousPrice || 0;
        state.previousPrice = 0;
        state.brandLabel = 'item0';
      } else {
        state.extraPrice -= state.previousPrice || 0; // Уменьшаем totalPrice на предыдущую цену
        state.extraPrice += price; // Увеличиваем totalPrice на новую цену
        state.previousPrice = price; // Сохраняем новую цену в previousPrice
        state.brandLabel = value; // Устанавливаем brandLabel в значение выбранного элемента
      }

      state.totalPrice = state.basePrice + state.extraPrice;
    },

    handlePulse: (state, { payload }) => {
      state.pulse = payload;
    },
  },
});

export const {
  popelateInitialState,
  handleSalonScheme,
  selectMaterialType,
  selectColorRug,
  selectColorEdge,
  addHeel,
  addBrandLabel,
  selectSizeBoart,
  selecttypeRug,
  handlePulse,
} = constructorRugSlice.actions;

export const constructorRugReducer = constructorRugSlice.reducer;
/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
