import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { resourceUrl, getRequestParams } from '../../api/getRequestParams';

/* ---------------------------- список категории ---------------------------- */
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, { getState, rejectWithValue }) => {
    try {
      const params = getRequestParams();
      const currentLeng = getState().language.language;

      const response = await axios.get(
        `${resourceUrl}/wc/v3/products/categories?lang=${currentLeng}&_fields=id,name,slug,image,parent&per_page=20`,
        { params }
      );

      const data = response.data.map(({ id, name, slug, image, parent }) => ({
        id,
        name,
        slug,
        image: { id: image?.id || 0, src: image?.src || '' },
        parent,
      }));

      return data;
    } catch (error) {
      console.error('Ошибка при загрузке товаров:', error);
      return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке товаров');
    }
  }
);
