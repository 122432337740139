import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const StyledModal = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  padding: 15px;
  background: ${(p) => p.theme.colors.white};
  border-radius: 12px;

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  font-size: 20px;
  fill: ${(p) => p.theme.colors.black};
  cursor: pointer;
`;

export const Title = styled.h3`
  font-size: 24px;
  margin-bottom: 24px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
  width: 615px;
  height: 515px;
  color: ${(p) => p.theme.colors.black};

  /* outline: 1px solid red; */
  @media (max-width: 1260px) {
    width: 490px;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 2px;
    /* padding-bottom: 24px; */
    /* height: 774px; */
    border-radius: 12px;
    background: ${(p) => p.theme.colors.white};
  }
`;

export const StiledPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
  margin-top: auto;
  @media (max-width: 992px) {
    padding: 15px 25px 0 25px;
  }
`;

export const StyledTextPrice = styled.p`
  font-size: ${(p) => p.theme.fontSizes.fs32};

  @media screen and (max-width: 425px) {
    font-size: ${(p) => p.theme.fontSizes.fs20};
  }
`;

export const StyledTitle = styled.h3`
  margin: ${({ margin }) => margin || '0'};
  text-align: ${({ center }) => center || ''};
  font-size: 32px;
  font-weight: 400;

  @media (max-width: 992px) {
    text-align: center;
    padding: 25px 25px 0 25px;
  }
`;

export const StyledBtn = styled(Link)`
  display: inline-block;
  padding: 15px 25px;
  font-size: ${(p) => p.theme.fontSizes.fs20};
  line-height: ${(p) => p.theme.lineHeights.lh24};
  color: ${(p) => p.theme.colors.black};
  border: 2px solid ${(p) => p.theme.colors.black};
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;

  &:hover {
    color: ${(p) => p.theme.colors.accentColor};
    border-color: ${(p) => p.theme.colors.accentColor};
  }

  @media screen and (max-width: 768px) {
    font-size: ${(p) => p.theme.fontSizes.fs16};
    padding: 15px 35px;
  }

  @media screen and (max-width: 425px) {
    font-size: ${(p) => p.theme.fontSizes.fs14};
    padding: 5px 15px;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;
