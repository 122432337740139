import { createSlice } from '@reduxjs/toolkit';
import { fetchCategories } from './categoriesApi';

const initialState = {
  catalogWP: [],
  subCategories: [],
  filteredSubCategories: [],
  isLoading: false,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,

  reducers: {
    filterSubCatecories: (state, { payload }) => {
      const numberValue = Number(payload);

      const subCategories = state.subCategories.filter(({ parent }) => parent === numberValue);

      state.filteredSubCategories = subCategories;
    },

    cleanState: (state) => {
      state.filteredSubCategories = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        const recommended = 332; //id категории рекомендуемые из WP

        const updatedCatalog = payload.filter((el) => el.id !== recommended);
        const mainCategories = updatedCatalog.filter(({ parent }) => parent === 0);

        state.catalogWP = mainCategories;
        state.subCategories = updatedCatalog;
        state.isLoading = false;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { filterSubCatecories, cleanState } = categoriesSlice.actions;

export const categoriesReducer = categoriesSlice.reducer;
