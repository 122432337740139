import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestParams, resourceUrl } from '../../api/getRequestParams';
import { transliterateToEnglish } from '../../utils/formatURL';
import { keyWords } from './keyWords';

export const fetchProductsBySearch = createAsyncThunk(
  'products/fetchProductsBySearch',
  async ({ query }, { rejectWithValue }) => {
    try {
      const params = getRequestParams();
      let url;

      let transformedQuery = query; // Используем нормализованный input из компонента

      // Определяем, является ли введённый текст брендом на русском или украинском
      if (keyWords[query]) {
        transformedQuery = keyWords[query]; // Заменяем на английский эквивалент
      } else {
        const brandMatch = Object.keys(keyWords).find((brand) => query.startsWith(brand));
        if (brandMatch) {
          transformedQuery = query.replace(brandMatch, keyWords[brandMatch]);
        }
      }

      // Если введён бренд на русском или украинском, транслитерируем его
      if (transformedQuery !== query) {
        const currentMaterial = Object.keys(keyWords).find((mat) => query.startsWith(mat));
        const mat = 'eva' || 'eco' || 'ева' || 'еко';

        if (currentMaterial === mat) {
          transformedQuery = query.replace(currentMaterial, keyWords[currentMaterial]);
        } else {
          transformedQuery = transliterateToEnglish(transformedQuery);
        }
      }

      console.log('transformedQuery', transformedQuery);

      // Если запрос выглядит как SKU, используем параметр sku
      const artikle = ['EC_', 'ec_', 'ус_', 'УС_'];
      const comparisonQuery = transformedQuery.split('').splice(0, 3).join('');
      const searchKey = artikle.includes(comparisonQuery) ? 'sku' : 'search';

      url = `${resourceUrl}/wc/v3/products?${searchKey}=${transformedQuery}&_fields=id,name,price,sku,slug,categories&per_page=100`;

      const response = await axios.get(url, { params });

      const data = response.data.map(({ id, name, price, sku, slug, categories }) => ({
        id,
        name,
        price,
        sku,
        slug,
        categories,
      }));

      console.log('data', data);

      return data;
    } catch (error) {
      console.error('Ошибка при поиске товаров:', error);
      return rejectWithValue(error.response?.data || 'Ошибка при выполнении поиска');
    }
  }
);
