import { uid } from 'uid';

export const CATALOG = {
  Acura: {
    logo: '/images/catalog/logo/acura-100x100.png',
    name: 'Acura',
    models: [
      {
        id: '1',
        model: 'ILX',
        year: ['2012', '2022'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5823-250x150.jpg',
        title: 'Килимки в салон Acura ILX 2012-2022 I покоління USA Sedan',
        titleKey: 'acura1',
      },
      {
        id: '2',
        model: 'MDX',
        year: ['2006', '2013'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5825-250x150.jpg',
        title: 'Килимки в салон Acura MDX (YD2) 2006-2013 II покоління USA Crossover 7-ми місна',
        titleKey: 'acura2',
      },
      {
        id: '3',
        model: 'MDX',
        year: ['2013', '2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5826-250x150.jpg',
        title: 'Килимки в салон Acura MDX (YD3) 2013-2016 III покоління USA Crossover дорест 7-ми місна',
        titleKey: 'acura3',
      },
      {
        id: '4',
        model: 'MDX',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5827-250x150.jpg',
        title: 'Килимки в салон Acura MDX (YD3) 2016-2020 III покоління USA Crossover рест 7-ми місна',
        titleKey: 'acura4',
      },
      {
        id: '5',
        model: 'MDX',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5828-250x150.jpg',
        title: 'Килимки в салон Acura MDX (YD3) 2016-2020 III покоління USA Crossover рест 7-ми місна Hybrid',
        titleKey: 'acura5',
      },
      {
        id: '6',
        model: 'MDX',
        year: ['2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5829-250x150.jpg',
        title: 'Килимки в салон Acura MDX (YD4) 2020-… IV покоління USA Crossover 7-ми місна',
        titleKey: 'acura6',
      },
      {
        id: '7',
        model: 'MDX',
        year: ['2006', '2009'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5824-250x150.jpg',
        title: 'Килимки в салон Acura MDX 2001-2006 I покоління USA Crossover',
        titleKey: 'acura7',
      },
      {
        id: '8',
        model: 'MDX',
        year: ['2001', '2006'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5830-250x150.jpg',
        title: 'Килимки в салон Acura RDX (TB1) 2006-2009 I покоління USA Crossover дорест',
        titleKey: 'acura8',
      },
      {
        id: '9',
        model: 'RDX',
        year: ['2009', '2012'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5831-250x150.jpg',
        title: 'Килимки в салон Acura RDX (TB1) 2009-2012 I покоління USA Crossover рест',
        titleKey: 'acura9',
      },
      {
        id: '10',
        model: 'RDX',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5832-250x150.jpg',
        title: 'Килимки в салон Acura RDX (TB3) 2012-2018 II покоління USA Crossover',
        titleKey: 'acura10',
      },
      {
        id: '11',
        model: 'RDX',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5833-250x150.jpg',
        title: 'Килимки в салон Acura RDX 2018-… III покоління USA Crossover',
        titleKey: 'acura11',
      },
      {
        id: '12',
        model: 'RL',
        year: ['1996', '2005'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5834-250x150.jpg',
        title: 'Килимки в салон Acura RL (KA9) 1996-2005 I покоління USA Sedan',
        titleKey: 'acura12',
      },
      {
        id: '13',
        model: 'RL',
        year: ['2005', '2009'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5835-250x150.jpg',
        title: 'Килимки в салон Acura RL 2005-2009 II покоління USA Sedan',
        titleKey: 'acura13',
      },
      {
        id: '14',
        model: 'RL',
        year: ['2005', '2009'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5836-250x150.jpg',
        title: 'Килимки в салон Acura RL 2005-2009 II покоління USA Sedan AWD',
        titleKey: 'acura14',
      },
      {
        id: '15',
        model: 'RSX',
        year: ['2002', '2006'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/acura/5838-250x150.jpg',
        title: 'Килимки в салон Acura RSX 2002-2006 I покоління USA Coupe',
        titleKey: 'acura15',
      },
      {
        id: '16',
        model: 'TL',
        year: ['2009', '2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5839-250x150.jpg',
        title: 'Килимки в салон Acura TL SH (UA9) 2009-2014 IV покоління USA Sedan AWD',
        titleKey: 'acura16',
      },
      {
        id: '17',
        model: 'TLX',
        year: ['2014', '2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5841-250x150.jpg',
        title: 'Килимки в салон Acura TLX 2014-2020 I покоління USA Sedan',
        titleKey: 'acura17',
      },
      {
        id: '18',
        model: 'TLX',
        year: ['2014', '2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5840-250x150.jpg',
        title: 'Килимки в салон Acura TLX 2014-2020 I покоління USA Sedan AWD',
        titleKey: 'acura18',
      },
      {
        id: '19',
        model: 'TSX',
        year: ['2009', '2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5842-250x150.jpg',
        title: 'Килимки в салон Acura TSX (CU2) 2009-2014 II покоління USA Sedan',
        titleKey: 'acura19',
      },
      {
        id: '20',
        model: 'ZDX',
        year: ['2010', '2013'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/acura/5843-250x150.jpg',
        title: 'Килимки в салон Acura ZDX 2010-2013 I покоління USA Crossover',
        titleKey: 'acura20',
      },
      {
        id: '21',
        model: 'RLX',
        year: ['2014', '2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/acura/5837-250x150.jpg',
        title: 'Килимки в салон Acura  RLX Sport 2014-2020 I покоління USA Sedan Hybrid',
        titleKey: 'acura21',
      },
    ],
  },
  /* -------------------------------- AlfaRomeo ------------------------------- */
  Alfaromeo: {
    logo: '/images/catalog/logo/alfa_romeo-100x100.png',
    name: 'Alfa Romeo',
    models: [
      {
        id: '22',
        model: '146',
        year: ['1994', '2000'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5844-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 146 1994-2000 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'alfaromeo1',
      },
      {
        id: '23',
        model: '147',
        year: ['2000', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5846-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 147(937) 2000-2010 I покоління EU Hatchback 3-х дверна',
        titleKey: 'alfaromeo2',
      },
      {
        id: '24',
        model: '147',
        year: ['2000', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5845-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 147(937) 2000-2010 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'alfaromeo3',
      },
      {
        id: '25',
        model: '155',
        year: ['1992', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5847-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 155 1992-1998 I покоління EU Sedan',
        titleKey: 'alfaromeo4',
      },
      {
        id: '26',
        model: '156',
        year: ['1997', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5848-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 156(932) 1997-2007 I покоління EU Sedan',
        titleKey: 'alfaromeo5',
      },
      {
        id: '27',
        model: '156',
        year: ['1997', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5849-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 156(932) 1997-2007 I покоління EU Universal',
        titleKey: 'alfaromeo6',
      },
      {
        id: '28',
        model: '159',
        year: ['2005', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5851-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 159(939) 2005-2009 I покоління EU Universal дорест',
        titleKey: 'alfaromeo7',
      },
      {
        id: '29',
        model: '159',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5850-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 159(939) 2005-2011 I покоління EU Sedan',
        titleKey: 'alfaromeo8',
      },
      {
        id: '30',
        model: '159',
        year: ['2008', '2011'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5852-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 159(939) 2008-2011 I покоління EU Universal рест',
        titleKey: 'alfaromeo9',
      },
      {
        id: '31',
        model: '164',
        year: ['1987', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5853-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 164 1987-1998 I покоління EU Sedan',
        titleKey: 'alfaromeo10',
      },
      {
        id: '32',
        model: '166',
        year: ['1998', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5854-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo 166 (936) 1998-2007 I покоління EU Sedan',
        titleKey: 'alfaromeo11',
      },
      {
        id: '33',
        model: 'Alfetta',
        year: ['1972', '1987'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5855-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo Alfetta 1972-1987 I покоління EU Sedan',
        titleKey: 'alfaromeo12',
      },
      {
        id: '34',
        model: 'Brera',
        year: ['2005', '2010'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5856-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo Brera 2005-2010 I покоління EU Coupe',
        titleKey: 'alfaromeo13',
      },
      {
        id: '35',
        model: 'Giulia',
        year: ['2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5857-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo Giulia (952) 2016-… I покоління EU Sedan',
        titleKey: 'alfaromeo14',
      },
      {
        id: '36',
        model: 'Giulia',
        year: ['2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/alfa_romeo/5858-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo Giulia (952) 2016-… I покоління USA Sedan AWD',
        titleKey: 'alfaromeo15',
      },
      {
        id: '37',
        model: 'Giulietta',
        year: ['2010', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5859-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo Giulietta (940) 2010-2020 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'alfaromeo16',
      },
      {
        id: '38',
        model: 'GT',
        year: ['2003', '2010'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5860-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo GT 2003-2010 I покоління EU Coupe',
        titleKey: 'alfaromeo17',
      },
      {
        id: '39',
        model: 'Mito',
        year: ['2008', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5861-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo Mito 2008-2018 I покоління EU Hatchback',
        titleKey: 'alfaromeo18',
      },
      {
        id: '40',
        model: 'Spider',
        year: ['2006', '2010'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/alfa_romeo/5862-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo Spider 2006-2010 I покоління EU Cabriolet',
        titleKey: 'alfaromeo19',
      },
      {
        id: '41',
        model: 'Stelvio',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/alfa_romeo/5863-250x150.jpg',
        title: 'Килимки в салон Alfa Romeo Stelvio 2017-… I покоління USA Crossover',
        titleKey: 'alfaromeo20',
      },
    ],
  },
  /* ---------------------------------- Audi ---------------------------------- */
  Audi: {
    logo: '/images/catalog/logo/audi-100x100.png',
    name: 'Audi',
    models: [
      {
        id: '42',
        model: '100',
        year: ['1982', '1988'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5210-250x150.jpg',
        title: 'Килимки в салон Audi 100 (C3) 1982-1988 III покоління EU Sedan дорест',
        titleKey: 'Audi1',
      },
      {
        id: '43',
        model: '100',
        year: ['1982', '1991'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5211-250x150.jpg',
        title: 'Килимки в салон Audi 100 (C3) 1982-1991 III покоління EU Universal',
        titleKey: 'Audi2',
      },
      {
        id: '44',
        model: '100',
        year: ['1988', '1991'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5212-250x150.jpg',
        title: 'Килимки в салон Audi 100 (C3) 1988-1991 III покоління EU Sedan рест',
        titleKey: 'Audi3',
      },
      {
        id: '45',
        model: '100',
        year: ['1990', '1994'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5213-250x150.jpg',
        title: 'Килимки в салон Audi 100 (C4) 1990-1994 IV покоління EU Sedan',
        titleKey: 'Audi4',
      },
      {
        id: '46',
        model: '100',
        year: ['1990', '1994'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5214-250x150.jpg',
        title: 'Килимки в салон Audi 100 (C4) 1990-1994 IV покоління EU Universal',
        titleKey: 'Audi5',
      },
      {
        id: '47',
        model: '80',
        year: ['1978', '1986'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5205-250x150.jpg',
        title: 'Килимки в салон Audi 80 (B2) 1978-1986 II покоління EU Sedan',
        titleKey: 'Audi6',
      },
      {
        id: '48',
        model: '80',
        year: ['1986', '1991'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5206-250x150.jpg',
        title: 'Килимки в салон Audi 80 (B3) 1986-1991 III покоління EU Sedan',
        titleKey: 'Audi7',
      },
      {
        id: '49',
        model: '80',
        year: ['1991', '1995'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/audi/5207-250x150.jpg',
        title: 'Килимки в салон Audi 80 (B4) 1991-1995 IV покоління EU Coupe',
        titleKey: 'Audi8',
      },
      {
        id: '50',
        model: '80',
        year: ['1991', '1995'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5208-250x150.jpg',
        title: 'Килимки в салон Audi 80 (B4) 1991-1995 IV покоління EU Sedan',
        titleKey: 'Audi9',
      },
      {
        id: '51',
        model: '80',
        year: ['1991', '1995'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5209-250x150.jpg',
        title: 'Килимки в салон Audi 80 (B4) 1991-1995 IV покоління EU Universal',
        titleKey: 'Audi10',
      },
      {
        id: '52',
        model: 'A1',
        year: ['2010', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5216-250x150.jpg',
        title: 'Килимки в салон Audi A1 (8X) 2010-2018 I покоління EU Hatchback 3-х дверна',
        titleKey: 'Audi11',
      },

      {
        id: '53',
        model: 'A1',
        year: ['2010', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5215-250x150.jpg',
        title: 'Килимки в салон Audi A1 (8X) 2010-2018 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'Audi12',
      },
      {
        id: '54',
        model: 'A2',
        year: ['1999', '2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5217-250x150.jpg',
        title: 'Килимки в салон Audi A2 1999-2005 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'Audi13',
      },
      {
        id: '55',
        model: 'A3',
        year: ['1996', '2000'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5218-250x150.jpg',
        title: 'Килимки в салон Audi A3 (8L) 1996-2000 I покоління EU Hatchback дорест 3-х дверна',
        titleKey: 'Audi14',
      },
      {
        id: '56',
        model: 'A3',
        year: ['1996', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5219-250x150.jpg',
        title: 'Килимки в салон Audi A3 (8L) 1996-2003 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'Audi15',
      },
      {
        id: '57',
        model: 'A3',
        year: ['2000', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5220-250x150.jpg',
        title: 'Килимки в салон Audi A3 (8L) 2000-2003 I покоління EU Hatchback рест 3-х дверна',
        titleKey: 'Audi16',
      },
      {
        id: '58',
        model: 'A3',
        year: ['2004', '2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5222-250x150.jpg',
        title: 'Килимки в салон Audi A3 (8P) 2004-2013 II покоління EU Hatchback 3-х дверна',
        titleKey: 'Audi17',
      },
      {
        id: '59',
        model: 'A3',
        year: ['2012', '2020'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5224-250x150.jpg',
        title: 'Килимки в салон Audi A3 (8V) 2012-2020 III покоління EU/USA Hatchback 3-х дверна',
        titleKey: 'Audi18',
      },
      {
        id: '60',
        model: 'A3',
        year: ['2012', '2020'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5225-250x150.jpg',
        title: 'Килимки в салон Audi A3 (8V) 2012-2020 III покоління EU/USA Sedan',
        titleKey: 'Audi19',
      },
      {
        id: '61',
        model: 'A3',
        year: ['2012', '2020'],
        body: 'Cabriolet',
        market: 'USA',
        img: '/images/catalog/models/audi/5226-250x150.jpg',
        title: 'Килимки в салон Audi A3 (8V) 2012-2020 III покоління USA Cabriolet',
        titleKey: 'Audi20',
      },
      {
        id: '62',
        model: 'A3',
        year: ['2014', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5227-250x150.jpg',
        title: 'Килимки в салон Audi A3 E-tron (8V) 2014-2020 III покоління EU Hatchback',
        titleKey: 'Audi21',
      },

      {
        id: '63',
        model: 'A3',
        year: ['2004', '2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5221-250x150.jpg',
        title: 'Килимки в салон Audi A3 Sportback (8P) 2004-2013 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'Audi22',
      },
      {
        id: '64',
        model: 'A3',
        year: ['2012', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/audi/5223-250x150.jpg',
        title: 'Килимки в салон Audi A3 Sportback (8V) 2012-2020 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'Audi23',
      },
      {
        id: '65',
        model: 'A4',
        year: ['1994', '1999'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5229-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B5) 1994-1999 I покоління EU Sedan дорест',
        titleKey: 'Audi24',
      },
      {
        id: '66',
        model: 'A4',
        year: ['1994', '1999'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5228-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B5) 1994-1999 I покоління EU Universal дорест',
        titleKey: 'Audi25',
      },
      {
        id: '67',
        model: 'A4',
        year: ['1999', '2001'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5232-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B5) 1999-2001 I покоління EU Sedan рест',
        titleKey: 'Audi26',
      },
      {
        id: '68',
        model: 'A4',
        year: ['1999', '2001'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5231-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B5) 1999-2001 I покоління EU Universal рест',
        titleKey: 'Audi27',
      },
      {
        id: '69',
        model: 'A4',
        year: ['1996', '2001'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5230-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B5) Avant 1996-2001 I покоління EU Universal',
        titleKey: 'Audi28',
      },
      {
        id: '70',
        model: 'A4',
        year: ['2000', '2004'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/audi/5235-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B6) 2000-2004 II покоління EU Cabriolet',
        titleKey: 'Audi29',
      },
      {
        id: '71',
        model: 'A4',
        year: ['2000', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5233-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B6) 2000-2004 II покоління EU Sedan',
        titleKey: 'Audi30',
      },
      {
        id: '72',
        model: 'A4',
        year: ['2000', '2004'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5234-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B6) 2000-2004 II покоління EU Universal',
        titleKey: 'Audi31',
      },

      {
        id: '73',
        model: 'A4',
        year: ['2004', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5236-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B7) 2004-2008 III покоління EU Sedan',
        titleKey: 'Audi32',
      },
      {
        id: '74',
        model: 'A4',
        year: ['2004', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5237-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B7) 2004-2008 III покоління EU Universal',
        titleKey: 'Audi33',
      },
      {
        id: '75',
        model: 'A4',
        year: ['2007', '2015'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5238-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B8) 2007-2015 IV покоління EU/USA Sedan',
        titleKey: 'Audi34',
      },
      {
        id: '76',
        model: 'A4',
        year: ['2007', '2015'],
        body: 'Universal',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5239-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B8) 2007-2015 IV покоління EU/USA Universal',
        titleKey: 'Audi35',
      },
      {
        id: '77',
        model: 'A4',
        year: ['2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5241-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B9) 2015-… V покоління EU Universal',
        titleKey: 'Audi36',
      },
      {
        id: '78',
        model: 'A4',
        year: ['2015'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5240-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B9) 2015-… V покоління EU/USA Sedan',
        titleKey: 'Audi37',
      },
      {
        id: '79',
        model: 'A4',
        year: ['2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5242-250x150.jpg',
        title: 'Килимки в салон Audi A4 (B9) Allroad Quattro 2016-… V покоління EU Universal',
        titleKey: 'Audi38',
      },
      {
        id: '80',
        model: 'A5',
        year: ['2007', '2016'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/audi/5244-250x150.jpg',
        title: 'Килимки в салон Audi A5 (8T) 2007-2016 I покоління EU Cabriolet',
        titleKey: 'Audi39',
      },
      {
        id: '81',
        model: 'A5',
        year: ['2007', '2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/audi/5243-250x150.jpg',
        title: 'Килимки в салон Audi A5 (8T) 2007-2016 I покоління EU Coupe',
        titleKey: 'Audi40',
      },
      {
        id: '82',
        model: 'A5',
        year: ['2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/audi/5247-250x150.jpg',
        title: 'Килимки в салон Audi A5 (F5) 2016-… II покоління EU Coupe',
        titleKey: 'Audi41',
      },

      {
        id: '83',
        model: 'A5',
        year: ['2016'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/audi/5246-250x150.jpg',
        title: 'Килимки в салон Audi A5 (F5) 2016-… II покоління USA Coupe',
        titleKey: 'Audi42',
      },
      {
        id: '84',
        model: 'A5',
        year: ['2009', '2016'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/audi/5245-250x150.jpg',
        title: 'Килимки в салон Audi A5 Sportback (8T) 2009-2016 I покоління EU Liftback',
        titleKey: 'Audi43',
      },
      {
        id: '85',
        model: 'A5',
        year: ['2016'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/audi/5249-250x150.jpg',
        title: 'Килимки в салон Audi A5 Sportback (F5) 2016-… II покоління EU Liftback',
        titleKey: 'Audi44',
      },
      {
        id: '86',
        model: 'A5',
        year: ['2016'],
        body: 'Liftback',
        market: 'USA',
        img: '/images/catalog/models/audi/5248-250x150.jpg',
        title: 'Килимки в салон Audi A5 Sportback (F5) 2016-… II покоління USA Liftback',
        titleKey: 'Audi45',
      },
      {
        id: '87',
        model: 'A6',
        year: ['1994', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5250-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C4) 1994-1997 I покоління EU Sedan',
        titleKey: 'Audi46',
      },
      {
        id: '88',
        model: 'A6',
        year: ['1994', '1997'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5251-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C4) 1994-1997 I покоління EU Universal',
        titleKey: 'Audi47',
      },
      {
        id: '89',
        model: 'A6',
        year: ['1997', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5253-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C5) 1997-2000 II покоління EU Sedan дорест 4WD',
        titleKey: 'Audi48',
      },
      {
        id: '90',
        model: 'A6',
        year: ['1997', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5252-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C5) 1997-2000 II покоління EU Sedan дорест FWD',
        titleKey: 'Audi49',
      },
      {
        id: '91',
        model: 'A6',
        year: ['1997', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5255-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C5) 1997-2000 II покоління EU Universal дорест 4WD',
        titleKey: 'Audi50',
      },
      {
        id: '92',
        model: 'A6',
        year: ['1997', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5254-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C5) 1997-2000 II покоління EU Universal дорест FWD',
        titleKey: 'Audi51',
      },

      {
        id: '93',
        model: 'A6',
        year: ['2000', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5258-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C5) 2000-2004 II покоління EU Sedan рест 4WD',
        titleKey: 'Audi52',
      },
      {
        id: '94',
        model: 'A6',
        year: ['2000', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5259-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C5) 2000-2004 II покоління EU Sedan рест FWD',
        titleKey: 'Audi53',
      },
      {
        id: '95',
        model: 'A6',
        year: ['2000', '2004'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5257-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C5) 2000-2004 II покоління EU Universal рест 4WD',
        titleKey: 'Audi54',
      },
      {
        id: '96',
        model: 'A6',
        year: ['2000', '2004'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5256-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C5) 2000-2004 II покоління EU Universal рест FWD',
        titleKey: 'Audi55',
      },
      {
        id: '97',
        model: 'A6',
        year: ['2004', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5260-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C6) 2004-2011 III покоління EU Sedan',
        titleKey: 'Audi56',
      },
      {
        id: '98',
        model: 'A6',
        year: ['2004', '2011'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5261-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C6) 2004-2011 III покоління EU Universal',
        titleKey: 'Audi57',
      },
      {
        id: '99',
        model: 'A6',
        year: ['2011', '2018'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5262-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C7) 2011-2018 IV покоління EU/USA Sedan',
        titleKey: 'Audi58',
      },
      {
        id: '100',
        model: 'A6',
        year: ['2011', '2018'],
        body: 'Universal',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5263-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C7) 2011-2018 IV покоління EU/USA Universal',
        titleKey: 'Audi59',
      },
      {
        id: '101',
        model: 'A6',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5265-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C8) 2018-… V покоління EU Sedan',
        titleKey: 'Audi60',
      },
      {
        id: '102',
        model: 'A6',
        year: ['2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/audi/5264-250x150.jpg',
        title: 'Килимки в салон Audi A6 (C8) 2018-… V покоління EU Universal',
        titleKey: 'Audi61',
      },

      {
        id: '103',
        model: 'A7',
        year: ['2010', '2014'],
        body: 'Liftback',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5266-250x150.jpg',
        title: 'Килимки в салон Audi A7 (4G7) 2010-2014 I покоління EU/USA Liftback дорест',
        titleKey: 'Audi62',
      },
      {
        id: '104',
        model: 'A7',
        year: ['2014', '2018'],
        body: 'Liftback',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5267-250x150.jpg',
        title: 'Килимки в салон Audi A7 (4G7) 2014-2018 I покоління EU/USA Liftback рест',
        titleKey: 'Audi63',
      },
      {
        id: '105',
        model: 'A8',
        year: ['1994', '2002'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5268-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D2) 1994-2002 I покоління EU Sedan Long',
        titleKey: 'Audi64',
      },
      {
        id: '106',
        model: 'A8',
        year: ['1994', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5269-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D2) 1994-2002 I покоління EU Sedan Short',
        titleKey: 'Audi65',
      },
      {
        id: '107',
        model: 'A8',
        year: ['2002', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5272-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D3) 2002-2009 II покоління EU Sedan Long/AWD',
        titleKey: 'Audi66',
      },
      {
        id: '108',
        model: 'A8',
        year: ['2002', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5271-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D3) 2002-2009 II покоління EU Sedan Short/AWD',
        titleKey: 'Audi67',
      },
      {
        id: '109',
        model: 'A8',
        year: ['2002', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5270-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D3) 2002-2009 II покоління EU Sedan Short/FWD',
        titleKey: 'Audi68',
      },
      {
        id: '110',
        model: 'A8',
        year: ['2010', '2013'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5273-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D4) 2010-2013 III покоління EU/USA Sedan дорест Long/AWD',
        titleKey: 'Audi69',
      },
      {
        id: '111',
        model: 'A8',
        year: ['2010', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5274-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D4) 2010-2017 III покоління EU Sedan Short',
        titleKey: 'Audi70',
      },
      {
        id: '112',
        model: 'A8',
        year: ['2010', '2017'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5275-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D4) 2010-2017 III покоління EU/USA Sedan Long/FWD',
        titleKey: 'Audi71',
      },

      {
        id: '113',
        model: 'A8',
        year: ['2013', '2017'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5276-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D4) 2013-2017 III покоління EU/USA Sedan рест Long/AWD',
        titleKey: 'Audi72',
      },
      {
        id: '114',
        model: 'A8',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5278-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D5) 2017-… IV покоління EU Sedan Long / AWD',
        titleKey: 'Audi73',
      },
      {
        id: '115',
        model: 'A8',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5277-250x150.jpg',
        title: 'Килимки в салон Audi A8 (D5) 2017-… IV покоління EU Sedan Short',
        titleKey: 'Audi74',
      },
      {
        id: '116',
        model: 'e-tron',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/audi/5279-250x150.jpg',
        title: 'Килимки в салон Audi e-tron (GE) (Prestige) 2018-… I покоління EU Crossover',
        titleKey: 'Audi75',
      },
      {
        id: '117',
        model: 'e-tron',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5280-250x150.jpg',
        title: 'Килимки в салон Audi e-tron (GE) 2018-… I покоління EU/USA Crossover',
        titleKey: 'Audi76',
      },
      {
        id: '118',
        model: 'e-tron',
        year: ['2018'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/audi/5281-250x150.jpg',
        title: 'Килимки в салон Audi e-tron Sportback (GE) 2018-… I покоління EU Liftback',
        titleKey: 'Audi77',
      },
      {
        id: '119',
        model: 'Q2',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/audi/5283-250x150.jpg',
        title: 'Килимки в салон Audi Q2 2016-… I покоління EU Crossover',
        titleKey: 'Audi78',
      },
      {
        id: '120',
        model: 'Q2L',
        year: ['2019'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/audi/5283-250x150.jpg',
        title: 'Килимки в салон Audi Q2L e-tron 2019-… I покоління China Crossover',
        titleKey: 'Audi79',
      },
      {
        id: '121',
        model: 'Q3',
        year: ['2011', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/audi/5285-250x150.jpg',
        title: 'Килимки в салон Audi Q3 (8U) 2011-2014 I покоління EU Crossover дорест',
        titleKey: 'Audi80',
      },
      {
        id: '122',
        model: 'Q3',
        year: ['2014', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/audi/5286-250x150.jpg',
        title: 'Килимки в салон Audi Q3 (8U) 2014-2018 I покоління USA Crossover рест',
        titleKey: 'Audi81',
      },

      {
        id: '123',
        model: 'Q3',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5287-250x150.jpg',
        title: 'Килимки в салон Audi Q3 (F3) 2018-… II покоління EU/USA Crossover',
        titleKey: 'Audi82',
      },
      {
        id: '124',
        model: 'Q4',
        year: ['2021'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/audi/5990-250x150.jpg',
        title: 'Килимки в салон Audi Q4 e-tron (FZ) 2021-... I покоління China Crossover',
        titleKey: 'Audi83',
      },
      {
        id: '125',
        model: 'Q5',
        year: ['2008', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5288-250x150.jpg',
        title: 'Килимки в салон Audi Q5 (8R) 2008-2012 I покоління EU/USA Crossover дорест',
        titleKey: 'Audi84',
      },
      {
        id: '126',
        model: 'Q5',
        year: ['2008', '2017'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5986-250x150.jpg',
        title: 'Килимки в салон Audi Q5 (8R) 2008-2017 I покоління EU/USA Crossover hybrid',
        titleKey: 'Audi85',
      },
      {
        id: '127',
        model: 'Q5',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5290-250x150.jpg',
        title: 'Килимки в салон Audi Q5 (8R) 2012-2017 I покоління EU/USA Crossover рест',
        titleKey: 'Audi86',
      },
      {
        id: '128',
        model: 'Q5',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/audi/5294-250x150.jpg',
        title: 'Килимки в салон Audi Q5 (FY) 2017-… II покоління EU Crossover',
        titleKey: 'Audi87',
      },
      {
        id: '129',
        model: 'Q5',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/audi/5293-250x150.jpg',
        title: 'Килимки в салон Audi Q5 (FY) 2017-… II покоління EU Crossover Plug-in Hybrid',
        titleKey: 'Audi88',
      },
      {
        id: '130',
        model: 'Q5',
        year: ['2022'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/audi/5291-250x150.jpg',
        title: 'Килимки в салон Audi Q5 e-tron 2022-… I покоління China Crossover 6-ти місне',
        titleKey: 'Audi89',
      },
      {
        id: '131',
        model: 'Q5',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/audi/5294-250x150.jpg',
        title: 'Килимки в салон Audi Q5 Quattro Premium (FY) 2017-… II покоління USA Crossover',
        titleKey: 'Audi90',
      },
      {
        id: '132',
        model: 'Q7',
        year: ['2005', '2015'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5296-250x150.jpg',
        title: 'Килимки в салон Audi Q7 (4L) 2005-2015 I покоління EU/USA Crossover 5-ти місна',
        titleKey: 'Audi91',
      },

      {
        id: '133',
        model: 'Q7',
        year: ['2005', '2015'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5295-250x150.jpg',
        title: 'Килимки в салон Audi Q7 (4L) 2005-2015 I покоління EU/USA Crossover 7-ми місна',
        titleKey: 'Audi92',
      },
      {
        id: '134',
        model: 'Q7',
        year: ['2015'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5297-250x150.jpg',
        title: 'Килимки в салон Audi Q7 (4M) 2015-… II покоління EU/USA Crossover 5-ти місна',
        titleKey: 'Audi93',
      },
      {
        id: '135',
        model: 'Q7',
        year: ['2015'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/audi/5298-250x150.jpg',
        title: 'Килимки в салон Audi Q7 (4M) 2015-… II покоління EU/USA Crossover 7-ми місна',
        titleKey: 'Audi94',
      },
      {
        id: '136',
        model: 'Q8',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/audi/5299-250x150.jpg',
        title: 'Килимки в салон Audi Q8 2018-… I покоління EU Crossover',
        titleKey: 'Audi95',
      },
      {
        id: '137',
        model: 'R8',
        year: ['2006', '2015'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/audi/5300-250x150.jpg',
        title: 'Килимки в салон Audi R8 (Type 42) 2006-2015 I покоління EU Coupe',
        titleKey: 'Audi96',
      },
      {
        id: '138',
        model: 'S5',
        year: ['2012', '2016'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/audi/5302-250x150.jpg',
        title: 'Килимки в салон Audi S5 2012-2016 I покоління EU Cabriolet 3-х дверна',
        titleKey: 'Audi97',
      },
      {
        id: '139',
        model: 'S5',
        year: ['2012', '2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/audi/5301-250x150.jpg',
        title: 'Килимки в салон Audi S5 2012-2016 I покоління EU Coupe 3-х дверна',
        titleKey: 'Audi98',
      },
      {
        id: '140',
        model: 'S6',
        year: ['2004', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5303-250x150.jpg',
        title: 'Килимки в салон Audi S6 (C6) 2004-2011 III покоління EU Sedan',
        titleKey: 'Audi99',
      },
      {
        id: '141',
        model: 'S8',
        year: ['1996', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/audi/5304-250x150.jpg',
        title: 'Килимки в салон Audi S8 (D2) 1996-2002 I покоління EU Sedan',
        titleKey: 'Audi100',
      },
      {
        id: '142',
        model: 'SQ5',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/audi/5289-250x150.jpg',
        title: 'Килимки в салон Audi SQ5 (8R) 2012-2017 I покоління EU Crossover рест',
        titleKey: 'Audi101',
      },

      {
        id: '143',
        model: 'SQ5',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/audi/5292-250x150.jpg',
        title: 'Килимки в салон Audi SQ5 (FY) 2017-… II покоління EU Crossover',
        titleKey: 'Audi102',
      },
      {
        id: '144',
        model: 'TT',
        year: ['2006', '2014'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/audi/5306-250x150.jpg',
        title: 'Килимки в салон Audi TT (8J) 2006-2014 II покоління EU Coupe',
        titleKey: 'Audi103',
      },
      {
        id: '145',
        model: 'TT',
        year: ['1998', '2006'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/audi/5305-250x150.jpg',
        title: 'Килимки в салон Audi TT (8N) 1998-2006 I покоління EU Coupe',
        titleKey: 'Audi104',
      },
      {
        id: '146',
        model: 'TT',
        year: ['2014'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/audi/5307-250x150.jpg',
        title: 'Килимки в салон Audi TT (8S) 2014-… III покоління EU Coupe',
        titleKey: 'Audi105',
      },
    ],
  },
  /* --------------------------------- Beijing -------------------------------- */
  Beijing: {
    logo: '/images/catalog/logo/Beijing-100x100.png',
    name: 'Beijing',
    models: [
      {
        id: '147',
        model: 'EV5',
        year: ['2019'],
        body: 'Minivan',
        market: 'China',
        img: '/images/catalog/models/beijing/5690-250x150.jpg',
        title: 'Килимки в салон Beijing EV5 2019-… I покоління China Minivan',
        titleKey: 'beijing1',
      },
      {
        id: '148',
        model: 'EX3',
        year: ['2018'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/beijing/5691-250x150.jpg',
        title: 'Килимки в салон Beijing EX3 2018-… I покоління China Hatchback',
        titleKey: 'beijing2',
      },
    ],
  },
  /* ----------------------------------- BMW ---------------------------------- */
  Bmw: {
    logo: '/images/catalog/logo/bmw-100x100.png',
    name: 'BMW',
    models: [
      {
        id: '149',
        model: '3-Series',
        year: ['1982', '1994'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5360-250x150.jpg',
        title: 'Килимки в салон BMW (E30) 3-Series 1982-1994 II покоління EU Cabriolet',
        titleKey: 'bmw1',
      },
      {
        id: '150',
        model: '3-Series',
        year: ['1982', '1994'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5361-250x150.jpg',
        title: 'Килимки в салон BMW (E30) 3-Series 1982-1994 II покоління EU Coupe',
        titleKey: 'bmw2',
      },
      {
        id: '151',
        model: '3-Series',
        year: ['1982', '1994'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5363-250x150.jpg',
        title: 'Килимки в салон BMW (E30) 3-Series 1982-1994 II покоління EU Sedan',
        titleKey: 'bmw3',
      },
      {
        id: '152',
        model: '3-Series',
        year: ['1982', '1994'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5362-250x150.jpg',
        title: 'Килимки в салон BMW (E30) 3-Series 1982-1994 II покоління EU Universal',
        titleKey: 'bmw4',
      },
      {
        id: '153',
        model: '1-Series',
        year: ['2004', '2012'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5350-250x150.jpg',
        title: 'Килимки в салон BMW (E82) 1-Series 2004-2012 I покоління EU Coupe',
        titleKey: 'bmw5',
      },
      {
        id: '154',
        model: '1-Series',
        year: ['2004', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5349-250x150.jpg',
        title: 'Килимки в салон BMW (E87) 1-Series 2004-2012 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'bmw6',
      },
      {
        id: '155',
        model: '1-Series',
        year: ['2012', '2015'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/bmw/5353-250x150.jpg',
        title: 'Килимки в салон BMW (F20) 1-Series 2012-2015 II покоління USA Hatchback 5-ти дверна',
        titleKey: 'bmw7',
      },
      {
        id: '156',
        model: '1-Series',
        year: ['2012', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5351-250x150.jpg',
        title: 'Килимки в салон BMW (F20) 1-Series 2012-2019 II покоління EU Hatchback 3-х дверна',
        titleKey: 'bmw8',
      },
      {
        id: '157',
        model: '1-Series',
        year: ['2012', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5352-250x150.jpg',
        title: 'Килимки в салон BMW (F20) 1-Series 2012-2019 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'bmw9',
      },
      {
        id: '158',
        model: '2-Series',
        year: ['2013', '2021'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5356-250x150.jpg',
        title: 'Килимки в салон BMW (F22) 2-Series 2013-2021 I покоління EU Cabriolet',
        titleKey: 'bmw10',
      },

      {
        id: '159',
        model: '2-Series',
        year: ['2013', '2021'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5355-250x150.jpg',
        title: 'Килимки в салон BMW (F22) 2-Series 2013-2021 I покоління EU Coupe',
        titleKey: 'bmw11',
      },
      {
        id: '160',
        model: '1-Series',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5354-250x150.jpg',
        title: 'Килимки в салон BMW (F40) 1-Series 2019-… III покоління EU Hatchback',
        titleKey: 'bmw12',
      },
      {
        id: '161',
        model: '2-Series',
        year: ['2014', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5357-250x150.jpg',
        title: 'Килимки в салон BMW (F45) 2-series 216D 2014-2021 I покоління EU Crossover',
        titleKey: 'bmw13',
      },
      {
        id: '162',
        model: '2-Series',
        year: ['2014', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5359-250x150.jpg',
        title: 'Килимки в салон BMW (F45) 2-series 225XE 2014-2021 I покоління EU Crossover',
        titleKey: 'bmw14',
      },
      {
        id: '163',
        model: '2-Series',
        year: ['2014', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5358-250x150.jpg',
        title: 'Килимки в салон BMW (F46) 2-Series 2014-2021 I покоління EU Crossover 7-ми місна',
        titleKey: 'bmw15',
      },
      {
        id: '164',
        model: '7-Series',
        year: ['1976', '1986'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5424-250x150.jpg',
        title: 'Килимки в салон BMW E23 7-Series 1976-1986 I покоління EU Sedan',
        titleKey: 'bmw16',
      },
      {
        id: '165',
        model: '5-Series',
        year: ['1981', '1987'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5393-250x150.jpg',
        title: 'Килимки в салон BMW E28 5-Series 1981-1987 II покоління EU Sedan',
        titleKey: 'bmw17',
      },
      {
        id: '166',
        model: '7-Series',
        year: ['1986', '1994'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5425-250x150.jpg',
        title: 'Килимки в салон BMW E32 7-Series 1986-1994 II покоління EU Sedan',
        titleKey: 'bmw18',
      },
      {
        id: '167',
        model: '5-Series',
        year: ['1987', '1996'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5395-250x150.jpg',
        title: 'Килимки в салон BMW E34 5-Series 1987-1996 III покоління EU Sedan',
        titleKey: 'bmw19',
      },
      {
        id: '168',
        model: '5-Series',
        year: ['1987', '1996'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5394-250x150.jpg',
        title: 'Килимки в салон BMW E34 5-Series 1987-1996 III покоління EU Universal',
        titleKey: 'bmw20',
      },

      {
        id: '169',
        model: '3-Series',
        year: ['1990', '2000'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5365-250x150.jpg',
        title: 'Килимки в салон BMW E36 3-Series 1990-2000 III покоління EU Coupe',
        titleKey: 'bmw21',
      },
      {
        id: '170',
        model: '3-Series',
        year: ['1990', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5364-250x150.jpg',
        title: 'Килимки в салон BMW E36 3-Series 1990-2000 III покоління EU Sedan',
        titleKey: 'bmw22',
      },
      {
        id: '171',
        model: '3-Series',
        year: ['1990', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5366-250x150.jpg',
        title: 'Килимки в салон BMW E36 3-Series 1990-2000 III покоління EU Universal',
        titleKey: 'bmw23',
      },
      {
        id: '172',
        model: '3-Series',
        year: ['1990', '2000'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5367-250x150.jpg',
        title: 'Килимки в салон BMW E36 Compact 3-Series 1990-2000 III покоління EU Hatchback 3-х дверна',
        titleKey: 'bmw24',
      },
      {
        id: '173',
        model: '7-Series',
        year: ['1994', '2001'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5426-250x150.jpg',
        title: 'Килимки в салон BMW E38 7-Series 1994-2001 III покоління EU Sedan Long',
        titleKey: 'bmw25',
      },
      {
        id: '174',
        model: '7-Series',
        year: ['1994', '2001'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5427-250x150.jpg',
        title: 'Килимки в салон BMW E38 7-Series 1994-2001 III покоління EU Sedan Short',
        titleKey: 'bmw26',
      },
      {
        id: '175',
        model: '5-Series',
        year: ['1995', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5396-250x150.jpg',
        title: 'Килимки в салон BMW E39 5-Series 1995-2004 IV покоління EU Sedan',
        titleKey: 'bmw27',
      },
      {
        id: '176',
        model: '5-Series',
        year: ['1995', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5397-250x150.jpg',
        title: 'Килимки в салон BMW E39 5-Series 1995-2004 IV покоління EU Sedan праве кермо',
        titleKey: 'bmw28',
      },
      {
        id: '177',
        model: '5-Series',
        year: ['1995', '2004'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5398-250x150.jpg',
        title: 'Килимки в салон BMW E39 5-Series 1995-2004 IV покоління EU Universal',
        titleKey: 'bmw29',
      },
      {
        id: '178',
        model: '5-Series',
        year: ['1997', '2003'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5371-250x150.jpg',
        title: 'Килимки в салон BMW E46 3-Series 1997-2003 IV покоління EU Coupe дорест',
        titleKey: 'bmw30',
      },

      {
        id: '179',
        model: '3-Series',
        year: ['1997', '2006'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5369-250x150.jpg',
        title: 'Килимки в салон BMW E46 3-Series 1997-2006 IV покоління EU Cabriolet',
        titleKey: 'bmw31',
      },
      {
        id: '180',
        model: '3-Series',
        year: ['1997', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5372-250x150.jpg',
        title: 'Килимки в салон BMW E46 3-Series 1997-2006 IV покоління EU Hatchback',
        titleKey: 'bmw32',
      },
      {
        id: '181',
        model: '3-Series',
        year: ['1997', '2006'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5368-250x150.jpg',
        title: 'Килимки в салон BMW E46 3-Series 1997-2006 IV покоління EU Universal',
        titleKey: 'bmw33',
      },
      {
        id: '182',
        model: '3-Series',
        year: ['2003', '2006'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5373-250x150.jpg',
        title: 'Килимки в салон BMW E46 3-Series 2003-2006 IV покоління EU Coupe рест',
        titleKey: 'bmw34',
      },
      {
        id: '183',
        model: '5-Series',
        year: ['2003', '2007'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5399-250x150.jpg',
        title: 'Килимки в салон BMW E60 5-Series 2003-2007 V покоління EU/USA Sedan дорест',
        titleKey: 'bmw35',
      },
      {
        id: '184',
        model: '5-Series',
        year: ['2007', '2010'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5402-250x150.jpg',
        title: 'Килимки в салон BMW E60 5-Series 2007-2010 V покоління EU/USA Sedan рест',
        titleKey: 'bmw36',
      },
      {
        id: '185',
        model: '5-Series',
        year: ['2003', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5400-250x150.jpg',
        title: 'Килимки в салон BMW E61 5-Series 2003-2010 V покоління EU Universal',
        titleKey: 'bmw37',
      },
      {
        id: '186',
        model: '5-Series',
        year: ['2003', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5401-250x150.jpg',
        title: 'Килимки в салон BMW E61 5-Series 2003-2010 V покоління EU Universal xDrive',
        titleKey: 'bmw38',
      },
      {
        id: '187',
        model: '6-Series',
        year: ['2003', '2010'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5416-250x150.jpg',
        title: 'Килимки в салон BMW E63 6-Series 2003-2010 II покоління EU Cabriolet',
        titleKey: 'bmw39',
      },
      {
        id: '188',
        model: '6-Series',
        year: ['2003', '2011'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5415-250x150.jpg',
        title: 'Килимки в салон BMW E63 6-Series 2003-2011 II покоління EU Coupe',
        titleKey: 'bmw40',
      },

      {
        id: '189',
        model: '7-Series',
        year: ['2001', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5428-250x150.jpg',
        title: 'Килимки в салон BMW E65 7-Series 2001-2008 IV покоління EU Sedan',
        titleKey: 'bmw41',
      },
      {
        id: '190',
        model: '7-Series',
        year: ['2001', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5429-250x150.jpg',
        title: 'Килимки в салон BMW E66 7-Series 2001-2008 IV покоління EU Sedan Long',
        titleKey: 'bmw42',
      },
      {
        id: '191',
        model: '3-Series',
        year: ['2005', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5374-250x150.jpg',
        title: 'Килимки в салон BMW E90 3-Series 2005-2013 V покоління EU Sedan',
        titleKey: 'bmw43',
      },
      {
        id: '192',
        model: '3-Series',
        year: ['2005', '2013'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5375-250x150.jpg',
        title: 'Килимки в салон BMW E91 3-Series 2005-2013 V покоління EU Universal',
        titleKey: 'bmw44',
      },
      {
        id: '193',
        model: '3-Series',
        year: ['2005', '2010'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5377-250x150.jpg',
        title: 'Килимки в салон BMW E92 3-Series 2005-2010 V покоління EU Coupe AWD',
        titleKey: 'bmw45',
      },
      {
        id: '194',
        model: '3-Series',
        year: ['2005', '2013'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5376-250x150.jpg',
        title: 'Килимки в салон BMW E92 3-Series 2005-2013 V покоління EU Coupe',
        titleKey: 'bmw46',
      },
      {
        id: '195',
        model: '3-Series',
        year: ['2005', '2013'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5378-250x150.jpg',
        title: 'Килимки в салон BMW E93 3-Series 2005-2013 V покоління EU Cabriolet',
        titleKey: 'bmw47',
      },
      {
        id: '196',
        model: '7-Series',
        year: ['2008', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5431-250x150.jpg',
        title: 'Килимки в салон BMW F01 7-Series 2008-2015 V покоління EU Sedan',
        titleKey: 'bmw48',
      },
      {
        id: '197',
        model: '7-Series',
        year: ['2008', '2015'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5430-250x150.jpg',
        title: 'Килимки в салон BMW F01 7-Series 2008-2015 V покоління EU/USA Sedan xDrive',
        titleKey: 'bmw49',
      },
      {
        id: '198',
        model: '7-Series',
        year: ['2008', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5433-250x150.jpg',
        title: 'Килимки в салон BMW F02 7-Series 2008-2015 V покоління EU Sedan Long',
        titleKey: 'bmw50',
      },

      {
        id: '199',
        model: '7-Series',
        year: ['2008', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5432-250x150.jpg',
        title: 'Килимки в салон BMW F02 7-Series 2008-2015 V покоління EU Sedan Long/xDrive',
        titleKey: 'bmw51',
      },
      {
        id: '200',
        model: '6-Series',
        year: ['2011', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5422-250x150.jpg',
        title: 'Килимки в салон BMW F06 6-Series Gran Coupe 2011-2018 III покоління EU Sedan',
        titleKey: 'bmw52',
      },
      {
        id: '201',
        model: '6-Series',
        year: ['2011', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5421-250x150.jpg',
        title: 'Килимки в салон BMW F06 6-Series Gran Coupe 2011-2018 III покоління EU Sedan xDrive',
        titleKey: 'bmw53',
      },
      {
        id: '202',
        model: '5-Series',
        year: ['2009', '2013'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5403-250x150.jpg',
        title: 'Килимки в салон BMW F07 5-Series Gran Turismo 2009-2013 VI покоління EU Liftback дорест',
        titleKey: 'bmw54',
      },
      {
        id: '203',
        model: '5-Series',
        year: ['2013', '2017'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5409-250x150.jpg',
        title: 'Килимки в салон BMW F07 5-Series Gran Turismo 2013-2017 VI покоління EU Liftback рест',
        titleKey: 'bmw55',
      },
      {
        id: '204',
        model: '5-Series',
        year: ['2010', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5404-250x150.jpg',
        title: 'Килимки в салон BMW F10 5-Series 2010-2013 VI покоління EU Sedan дорест',
        titleKey: 'bmw56',
      },
      {
        id: '205',
        model: '5-Series',
        year: ['2010', '2013'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/bmw/5405-250x150.jpg',
        title: 'Килимки в салон BMW F10 5-Series 2010-2013 VI покоління USA Sedan дорест',
        titleKey: 'bmw57',
      },
      {
        id: '206',
        model: '5-Series',
        year: ['2010', '2013'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/bmw/5406-250x150.jpg',
        title: 'Килимки в салон BMW F10 5-Series 2010-2013 VI покоління USA Sedan дорест xDrive',
        titleKey: 'bmw58',
      },
      {
        id: '207',
        model: '5-Series',
        year: ['2013', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5410-250x150.jpg',
        title: 'Килимки в салон BMW F10 5-Series 2013-2017 VI покоління EU Sedan рест',
        titleKey: 'bmw59',
      },
      {
        id: '208',
        model: '5-Series',
        year: ['2013', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/bmw/5411-250x150.jpg',
        title: 'Килимки в салон BMW F10 5-Series 2013-2017 VI покоління USA Sedan рест',
        titleKey: 'bmw60',
      },

      {
        id: '209',
        model: '5-Series',
        year: ['2013', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/bmw/5412-250x150.jpg',
        title: 'Килимки в салон BMW F10 5-Series 2013-2017 VI покоління USA Sedan рест xDrive',
        titleKey: 'bmw61',
      },
      {
        id: '210',
        model: '5-Series',
        year: ['2010', '2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5407-250x150.jpg',
        title: 'Килимки в салон BMW F11 5-Series 2010-2017 VI покоління EU Universal',
        titleKey: 'bmw62',
      },
      {
        id: '211',
        model: '5-Series',
        year: ['2010', '2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/bmw/5408-250x150.jpg',
        title: 'Килимки в салон BMW F11 5-Series 2010-2017 VI покоління EU Universal xDrive',
        titleKey: 'bmw63',
      },
      {
        id: '212',
        model: '6-Series',
        year: ['2011', '2017'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5420-250x150.jpg',
        title: 'Килимки в салон BMW F12 6-Series 2011-2017 III покоління EU Cabriolet',
        titleKey: 'bmw64',
      },
      {
        id: '213',
        model: '6-Series',
        year: ['2011', '2017'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5417-250x150.jpg',
        title: 'Килимки в салон BMW F13 6-Series 2011-2017 III покоління EU Cabriolet xDrive',
        titleKey: 'bmw65',
      },
      {
        id: '214',
        model: '6-Series',
        year: ['2011', '2017'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5419-250x150.jpg',
        title: 'Килимки в салон BMW F13 6-Series 2011-2017 III покоління EU Coupe',
        titleKey: 'bmw66',
      },
      {
        id: '215',
        model: '6-Series',
        year: ['2011', '2017'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5418-250x150.jpg',
        title: 'Килимки в салон BMW F13 6-Series 2011-2017 III покоління EU Coupe xDrive',
        titleKey: 'bmw67',
      },
      {
        id: '216',
        model: '3-Series',
        year: ['2011', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5380-250x150.jpg',
        title: 'Килимки в салон BMW F30 3-Series 2011-2019 VI покоління EU Sedan',
        titleKey: 'bmw68',
      },
      {
        id: '217',
        model: '3-Series',
        year: ['2011', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5379-250x150.jpg',
        title: 'Килимки в салон BMW F30 3-Series 2011-2019 VI покоління EU Sedan AWD',
        titleKey: 'bmw69',
      },
      {
        id: '218',
        model: '3-Series',
        year: ['2011', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/bmw/5381-250x150.jpg',
        title: 'Килимки в салон BMW F30 3-Series 2011-2019 VI покоління USA Sedan',
        titleKey: 'bmw70',
      },

      {
        id: '219',
        model: '3-Series',
        year: ['2011', '2019'],
        body: 'Universal',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5382-250x150.jpg',
        title: 'Килимки в салон BMW F31 3-Series 2011-2019 VI покоління EU/USA Universal',
        titleKey: 'bmw71',
      },
      {
        id: '220',
        model: '3-Series',
        year: ['2011', '2019'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/bmw/5383-250x150.jpg',
        title: 'Килимки в салон BMW F31 3-Series 2011-2019 VI покоління USA Universal AWD',
        titleKey: 'bmw72',
      },
      {
        id: '221',
        model: '4-Series',
        year: ['2013', '2020'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5389-250x150.jpg',
        title: 'Килимки в салон BMW F32 4-Series 2013-2020 I покоління EU Coupe',
        titleKey: 'bmw73',
      },
      {
        id: '222',
        model: '4-Series',
        year: ['2013', '2020'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5388-250x150.jpg',
        title: 'Килимки в салон BMW F32 4-Series 2013-2020 I покоління EU Coupe xDrive',
        titleKey: 'bmw74',
      },
      {
        id: '223',
        model: '4-Series',
        year: ['2013', '2020'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5386-250x150.jpg',
        title: 'Килимки в салон BMW F33 4-Series 2013-2020 I покоління EU Cabriolet',
        titleKey: 'bmw75',
      },
      {
        id: '224',
        model: '4-Series',
        year: ['2013', '2020'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/bmw/5387-250x150.jpg',
        title: 'Килимки в салон BMW F33 4-Series 2013-2020 I покоління EU Cabriolet xDrive',
        titleKey: 'bmw76',
      },
      {
        id: '225',
        model: '3-Series',
        year: ['2013', '2019'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5384-250x150.jpg',
        title: 'Килимки в салон BMW F34 3-Series Gran Turisma 2013-2019 VI покоління EU Liftback 5-ти дверна xDrive',
        titleKey: 'bmw77',
      },
      {
        id: '226',
        model: '4-Series',
        year: ['2013', '2020'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5390-250x150.jpg',
        title: 'Килимки в салон BMW F36 Gran Coupe 4-Series 2013-2020 I покоління EU/USA Sedan',
        titleKey: 'bmw78',
      },
      {
        id: '227',
        model: '4-Series',
        year: ['2013', '2020'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5391-250x150.jpg',
        title: 'Килимки в салон BMW F36 Gran Coupe 4-Series 2013-2020 I покоління EU/USA Sedan xDrive',
        titleKey: 'bmw79',
      },
      {
        id: '228',
        model: '7-Series',
        year: ['2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5434-250x150.jpg',
        title: 'Килимки в салон BMW G11 7-Series 2015-… VI покоління EU Sedan Long',
        titleKey: 'bmw80',
      },

      {
        id: '229',
        model: '7-Series',
        year: ['2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5435-250x150.jpg',
        title: 'Килимки в салон BMW G12 7-Series 2015-… VI покоління EU Sedan',
        titleKey: 'bmw81',
      },
      {
        id: '230',
        model: '3-Series',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5385-250x150.jpg',
        title: 'Килимки в салон BMW G20 3-Series 2018-… VII покоління EU/USA Sedan',
        titleKey: 'bmw82',
      },
      {
        id: '231',
        model: '4-Series',
        year: ['2020'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5392-250x150.jpg',
        title: 'Килимки в салон BMW G22 4-Series 2020-… II покоління EU Coupe',
        titleKey: 'bmw83',
      },
      {
        id: '232',
        model: '5-Series',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5414-250x150.jpg',
        title: 'Килимки в салон BMW G30 5-Series 2017-… VII покоління EU Sedan',
        titleKey: 'bmw84',
      },
      {
        id: '233',
        model: '5-Series',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5413-250x150.jpg',
        title: 'Килимки в салон BMW G30 5-Series 2017-… VII покоління EU Sedan Plug-in Hybrid',
        titleKey: 'bmw85',
      },
      {
        id: '234',
        model: '6-Series',
        year: ['2017'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5423-250x150.jpg',
        title: 'Килимки в салон BMW G32 6-Series Gran Turismo 2017-… IV покоління EU Liftback',
        titleKey: 'bmw86',
      },
      {
        id: '235',
        model: 'I3',
        year: ['2013'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5437-250x150.jpg',
        title: 'Килимки в салон BMW I3 2013-… I покоління EU/USA Hatchback',
        titleKey: 'bmw87',
      },
      {
        id: '236',
        model: 'I3',
        year: ['2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5436-250x150.jpg',
        title: 'Килимки в салон BMW I3 Range Extender 2013-… I покоління EU Hatchback',
        titleKey: 'bmw88',
      },
      {
        id: '237',
        model: 'I3',
        year: ['2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/bmw/5438-250x150.jpg',
        title: 'Килимки в салон BMW I3 2017-… I покоління EU Hatchback',
        titleKey: 'bmw89',
      },
      {
        id: '238',
        model: 'iX',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5439-250x150.jpg',
        title: 'Килимки в салон BMW iX (120) xDrive 40 2021-… I покоління EU Crossover',
        titleKey: 'bmw90',
      },

      {
        id: '239',
        model: 'iX3',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5440-250x150.jpg',
        title: 'Килимки в салон BMW iX3 (G08) 2020-… I покоління EU Crossover',
        titleKey: 'bmw91',
      },
      {
        id: '240',
        model: 'Rover 75',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/bmw/5441-250x150.jpg',
        title: 'Килимки в салон BMW Rover 75 1998-2005 I покоління EU Sedan',
        titleKey: 'bmw92',
      },
      {
        id: '241',
        model: 'X1',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5442-250x150.jpg',
        title: 'Килимки в салон BMW X1 E84 2009-2015 I покоління EU Crossover',
        titleKey: 'bmw93',
      },
      {
        id: '242',
        model: 'X1',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5443-250x150.jpg',
        title: 'Килимки в салон BMW X1 E84 2009-2015 I покоління EU Crossover xDrive',
        titleKey: 'bmw94',
      },
      {
        id: '243',
        model: 'X1',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5444-250x150.jpg',
        title: 'Килимки в салон BMW X1 F48 2015-2022 II покоління EU Crossover',
        titleKey: 'bmw95',
      },
      {
        id: '244',
        model: 'X2',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5445-250x150.jpg',
        title: 'Килимки в салон BMW X2 F39 2017-… I покоління EU/USA Crossover',
        titleKey: 'bmw96',
      },
      {
        id: '245',
        model: 'X3',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон BMW X3 30e G01 2017-… III покоління EU/USA Crossover hybrid xDrive',
        titleKey: 'bmw97',
      },
      {
        id: '246',
        model: 'X3',
        year: ['2003', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5446-250x150.jpg',
        title: 'Килимки в салон BMW X3 E83 2003-2010 I покоління EU Crossover',
        titleKey: 'bmw98',
      },
      {
        id: '247',
        model: 'X3',
        year: ['2006', '2013'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/bmw/5447-250x150.jpg',
        title: 'Килимки в салон BMW X3 E83 2006-2013 I покоління USA Crossover',
        titleKey: 'bmw99',
      },
      {
        id: '248',
        model: 'X3',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5449-250x150.jpg',
        title: 'Килимки в салон BMW X3 F25 2010-2017 II покоління EU Crossover',
        titleKey: 'bmw100',
      },

      {
        id: '249',
        model: 'X3',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/bmw/5448-250x150.jpg',
        title: 'Килимки в салон BMW X3 F25 2010-2017 II покоління USA Crossover',
        titleKey: 'bmw101',
      },
      {
        id: '250',
        model: 'X3',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5450-250x150.jpg',
        title: 'Килимки в салон BMW X3 G01 2017-… III покоління EU/USA Crossover',
        titleKey: 'bmw102',
      },
      {
        id: '251',
        model: 'X4',
        year: ['2014', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5451-250x150.jpg',
        title: 'Килимки в салон BMW X4 F26 2014-2018 I покоління EU Crossover',
        titleKey: 'bmw103',
      },
      {
        id: '252',
        model: 'X4',
        year: ['2014', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/bmw/5452-250x150.jpg',
        title: 'Килимки в салон BMW X4 F26 2014-2018 I покоління USA Crossover',
        titleKey: 'bmw104',
      },
      {
        id: '253',
        model: 'X4',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5453-250x150.jpg',
        title: 'Килимки в салон BMW X4 G02 2018-… II покоління EU/USA Crossover',
        titleKey: 'bmw105',
      },
      {
        id: '254',
        model: 'X5',
        year: ['1996', '2006'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5454-250x150.jpg',
        title: 'Килимки в салон BMW X5 E53 1996-2006 I покоління EU/USA Crossover',
        titleKey: 'bmw106',
      },
      {
        id: '255',
        model: 'X5',
        year: ['2007', '2013'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5455-250x150.jpg',
        title: 'Килимки в салон BMW X5 E70 2007-2013 II покоління EU/USA Crossover',
        titleKey: 'bmw107',
      },
      {
        id: '256',
        model: 'X5',
        year: ['2013', '2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5456-250x150.jpg',
        title: 'Килимки в салон BMW X5 F15 2013-2018 III покоління EU/USA Crossover 5-ти місна',
        titleKey: 'bmw108',
      },
      {
        id: '257',
        model: 'X5',
        year: ['2013', '2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5457-250x150.jpg',
        title: 'Килимки в салон BMW X5 F15 2013-2018 III покоління EU/USA Crossover 7-ми місна',
        titleKey: 'bmw109',
      },
      {
        id: '258',
        model: 'X5',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5458-250x150.jpg',
        title: 'Килимки в салон BMW X5 G05 2018-… IV покоління EU Crossover',
        titleKey: 'bmw110',
      },

      {
        id: '259',
        model: 'X6',
        year: ['2008', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5459-250x150.jpg',
        title: 'Килимки в салон BMW X6 E71 2008-2012 I покоління EU/USA Crossover дорест',
        titleKey: 'bmw111',
      },
      {
        id: '260',
        model: 'X6',
        year: ['2012', '2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/bmw/5460-250x150.jpg',
        title: 'Килимки в салон BMW X6 E71 2012-2014 I покоління EU/USA Crossover рест',
        titleKey: 'bmw112',
      },
      {
        id: '261',
        model: 'X6',
        year: ['2014', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5461-250x150.jpg',
        title: 'Килимки в салон BMW X6 F16 2014-2019 II покоління EU Crossover',
        titleKey: 'bmw113',
      },
      {
        id: '262',
        model: 'X6',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU/USA ',
        img: '/images/catalog/models/bmw/5462-250x150.jpg',
        title: 'Килимки в салон BMW X6 G06 2019-… III покоління EU/USA Crossover',
        titleKey: 'bmw114',
      },
      {
        id: '263',
        model: 'X7',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/bmw/5463-250x150.jpg',
        title: 'Килимки в салон BMW X7 G07 2018-… I покоління EU Crossover 7-ми місна',
        titleKey: 'bmw115',
      },
      {
        id: '264',
        model: 'Z3',
        year: ['1995', '2002'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5464-250x150.jpg',
        title: 'Килимки в салон BMW Z3 1995-2002 I покоління EU Coupe',
        titleKey: 'bmw116',
      },
      {
        id: '265',
        model: 'Z4',
        year: ['2009', '2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/bmw/5465-250x150.jpg',
        title: 'Килимки в салон BMW Z4 E89 2009-2016 II покоління EU Coupe',
        titleKey: 'bmw117',
      },
    ],
  },
  /* ---------------------------------- Buick --------------------------------- */
  Buick: {
    logo: '/images/catalog/logo/Buick-100x100.png',
    name: 'Buick',
    models: [
      {
        id: '266',
        model: 'Enclave',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/buick/5682-250x150.jpg',
        title: 'Килимки в салон Buick Enclave 2012-2017 I покоління USA Crossover рест 7-ми місна',
        titleKey: 'buick1',
      },
      {
        id: '267',
        model: 'Enclave',
        year: ['2017', '2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/buick/5683-250x150.jpg',
        title: 'Килимки в салон Buick Enclave 2017-2021 II покоління USA Crossover 7-ми місна',
        titleKey: 'buick2',
      },
      {
        id: '268',
        model: 'Encore',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/buick/5685-250x150.jpg',
        title: 'Килимки в салон Buick Encore (GX) 2019-… II покоління USA Crossover',
        titleKey: 'buick3',
      },
      {
        id: '269',
        model: 'Encore',
        year: ['2012', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/buick/5684-250x150.jpg',
        title: 'Килимки в салон Buick Encore 2012-2019 I покоління USA Crossover',
        titleKey: 'buick4',
      },
      {
        id: '270',
        model: 'Envision',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'EUUSA',
        img: '/images/catalog/models/buick/5686-250x150.jpg',
        title: 'Килимки в салон Buick Envision 2016-2020 I покоління EU/USA Crossover',
        titleKey: 'buick5',
      },
      {
        id: '271',
        model: 'Regal',
        year: ['2008', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/buick/5687-250x150.jpg',
        title: 'Килимки в салон Buick Regal 2008-2017 V покоління EU Sedan',
        titleKey: 'buick6',
      },
      {
        id: '272',
        model: 'Regal',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/buick/5688-250x150.jpg',
        title: 'Килимки в салон Buick Regal Tour X 2017-… VI покоління EU Crossover',
        titleKey: 'buick7',
      },
    ],
  },
  /* ----------------------------------- Byd ---------------------------------- */
  Byd: {
    logo: '/images/catalog/logo/byd-100x100.png',
    name: 'BYD',
    models: [
      {
        id: '273',
        model: 'Dolphin',
        year: ['2021'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон BYD Dolphin 2021-… China Hatchback',
        titleKey: 'byd1',
      },
      {
        id: '274',
        model: 'E2',
        year: ['2019'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/byd/5666-250x150.jpg',
        title: 'Килимки в салон BYD E2 2019-… I покоління China Hatchback',
        titleKey: 'byd2',
      },
      {
        id: '275',
        model: 'E5',
        year: ['2015', '2020'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/byd/5667-250x150.jpg',
        title: 'Килимки в салон BYD E5 2015-2020 I покоління China Sedan',
        titleKey: 'byd3',
      },
      {
        id: '276',
        model: 'F0',
        year: ['2008', '2014'],
        body: 'Hatchback',
        market: 'RU',
        img: '/images/catalog/models/byd/5668-250x150.jpg',
        title: 'Килимки в салон BYD F0 2008-2014 I покоління RU Hatchback',
        titleKey: 'byd4',
      },
      {
        id: '277',
        model: 'F3',
        year: ['2005', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/byd/5669-250x150.jpg',
        title: 'Килимки в салон BYD F3 2005-2013 I покоління EU Sedan',
        titleKey: 'byd5',
      },
      {
        id: '278',
        model: 'Han',
        year: ['2020'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/byd/5670-250x150.jpg',
        title: 'Килимки в салон BYD Han EV 2020-… China Sedan',
        titleKey: 'byd6',
      },
      {
        id: '279',
        model: 'New F3',
        year: ['2020'],
        body: 'Sedan',
        market: 'UA',
        img: '/images/catalog/models/byd/5671-250x150.jpg',
        title: 'Килимки в салон BYD New F3 2013-… I покоління UA Sedan',
        titleKey: 'byd7',
      },
      {
        id: '280',
        model: 'S6',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/byd/5672-250x150.jpg',
        title: 'Килимки в салон BYD S6 2010-2017 I покоління EU Crossover',
        titleKey: 'byd8',
      },
      {
        id: '281',
        model: 'Seagull',
        year: ['2023'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/byd/5991-250x150.jpg',
        title: 'Килимки в салон BYD Seagull 2023-… I покоління China Hatchback',
        titleKey: 'byd9',
      },
      {
        id: '282',
        model: 'Song',
        year: ['2023'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон BYD Song L 2023-… I покоління China Crossover',
        titleKey: 'byd10',
      },

      {
        id: '283',
        model: 'Song',
        year: ['2020'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/byd/5673-250x150.jpg',
        title: 'Килимки в салон BYD Song Plus 2020-… II покоління China Crossover',
        titleKey: 'byd11',
      },
      {
        id: '284',
        model: 'Tang',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/byd/5674-250x150.jpg',
        title: 'Килимки в салон BYD Tang (EV) 2018-… II покоління EU Crossover 6-ти місна',
        titleKey: 'byd12',
      },
      {
        id: '285',
        model: 'Tang',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/byd/5675-250x150.jpg',
        title: 'Килимки в салон BYD Tang (EV) 2018-… II покоління EU Crossover 7-ми місна',
        titleKey: 'byd13',
      },
      {
        id: '286',
        model: 'Yuan',
        year: ['2016', '2021'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/byd/5676-250x150.jpg',
        title: 'Килимки в салон BYD Yuan EV360 2016-2021 I покоління China Crossover',
        titleKey: 'byd14',
      },
      {
        id: '287',
        model: 'Yuan',
        year: ['2021'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/byd/5679-250x150.jpg',
        title: 'Килимки в салон BYD Yuan Plus 2021-… II покоління China Crossover',
        titleKey: 'byd15',
      },
      {
        id: '288',
        model: 'Yuan',
        year: ['2021'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/byd/5678-250x150.jpg',
        title: 'Килимки в салон BYD Yuan Pro 2021-… I покоління China Crossover',
        titleKey: 'byd16',
      },
      {
        id: '289',
        model: 'Yuan',
        year: ['2016', '2021'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/byd/5677-250x150.jpg',
        title: 'Килимки в салон BYD Yuan S1 EV 2016-2021 I покоління China Crossover',
        titleKey: 'byd17',
      },
    ],
  },
  /* -------------------------------- Cadillac -------------------------------- */
  Cadillac: {
    logo: '/images/catalog/logo/cadillac-100x100.png',
    name: 'Cadillac',
    models: [
      {
        id: '290',
        model: 'ATS',
        year: ['2012', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5807-250x150.jpg',
        title: 'Килимки в салон Cadillac ATS 2012-2019 I покоління USA Sedan',
        titleKey: 'cadillac1',
      },
      {
        id: '291',
        model: 'BLS',
        year: ['2006', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/cadillac/5808-250x150.jpg',
        title: 'Килимки в салон Cadillac BLS 2006-2009 I покоління EU Sedan',
        titleKey: 'cadillac2',
      },
      {
        id: '292',
        model: 'CTS',
        year: ['2007', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/cadillac/5809-250x150.jpg',
        title: 'Килимки в салон Cadillac CTS 2007-2013 II покоління EU Sedan',
        titleKey: 'cadillac3',
      },
      {
        id: '293',
        model: 'CTS',
        year: ['2013', '2019'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/cadillac/5810-250x150.jpg',
        title: 'Килимки в салон Cadillac CTS 2013-2019 III покоління EU/USA Sedan',
        titleKey: 'cadillac4',
      },
      {
        id: '294',
        model: 'ELR',
        year: ['2013', '2016'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5811-250x150.jpg',
        title: 'Килимки в салон Cadillac ELR 2013-2016 I покоління USA Coupe',
        titleKey: 'cadillac5',
      },
      {
        id: '295',
        model: 'Escalade',
        year: ['2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5816-250x150.jpg',
        title: 'Килимки в салон Cadillac Escalade (GMT K2XL) 2014-… IV покоління USA Crossover 7-ми місна',
        titleKey: 'cadillac6',
      },
      {
        id: '296',
        model: 'Escalade',
        year: ['2007', '2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5814-250x150.jpg',
        title: 'Килимки в салон Cadillac Escalade (GMT900) 2007-2014 III покоління USA Crossover 5-ти місна',
        titleKey: 'cadillac7',
      },
      {
        id: '297',
        model: 'Escalade',
        year: ['2007', '2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5815-250x150.jpg',
        title: 'Килимки в салон Cadillac Escalade (GMT900) 2007-2014 III покоління USA Crossover 6-ти місна',
        titleKey: 'cadillac8',
      },
      {
        id: '298',
        model: 'Escalade',
        year: ['2007', '2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5813-250x150.jpg',
        title: 'Килимки в салон Cadillac Escalade (GMT900) 2007-2014 III покоління USA Crossover 7-ми місна',
        titleKey: 'cadillac9',
      },
      {
        id: '299',
        model: 'Escalade',
        year: ['2007', '2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5812-250x150.jpg',
        title: 'Килимки в салон Cadillac Escalade (GMT900) 2007-2014 III покоління USA Crossover 8-ми місна',
        titleKey: 'cadillac10',
      },

      {
        id: '300',
        model: 'Fleetwood',
        year: ['1993', '1996'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5817-250x150.jpg',
        title: 'Килимки в салон Cadillac Fleetwood 1993-1996 II покоління USA Sedan',
        titleKey: 'cadillac11',
      },
      {
        id: '301',
        model: 'SRX',
        year: ['2004', '2010'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5818-250x150.jpg',
        title: 'Килимки в салон Cadillac SRX 2004-2010 I покоління USA Crossover',
        titleKey: 'cadillac12',
      },
      {
        id: '302',
        model: 'SRX',
        year: ['2010'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5819-250x150.jpg',
        title: 'Килимки в салон Cadillac SRX 2010-… II покоління USA Crossover',
        titleKey: 'cadillac13',
      },
      {
        id: '303',
        model: 'XT4',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5820-250x150.jpg',
        title: 'Килимки в салон Cadillac XT4 2018-… USA Crossover',
        titleKey: 'cadillac14',
      },
      {
        id: '304',
        model: 'XT5',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5821-250x150.jpg',
        title: 'Килимки в салон Cadillac XT5 2016-… I покоління USA Crossover',
        titleKey: 'cadillac15',
      },
      {
        id: '305',
        model: 'XTS',
        year: ['2012', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/cadillac/5822-250x150.jpg',
        title: 'Килимки в салон Cadillac XTS 2012-2019 USA Sedan',
        titleKey: 'cadillac16',
      },
    ],
  },
  /* ---------------------------------- Chana --------------------------------- */
  Chana: {
    logo: '/images/catalog/logo/Chana-100x100.png',
    name: 'Chana',
    models: [
      {
        id: '306',
        model: 'Benni',
        year: ['2007', '2009'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/chana/5805-250x150.jpg',
        title: 'Килимки в салон Chana Benni 2007-2009 I покоління China Hatchback',
        titleKey: 'chana1',
      },
      {
        id: '307',
        model: 'Benni mini',
        year: ['2007', '2009'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/chana/5806-250x150.jpg',
        title: 'Килимки в салон Chana Benni mini 2007-2009 I покоління China Hatchback',
        titleKey: 'chana2',
      },
    ],
  },
  /* ---------------------------------- Chery --------------------------------- */
  Chery: {
    logo: '/images/catalog/logo/chery-100x100.png',
    name: 'Chery',
    models: [
      {
        id: '308',
        model: 'A13',
        year: ['2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chery/5716-250x150.jpg',
        title: 'Килимки в салон Chery A13 2008-… I покоління EU Hatchback',
        titleKey: 'chery1',
      },
      {
        id: '309',
        model: 'Amulet',
        year: ['2003', '2014'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/chery/5717-250x150.jpg',
        title: 'Килимки в салон Chery Amulet 2003-2014 I покоління EU Liftback',
        titleKey: 'chery2',
      },
      {
        id: '310',
        model: 'Beat',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5718-250x150.jpg',
        title: 'Килимки в салон Chery Beat 2009-2015 I покоління EU Crossover',
        titleKey: 'chery3',
      },
      {
        id: '311',
        model: 'E5',
        year: ['2011', '2016'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/chery/5719-250x150.jpg',
        title: 'Килимки в салон Chery E5 2011-2016 I покоління China Sedan',
        titleKey: 'chery4',
      },
      {
        id: '312',
        model: 'Eastar',
        year: ['2003', '2011'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/chery/5720-250x150.jpg',
        title: 'Килимки в салон Chery Eastar 2003-2011 I покоління China Sedan',
        titleKey: 'chery5',
      },
      {
        id: '313',
        model: 'Elara A5',
        year: ['2006'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/chery/5721-250x150.jpg',
        title: 'Килимки в салон Chery Elara A5 2006-… I покоління EU Sedan',
        titleKey: 'chery6',
      },
      {
        id: '314',
        model: 'Jaggi',
        year: ['2006'],
        body: 'Liftback',
        market: 'China',
        img: '/images/catalog/models/chery/5722-250x150.jpg',
        title: 'Килимки в салон Chery Jaggi (QQ6) 2006-… I покоління China Liftback',
        titleKey: 'chery7',
      },
      {
        id: '315',
        model: 'Jetour',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/chery/5723-250x150.jpg',
        title: 'Килимки в салон Chery Jetour (X70) 2018-… I покоління China Crossover 7-ми місна',
        titleKey: 'chery8',
      },
      {
        id: '316',
        model: 'Kimo',
        year: ['2007'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chery/5724-250x150.jpg',
        title: 'Килимки в салон Chery Kimo (A1) 2007-… I покоління EU Hatchback',
        titleKey: 'chery9',
      },
      {
        id: '317',
        model: 'M11',
        year: ['2008', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chery/5725-250x150.jpg',
        title: 'Килимки в салон Chery M11 (A3) 2008-2015 I покоління EU Hatchback',
        titleKey: 'chery10',
      },

      {
        id: '318',
        model: 'M11',
        year: ['2008', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chery/5726-250x150.jpg',
        title: 'Килимки в салон Chery M11 (A3) 2008-2015 I покоління EU Sedan',
        titleKey: 'chery11',
      },
      {
        id: '319',
        model: 'QQ',
        year: ['2003', '2013'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/chery/5727-250x150.jpg',
        title: 'Килимки в салон Chery QQ (S11) 2003-2013 I покоління China Hatchback',
        titleKey: 'chery12',
      },
      {
        id: '320',
        model: 'Tiggo',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5731-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 2 2016-… I покоління EU Crossover',
        titleKey: 'chery13',
      },
      {
        id: '321',
        model: 'Tiggo',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5735-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 2 Pro 2020-… I покоління EU Crossover',
        titleKey: 'chery14',
      },
      {
        id: '322',
        model: 'Tiggo',
        year: ['2005', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5728-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 2005-2014 I покоління EU Crossover',
        titleKey: 'chery15',
      },
      {
        id: '323',
        model: 'Tiggo',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5730-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 3 2014-2020 I покоління EU Crossover',
        titleKey: 'chery16',
      },
      {
        id: '324',
        model: 'Tiggo',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5733-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 4 2017-… I покоління EU Crossover',
        titleKey: 'chery17',
      },
      {
        id: '325',
        model: 'Tiggo',
        year: ['2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5729-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 5 2013-… I покоління EU Crossover',
        titleKey: 'chery18',
      },
      {
        id: '326',
        model: 'Tiggo',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5732-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 7 2016-2020 I покоління EU Crossover',
        titleKey: 'chery19',
      },
      {
        id: '327',
        model: 'Tiggo',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5736-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 7 2020-… II покоління EU Crossover',
        titleKey: 'chery20',
      },
      {
        id: '328',
        model: 'Tiggo',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5737-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 7 Pro 2020-… II покоління EU Crossover',
        titleKey: 'chery21',
      },
      {
        id: '329',
        model: 'Tiggo',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chery/5734-250x150.jpg',
        title: 'Килимки в салон Chery Tiggo 8 2018-… I покоління EU Crossover 7-ми місна',
        titleKey: 'chery22',
      },
    ],
  },
  /* -------------------------------- Chevrolet ------------------------------- */
  Chevrolet: {
    logo: '/images/catalog/logo/chevrolet-100x100.png',
    name: 'Chevrolet',
    models: [
      {
        id: '330',
        model: 'Aveo',
        year: ['2002', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5740-250x150.jpg',
        title: 'Килимки в салон Chevrolet Aveo (T200) 2002-2008 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'chevrolet1',
      },
      {
        id: '331',
        model: 'Aveo',
        year: ['2002', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5739-250x150.jpg',
        title: 'Килимки в салон Chevrolet Aveo (T200) 2002-2008 I покоління EU Sedan',
        titleKey: 'chevrolet2',
      },
      {
        id: '332',
        model: 'Aveo',
        year: ['2005', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5743-250x150.jpg',
        title: 'Килимки в салон Chevrolet Aveo (T250) 2005-2011 II покоління EU Hatchback 3-х дверна',
        titleKey: 'chevrolet3',
      },
      {
        id: '333',
        model: 'Aveo',
        year: ['2005', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5742-250x150.jpg',
        title: 'Килимки в салон Chevrolet Aveo (T250) 2005-2011 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'chevrolet4',
      },
      {
        id: '334',
        model: 'Aveo',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5741-250x150.jpg',
        title: 'Килимки в салон Chevrolet Aveo (T250) 2005-2011 II покоління EU Sedan',
        titleKey: 'chevrolet5',
      },
      {
        id: '335',
        model: 'Aveo',
        year: ['2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5745-250x150.jpg',
        title: 'Килимки в салон Chevrolet Aveo (T300) 2011-… III покоління EU Hatchback',
        titleKey: 'chevrolet6',
      },
      {
        id: '336',
        model: 'Aveo',
        year: ['2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5744-250x150.jpg',
        title: 'Килимки в салон Chevrolet Aveo (T300) 2011-… III покоління EU Sedan',
        titleKey: 'chevrolet7',
      },
      {
        id: '337',
        model: 'Blazer',
        year: ['1983', '2005'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5746-250x150.jpg',
        title: 'Килимки в салон Chevrolet Blazer 1983-2005 II покоління USA Crossover',
        titleKey: 'chevrolet8',
      },
      {
        id: '338',
        model: 'Bolt',
        year: ['2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5992-250x150.jpg',
        title: 'Килимки в салон Chevrolet Bolt EUV 2021-… I покоління USA Crossover',
        titleKey: 'chevrolet9',
      },
      {
        id: '339',
        model: 'Bolt',
        year: ['2016'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5747-250x150.jpg',
        title: 'Килимки в салон Chevrolet Bolt EV 2016-… I покоління USA Hatchback',
        titleKey: 'chevrolet10',
      },
      {
        id: '340',
        model: 'Camaro',
        year: ['2009', '2015'],
        body: 'Coupe',
        market: 'EU/USA',
        img: '/images/catalog/models/chevrolet/5748-250x150.jpg',
        title: 'Килимки в салон Chevrolet Camaro 2009-2015 V покоління EU/USA Coupe',
        titleKey: 'chevrolet11',
      },
      {
        id: '341',
        model: 'Camaro',
        year: ['2015'],
        body: 'Coupe',
        market: 'EU/USA',
        img: '/images/catalog/models/chevrolet/5749-250x150.jpg',
        title: 'Килимки в салон Chevrolet Camaro 2015-… VI покоління EU/USA Coupe',
        titleKey: 'chevrolet12',
      },

      {
        id: '342',
        model: 'Captiva',
        year: ['2006', '2011'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5751-250x150.jpg',
        title: 'Килимки в салон Chevrolet Captiva (C100) 2006-2011 I покоління EU Crossover дорест 5-ти місна',
        titleKey: 'chevrolet13',
      },
      {
        id: '343',
        model: 'Captiva',
        year: ['2006', '2011'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5750-250x150.jpg',
        title: 'Килимки в салон Chevrolet Captiva (C100) 2006-2011 I покоління EU Crossover дорест 7-ми місна',
        titleKey: 'chevrolet14',
      },
      {
        id: '344',
        model: 'Captiva',
        year: ['2011', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5753-250x150.jpg',
        title: 'Килимки в салон Chevrolet Captiva (С140) 2011-2018 I покоління EU Crossover рест 5-ти місна',
        titleKey: 'chevrolet15',
      },
      {
        id: '345',
        model: 'Captiva',
        year: ['2011', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5752-250x150.jpg',
        title: 'Килимки в салон Chevrolet Captiva (С140) 2011-2018 I покоління EU Crossover рест 7-ми місна',
        titleKey: 'chevrolet16',
      },
      {
        id: '346',
        model: 'Captiva',
        year: ['2011', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5754-250x150.jpg',
        title: 'Килимки в салон Chevrolet Captiva Sport 2011-2015 I покоління USA Crossover 5-ти місна',
        titleKey: 'chevrolet17',
      },
      {
        id: '347',
        model: 'Cobalt',
        year: ['2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5755-250x150.jpg',
        title: 'Килимки в салон Chevrolet Cobalt 2012-… II покоління EU Sedan',
        titleKey: 'chevrolet18',
      },
      {
        id: '348',
        model: 'Colorado',
        year: ['2013'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5756-250x150.jpg',
        title: 'Килимки в салон Chevrolet Colorado 2013-… II покоління USA Pickup',
        titleKey: 'chevrolet19',
      },
      {
        id: '349',
        model: 'Cruze',
        year: ['2009', '2016'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5758-250x150.jpg',
        title: 'Килимки в салон Chevrolet Cruze (J300) 2009-2016 II покоління EU Hatchback',
        titleKey: 'chevrolet20',
      },
      {
        id: '350',
        model: 'Cruze',
        year: ['2009', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5757-250x150.jpg',
        title: 'Килимки в салон Chevrolet Cruze (J300) 2009-2016 II покоління EU Sedan',
        titleKey: 'chevrolet21',
      },
      {
        id: '351',
        model: 'Cruze',
        year: ['2009', '2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5759-250x150.jpg',
        title: 'Килимки в салон Chevrolet Cruze (J300) 2009-2016 II покоління USA Sedan',
        titleKey: 'chevrolet22',
      },
      {
        id: '352',
        model: 'Cruze',
        year: ['2009', '2016'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5760-250x150.jpg',
        title: 'Килимки в салон Chevrolet Cruze (J300) 2009-2016 II покоління USA Universal',
        titleKey: 'chevrolet23',
      },
      {
        id: '353',
        model: 'Cruze',
        year: ['2016'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/chevrolet/5762-250x150.jpg',
        title: 'Килимки в салон Chevrolet Cruze (J400) 2016-… III покоління EU/USA Hatchback',
        titleKey: 'chevrolet24',
      },

      {
        id: '354',
        model: 'Cruze',
        year: ['2016'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/chevrolet/5761-250x150.jpg',
        title: 'Килимки в салон Chevrolet Cruze (J400) 2016-… III покоління EU/USA Sedan',
        titleKey: 'chevrolet25',
      },
      {
        id: '355',
        model: 'Epica',
        year: ['2006', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5763-250x150.jpg',
        title: 'Килимки в салон Chevrolet Epica 2006-2014 I покоління EU Sedan',
        titleKey: 'chevrolet26',
      },
      {
        id: '356',
        model: 'Equinox',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5764-250x150.jpg',
        title: 'Килимки в салон Chevrolet Equinox 2009-2015 II покоління USA Crossover дорест',
        titleKey: 'chevrolet27',
      },
      {
        id: '357',
        model: 'Equinox',
        year: ['2015', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5765-250x150.jpg',
        title: 'Килимки в салон Chevrolet Equinox 2015-2017 II покоління USA Crossover рест',
        titleKey: 'chevrolet28',
      },
      {
        id: '358',
        model: 'Equinox',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5766-250x150.jpg',
        title: 'Килимки в салон Chevrolet Equinox 2017-… III покоління USA Crossover',
        titleKey: 'chevrolet29',
      },
      {
        id: '359',
        model: 'Evanda',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5767-250x150.jpg',
        title: 'Килимки в салон Chevrolet Evanda 2000-2006 I покоління EU Sedan',
        titleKey: 'chevrolet30',
      },
      {
        id: '360',
        model: 'Express',
        year: ['2003', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5768-250x150.jpg',
        title: 'Килимки в салон Chevrolet Express 2003-2014 II покоління EU VAN 7-ми місна',
        titleKey: 'chevrolet31',
      },
      {
        id: '361',
        model: 'HHR',
        year: ['2005', '2011'],
        body: 'Universal',
        market: 'EU/USA',
        img: '/images/catalog/models/chevrolet/5769-250x150.jpg',
        title: 'Килимки в салон Chevrolet HHR 2005-2011 I покоління EU/USA Universal',
        titleKey: 'chevrolet32',
      },
      {
        id: '362',
        model: 'Impala',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5770-250x150.jpg',
        title: 'Килимки в салон Chevrolet Impala 2013-… X покоління EU Sedan',
        titleKey: 'chevrolet33',
      },
      {
        id: '363',
        model: 'Lacetti',
        year: ['2002'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5772-250x150.jpg',
        title: 'Килимки в салон Chevrolet Lacetti 2002-… I покоління EU Hatchback',
        titleKey: 'chevrolet34',
      },
      {
        id: '364',
        model: 'Lacetti',
        year: ['2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5771-250x150.jpg',
        title: 'Килимки в салон Chevrolet Lacetti 2002-… I покоління EU Sedan',
        titleKey: 'chevrolet35',
      },
      {
        id: '365',
        model: 'Lacetti',
        year: ['2002'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5773-250x150.jpg',
        title: 'Килимки в салон Chevrolet Lacetti 2002-… I покоління EU Universal',
        titleKey: 'chevrolet36',
      },

      {
        id: '366',
        model: 'Lanos',
        year: ['2004', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5774-250x150.jpg',
        title: 'Килимки в салон Chevrolet Lanos 2004-2017 EU Sedan',
        titleKey: 'chevrolet37',
      },
      {
        id: '367',
        model: 'Malibu',
        year: ['2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5775-250x150.jpg',
        title: 'Килимки в салон Chevrolet Malibu 2015-… IX покоління USA Sedan',
        titleKey: 'chevrolet38',
      },
      {
        id: '368',
        model: 'Malibu',
        year: ['2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5776-250x150.jpg',
        title: 'Килимки в салон Chevrolet Malibu 2015-… IX покоління USA Sedan Hybrid',
        titleKey: 'chevrolet39',
      },
      {
        id: '369',
        model: 'Malibu',
        year: ['2012', '2015'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/chevrolet/5777-250x150.jpg',
        title: 'Килимки в салон Chevrolet Malibu 8 2012-2015 VIII покоління EU/USA Sedan',
        titleKey: 'chevrolet40',
      },
      {
        id: '370',
        model: 'Menlo',
        year: ['2020'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/chevrolet/5778-250x150.jpg',
        title: 'Килимки в салон Chevrolet Menlo 2020-… I покоління China Crossover',
        titleKey: 'chevrolet41',
      },
      {
        id: '371',
        model: 'Monza',
        year: ['2019'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Chevrolet Monza 2019-… I покоління China Sedan',
        titleKey: 'chevrolet42',
      },
      {
        id: '372',
        model: 'Niva',
        year: ['2002', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5779-250x150.jpg',
        title: 'Килимки в салон Chevrolet Niva 2002-2009 I покоління EU Crossover дорест',
        titleKey: 'chevrolet43',
      },
      {
        id: '373',
        model: 'Niva',
        year: ['2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5780-250x150.jpg',
        title: 'Килимки в салон Chevrolet Niva 2009-… I покоління EU Crossover рест',
        titleKey: 'chevrolet44',
      },
      {
        id: '374',
        model: 'Orlando',
        year: ['2010', '2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5781-250x150.jpg',
        title: 'Килимки в салон Chevrolet Orlando (J309) 2010-2018 I покоління EU Minivan 7-ми місна',
        titleKey: 'chevrolet45',
      },
      {
        id: '375',
        model: 'Silverado',
        year: ['2007', '2014'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5782-250x150.jpg',
        title: 'Килимки в салон Chevrolet Silverado 2007-2014 II покоління USA Pickup',
        titleKey: 'chevrolet46',
      },

      {
        id: '376',
        model: 'Silverado',
        year: ['2013', '2019'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5783-250x150.jpg',
        title: 'Килимки в салон Chevrolet Silverado 2013-2019 III покоління USA Pickup',
        titleKey: 'chevrolet47',
      },
      {
        id: '377',
        model: 'Sonic',
        year: ['2011'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5784-250x150.jpg',
        title: 'Килимки в салон Chevrolet Sonic (T300) 2011-… III покоління USA Hatchback',
        titleKey: 'chevrolet48',
      },
      {
        id: '378',
        model: 'Sonic',
        year: ['2011'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5785-250x150.jpg',
        title: 'Килимки в салон Chevrolet Sonic (T300) 2011-… III покоління USA Sedan',
        titleKey: 'chevrolet49',
      },
      {
        id: '379',
        model: 'Spark',
        year: ['2009', '2012'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5788-250x150.jpg',
        title: 'Килимки в салон Chevrolet Spark (M300) 2009-2012 III покоління USA Hatchback дорест',
        titleKey: 'chevrolet50',
      },
      {
        id: '380',
        model: 'Spark',
        year: ['2012', '2015'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5789-250x150.jpg',
        title: 'Килимки в салон Chevrolet Spark (M300) 2012-2015 III покоління USA Hatchback рест',
        titleKey: 'chevrolet51',
      },
      {
        id: '381',
        model: 'Spark',
        year: ['2015'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/chevrolet/5790-250x150.jpg',
        title: 'Килимки в салон Chevrolet Spark (M400) 2015-… IV покоління EU/USA Hatchback 5-ти дверна',
        titleKey: 'chevrolet52',
      },
      {
        id: '382',
        model: 'Spark',
        year: ['2005', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5787-250x150.jpg',
        title: 'Килимки в салон Chevrolet Spark 2005-2009 II покоління EU Hatchback',
        titleKey: 'chevrolet53',
      },
      {
        id: '383',
        model: 'Spark',
        year: ['2013', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5786-250x150.jpg',
        title: 'Килимки в салон Chevrolet Spark EV (2LT) 2013-2016 I покоління EU Hatchback',
        titleKey: 'chevrolet54',
      },
      {
        id: '384',
        model: 'Suburban',
        year: ['2007', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5791-250x150.jpg',
        title: 'Килимки в салон Chevrolet Suburban 2007-2014 XI покоління EU Crossover',
        titleKey: 'chevrolet55',
      },
      {
        id: '385',
        model: 'Tacuma',
        year: ['2000', '2011'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/chevrolet/5792-250x150.jpg',
        title: 'Килимки в салон Chevrolet Tacuma 2000-2011 I покоління EU Minivan',
        titleKey: 'chevrolet56',
      },

      {
        id: '386',
        model: 'Tahoe',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5795-250x150.jpg',
        title: 'Килимки в салон Chevrolet Tahoe (GMT K2UC) 2014-2020 IV покоління USA Crossover',
        titleKey: 'chevrolet57',
      },
      {
        id: '387',
        model: 'Tahoe',
        year: ['1999', '2006'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5793-250x150.jpg',
        title: 'Килимки в салон Chevrolet Tahoe (GMT800) 1999-2006 II покоління USA Crossover',
        titleKey: 'chevrolet58',
      },
      {
        id: '388',
        model: 'Tahoe',
        year: ['2007', '2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5794-250x150.jpg',
        title: 'Килимки в салон Chevrolet Tahoe (GMT900) 2007-2014 III покоління USA Crossover',
        titleKey: 'chevrolet59',
      },
      {
        id: '389',
        model: 'Tahoe',
        year: ['2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5796-250x150.jpg',
        title: 'Килимки в салон Chevrolet Tahoe (GMTT1XX) 2020-… V покоління USA Crossover',
        titleKey: 'chevrolet60',
      },
      {
        id: '390',
        model: 'Tracker (Trax)',
        year: ['2013', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5797-250x150.jpg',
        title: 'Килимки в салон Chevrolet Tracker (Trax) 2013-2019 III покоління USA Crossover',
        titleKey: 'chevrolet61',
      },
      {
        id: '391',
        model: 'Tracker (Trax)',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5798-250x150.jpg',
        title: 'Килимки в салон Chevrolet Tracker (Trax) 2019-… IV покоління USA Crossover',
        titleKey: 'chevrolet62',
      },
      {
        id: '392',
        model: 'TrailBlazer',
        year: ['2001', '2005'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5799-250x150.jpg',
        title: 'Килимки в салон Chevrolet TrailBlazer 2001-2005 I покоління USA Crossover дорест',
        titleKey: 'chevrolet63',
      },
      {
        id: '393',
        model: 'TrailBlazer',
        year: ['2012', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5800-250x150.jpg',
        title: 'Килимки в салон Chevrolet TrailBlazer 2012-2019 II покоління USA Crossover 7-ми місна',
        titleKey: 'chevrolet64',
      },
      {
        id: '394',
        model: 'Traverse',
        year: ['2008', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5801-250x150.jpg',
        title: 'Килимки в салон Chevrolet Traverse 2008-2017 I покоління USA Crossover 7-ми місна',
        titleKey: 'chevrolet65',
      },
      {
        id: '395',
        model: 'Traverse',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5802-250x150.jpg',
        title: 'Килимки в салон Chevrolet Traverse 2017-… II покоління USA Crossover 7-ми місна',
        titleKey: 'chevrolet66',
      },

      {
        id: '396',
        model: 'Volt',
        year: ['2010', '2015'],
        body: 'Liftback',
        market: 'EU/USA',
        img: '/images/catalog/models/chevrolet/5803-250x150.jpg',
        title: 'Килимки в салон Chevrolet Volt 2010-2015 I покоління EU/USA Liftback 5-ти дверна',
        titleKey: 'chevrolet67',
      },
      {
        id: '397',
        model: 'Volt',
        year: ['2015', '2019'],
        body: 'Liftback',
        market: 'USA',
        img: '/images/catalog/models/chevrolet/5804-250x150.jpg',
        title: 'Килимки в салон Chevrolet Volt 2015-2019 II покоління USA Liftback 5-ти дверна',
        titleKey: 'chevrolet68',
      },
    ],
  },
  /* -------------------------------- Chrysler -------------------------------- */
  Chrysler: {
    logo: '/images/catalog/logo/chrysler-100x100.png',
    name: 'Chrysler',
    models: [
      {
        id: '398',
        model: '200',
        year: ['2010', '2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5692-250x150.jpg',
        title: 'Килимки в салон Chrysler 200 2010-2014 I покоління USA Sedan',
        titleKey: 'chrysler1',
      },
      {
        id: '399',
        model: '200',
        year: ['2014', '2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5693-250x150.jpg',
        title: 'Килимки в салон Chrysler 200 2014-2016 II покоління USA Sedan',
        titleKey: 'chrysler2',
      },
      {
        id: '400',
        model: '200S',
        year: ['2010', '2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5695-250x150.jpg',
        title: 'Килимки в салон Chrysler 200S 2010-2014 I покоління USA Sedan',
        titleKey: 'chrysler3',
      },
      {
        id: '401',
        model: '300C',
        year: ['2004', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5696-250x150.jpg',
        title: 'Килимки в салон Chrysler 300C 2004-2011 I покоління EU Sedan',
        titleKey: 'chrysler4',
      },
      {
        id: '402',
        model: '300C',
        year: ['2004', '2011'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5697-250x150.jpg',
        title: 'Килимки в салон Chrysler 300C 2004-2011 I покоління EU Universal',
        titleKey: 'chrysler5',
      },
      {
        id: '403',
        model: '300C',
        year: ['2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5698-250x150.jpg',
        title: 'Килимки в салон Chrysler 300C 2011-… II покоління EU Sedan',
        titleKey: 'chrysler6',
      },
      {
        id: '404',
        model: '300C',
        year: ['2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5694-250x150.jpg',
        title: 'Килимки в салон Chrysler 300C 2011-… II покоління EU Sedan 4WD',
        titleKey: 'chrysler7',
      },
      {
        id: '405',
        model: '300M',
        year: ['1998', '2004'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5699-250x150.jpg',
        title: 'Килимки в салон Chrysler 300M 1998-2004 I покоління USA Sedan',
        titleKey: 'chrysler8',
      },
      {
        id: '406',
        model: 'Aspen',
        year: ['2007', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5700-250x150.jpg',
        title: 'Килимки в салон Chrysler Aspen 2007-2009 I покоління EU Crossover',
        titleKey: 'chrysler9',
      },
      {
        id: '407',
        model: 'Concorde',
        year: ['1998', '2004'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5701-250x150.jpg',
        title: 'Килимки в салон Chrysler Concorde 1998-2004 II покоління USA Sedan',
        titleKey: 'chrysler10',
      },

      {
        id: '408',
        model: 'Grand Voyager',
        year: ['2001', '2007'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5702-250x150.jpg',
        title: 'Килимки в салон Chrysler Grand Voyager 2001-2007 IV покоління EU Minivan 7-ми місна',
        titleKey: 'chrysler11',
      },
      {
        id: '409',
        model: 'Grand Voyager',
        year: ['2008', '2016'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5703-250x150.jpg',
        title: 'Килимки в салон Chrysler Grand Voyager 2008-2016 V покоління USA Minivan 7-ми місна',
        titleKey: 'chrysler12',
      },
      {
        id: '410',
        model: 'Pacifica',
        year: ['2003', '2007'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5704-250x150.jpg',
        title: 'Килимки в салон Chrysler Pacifica (CS) 2003-2007 I покоління USA Minivan',
        titleKey: 'chrysler13',
      },
      {
        id: '411',
        model: 'Pacifica',
        year: ['2016'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5706-250x150.jpg',
        title: 'Килимки в салон Chrysler Pacifica (RU) 2016-… II покоління USA Minivan 7-ми місна Hybrid',
        titleKey: 'chrysler14',
      },
      {
        id: '412',
        model: 'Sebring',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/chrysler/5708-250x150.jpg',
        title: 'Килимки в салон Chrysler Sebring (JR) 2000-2006 II покоління EU/USA Sedan',
        titleKey: 'chrysler15',
      },
      {
        id: '413',
        model: 'Sebring',
        year: ['2007', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5709-250x150.jpg',
        title: 'Килимки в салон Chrysler Sebring (JS) 2007-2010 III покоління EU Sedan',
        titleKey: 'chrysler16',
      },
      {
        id: '414',
        model: 'Stratus',
        year: ['1995', '2000'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5710-250x150.jpg',
        title: 'Килимки в салон Chrysler Stratus 1995-2000 I покоління USA Sedan',
        titleKey: 'chrysler17',
      },
      {
        id: '415',
        model: 'Toun-Country',
        year: ['1982', '2016'],
        body: 'Miniven',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5711-250x150.jpg',
        title: 'Килимки в салон Chrysler Toun-Country 1982-2016 I покоління EU Miniven 7-ми місна',
        titleKey: 'chrysler18',
      },
      {
        id: '416',
        model: 'Vision',
        year: ['1993', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5712-250x150.jpg',
        title: 'Килимки в салон Chrysler Vision 1993-1997 I покоління EU Sedan',
        titleKey: 'chrysler19',
      },
      {
        id: '417',
        model: 'Voyager',
        year: ['1996', '2000'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5714-250x150.jpg',
        title: 'Килимки в салон Chrysler Voyager (GH) 1996-2000 III покоління EU Minivan 7-ми місна',
        titleKey: 'chrysler20',
      },

      {
        id: '418',
        model: 'Voyager',
        year: ['2001', '2007'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/chrysler/5715-250x150.jpg',
        title: 'Килимки в салон Chrysler Voyager (RG) 2001-2007 IV покоління EU Minivan 7-ми місна Short',
        titleKey: 'chrysler21',
      },
      {
        id: '419',
        model: 'Voyager',
        year: ['1991', '1995'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/chrysler/5713-250x150.jpg',
        title: 'Килимки в салон Chrysler Voyager 1991-1995 II покоління USA Minivan',
        titleKey: 'chrysler22',
      },
    ],
  },
  /* ---------------------------------- Citroen --------------------------------- */
  Citroen: {
    logo: '/images/catalog/logo/citroen-100x100.png',
    name: 'Citroen',
    models: [
      {
        id: '420',
        model: 'Berlingo',
        year: ['2008', '2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5472-250x150.jpg',
        title: 'Килимки в салон Citroen Berlingo (B9) 2008-2018 II покоління EU Minivan 5-ти місна пасажир',
        titleKey: 'citroen1',
      },
      {
        id: '421',
        model: 'Berlingo',
        year: ['2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5473-250x150.jpg',
        title: 'Килимки в салон Citroen Berlingo (K9) 2018-… III покоління EU Minivan пасажир',
        titleKey: 'citroen2',
      },
      {
        id: '422',
        model: 'Berlingo',
        year: ['2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5475-250x150.jpg',
        title: 'Килимки в салон Citroen Berlingo (K9) Cargo 2018-… III покоління EU Minivan грузовий',
        titleKey: 'citroen3',
      },
      {
        id: '423',
        model: 'Berlingo',
        year: ['1996', '2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5471-250x150.jpg',
        title: 'Килимки в салон Citroen Berlingo (M49) 1996-2010 I покоління EU Minivan 5-ти місна грузо-пасажирський',
        titleKey: 'citroen4',
      },
      {
        id: '424',
        model: 'Berlingo',
        year: ['2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5474-250x150.jpg',
        title: 'Килимки в салон Citroen Berlingo XL (K9) 2018-… III покоління EU Minivan',
        titleKey: 'citroen5',
      },
      {
        id: '425',
        model: 'C-Crosser',
        year: ['2007', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/citroen/5503-250x150.jpg',
        title: 'Килимки в салон Citroen C-Crosser 2007-2013 EU Crossover',
        titleKey: 'citroen6',
      },
      {
        id: '426',
        model: 'C-Elysee',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5504-250x150.jpg',
        title: 'Килимки в салон Citroen C-Elysee 2013-… EU Sedan',
        titleKey: 'citroen7',
      },
      {
        id: '427',
        model: 'C-Zero',
        year: ['2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5505-250x150.jpg',
        title: 'Килимки в салон Citroen C-Zero 2010-… EU Hatchback',
        titleKey: 'citroen8',
      },
      {
        id: '428',
        model: 'C1',
        year: ['2005', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5476-250x150.jpg',
        title: 'Килимки в салон Citroen C1 2005-2014 I покоління EU Hatchback',
        titleKey: 'citroen9',
      },
      {
        id: '429',
        model: 'C1',
        year: ['2014', '2022'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5477-250x150.jpg',
        title: 'Килимки в салон Citroen C1 2014-2022 II покоління EU Hatchback',
        titleKey: 'citroen10',
      },

      {
        id: '430',
        model: 'C2',
        year: ['2003', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5478-250x150.jpg',
        title: 'Килимки в салон Citroen C2 2003-2010 I покоління EU Hatchback',
        titleKey: 'citroen11',
      },
      {
        id: '431',
        model: 'C3',
        year: ['2002', '2009'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/citroen/5480-250x150.jpg',
        title: 'Килимки в салон Citroen C3 2002-2009 I покоління EU Cabriolet',
        titleKey: 'citroen12',
      },
      {
        id: '432',
        model: 'C3',
        year: ['2002', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5479-250x150.jpg',
        title: 'Килимки в салон Citroen C3 2002-2009 I покоління EU Hatchback',
        titleKey: 'citroen13',
      },
      {
        id: '433',
        model: 'C3',
        year: ['2009', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5482-250x150.jpg',
        title: 'Килимки в салон Citroen C3 2009-2016 II покоління EU Hatchback',
        titleKey: 'citroen14',
      },
      {
        id: '434',
        model: 'C3',
        year: ['2016', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5483-250x150.jpg',
        title: 'Килимки в салон Citroen C3 2016-2020 III покоління EU Hatchback дорест',
        titleKey: 'citroen15',
      },
      {
        id: '435',
        model: 'C3',
        year: ['2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5484-250x150.jpg',
        title: 'Килимки в салон Citroen C3 2020-… III покоління EU Hatchback рест',
        titleKey: 'citroen16',
      },
      {
        id: '436',
        model: 'C3-Aircross',
        year: ['2017'],
        body: 'Miniven',
        market: 'EU',
        img: '/images/catalog/models/citroen/5485-250x150.jpg',
        title: 'Килимки в салон Citroen C3-Aircross 2017-… II покоління EU Miniven',
        titleKey: 'citroen17',
      },
      {
        id: '437',
        model: 'C3-Picasso',
        year: ['2008', '2017'],
        body: 'Miniven',
        market: 'EU',
        img: '/images/catalog/models/citroen/5486-250x150.jpg',
        title: 'Килимки в салон Citroen C3-Picasso 2008-2017 I покоління EU Minivan',
        titleKey: 'citroen18',
      },
      {
        id: '438',
        model: 'C3-Pluriel',
        year: ['2003', '2010'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/citroen/5481-250x150.jpg',
        title: 'Килимки в салон Citroen C3-Pluriel 2003-2010 I покоління EU Cabriolet',
        titleKey: 'citroen19',
      },
      {
        id: '439',
        model: 'C4',
        year: ['2004', '2010'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/citroen/5488-250x150.jpg',
        title: 'Килимки в салон Citroen C4 (L) 2004-2010 I покоління EU Coupe',
        titleKey: 'citroen20',
      },

      {
        id: '440',
        model: 'C4',
        year: ['2004', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5487-250x150.jpg',
        title: 'Килимки в салон Citroen C4 (L) 2004-2010 I покоління EU Hatchback',
        titleKey: 'citroen21',
      },
      {
        id: '441',
        model: 'C4',
        year: ['2010', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5489-250x150.jpg',
        title: 'Килимки в салон Citroen C4 2010-2018 II покоління EU Hatchback',
        titleKey: 'citroen22',
      },
      {
        id: '442',
        model: 'C4',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/citroen/5490-250x150.jpg',
        title: 'Килимки в салон Citroen C4 2020-… III покоління EU Crossover',
        titleKey: 'citroen23',
      },
      {
        id: '443',
        model: 'C4-Aircross',
        year: ['2012', '2017'],
        body: 'Miniven',
        market: 'EU',
        img: '/images/catalog/models/citroen/5491-250x150.jpg',
        title: 'Килимки в салон Citroen C4-Aircross 2012-2017 I покоління EU Miniven',
        titleKey: 'citroen24',
      },
      {
        id: '444',
        model: 'C4-Cactus',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/citroen/5492-250x150.jpg',
        title: 'Килимки в салон Citroen C4-Cactus 2014-2020 I покоління EU Crossover',
        titleKey: 'citroen25',
      },
      {
        id: '445',
        model: 'C4-Picasso',
        year: ['2006', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5493-250x150.jpg',
        title: 'Килимки в салон Citroen C4-Picasso 2006-2013 I покоління EU Minivan 5-ти місна',
        titleKey: 'citroen26',
      },
      {
        id: '446',
        model: 'C4-Picasso',
        year: ['2013', '2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5494-250x150.jpg',
        title: 'Килимки в салон Citroen C4-Picasso 2013-2018 II покоління EU Minivan 5-ти місна',
        titleKey: 'citroen27',
      },
      {
        id: '447',
        model: 'C4-Picasso Grand',
        year: ['2006', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5495-250x150.jpg',
        title: 'Килимки в салон Citroen C4-Picasso Grand 2006-2013 I покоління EU Minivan 7-ми місна',
        titleKey: 'citroen28',
      },
      {
        id: '448',
        model: 'C4-Picasso Grand',
        year: ['2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5496-250x150.jpg',
        title: 'Килимки в салон Citroen C4-Picasso Grand 2013-… II покоління EU Minivan 7-ми місна',
        titleKey: 'citroen29',
      },
      {
        id: '449',
        model: 'C4',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/citroen/5953-250x150.jpg',
        title: 'Килимки в салон Citroen C4 2020-... III покоління EU Crossover Long',
        titleKey: 'citroen30',
      },

      {
        id: '450',
        model: 'C5',
        year: ['2000', '2008'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5498-250x150.jpg',
        title: 'Килимки в салон Citroen C5 (DC) 2000-2008 I покоління EU Liftback',
        titleKey: 'citroen31',
      },
      {
        id: '451',
        model: 'C5',
        year: ['2000', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/citroen/5497-250x150.jpg',
        title: 'Килимки в салон Citroen C5 (DE) 2000-2008 I покоління EU Universal',
        titleKey: 'citroen32',
      },
      {
        id: '452',
        model: 'C5',
        year: ['2008', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5499-250x150.jpg',
        title: 'Килимки в салон Citroen C5 2008-2017 II покоління EU Sedan',
        titleKey: 'citroen33',
      },
      {
        id: '453',
        model: 'C5',
        year: ['2008', '2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/citroen/5500-250x150.jpg',
        title: 'Килимки в салон Citroen C5 2008-2017 II покоління EU Universal',
        titleKey: 'citroen34',
      },
      {
        id: '454',
        model: 'C5-Aircross',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/citroen/5501-250x150.jpg',
        title: 'Килимки в салон Citroen C5-Aircross 2017-… I покоління EU Crossover',
        titleKey: 'citroen35',
      },
      {
        id: '455',
        model: 'C8',
        year: ['2002', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5502-250x150.jpg',
        title: 'Килимки в салон Citroen C8 2002-2014 I покоління EU Minivan',
        titleKey: 'citroen36',
      },
      {
        id: '456',
        model: 'DS3',
        year: ['2009', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5506-250x150.jpg',
        title: 'Килимки в салон Citroen DS3 2009-2018 I покоління EU Hatchback',
        titleKey: 'citroen37',
      },
      {
        id: '457',
        model: 'DS3',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/citroen/5507-250x150.jpg',
        title: 'Килимки в салон Citroen DS3 Crossback 2018-… I покоління EU Crossover',
        titleKey: 'citroen38',
      },
      {
        id: '458',
        model: 'DS4',
        year: ['2011', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5508-250x150.jpg',
        title: 'Килимки в салон Citroen DS4 2011-2015 I покоління EU Hatchback',
        titleKey: 'citroen39',
      },
      {
        id: '459',
        model: 'DS5',
        year: ['2011', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5509-250x150.jpg',
        title: 'Килимки в салон Citroen DS5 2011-2015 I покоління EU Hatchback',
        titleKey: 'citroen40',
      },

      {
        id: '460',
        model: 'C4',
        year: ['2020'],
        body: 'Fastback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5972-250x150.jpg',
        title: 'Килимки в салон Citroen C4 2020-... III покоління EU Fastback',
        titleKey: 'citroen41',
      },
      {
        id: '461',
        model: 'Jumper',
        year: ['2006'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/citroen/5510-250x150.jpg',
        title: 'Килимки в салон Citroen Jumper 2006-… II покоління EU VAN',
        titleKey: 'citroen42',
      },
      {
        id: '462',
        model: 'Jumpy',
        year: ['1995', '2004'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/citroen/5511-250x150.jpg',
        title: 'Килимки в салон Citroen Jumpy 1995-2004 I покоління EU VAN дорест',
        titleKey: 'citroen43',
      },
      {
        id: '463',
        model: 'Jumpy',
        year: ['2004', '2007'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/citroen/5512-250x150.jpg',
        title: 'Килимки в салон Citroen Jumpy 2004-2007 I покоління EU VAN рест',
        titleKey: 'citroen44',
      },
      {
        id: '464',
        model: 'Jumpy',
        year: ['2007', '2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/citroen/5513-250x150.jpg',
        title: 'Килимки в салон Citroen Jumpy 2007-2016 II покоління EU VAN',
        titleKey: 'citroen45',
      },
      {
        id: '465',
        model: 'Saxo',
        year: ['1996', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5514-250x150.jpg',
        title: 'Килимки в салон Citroen Saxo 1996-2003 I покоління EU Hatchback',
        titleKey: 'citroen46',
      },
      {
        id: '466',
        model: 'Space Tourer',
        year: ['2016'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5515-250x150.jpg',
        title: 'Килимки в салон Citroen Space Tourer 2016-… I покоління EU Minivan 7-ми місна',
        titleKey: 'citroen47',
      },
      {
        id: '467',
        model: 'Space Tourer',
        year: ['2016'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5516-250x150.jpg',
        title: 'Килимки в салон Citroen Space Tourer 2016-… I покоління EU Minivan 9-ти місна',
        titleKey: 'citroen48',
      },
      {
        id: '468',
        model: 'Xsara',
        year: ['1997', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Citroen Xsara 1997-2006 I покоління EU Sedan',
        titleKey: 'citroen49',
      },
      {
        id: '469',
        model: 'Xsara',
        year: ['1999', '2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/citroen/5517-250x150.jpg',
        title: 'Килимки в салон Citroen Xsara Picasso 1999-2012 I покоління EU Minivan',
        titleKey: 'citroen50',
      },

      {
        id: '470',
        model: 'ZX',
        year: ['1991', '1998'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/citroen/5518-250x150.jpg',
        title: 'Килимки в салон Citroen ZX 1991-1998 I покоління EU Hatchback 3-х дверна',
        titleKey: 'citroen51',
      },
    ],
  },
  /* ---------------------------------- Cupra --------------------------------- */
  Cupra: {
    logo: '/images/catalog/logo/cupra-100x100.png',
    name: 'Cupra',
    models: [
      {
        id: '471',
        model: 'Formentor',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/cupra/5519-250x150.jpg',
        title: 'Килимки в салон Cupra Formentor 2020-… EU Crossover',
        titleKey: 'cupra1',
      },
    ],
  },
  /* ---------------------------------- Dacia --------------------------------- */
  Dacia: {
    logo: '/images/catalog/logo/dacia-100x100.png',
    name: 'Dacia',
    models: [
      {
        id: '472',
        model: 'Dokker',
        year: ['2012'],
        body: 'Furgon',
        market: 'EU',
        img: '/images/catalog/models/dacia/5187-250x150.jpg',
        title: 'Килимки в салон Dacia Dokker 2012-… I покоління EU Furgon',
        titleKey: 'dacia1',
      },
      {
        id: '473',
        model: 'Duster',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dacia/5191-250x150.jpg',
        title: 'Килимки в салон Dacia Duster (HM) 2018-… II покоління EU Crossover',
        titleKey: 'dacia2',
      },
      {
        id: '474',
        model: 'Duster',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dacia/5189-250x150.jpg',
        title: 'Килимки в салон Dacia Duster 2010-2015 I покоління EU Crossover дорест AWD',
        titleKey: 'dacia3',
      },
      {
        id: '475',
        model: 'Duster',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dacia/5188-250x150.jpg',
        title: 'Килимки в салон Dacia Duster 2010-2015 I покоління EU Crossover дорест FWD',
        titleKey: 'dacia4',
      },
      {
        id: '476',
        model: 'Duster',
        year: ['2015', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dacia/5190-250x150.jpg',
        title: 'Килимки в салон Dacia Duster 2015-2018 I покоління EU Crossover рест AWD',
        titleKey: 'dacia5',
      },
      {
        id: '477',
        model: 'Lodgy',
        year: ['2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/dacia/5193-250x150.jpg',
        title: 'Килимки в салон Dacia Lodgy 2012-… I покоління EU Minivan 5-ти місна',
        titleKey: 'dacia6',
      },
      {
        id: '478',
        model: 'Lodgy',
        year: ['2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/dacia/5192-250x150.jpg',
        title: 'Килимки в салон Dacia Lodgy 2012-… I покоління EU Minivan 7-ми місна',
        titleKey: 'dacia7',
      },
      {
        id: '479',
        model: 'Logan',
        year: ['2004', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/dacia/5194-250x150.jpg',
        title: 'Килимки в салон Dacia Logan 2004-2012 I покоління EU Sedan',
        titleKey: 'dacia8',
      },
      {
        id: '480',
        model: 'Logan',
        year: ['2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/dacia/5198-250x150.jpg',
        title: 'Килимки в салон Dacia Logan 2012-… II покоління EU Sedan',
        titleKey: 'dacia9',
      },
      {
        id: '481',
        model: 'Logan',
        year: ['2004', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/dacia/5196-250x150.jpg',
        title: 'Килимки в салон Dacia Logan MCV 2004-2008 I покоління EU Universal дорест 7-ми місна',
        titleKey: 'dacia10',
      },

      {
        id: '482',
        model: 'Logan',
        year: ['2004', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/dacia/5195-250x150.jpg',
        title: 'Килимки в салон Dacia Logan MCV 2004-2012 I покоління EU Universal 5-ти місна',
        titleKey: 'dacia11',
      },
      {
        id: '483',
        model: 'Logan',
        year: ['2008', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/dacia/5197-250x150.jpg',
        title: 'Килимки в салон Dacia Logan MCV 2008-2012 I покоління EU Universal рест 7-ми місна',
        titleKey: 'dacia12',
      },
      {
        id: '484',
        model: 'Logan',
        year: ['2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/dacia/5199-250x150.jpg',
        title: 'Килимки в салон Dacia Logan MCV 2012-… II покоління EU Universal',
        titleKey: 'dacia13',
      },
      {
        id: '485',
        model: 'Sandero',
        year: ['2012', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dacia/5201-250x150.jpg',
        title: 'Килимки в салон Dacia Sandero (B52) 2012-2020 II покоління EU Crossover',
        titleKey: 'dacia14',
      },
      {
        id: '486',
        model: 'Sandero',
        year: ['2007', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/dacia/5200-250x150.jpg',
        title: 'Килимки в салон Dacia Sandero (B90) 2007-2012 I покоління EU Hatchback',
        titleKey: 'dacia15',
      },
      {
        id: '487',
        model: 'Sandero StepWay',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dacia/5202-250x150.jpg',
        title: 'Килимки в салон Dacia Sandero StepWay 2012-2017 II покоління EU Crossover дорест',
        titleKey: 'dacia16',
      },
      {
        id: '488',
        model: 'Sandero StepWay',
        year: ['2017', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dacia/5203-250x150.jpg',
        title: 'Килимки в салон Dacia Sandero StepWay 2017-2020 II покоління EU Crossover рест',
        titleKey: 'dacia17',
      },
      {
        id: '489',
        model: 'Solenza',
        year: ['2017', '2020'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/dacia/5204-250x150.jpg',
        title: 'Килимки в салон Dacia Solenza 2003-2005 I покоління EU Liftback',
        titleKey: 'dacia18',
      },
      {
        id: '490',
        model: 'Spring',
        year: ['2021'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Dacia Spring 2021-… I покоління China Hatchback',
        titleKey: 'dacia19',
      },
    ],
  },
  /* ---------------------------------- Dadi ---------------------------------- */
  Dadi: {
    logo: '/images/catalog/logo/Dadi-100x100.png',
    name: 'Dadi',
    models: [
      {
        id: '491',
        model: 'Blis',
        year: ['2005', '2012'],
        body: 'Pickup',
        market: 'China',
        img: '/images/catalog/models/dadi/5185-250x150.jpg',
        title: 'Килимки в салон Dadi Blis 2005-2012 I покоління China Pickup',
        titleKey: 'dadi1',
      },
      {
        id: '492',
        model: 'Shuttle',
        year: ['2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dadi/5186-250x150.jpg',
        title: 'Килимки в салон Dadi Shuttle 2005-… I покоління EU Crossover',
        titleKey: 'dadi2',
      },
    ],
  },
  /* --------------------------------- Daewoo --------------------------------- */
  Daewoo: {
    logo: '/images/catalog/logo/daewoo-100x100.png',
    name: 'Daewoo',
    models: [
      {
        id: '493',
        model: 'Espero',
        year: ['1990', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5865-250x150.jpg',
        title: 'Килимки в салон Daewoo Espero 1990-1998 I покоління EU Sedan',
        titleKey: 'daewoo1',
      },
      {
        id: '494',
        model: 'Gentra',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5866-250x150.jpg',
        title: 'Килимки в салон Daewoo Gentra 2013-… I покоління EU Sedan',
        titleKey: 'daewoo2',
      },
      {
        id: '495',
        model: 'Lanos',
        year: ['1998', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5869-250x150.jpg',
        title: 'Килимки в салон Daewoo Lanos 1998-2019 I покоління EU Hatchback',
        titleKey: 'daewoo3',
      },
      {
        id: '496',
        model: 'Lanos',
        year: ['1998', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5867-250x150.jpg',
        title: 'Килимки в салон Daewoo Lanos 1998-2019 I покоління EU Sedan',
        titleKey: 'daewoo4',
      },
      {
        id: '497',
        model: 'Lanos',
        year: ['1998', '2008'],
        body: 'Sedan',
        market: 'PL',
        img: '/images/catalog/models/daewoo/5868-250x150.jpg',
        title: 'Килимки в салон Daewoo Lanos T150 1998-2008 I покоління PL Sedan',
        titleKey: 'daewoo5',
      },
      {
        id: '498',
        model: 'Leganza',
        year: ['1997', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5870-250x150.jpg',
        title: 'Килимки в салон Daewoo Leganza (V100) 1997-2008 I покоління EU Sedan',
        titleKey: 'daewoo6',
      },
      {
        id: '499',
        model: 'Matiz',
        year: ['1997', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5871-250x150.jpg',
        title: 'Килимки в салон Daewoo Matiz 1997-2015 I покоління EU Hatchback',
        titleKey: 'daewoo7',
      },
      {
        id: '500',
        model: 'Nexia',
        year: ['1995', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5873-250x150.jpg',
        title: 'Килимки в салон Daewoo Nexia 1995-2016 I покоління EU Hatchback',
        titleKey: 'daewoo8',
      },
      {
        id: '501',
        model: 'Nexia',
        year: ['1995', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5872-250x150.jpg',
        title: 'Килимки в салон Daewoo Nexia 1995-2016 I покоління EU Sedan',
        titleKey: 'daewoo9',
      },
      {
        id: '502',
        model: 'Nubira',
        year: ['1997', '1999'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5874-250x150.jpg',
        title: 'Килимки в салон Daewoo Nubira (J100) 1997-1999 I покоління EU Sedan',
        titleKey: 'daewoo10',
      },
      {
        id: '503',
        model: 'Nubira',
        year: ['1999', '2003'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5875-250x150.jpg',
        title: 'Килимки в салон Daewoo Nubira (J150) 1999-2003 II покоління EU Universal',
        titleKey: 'daewoo11',
      },
      {
        id: '504',
        model: 'Sens',
        year: ['2000', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5877-250x150.jpg',
        title: 'Килимки в салон Daewoo Sens 2000-2017 I покоління EU Hatchback',
        titleKey: 'daewoo12',
      },
      {
        id: '505',
        model: 'Sens',
        year: ['2000', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/daewoo/5876-250x150.jpg',
        title: 'Килимки в салон Daewoo Sens 2000-2017 I покоління EU Sedan',
        titleKey: 'daewoo13',
      },
    ],
  },
  /* -------------------------------- Daihatsu -------------------------------- */
  Daihatsu: {
    logo: '/images/catalog/logo/daihatsu-100x100.png',
    name: 'Daihatsu',
    models: [
      {
        id: '506',
        model: 'Cuore',
        year: ['2002', '2006'],
        body: 'Hatchback',
        market: 'Japan',
        img: '/images/catalog/models/daihatsu/5162-250x150.jpg',
        title: 'Килимки в салон Daihatsu Cuore (L250) 2002-2006 VI покоління Japan Hatchback',
        titleKey: 'daihatsu1',
      },
      {
        id: '507',
        model: 'Cuore',
        year: ['2007', '2013'],
        body: 'Hatchback',
        market: 'Japan',
        img: '/images/catalog/models/daihatsu/5163-250x150.jpg',
        title: 'Килимки в салон Daihatsu Cuore (L276) 2007-2013 VII покоління Japan Hatchback',
        titleKey: 'daihatsu2',
      },
      {
        id: '508',
        model: 'Cuore',
        year: ['1998', '2002'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daihatsu/5160-250x150.jpg',
        title: 'Килимки в салон Daihatsu Cuore (L700) 1998-2002 V покоління EU Hatchback 3-х дверна',
        titleKey: 'daihatsu3',
      },
      {
        id: '509',
        model: 'Cuore',
        year: ['1998', '2002'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daihatsu/5161-250x150.jpg',
        title: 'Килимки в салон Daihatsu Cuore (L700) 1998-2002 V покоління EU Hatchback 5-ти дверна',
        titleKey: 'daihatsu4',
      },
      {
        id: '510',
        model: 'Materia',
        year: ['2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daihatsu/5164-250x150.jpg',
        title: 'Килимки в салон Daihatsu Materia 2006-… II покоління EU Hatchback',
        titleKey: 'daihatsu5',
      },
      {
        id: '511',
        model: 'Sirion',
        year: ['2004', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daihatsu/5166-250x150.jpg',
        title: 'Килимки в салон Daihatsu Sirion (M300) 2004-2015 II покоління EU Hatchback',
        titleKey: 'daihatsu6',
      },
      {
        id: '512',
        model: 'Sirion',
        year: ['1998', '2004'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/daihatsu/5165-250x150.jpg',
        title: 'Килимки в салон Daihatsu Sirion 1998-2004 I покоління EU Hatchback',
        titleKey: 'daihatsu7',
      },
      {
        id: '513',
        model: 'Terios',
        year: ['1997', '2006'],
        body: 'Crossover',
        market: 'Japan',
        img: '/images/catalog/models/daihatsu/5167-250x150.jpg',
        title: 'Килимки в салон Daihatsu Terios (J100) 1997-2006 I покоління Japan Crossover',
        titleKey: 'daihatsu8',
      },
      {
        id: '514',
        model: 'Terios',
        year: ['2006', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/daihatsu/5168-250x150.jpg',
        title: 'Килимки в салон Daihatsu Terios (J200) 2006-2017 II покоління EU Crossover',
        titleKey: 'daihatsu9',
      },
    ],
  },
  /* ---------------------------------- Dodge --------------------------------- */
  Dodge: {
    logo: '/images/catalog/logo/dodge-100x100.png',
    name: 'Dodge',
    models: [
      {
        id: '515',
        model: 'Avenger',
        year: ['2007', '2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/dodge/5128-250x150.jpg',
        title: 'Килимки в салон Dodge Avenger (JS) 2007-2014 II покоління USA Sedan',
        titleKey: 'dodge1',
      },
      {
        id: '516',
        model: 'Caliber',
        year: ['2006', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/dodge/5130-250x150.jpg',
        title: 'Килимки в салон Dodge Caliber 2006-2012 I покоління EU Hatchback',
        titleKey: 'dodge2',
      },
      {
        id: '517',
        model: 'Caliber',
        year: ['2006', '2012'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/dodge/5129-250x150.jpg',
        title: 'Килимки в салон Dodge Caliber SXT 2006-2012 I покоління USA Hatchback',
        titleKey: 'dodge3',
      },
      {
        id: '518',
        model: 'Challenger',
        year: ['2008', '2015'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/dodge/5131-250x150.jpg',
        title: 'Килимки в салон Dodge Challenger 2008-2015 III покоління USA Coupe дорест',
        titleKey: 'dodge4',
      },
      {
        id: '519',
        model: 'Challenger',
        year: ['2014'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/dodge/5132-250x150.jpg',
        title: 'Килимки в салон Dodge Challenger 2014-… III покоління USA Coupe AWD',
        titleKey: 'dodge5',
      },
      {
        id: '520',
        model: 'Challenger',
        year: ['2015'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/dodge/5133-250x150.jpg',
        title: 'Килимки в салон Dodge Challenger 2015-… III покоління USA Coupe рест',
        titleKey: 'dodge6',
      },
      {
        id: '521',
        model: 'Charger',
        year: ['2010'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/dodge/5135-250x150.jpg',
        title: 'Килимки в салон Dodge Charger (LD) 2010-… VII покоління USA Sedan',
        titleKey: 'dodge7',
      },
      {
        id: '522',
        model: 'Charger',
        year: ['2005', '2010'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/dodge/5134-250x150.jpg',
        title: 'Килимки в салон Dodge Charger 2005-2010 VI покоління USA Sedan',
        titleKey: 'dodge8',
      },
      {
        id: '523',
        model: 'Dart',
        year: ['2012', '2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/dodge/5136-250x150.jpg',
        title: 'Килимки в салон Dodge Dart 2012-2016 I покоління USA Sedan',
        titleKey: 'dodge9',
      },
      {
        id: '524',
        model: 'Durango',
        year: ['2010', '2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/dodge/5138-250x150.jpg',
        title: 'Килимки в салон Dodge Durango (WK2) 2010-2014 III покоління USA Crossover дорест 7-ми місна',
        titleKey: 'dodge10',
      },

      {
        id: '525',
        model: 'Durango',
        year: ['2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/dodge/5140-250x150.jpg',
        title: 'Килимки в салон Dodge Durango (WK2) 2014-… III покоління USA Crossover рест',
        titleKey: 'dodge11',
      },
      {
        id: '526',
        model: 'Durango',
        year: ['2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/dodge/5139-250x150.jpg',
        title: 'Килимки в салон Dodge Durango (WK2) 2014-… III покоління USA Crossover рест 6-ти місна',
        titleKey: 'dodge12',
      },
      {
        id: '527',
        model: 'Durango',
        year: ['2004', '2010'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/dodge/5137-250x150.jpg',
        title: 'Килимки в салон Dodge Durango 2004-2010 II покоління USA Crossover',
        titleKey: 'dodge13',
      },
      {
        id: '528',
        model: 'Grand Caravan',
        year: ['2010', '2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/dodge/5141-250x150.jpg',
        title: 'Килимки в салон Dodge Grand Caravan 2010-2020 V покоління EU Minivan рест 7-ми місна',
        titleKey: 'dodge14',
      },
      {
        id: '529',
        model: 'Grand Caravan',
        year: ['2010', '2020'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/dodge/5142-250x150.jpg',
        title: 'Килимки в салон Dodge Grand Caravan 2010-2020 V покоління USA Minivan рест 8-ми місна',
        titleKey: 'dodge15',
      },
      {
        id: '530',
        model: 'Journey',
        year: ['2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dodge/5143-250x150.jpg',
        title: 'Килимки в салон Dodge Journey 2007-… I покоління EU Crossover 6-ти місна',
        titleKey: 'dodge16',
      },
      {
        id: '531',
        model: 'Journey',
        year: ['2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/dodge/5144-250x150.jpg',
        title: 'Килимки в салон Dodge Journey 2007-… I покоління EU Crossover 7-ми місна',
        titleKey: 'dodge17',
      },
      {
        id: '532',
        model: 'Journey',
        year: ['2007'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/dodge/5145-250x150.jpg',
        title: 'Килимки в салон Dodge Journey 2007-… I покоління EU/USA Crossover 5-ти місна',
        titleKey: 'dodge18',
      },
      {
        id: '533',
        model: 'Nitro',
        year: ['2006', '2011'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/dodge/5146-250x150.jpg',
        title: 'Килимки в салон Dodge Nitro 2006-2011 I покоління USA Crossover',
        titleKey: 'dodge19',
      },
      {
        id: '534',
        model: 'Ram 1500',
        year: ['2019'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/dodge/5153-250x150.jpg',
        title: 'Килимки в салон Dodge Ram 1500 (DT) 2019-… V покоління USA Pickup 4-х дверна Crew Cab',
        titleKey: 'dodge20',
      },

      {
        id: '535',
        model: 'Ram 1500',
        year: ['2002', '2009'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/dodge/5147-250x150.jpg',
        title: 'Килимки в салон Dodge Ram 1500 2002-2009 III покоління USA Pickup 4-х дверна Quad Cab',
        titleKey: 'dodge21',
      },
      {
        id: '536',
        model: 'Ram 1500',
        year: ['2009', '2018'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/dodge/5149-250x150.jpg',
        title: 'Килимки в салон Dodge Ram 1500 2009-2018 IV покоління USA Pickup 4-х дверна 5-ти місна Crew Cab',
        titleKey: 'dodge22',
      },
      {
        id: '537',
        model: 'Ram 1500',
        year: ['2009', '2018'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/dodge/5152-250x150.jpg',
        title: 'Килимки в салон Dodge Ram 1500 2009-2018 IV покоління USA Pickup 4-х дверна 5-ти місна Quad Cab',
        titleKey: 'dodge23',
      },
      {
        id: '538',
        model: 'Ram 1500',
        year: ['2009', '2018'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/dodge/5150-250x150.jpg',
        title: 'Килимки в салон Dodge Ram 1500 2009-2018 IV покоління USA Pickup 4-х дверна 6-ти місна Crew Cab',
        titleKey: 'dodge24',
      },
      {
        id: '539',
        model: 'Ram 1500',
        year: ['2009', '2018'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/dodge/5148-250x150.jpg',
        title: 'Килимки в салон Dodge Ram 1500 2009-2018 IV покоління USA Pickup 4-х дверна 6-ти місна Quad Cab',
        titleKey: 'dodge25',
      },
      {
        id: '540',
        model: 'Ram 1500',
        year: ['2009', '2018'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/dodge/5151-250x150.jpg',
        title: 'Килимки в салон Dodge Ram 1500 Sport 2009-2018 IV покоління EU Pickup 4-х дверна Crew Cab',
        titleKey: 'dodge26',
      },
      {
        id: '541',
        model: 'Ram Van',
        year: ['1994', '2003'],
        body: 'VAN',
        market: 'USA',
        img: '/images/catalog/models/dodge/5154-250x150.jpg',
        title: 'Килимки в салон Dodge Ram Van 1994-2003 III покоління USA VAN',
        titleKey: 'dodge27',
      },
      {
        id: '542',
        model: 'Stratus',
        year: ['1995', '2000'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/dodge/5155-250x150.jpg',
        title: 'Килимки в салон Dodge Stratus 1995-2000 I покоління USA Coupe',
        titleKey: 'dodge28',
      },
      {
        id: '543',
        model: 'Stratus',
        year: ['2000', '2006'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/dodge/5156-250x150.jpg',
        title: 'Килимки в салон Dodge Stratus 2000-2006 II покоління USA Coupe',
        titleKey: 'dodge29',
      },
      {
        id: '544',
        model: 'Stratus',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/dodge/5157-250x150.jpg',
        title: 'Килимки в салон Dodge Stratus 2000-2006 II покоління USA Sedan',
        titleKey: 'dodge30',
      },
    ],
  },
  /* ---------------------------------- Fiat ---------------------------------- */
  Fiat: {
    logo: '/images/catalog/logo/fiat-100x100.png',
    name: 'Fiat',
    models: [
      {
        id: '545',
        model: '500',
        year: ['2007'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5080-250x150.jpg',
        title: 'Килимки в салон Fiat 500 2007-… I покоління EU Hatchback',
        titleKey: 'fiat1',
      },
      {
        id: '546',
        model: '500',
        year: ['2007'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/fiat/5081-250x150.jpg',
        title: 'Килимки в салон Fiat 500 Abarth 2007-… I покоління USA Hatchback',
        titleKey: 'fiat2',
      },
      {
        id: '547',
        model: '500e',
        year: ['2013', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5082-250x150.jpg',
        title: 'Килимки в салон Fiat 500e 2013-2020 I покоління EU Hatchback electric',
        titleKey: 'fiat3',
      },
      {
        id: '548',
        model: '500e',
        year: ['2013', '2020'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/fiat/5082-250x150.jpg',
        title: 'Килимки в салон Fiat 500e 2013-2020 I покоління USA Hatchback electric',
        titleKey: 'fiat4',
      },
      {
        id: '549',
        model: '500e',
        year: ['2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Fiat 500e 2020-... II покоління EU Hatchback electric',
        titleKey: 'fiat5',
      },
      {
        id: '550',
        model: '500L',
        year: ['2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5083-250x150.jpg',
        title: 'Килимки в салон Fiat 500L 2012-… I покоління EU Minivan',
        titleKey: 'fiat6',
      },
      {
        id: '551',
        model: '500L',
        year: ['2012'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/fiat/5084-250x150.jpg',
        title: 'Килимки в салон Fiat 500L 2012-… I покоління USA Minivan',
        titleKey: 'fiat7',
      },
      {
        id: '552',
        model: '500X',
        year: ['2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/fiat/5085-250x150.jpg',
        title: 'Килимки в салон Fiat 500X 2014-… I покоління EU Crossover',
        titleKey: 'fiat8',
      },
      {
        id: '553',
        model: 'Albea',
        year: ['2002', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5086-250x150.jpg',
        title: 'Килимки в салон Fiat Albea 2002-2012 I покоління EU Sedan',
        titleKey: 'fiat9',
      },
      {
        id: '554',
        model: 'Bravo',
        year: ['1995', '2001'],
        body: 'Hatchback',
        market: 'EU',
        doors: '3-х дверна',
        img: '/images/catalog/models/fiat/5087-250x150.jpg',
        title: 'Килимки в салон Fiat Bravo 1995-2001 I покоління EU Hatchback 3-х дверна',
        titleKey: 'fiat10',
      },

      {
        id: '555',
        model: 'Bravo',
        year: ['2007', '2016'],
        body: 'Hatchback',
        market: 'EU',
        doors: '3-х дверна',
        img: '/images/catalog/models/fiat/5089-250x150.jpg',
        title: 'Килимки в салон Fiat Bravo 2007-2016 II покоління EU Hatchback 3-х дверна',
        titleKey: 'fiat11',
      },
      {
        id: '556',
        model: 'Bravo',
        year: ['2007', '2016'],
        body: 'Hatchback',
        market: 'EU',
        doors: '5-ти дверна',
        img: '/images/catalog/models/fiat/5088-250x150.jpg',
        title: 'Килимки в салон Fiat Bravo 2007-2016 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'fiat12',
      },
      {
        id: '557',
        model: 'Croma',
        year: ['1985', '1996'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5090-250x150.jpg',
        title: 'Килимки в салон Fiat Croma 1985-1996 I покоління EU Sedan',
        titleKey: 'fiat13',
      },
      {
        id: '558',
        model: 'Croma',
        year: ['2005', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/fiat/5091-250x150.jpg',
        title: 'Килимки в салон Fiat Croma 2005-2010 II покоління EU Universal',
        titleKey: 'fiat14',
      },
      {
        id: '559',
        model: 'Doblo',
        year: ['2010', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5094-250x150.jpg',
        title: 'Килимки в салон Fiat Doblo (263) 2010-2014 II покоління EU Minivan дорест',
        titleKey: 'fiat15',
      },
      {
        id: '560',
        model: 'Doblo',
        year: ['2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5095-250x150.jpg',
        title: 'Килимки в салон Fiat Doblo (263) 2014-… II покоління EU Minivan рест',
        titleKey: 'fiat16',
      },
      {
        id: '561',
        model: 'Doblo',
        year: ['2000', '2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5092-250x150.jpg',
        title: 'Килимки в салон Fiat Doblo 2000-2010 I покоління EU Minivan',
        titleKey: 'fiat17',
      },
      {
        id: '562',
        model: 'Doblo',
        year: ['2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5096-250x150.jpg',
        title: 'Килимки в салон Fiat Doblo Cargo (263) 2014-… II покоління EU Minivan рест',
        titleKey: 'fiat18',
      },
      {
        id: '563',
        model: 'Doblo',
        year: ['2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5097-250x150.jpg',
        title: 'Килимки в салон Fiat Doblo Maxi (263) 2014-… II покоління EU Minivan рест',
        titleKey: 'fiat19',
      },
      {
        id: '564',
        model: 'Doblo',
        year: ['2000', '2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5093-250x150.jpg',
        title: 'Килимки в салон Fiat Doblo Maxi 2000-2010 I покоління EU Minivan',
        titleKey: 'fiat20',
      },

      {
        id: '565',
        model: 'Ducato',
        year: ['1994', '2006'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/fiat/5099-250x150.jpg',
        title: 'Килимки в салон Fiat Ducato 1994-2006 II покоління EU VAN',
        titleKey: 'fiat21',
      },
      {
        id: '566',
        model: 'Ducato',
        year: ['2006', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/fiat/5100-250x150.jpg',
        title: 'Килимки в салон Fiat Ducato 2006-2014 III покоління EU VAN дорест',
        titleKey: 'fiat22',
      },
      {
        id: '567',
        model: 'Ducato',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/fiat/5098-250x150.jpg',
        title: 'Килимки в салон Fiat Ducato 2014-… III покоління EU VAN рест',
        titleKey: 'fiat23',
      },
      {
        id: '568',
        model: 'Fiorino',
        year: ['1988', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5101-250x150.jpg',
        title: 'Килимки в салон Fiat Fiorino 1988-2013 II покоління EU Minivan',
        titleKey: 'fiat24',
      },
      {
        id: '569',
        model: 'Fiorino',
        year: ['2008'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5102-250x150.jpg',
        title: 'Килимки в салон Fiat Fiorino 2008-… III покоління EU Minivan пасажирський',
        titleKey: 'fiat25',
      },
      {
        id: '570',
        model: 'Fiorino',
        year: ['2016'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5104-250x150.jpg',
        title: 'Килимки в салон Fiat Fiorino Cargo 2016-… III покоління EU Minivan',
        titleKey: 'fiat26',
      },
      {
        id: '571',
        model: 'Fiorino',
        year: ['2008'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5103-250x150.jpg',
        title: 'Килимки в салон Fiat Fiorino Qubo 2008-… III покоління EU Minivan',
        titleKey: 'fiat27',
      },
      {
        id: '572',
        model: 'Freemont',
        year: ['2011', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/fiat/5105-250x150.jpg',
        title: 'Килимки в салон Fiat Freemont 2011-2016 I покоління EU Crossover 7-ми місна',
        titleKey: 'fiat28',
      },
      {
        id: '573',
        model: 'Fullback',
        year: ['2018'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/fiat/5106-250x150.jpg',
        title: 'Килимки в салон Fiat Fullback 2018-… I покоління EU Pickup 4-х дверна Double cab',
        titleKey: 'fiat29',
      },
      {
        id: '574',
        model: 'Grande',
        year: ['2005', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5114-250x150.jpg',
        title: 'Килимки в салон Fiat Grande Punto (199) 2005-2018 III покоління EU Hatchback 3-х дверна',
        titleKey: 'fiat30',
      },

      {
        id: '575',
        model: 'Grande',
        year: ['2005', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5113-250x150.jpg',
        title: 'Килимки в салон Fiat Grande Punto (199) 2005-2018 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'fiat31',
      },
      {
        id: '576',
        model: 'Linea',
        year: ['2007', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5107-250x150.jpg',
        title: 'Килимки в салон Fiat Linea 2007-2018 I покоління EU Sedan',
        titleKey: 'fiat32',
      },
      {
        id: '577',
        model: 'Panda',
        year: ['2003', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5108-250x150.jpg',
        title: 'Килимки в салон Fiat Panda (169) 2003-2012 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'fiat33',
      },
      {
        id: '578',
        model: 'Panda',
        year: ['2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5109-250x150.jpg',
        title: 'Килимки в салон Fiat Panda 2011-… III покоління EU Hatchback',
        titleKey: 'fiat34',
      },
      {
        id: '579',
        model: 'Punto',
        year: ['1993', '1999'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5110-250x150.jpg',
        title: 'Килимки в салон Fiat Punto 176 1993-1999 I покоління EU Hatchback',
        titleKey: 'fiat35',
      },
      {
        id: '580',
        model: 'Punto',
        year: ['1999', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5111-250x150.jpg',
        title: 'Килимки в салон Fiat Punto Classic 1999-2012 II покоління EU Hatchback 3-х дверна',
        titleKey: 'fiat36',
      },
      {
        id: '581',
        model: 'Punto',
        year: ['1999', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5112-250x150.jpg',
        title: 'Килимки в салон Fiat Punto Classic 1999-2012 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'fiat37',
      },
      {
        id: '582',
        model: 'Punto',
        year: ['2009', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5115-250x150.jpg',
        title: 'Килимки в салон Fiat Punto EVO 2009-2012 III покоління EU Hatchback',
        titleKey: 'fiat38',
      },
      {
        id: '583',
        model: 'Qubo',
        year: ['2017'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5117-250x150.jpg',
        title: 'Килимки в салон Fiat Qubo 2017-… I покоління EU Minivan рест',
        titleKey: 'fiat39',
      },
      {
        id: '584',
        model: 'Qubo',
        year: ['2008', '2017'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5116-250x150.jpg',
        title: 'Килимки в салон Fiat Qubo Multijet 2008-2017 I покоління EU Minivan',
        titleKey: 'fiat40',
      },

      {
        id: '585',
        model: 'Scudo',
        year: ['1995', '2007'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/fiat/5118-250x150.jpg',
        title: 'Килимки в салон Fiat Scudo (220) 1995-2007 I покоління EU VAN',
        titleKey: 'fiat41',
      },
      {
        id: '586',
        model: 'Scudo',
        year: ['2007', '2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/fiat/5119-250x150.jpg',
        title: 'Килимки в салон Fiat Scudo (270) 2007-2016 II покоління EU VAN',
        titleKey: 'fiat42',
      },
      {
        id: '587',
        model: 'Sedici',
        year: ['2006', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5120-250x150.jpg',
        title: 'Килимки в салон Fiat Sedici 2006-2014 I покоління EU Hatchback',
        titleKey: 'fiat43',
      },
      {
        id: '588',
        model: 'Stilo',
        year: ['2001', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5122-250x150.jpg',
        title: 'Килимки в салон Fiat Stilo 2001-2008 I покоління EU Hatchback',
        titleKey: 'fiat44',
      },
      {
        id: '589',
        model: 'Stilo',
        year: ['2001', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/fiat/5121-250x150.jpg',
        title: 'Килимки в салон Fiat Stilo 2001-2008 I покоління EU Universal',
        titleKey: 'fiat45',
      },
      {
        id: '590',
        model: 'Tipo',
        year: ['1988', '1995'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5123-250x150.jpg',
        title: 'Килимки в салон Fiat Tipo (160) 1988-1995 I покоління EU Hatchback',
        titleKey: 'fiat46',
      },
      {
        id: '591',
        model: 'Tipo',
        year: ['2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/fiat/5125-250x150.jpg',
        title: 'Килимки в салон Fiat Tipo (356) 2015-… II покоління EU Hatchback',
        titleKey: 'fiat47',
      },
      {
        id: '592',
        model: 'Tipo',
        year: ['2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5124-250x150.jpg',
        title: 'Килимки в салон Fiat Tipo (356) 2015-… II покоління EU Sedan',
        titleKey: 'fiat48',
      },
      {
        id: '593',
        model: 'Tipo',
        year: ['2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/fiat/5126-250x150.jpg',
        title: 'Килимки в салон Fiat Tipo (356) 2015-… II покоління EU Universal',
        titleKey: 'fiat49',
      },
      {
        id: '594',
        model: 'Ulysse',
        year: ['2002', '2011'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/fiat/5127-250x150.jpg',
        title: 'Килимки в салон Fiat Ulysse 2002-2011 II покоління EU Minivan',
        titleKey: 'fiat50',
      },
    ],
  },
  /* --------------------------------- Fisker --------------------------------- */
  Fisker: {
    logo: '/images/catalog/logo/Fisker-100x100.png',
    name: 'Fisker',
    models: [
      {
        id: '595',
        model: 'Karma',
        year: ['2011', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/fisker/5184-250x150.jpg',
        title: 'Килимки в салон Fisker Karma 2011-2012 I покоління EU Sedan',
        titleKey: 'fisker1',
      },
    ],
  },
  /* ---------------------------------- Ford ---------------------------------- */
  Ford: {
    logo: '/images/catalog/logo/ford-100x100.png',
    name: 'Ford',
    models: [
      {
        id: '596',
        model: 'B-MAX',
        year: ['2012', '2017'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/4878-250x150.jpg',
        title: 'Килимки в салон Ford B-MAX 2012-2017 I покоління EU Minivan',
        titleKey: 'ford1',
      },
      {
        id: '597',
        model: 'C-MAX',
        year: ['2010', '2015'],
        body: 'Minivan ',
        market: 'EU',
        img: '/images/catalog/models/ford/4881-250x150.jpg',
        title: 'Килимки в салон Ford C-MAX (С344) 2010-2015 II покоління EU Minivan дорест',
        titleKey: 'ford2',
      },
      {
        id: '598',
        model: 'C-MAX',
        year: ['2010', '2015'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/ford/4880-250x150.jpg',
        title: 'Килимки в салон Ford C-MAX (С344) 2010-2015 II покоління USA Minivan дорест Hybrid',
        titleKey: 'ford3',
      },
      {
        id: '599',
        model: 'C-MAX',
        year: ['2015', '2019'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/4884-250x150.jpg',
        title: 'Килимки в салон Ford C-MAX (С344) 2015-2019 II покоління EU Minivan рест',
        titleKey: 'ford4',
      },
      {
        id: '600',
        model: 'C-MAX',
        year: ['2015', '2019'],
        body: 'Hybrid',
        market: 'USA',
        img: '/images/catalog/models/ford/4883-250x150.jpg',
        title: 'Килимки в салон Ford C-MAX (С344) 2015-2019 II покоління USA Minivan рест Hybrid',
        titleKey: 'ford5',
      },
      {
        id: '601',
        model: 'C-MAX',
        year: ['2003', '2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/4879-250x150.jpg',
        title: 'Килимки в салон Ford C-MAX 2003-2010 I покоління EU Minivan',
        titleKey: 'ford6',
      },
      {
        id: '602',
        model: 'C-MAX',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/5994-250x150.jpg',
        title: 'Килимки в салон Ford C-MAX Energi (С344) 2012-2017 II покоління USA Minivan Energi',
        titleKey: 'ford7',
      },
      {
        id: '603',
        model: 'Custom',
        year: ['2012'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/ford/4885-250x150.jpg',
        title: 'Килимки в салон Ford Custom 2012-… I покоління EU VAN',
        titleKey: 'ford8',
      },
      {
        id: '604',
        model: 'Eco Sport',
        year: ['2013', '2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/ford/4886-250x150.jpg',
        title: 'Килимки в салон Ford Eco Sport 2013-2018 II покоління EU/USA Crossover дорест',
        titleKey: 'ford9',
      },
      {
        id: '605',
        model: 'Eco Sport',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4887-250x150.jpg',
        title: 'Килимки в салон Ford Eco Sport 2018-… II покоління USA Crossover рест',
        titleKey: 'ford10',
      },

      {
        id: '606',
        model: 'Edge',
        year: ['2006', '2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/ford/4888-250x150.jpg',
        title: 'Килимки в салон Ford Edge 2006-2014 I покоління EU/USA Crossover дорест',
        titleKey: 'ford11',
      },
      {
        id: '607',
        model: 'Edge',
        year: ['2010', '2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/ford/4889-250x150.jpg',
        title: 'Килимки в салон Ford Edge 2010-2014 I покоління EU/USA Crossover рест',
        titleKey: 'ford12',
      },
      {
        id: '608',
        model: 'Edge',
        year: ['2014', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4890-250x150.jpg',
        title: 'Килимки в салон Ford Edge 2014-2018 II покоління USA Crossover дорест',
        titleKey: 'ford13',
      },
      {
        id: '609',
        model: 'Edge',
        year: ['2018', '2023'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4891-250x150.jpg',
        title: 'Килимки в салон Ford Edge 2018-2023 II покоління USA Crossover рест',
        titleKey: 'ford14',
      },
      {
        id: '610',
        model: 'Escape',
        year: ['2000', '2007'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4892-250x150.jpg',
        title: 'Килимки в салон Ford Escape 2000-2007 I покоління USA Crossover',
        titleKey: 'ford15',
      },
      {
        id: '611',
        model: 'Escape',
        year: ['2008', '2012'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4893-250x150.jpg',
        title: 'Килимки в салон Ford Escape 2008-2012 II покоління USA Crossover',
        titleKey: 'ford16',
      },
      {
        id: '612',
        model: 'Escape',
        year: ['2008', '2012'],
        body: 'Crossover Hybrid',
        market: 'USA',
        img: '/images/catalog/models/ford/4894-250x150.jpg',
        title: 'Килимки в салон Ford Escape 2008-2012 II покоління USA Crossover Hybrid',
        titleKey: 'ford17',
      },
      {
        id: '613',
        model: 'Escape',
        year: ['2012', '2016'],
        body: 'Crossover дорест',
        market: 'USA',
        img: '/images/catalog/models/ford/4895-250x150.jpg',
        title: 'Килимки в салон Ford Escape 2012-2016 III покоління USA Crossover дорест',
        titleKey: 'ford18',
      },
      {
        id: '614',
        model: 'Escape',
        year: ['2016', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4896-250x150.jpg',
        title: 'Килимки в салон Ford Escape 2016-2019 III покоління USA Crossover рест',
        titleKey: 'ford19',
      },
      {
        id: '615',
        model: 'Escape',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4898-250x150.jpg',
        title: 'Килимки в салон Ford Escape 2019-… IV покоління USA Crossover',
        titleKey: 'ford20',
      },

      {
        id: '616',
        model: 'Escape',
        year: ['2019'],
        body: 'Crossover Hybrid',
        market: 'USA',
        img: '/images/catalog/models/ford/4897-250x150.jpg',
        title: 'Килимки в салон Ford Escape 2019-… IV покоління USA Crossover Hybrid',
        titleKey: 'ford21',
      },
      {
        id: '617',
        model: 'Escort',
        year: ['1980', '1986'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/5993-250x150.jpg',
        title: 'Килимки в салон Ford Escort (III) 1980-1986 III покоління EU Universal',
        titleKey: 'ford22',
      },
      {
        id: '618',
        model: 'Escort',
        year: ['1986', '1990'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4899-250x150.jpg',
        title: 'Килимки в салон Ford Escort (IV) 1986-1990 IV покоління EU Hatchback 3-х дверна',
        titleKey: 'ford23',
      },
      {
        id: '619',
        model: 'Escort',
        year: ['1986', '1990'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4900-250x150.jpg',
        title: 'Килимки в салон Ford Escort (IV) 1986-1990 IV покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford24',
      },
      {
        id: '620',
        model: 'Escort',
        year: ['1986', '1990'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4902-250x150.jpg',
        title: 'Килимки в салон Ford Escort (IV) 1986-1990 IV покоління EU Sedan',
        titleKey: 'ford25',
      },
      {
        id: '621',
        model: 'Escort',
        year: ['1986', '1990'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4901-250x150.jpg',
        title: 'Килимки в салон Ford Escort (IV) 1986-1990 IV покоління EU Universal',
        titleKey: 'ford26',
      },
      {
        id: '622',
        model: 'Escort',
        year: ['1990', '1992'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4903-250x150.jpg',
        title: 'Килимки в салон Ford Escort (V) 1990-1992 V покоління EU Hatchback 3-х дверна',
        titleKey: 'ford27',
      },
      {
        id: '623',
        model: 'Escort',
        year: ['1990', '1992'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4905-250x150.jpg',
        title: 'Килимки в салон Ford Escort (V) 1990-1992 V покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford28',
      },
      {
        id: '624',
        model: 'Escort',
        year: ['1990', '1992'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4904-250x150.jpg',
        title: 'Килимки в салон Ford Escort (V) 1990-1992 V покоління EU Universal',
        titleKey: 'ford29',
      },
      {
        id: '625',
        model: 'Escort',
        year: ['1992', '1995'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4906-250x150.jpg',
        title: 'Килимки в салон Ford Escort (VI) 1992-1995 VI покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford30',
      },

      {
        id: '626',
        model: 'Escort',
        year: ['1992', '1995'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4907-250x150.jpg',
        title: 'Килимки в салон Ford Escort (VI) 1992-1995 VI покоління EU Universal',
        titleKey: 'ford31',
      },
      {
        id: '627',
        model: 'Escort',
        year: ['1995', '2000'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4909-250x150.jpg',
        title: 'Килимки в салон Ford Escort (VII) 1995-2000 VII покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford32',
      },
      {
        id: '628',
        model: 'Escort',
        year: ['1995', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4908-250x150.jpg',
        title: 'Килимки в салон Ford Escort (VII) 1995-2000 VII покоління EU Universal',
        titleKey: 'ford33',
      },
      {
        id: '629',
        model: 'Expedition',
        year: ['2007', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4913-250x150.jpg',
        title: 'Килимки в салон Ford Expedition (U324) 2007-2017 III покоління EU Crossover',
        titleKey: 'ford34',
      },
      {
        id: '630',
        model: 'Expedition',
        year: ['2007', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4911-250x150.jpg',
        title: 'Килимки в салон Ford Expedition (U324) 2007-2017 III покоління USA Crossover 7-ми місна Long',
        titleKey: 'ford35',
      },
      {
        id: '631',
        model: 'Expedition',
        year: ['1996', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4910-250x150.jpg',
        title: 'Килимки в салон Ford Expedition (UN93) 1996-2002 I покоління EU Crossover',
        titleKey: 'ford36',
      },
      {
        id: '632',
        model: 'Expedition',
        year: ['2007', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4912-250x150.jpg',
        title: 'Килимки в салон Ford Expedition Limited (U324) 2007-2017 III покоління USA Crossover',
        titleKey: 'ford37',
      },
      {
        id: '633',
        model: 'Expedition',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4914-250x150.jpg',
        title: 'Килимки в салон Ford Expedition Max (U553) 2017-… IV покоління EU Crossover 7-ми місна',
        titleKey: 'ford38',
      },
      {
        id: '634',
        model: 'Explorer',
        year: ['1995', '2001'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4917-250x150.jpg',
        title: 'Килимки в салон Ford Explorer (UN150) 1995-2001 II покоління USA Crossover 5-ти дверна',
        titleKey: 'ford39',
      },
      {
        id: '635',
        model: 'Explorer',
        year: ['1990', '1994'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4915-250x150.jpg',
        title: 'Килимки в салон Ford Explorer 1990-1994 I покоління EU Crossover',
        titleKey: 'ford40',
      },

      {
        id: '636',
        model: 'Explorer',
        year: ['2001', '2005'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4918-250x150.jpg',
        title: 'Килимки в салон Ford Explorer 2001-2005 III покоління USA Crossover',
        titleKey: 'ford41',
      },
      {
        id: '637',
        model: 'Explorer',
        year: ['2010', '2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4919-250x150.jpg',
        title: 'Килимки в салон Ford Explorer 2010-2016 V покоління USA Crossover дорест 7-ми місна',
        titleKey: 'ford42',
      },
      {
        id: '638',
        model: 'Explorer',
        year: ['2010', '2019'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/ford/4920-250x150.jpg',
        title: 'Килимки в салон Ford Explorer 2010-2019 V покоління EU/USA Crossover 5-ти місна',
        titleKey: 'ford43',
      },
      {
        id: '639',
        model: 'Explorer',
        year: ['2016', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4922-250x150.jpg',
        title: 'Килимки в салон Ford Explorer 2016-2019 V покоління USA Crossover рест 6-ти місна',
        titleKey: 'ford44',
      },
      {
        id: '640',
        model: 'Explorer',
        year: ['2016', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4921-250x150.jpg',
        title: 'Килимки в салон Ford Explorer 2016-2019 V покоління USA Crossover рест 7-ми місна',
        titleKey: 'ford45',
      },
      {
        id: '641',
        model: 'Explorer',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4923-250x150.jpg',
        title: 'Килимки в салон Ford Explorer 2019-… VI покоління USA Crossover 7-ми місна',
        titleKey: 'ford46',
      },
      {
        id: '642',
        model: 'Explorer',
        year: ['1995', '2001'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4916-250x150.jpg',
        title: 'Килимки в салон Ford Explorer Sport 1995-2001 II покоління USA Crossover',
        titleKey: 'ford47',
      },
      {
        id: '643',
        model: 'F-150',
        year: ['2004', '2008'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/ford/4924-250x150.jpg',
        title: 'Килимки в салон Ford F-150 2004-2008 XI покоління USA Pickup 4-х дверна Crew Cab',
        titleKey: 'ford48',
      },
      {
        id: '644',
        model: 'F-150',
        year: ['2014', '2017'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/ford/4925-250x150.jpg',
        title: 'Килимки в салон Ford F-150 2014-2017 XIII покоління USA Pickup дорест 2-х дверна SuperCab',
        titleKey: 'ford49',
      },
      {
        id: '645',
        model: 'F-150',
        year: ['2014', '2021'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/ford/4926-250x150.jpg',
        title: 'Килимки в салон Ford F-150 2014-2021 XIII покоління USA Pickup 4-х дверна Crew Cab',
        titleKey: 'ford50',
      },

      {
        id: '646',
        model: 'F-150',
        year: ['2014', '2021'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/ford/4927-250x150.jpg',
        title: 'Килимки в салон Ford F-150 Raptor 2014-2021 XIII покоління USA Pickup 4-х дверна SuperCrew',
        titleKey: 'ford51',
      },
      {
        id: '647',
        model: 'Fiesta (Mk 8)',
        year: ['2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4936-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk 8) 2017-… VII покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford52',
      },
      {
        id: '648',
        model: 'Fiesta',
        year: ['2017'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/ford/4937-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk 8) 2017-… VII покоління USA Hatchback',
        titleKey: 'ford53',
      },
      {
        id: '649',
        model: 'Fiesta',
        year: ['1989', '1997'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4928-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk3) 1989-1997 III покоління EU Hatchback',
        titleKey: 'ford54',
      },
      {
        id: '650',
        model: 'Fiesta',
        year: ['1999', '2002'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4930-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk5) 1999-2002 IV покоління EU Hatchback 3-х дверна',
        titleKey: 'ford55',
      },
      {
        id: '651',
        model: 'Fiesta',
        year: ['1999', '2002'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4929-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk5) 1999-2002 IV покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford56',
      },
      {
        id: '652',
        model: 'Fiesta',
        year: ['2002', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4932-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk6) 2002-2008 V покоління EU Hatchback 3-х дверна',
        titleKey: 'ford57',
      },
      {
        id: '653',
        model: 'Fiesta',
        year: ['2002', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4931-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk6) 2002-2008 V покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford58',
      },
      {
        id: '654',
        model: 'Fiesta',
        year: ['2009', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4934-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk7) 2009-2017 VI покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford59',
      },
      {
        id: '655',
        model: 'Fiesta',
        year: ['2009', '2017'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/ford/4935-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk7) 2009-2017 VI покоління USA Hatchback 5-ти дверна',
        titleKey: 'ford60',
      },

      {
        id: '656',
        model: 'Fiesta',
        year: ['2009', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4933-250x150.jpg',
        title: 'Килимки в салон Ford Fiesta (Mk7) 2009-2019 VI покоління USA Sedan',
        titleKey: 'ford61',
      },
      {
        id: '657',
        model: 'Flex',
        year: ['2009', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/ford/4938-250x150.jpg',
        title: 'Килимки в салон Ford Flex 2009-2019 I покоління USA Crossover рест 6-ти місна',
        titleKey: 'ford62',
      },
      {
        id: '658',
        model: 'Focus',
        year: ['1998', '2001'],
        body: 'Hatchback ',
        market: 'EU',
        img: '/images/catalog/models/ford/4941-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 1998-2001 I покоління EU Hatchback дорест 3-х дверна',
        titleKey: 'ford63',
      },
      {
        id: '659',
        model: 'Focus',
        year: ['1998', '2001'],
        body: 'Hatchback ',
        market: 'EU',
        img: '/images/catalog/models/ford/4942-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 1998-2001 I покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'ford64',
      },
      {
        id: '660',
        model: 'Focus',
        year: ['1998', '2001'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4940-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 1998-2001 I покоління EU Sedan дорест',
        titleKey: 'ford65',
      },
      {
        id: '661',
        model: 'Focus',
        year: ['1998', '2001'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4939-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 1998-2001 I покоління EU Universal дорест',
        titleKey: 'ford66',
      },
      {
        id: '662',
        model: 'Focus',
        year: ['2001', '2004'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4945-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 2001-2004 I покоління EU Hatchback рест 3-х дверна',
        titleKey: 'ford67',
      },
      {
        id: '663',
        model: 'Focus',
        year: ['2001', '2004'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4946-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 2001-2004 I покоління EU Hatchback рест 5-ти дверна',
        titleKey: 'ford68',
      },
      {
        id: '664',
        model: 'Focus',
        year: ['2001', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4944-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 2001-2004 I покоління EU Sedan рест',
        titleKey: 'ford69',
      },
      {
        id: '665',
        model: 'Focus',
        year: ['2001', '2004'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4943-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 2001-2004 I покоління EU Universal рест',
        titleKey: 'ford70',
      },

      {
        id: '666',
        model: 'Focus',
        year: ['2004', '2007'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4947-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C170) 2004-2007 I покоління USA Sedan рест',
        titleKey: 'ford71',
      },
      {
        id: '667',
        model: 'Focus',
        year: ['2004', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4950-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C307) 2004-2011 II покоління EU Hatchback 3-х дверна',
        titleKey: 'ford72',
      },
      {
        id: '668',
        model: 'Focus',
        year: ['2004', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4951-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C307) 2004-2011 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'ford73',
      },
      {
        id: '669',
        model: 'Focus',
        year: ['2004', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4948-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C307) 2004-2011 II покоління EU Sedan',
        titleKey: 'ford74',
      },
      {
        id: '670',
        model: 'Focus',
        year: ['2004', '2011'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4949-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C307) 2004-2011 II покоління EU Universal',
        titleKey: 'ford75',
      },
      {
        id: '671',
        model: 'Focus',
        year: ['2011', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4955-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C346) 2011-2018 III покоління EU Hatchback',
        titleKey: 'ford76',
      },
      {
        id: '672',
        model: 'Focus',
        year: ['2011', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4952-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C346) 2011-2018 III покоління EU Sedan',
        titleKey: 'ford77',
      },
      {
        id: '673',
        model: 'Focus',
        year: ['2011', '2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4954-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C346) 2011-2018 III покоління EU Universal',
        titleKey: 'ford78',
      },
      {
        id: '674',
        model: 'Focus',
        year: ['2011', '2018'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/ford/4956-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C346) 2011-2018 III покоління USA Hatchback',
        titleKey: 'ford79',
      },
      {
        id: '675',
        model: 'Focus',
        year: ['2011', '2018'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/ford/4953-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C346) 2011-2018 III покоління USA Universal',
        titleKey: 'ford80',
      },

      {
        id: '676',
        model: 'Focus',
        year: ['2014', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4958-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C346) 2014-2018 III покоління USA Sedan',
        titleKey: 'ford81',
      },
      {
        id: '677',
        model: 'Focus',
        year: ['2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4959-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C519) 2018-… IV покоління EU Hatchback',
        titleKey: 'ford82',
      },
      {
        id: '678',
        model: 'Focus',
        year: ['2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4960-250x150.jpg',
        title: 'Килимки в салон Ford Focus (C519) 2018-… IV покоління EU Universal',
        titleKey: 'ford83',
      },
      {
        id: '679',
        model: 'Focus',
        year: ['2011', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4957-250x150.jpg',
        title: 'Килимки в салон Ford Focus ST 2011-2018 III покоління EU Hatchback',
        titleKey: 'ford84',
      },
      {
        id: '680',
        model: 'Fusion',
        year: ['2002', '2009'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4962-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2002-2009 I покоління USA Sedan дорест',
        titleKey: 'ford85',
      },
      {
        id: '681',
        model: 'Fusion',
        year: ['2002', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4961-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2002-2012 I покоління EU Hatchback',
        titleKey: 'ford86',
      },
      {
        id: '682',
        model: 'Fusion',
        year: ['2009', '2012'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4963-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2009-2012 I покоління USA Sedan рест',
        titleKey: 'ford87',
      },
      {
        id: '683',
        model: 'Fusion',
        year: ['2012', '2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4964-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2012-2016 II покоління USA Sedan дорест',
        titleKey: 'ford88',
      },
      {
        id: '684',
        model: 'Fusion',
        year: ['2012', '2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4965-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2012-2016 II покоління USA Sedan дорест Hybrid',
        titleKey: 'ford89',
      },
      {
        id: '685',
        model: 'Fusion',
        year: ['2014', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4967-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2014-2017 II покоління USA Sedan дорест Plug-in Hybrid',
        titleKey: 'ford90',
      },

      {
        id: '686',
        model: 'Fusion',
        year: ['2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4969-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2016-… II покоління USA Sedan рест',
        titleKey: 'ford91',
      },
      {
        id: '687',
        model: 'Fusion',
        year: ['2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4968-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2016-… II покоління USA Sedan рест Hybrid',
        titleKey: 'ford92',
      },
      {
        id: '688',
        model: 'Fusion',
        year: ['2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/4971-250x150.jpg',
        title: 'Килимки в салон Ford Fusion 2017-… II покоління USA Sedan рест Plug-in Hybrid',
        titleKey: 'ford93',
      },
      {
        id: '689',
        model: 'Galaxy',
        year: ['2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/4975-250x150.jpg',
        title: 'Килимки в салон Ford Galaxy (CK) 2015-… III покоління EU Minivan 7-ми місна',
        titleKey: 'ford94',
      },
      {
        id: '690',
        model: 'Galaxy',
        year: ['2006', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/4974-250x150.jpg',
        title: 'Килимки в салон Ford Galaxy (WA6) 2006-2015 II покоління EU Minivan 7-ми місна',
        titleKey: 'ford95',
      },
      {
        id: '691',
        model: 'Galaxy',
        year: ['1995', '2000'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/4972-250x150.jpg',
        title: 'Килимки в салон Ford Galaxy (WGR) 1995-2000 I покоління EU Minivan дорест 7-ми місна',
        titleKey: 'ford96',
      },
      {
        id: '692',
        model: 'Galaxy',
        year: ['2000', '2006'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/4973-250x150.jpg',
        title: 'Килимки в салон Ford Galaxy (WGR) 2000-2006 I покоління EU Minivan рест 7-ми місна',
        titleKey: 'ford97',
      },
      {
        id: '693',
        model: 'Granada',
        year: ['1977', '1985'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4976-250x150.jpg',
        title: 'Килимки в салон Ford Granada 1977-1985 II покоління EU Sedan',
        titleKey: 'ford98',
      },
      {
        id: '694',
        model: 'Grand',
        year: ['2010', '2019'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/4882-250x150.jpg',
        title: 'Килимки в салон Ford Grand C-MAX 2010-2019 II покоління EU Minivan 7-ми місна',
        titleKey: 'ford99',
      },
      {
        id: '695',
        model: 'Ka',
        year: ['2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4978-250x150.jpg',
        title: 'Килимки в салон Ford Ka (B562) 2014-… III покоління EU Hatchback',
        titleKey: 'ford100',
      },

      {
        id: '696',
        model: 'Ka',
        year: ['2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4979-250x150.jpg',
        title: 'Килимки в салон Ford Ka (B562) 2014-… III покоління EU Sedan',
        titleKey: 'ford101',
      },
      {
        id: '697',
        model: 'Ka',
        year: ['1996', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4977-250x150.jpg',
        title: 'Килимки в салон Ford Ka (KBT) 1996-2008 I покоління EU Hatchback 3-х дверна',
        titleKey: 'ford102',
      },
      {
        id: '698',
        model: 'Kuga',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4983-250x150.jpg',
        title: 'Килимки в салон Ford Kuga (CX482) 2019-… III покоління EU Crossover',
        titleKey: 'ford103',
      },
      {
        id: '699',
        model: 'Kuga',
        year: ['2008', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4980-250x150.jpg',
        title: 'Килимки в салон Ford Kuga 2008-2012 I покоління EU Crossover',
        titleKey: 'ford104',
      },
      {
        id: '700',
        model: 'Kuga',
        year: ['2012', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4981-250x150.jpg',
        title: 'Килимки в салон Ford Kuga 2012-2016 II покоління EU Crossover дорест',
        titleKey: 'ford105',
      },
      {
        id: '701',
        model: 'Kuga',
        year: ['2016', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4982-250x150.jpg',
        title: 'Килимки в салон Ford Kuga 2016-2019 II покоління EU Crossover рест',
        titleKey: 'ford106',
      },
      {
        id: '702',
        model: 'Maverick',
        year: ['2000', '2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/4984-250x150.jpg',
        title: 'Килимки в салон Ford Maverick 2000-2007 II покоління EU Crossover',
        titleKey: 'ford107',
      },
      {
        id: '703',
        model: 'Mondeo',
        year: ['1993', '1996'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/4985-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 1993-1996 I покоління EU Hatchback',
        titleKey: 'ford108',
      },
      {
        id: '704',
        model: 'Mondeo',
        year: ['1996', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4986-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 1996-2000 II покоління EU Sedan',
        titleKey: 'ford109',
      },
      {
        id: '705',
        model: 'Mondeo',
        year: ['1996', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/5975-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 1996-2000 II покоління EU Universal',
        titleKey: 'ford110',
      },

      {
        id: '706',
        model: 'Mondeo',
        year: ['2000', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4987-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2000-2005 III покоління EU Sedan дорест',
        titleKey: 'ford111',
      },
      {
        id: '707',
        model: 'Mondeo',
        year: ['2000', '2007'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/ford/4989-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2000-2007 III покоління EU Liftback',
        titleKey: 'ford112',
      },
      {
        id: '708',
        model: 'Mondeo',
        year: ['2000', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4988-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2000-2007 III покоління EU Universal',
        titleKey: 'ford113',
      },
      {
        id: '709',
        model: 'Mondeo',
        year: ['2005', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4990-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2005-2007 III покоління EU Sedan рест',
        titleKey: 'ford114',
      },
      {
        id: '710',
        model: 'Mondeo',
        year: ['2007', '2010'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/ford/4993-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2007-2010 IV покоління EU Liftback дорест',
        titleKey: 'ford115',
      },
      {
        id: '711',
        model: 'Mondeo',
        year: ['2007', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4992-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2007-2010 IV покоління EU Universal дорест',
        titleKey: 'ford116',
      },
      {
        id: '712',
        model: 'Mondeo',
        year: ['2007', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4991-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2007-2014 IV покоління EU Sedan',
        titleKey: 'ford117',
      },
      {
        id: '713',
        model: 'Mondeo',
        year: ['2010', '2014'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/ford/4995-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2010-2014 IV покоління EU Liftback рест',
        titleKey: 'ford118',
      },
      {
        id: '714',
        model: 'Mondeo',
        year: ['2010', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4994-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2010-2014 IV покоління EU Universal рест',
        titleKey: 'ford119',
      },
      {
        id: '715',
        model: 'Mondeo',
        year: ['2012', '2022'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/ford/4996-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2012-2022 V покоління EU Liftback',
        titleKey: 'ford120',
      },

      {
        id: '716',
        model: 'Mondeo',
        year: ['2014', '2022'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4999-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2014-2022 V покоління EU Sedan',
        titleKey: 'ford121',
      },
      {
        id: '717',
        model: 'Mondeo',
        year: ['2014', '2022'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/4997-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2014-2022 V покоління EU Sedan Hybrid',
        titleKey: 'ford122',
      },
      {
        id: '718',
        model: 'Mondeo',
        year: ['2014', '2022'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/4998-250x150.jpg',
        title: 'Килимки в салон Ford Mondeo 2014-2022 V покоління EU Universal',
        titleKey: 'ford123',
      },
      {
        id: '719',
        model: 'Mustang',
        year: ['2005', '2012'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/ford/5001-250x150.jpg',
        title: 'Килимки в салон Ford Mustang (S197) 2005-2012 V покоління USA Coupe дорест',
        titleKey: 'ford124',
      },
      {
        id: '720',
        model: 'Mustang',
        year: ['2009', '2014'],
        body: 'Cabrio',
        market: 'USA',
        img: '/images/catalog/models/ford/5002-250x150.jpg',
        title: 'Килимки в салон Ford Mustang (S197) 2009-2014 V покоління USA Cabrio рест',
        titleKey: 'ford125',
      },
      {
        id: '721',
        model: 'Mustang',
        year: ['2012', '2014'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/ford/5003-250x150.jpg',
        title: 'Килимки в салон Ford Mustang (S197) 2012-2014 V покоління USA Coupe рест',
        titleKey: 'ford126',
      },
      {
        id: '722',
        model: 'Mustang',
        year: ['2015', '2017'],
        body: 'Cabrio',
        market: 'USA',
        img: '/images/catalog/models/ford/5005-250x150.jpg',
        title: 'Килимки в салон Ford Mustang (S550) 2015-2017 VI покоління USA Cabrio дорест',
        titleKey: 'ford127',
      },
      {
        id: '723',
        model: 'Mustang',
        year: ['2015'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/ford/5004-250x150.jpg',
        title: 'Килимки в салон Ford Mustang (S550) 2015-… VI покоління USA Coupe',
        titleKey: 'ford128',
      },
      {
        id: '724',
        model: 'Mustang',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/5000-250x150.jpg',
        title: 'Килимки в салон Ford Mustang Mach-E 2020-… I покоління EU Crossover',
        titleKey: 'ford129',
      },
      {
        id: '725',
        model: 'Probe',
        year: ['1988', '1992'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/ford/5006-250x150.jpg',
        title: 'Килимки в салон Ford Probe 1988-1992 I покоління EU Coupe',
        titleKey: 'ford130',
      },

      {
        id: '726',
        model: 'Puma',
        year: ['1997', '2002'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/ford/5007-250x150.jpg',
        title: 'Килимки в салон Ford Puma 1997-2002 I покоління EU Coupe',
        titleKey: 'ford131',
      },
      {
        id: '727',
        model: 'Puma',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ford/5008-250x150.jpg',
        title: 'Килимки в салон Ford Puma 2019-… II покоління EU Crossover',
        titleKey: 'ford132',
      },
      {
        id: '728',
        model: 'Ranger',
        year: ['2011', '2015'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/ford/5012-250x150.jpg',
        title: 'Килимки в салон Ford Ranger (T6) 2011-2015 III покоління USA Pickup дорест 4-х дверна',
        titleKey: 'ford133',
      },
      {
        id: '729',
        model: 'Ranger',
        year: ['2015'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/ford/5013-250x150.jpg',
        title: 'Килимки в салон Ford Ranger (T6) 2015-… III покоління USA Pickup рест 4-х дверна',
        titleKey: 'ford134',
      },
      {
        id: '730',
        model: 'Ranger',
        year: ['1998', '2006'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/ford/5009-250x150.jpg',
        title: 'Килимки в салон Ford Ranger 1998-2006 I покоління USA Pickup',
        titleKey: 'ford135',
      },
      {
        id: '731',
        model: 'Ranger',
        year: ['2006', '2011'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/ford/5011-250x150.jpg',
        title: 'Килимки в салон Ford Ranger 2006-2011 II покоління EU Pickup праве кермо',
        titleKey: 'ford136',
      },
      {
        id: '732',
        model: 'Ranger',
        year: ['2006', '2011'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/ford/5010-250x150.jpg',
        title: 'Килимки в салон Ford Ranger 2006-2011 II покоління EU Pickup 4-х дверна Double Cab',
        titleKey: 'ford137',
      },
      {
        id: '733',
        model: 'S-Max',
        year: ['2006', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5019-250x150.jpg',
        title: 'Килимки в салон Ford S-Max 2006-2014 I покоління EU Minivan 7-ми місна',
        titleKey: 'ford138',
      },
      {
        id: '734',
        model: 'S-Max',
        year: ['2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5020-250x150.jpg',
        title: 'Килимки в салон Ford S-Max 2014-… II покоління EU Minivan 5-ти місна',
        titleKey: 'ford139',
      },
      {
        id: '735',
        model: 'Scorpio',
        year: ['1985', '1994'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ford/5014-250x150.jpg',
        title: 'Килимки в салон Ford Scorpio 1985-1994 I покоління EU Hatchback',
        titleKey: 'ford140',
      },

      {
        id: '736',
        model: 'Scorpio',
        year: ['1985', '1994'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/5015-250x150.jpg',
        title: 'Килимки в салон Ford Scorpio 1985-1994 I покоління EU Sedan',
        titleKey: 'ford141',
      },
      {
        id: '737',
        model: 'Scorpio',
        year: ['1985', '1994'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/ford/5016-250x150.jpg',
        title: 'Килимки в салон Ford Scorpio 1985-1994 I покоління EU Universal',
        titleKey: 'ford142',
      },
      {
        id: '738',
        model: 'Sierra',
        year: ['1982', '1987'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/5017-250x150.jpg',
        title: 'Килимки в салон Ford Sierra 1982-1987 I покоління EU Sedan',
        titleKey: 'ford143',
      },
      {
        id: '739',
        model: 'Sierra',
        year: ['1987', '1990'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ford/5018-250x150.jpg',
        title: 'Килимки в салон Ford Sierra 1987-1990 II покоління EU Sedan дорест',
        titleKey: 'ford144',
      },
      {
        id: '740',
        model: 'Taurus',
        year: ['1991', '1995'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/5021-250x150.jpg',
        title: 'Килимки в салон Ford Taurus 1991-1995 II покоління USA Sedan',
        titleKey: 'ford145',
      },
      {
        id: '741',
        model: 'Taurus',
        year: ['2009', '2012'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/5022-250x150.jpg',
        title: 'Килимки в салон Ford Taurus 2009-2012 VI покоління USA Sedan дорест',
        titleKey: 'ford146',
      },
      {
        id: '742',
        model: 'Taurus',
        year: ['2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/ford/5023-250x150.jpg',
        title: 'Килимки в салон Ford Taurus 2016-… VII покоління USA Sedan',
        titleKey: 'ford147',
      },
      {
        id: '743',
        model: 'Territory',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/ford/5024-250x150.jpg',
        title: 'Килимки в салон Ford Territory EV (CX743) 2018-… I покоління China Crossover',
        titleKey: 'ford148',
      },
      {
        id: '744',
        model: 'Tourneo Connect',
        year: ['2012', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5028-250x150.jpg',
        title: 'Килимки в салон Ford Tourneo Connect (Turkish Assembly) 2012-2021 II покоління EU Minivan пасажир',
        titleKey: 'ford149',
      },
      {
        id: '745',
        model: 'Tourneo Connect',
        year: ['2009', '2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5025-250x150.jpg',
        title: 'Килимки в салон Ford Tourneo Connect 2009-2012 I покоління EU Minivan рест',
        titleKey: 'ford150',
      },

      {
        id: '746',
        model: 'Tourneo Connect',
        year: ['2012', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5026-250x150.jpg',
        title: 'Килимки в салон Ford Tourneo Connect 2012-2021 II покоління EU Minivan пасажир',
        titleKey: 'ford151',
      },
      {
        id: '747',
        model: 'Tourneo Connect',
        year: ['2012', '2021'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/ford/5027-250x150.jpg',
        title: 'Килимки в салон Ford Tourneo Connect 2012-2021 II покоління USA Minivan пасажир',
        titleKey: 'ford152',
      },
      {
        id: '748',
        model: 'Tourneo Connect',
        year: ['2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5029-250x150.jpg',
        title: 'Килимки в салон Ford Tourneo Connect 2021-… III покоління EU Minivan пасажир',
        titleKey: 'ford153',
      },
      {
        id: '749',
        model: 'Tourneo Courier',
        year: ['2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5030-250x150.jpg',
        title: 'Килимки в салон Ford Tourneo Courier 2014-… I покоління EU Minivan',
        titleKey: 'ford154',
      },
      {
        id: '750',
        model: 'Tourneo Custom',
        year: ['2012', '2022'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5031-250x150.jpg',
        title: 'Килимки в салон Ford Tourneo Custom 2012-2022 I покоління EU Minivan Long',
        titleKey: 'ford155',
      },
      {
        id: '751',
        model: 'Tourneo Custom',
        year: ['2012', '2022'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5032-250x150.jpg',
        title: 'Килимки в салон Ford Tourneo Custom 2012-2022 I покоління EU Minivan 7-ми місна',
        titleKey: 'ford156',
      },
      {
        id: '752',
        model: 'Transit',
        year: ['1986', '1994'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/ford/5037-250x150.jpg',
        title: 'Килимки в салон Ford Transit 1986-1994 IV покоління EU VAN дорест',
        titleKey: 'ford157',
      },
      {
        id: '753',
        model: 'Transit',
        year: ['1994', '2003'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/ford/5038-250x150.jpg',
        title: 'Килимки в салон Ford Transit 1994-2003 IV покоління EU VAN рест',
        titleKey: 'ford158',
      },
      {
        id: '754',
        model: 'Transit',
        year: ['2000', '2006'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/ford/5039-250x150.jpg',
        title: 'Килимки в салон Ford Transit 2000-2006 V покоління EU VAN',
        titleKey: 'ford159',
      },
      {
        id: '755',
        model: 'Transit',
        year: ['2006', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/ford/5040-250x150.jpg',
        title: 'Килимки в салон Ford Transit 2006-2014 VI покоління EU VAN',
        titleKey: 'ford160',
      },

      {
        id: '756',
        model: 'Transit',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/ford/5041-250x150.jpg',
        title: 'Килимки в салон Ford Transit 2014-… VII покоління EU VAN',
        titleKey: 'ford161',
      },
      {
        id: '757',
        model: 'Transit',
        year: ['2014'],
        body: 'VAN',
        market: 'USA',
        img: '/images/catalog/models/ford/5042-250x150.jpg',
        title: 'Килимки в салон Ford Transit 350 2014-… VII покоління USA VAN середня криша/пасажир',
        titleKey: 'ford162',
      },
      {
        id: '758',
        model: 'Transit Connect',
        year: ['2002', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5033-250x150.jpg',
        title: 'Килимки в салон Ford Transit Connect (V227) 2002-2013 I покоління EU Minivan',
        titleKey: 'ford163',
      },
      {
        id: '759',
        model: 'Transit Connect',
        year: ['2013', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ford/5035-250x150.jpg',
        title: 'Килимки в салон Ford Transit Connect 2013-2021 II покоління EU Minivan 7-ми місна пасажир',
        titleKey: 'ford164',
      },
      {
        id: '760',
        model: 'Transit Connect',
        year: ['2013', '2021'],
        body: 'VAN',
        market: 'EU/USA',
        img: '/images/catalog/models/ford/5036-250x150.jpg',
        title: 'Килимки в салон Ford Transit Connect XL 2013-2021 II покоління EU/USA VAN',
        titleKey: 'ford165',
      },
      {
        id: '761',
        model: 'Transit Custom',
        year: ['2023'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Ford Transit Custom (Tourneo) 2023-… II покоління EU VAN',
        titleKey: 'ford166',
      },
      {
        id: '762',
        model: 'Transit Custom',
        year: ['2012', '2023'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/ford/5034-250x150.jpg',
        title: 'Килимки в салон Ford Transit Custom 2012-2023 I покоління EU VAN',
        titleKey: 'ford167',
      },
      {
        id: '763',
        model: 'Transit',
        year: ['2014'],
        body: 'VAN',
        market: 'USA',
        img: '/images/catalog/models/ford/5043-250x150.jpg',
        title: 'Килимки в салон Ford Transit T-350 2014-… VII покоління USA VAN низька криша',
        titleKey: 'ford168',
      },
    ],
  },
  /* ----------------------------------- GAZ ---------------------------------- */
  Gaz: {
    logo: '/images/catalog/logo/gaz-100x100.png',
    name: 'GAZ',
    models: [
      {
        id: '764',
        model: '21',
        year: ['1956', '1970'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/gaz/5878-250x150.jpg',
        title: 'Килимки в салон GAZ 21 Волга 1956-1970 I покоління RU Sedan',

        titleKey: 'gaz1',
      },
      {
        id: '765',
        model: '2410',
        year: ['1970', '1985'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/gaz/5879-250x150.jpg',
        title: 'Килимки в салон GAZ 2410 Волга 1970-1985 I покоління RU Sedan',
        titleKey: 'gaz2',
      },
      {
        id: '766',
        model: '2410',
        year: ['1985', '1992'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/gaz/5880-250x150.jpg',
        title: 'Килимки в салон GAZ 2410 Волга 1985-1992 I покоління RU Sedan',
        titleKey: 'gaz3',
      },
      {
        id: '767',
        model: '3110',
        year: ['1997', '2005'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/gaz/5881-250x150.jpg',
        title: 'Килимки в салон GAZ 3110 Волга 1997-2005 I покоління RU Sedan',
        titleKey: 'gaz4',
      },
      {
        id: '768',
        model: '31105',
        year: ['2004', '2007'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/gaz/5883-250x150.jpg',
        title: 'Килимки в салон GAZ 31105 Волга 2004-2007 I покоління RU Sedan дорест',
        titleKey: 'gaz5',
      },
      {
        id: '769',
        model: '3302',
        year: ['1994'],
        body: 'VAN',
        market: 'RU',
        img: '/images/catalog/models/gaz/5882-250x150.jpg',
        title: 'Килимки в салон GAZ 3302',
        titleKey: 'gaz6',
      },
      {
        id: '770',
        model: 'Next',
        year: ['2013'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/gaz/5884-250x150.jpg',
        title: 'Килимки в салон GAZ Next Газель 2013-… I покоління EU VAN',
        titleKey: 'gaz7',
      },
      {
        id: '771',
        model: 'М-12',
        year: ['1950', '1960'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/gaz/5885-250x150.jpg',
        title: 'Килимки в салон GAZ М-12 ЗИМ 1950-1960 I покоління RU Sedan',
        titleKey: 'gaz8',
      },
      {
        id: '772',
        model: 'М-20',
        year: ['1946', '1958'],
        body: 'Cabrio',
        market: 'RU',
        img: '/images/catalog/models/gaz/5886-250x150.jpg',
        title: 'Килимки в салон GAZ М-20 Победа 1946-1958 I покоління RU Cabrio',
        titleKey: 'gaz9',
      },
      {
        id: '773',
        model: 'М-20',
        year: ['1946', '1958'],
        body: 'Hatchback',
        market: 'RU',
        img: '/images/catalog/models/gaz/5887-250x150.jpg',
        title: 'Килимки в салон GAZ М-20 Победа 1946-1958 I покоління RU Hatchback',
        titleKey: 'gaz10',
      },
    ],
  },
  /* ---------------------------------- Geely --------------------------------- */
  Geely: {
    logo: '/images/catalog/logo/geely-100x100.png',
    name: 'Geely',
    models: [
      {
        id: '774',
        model: 'Atlas',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/geely/4857-250x150.jpg',
        title: 'Килимки в салон Geely Atlas (NL-3) 2016-… I покоління EU Crossover',
        titleKey: 'geely1',
      },
      {
        id: '775',
        model: 'Atlas',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/geely/4858-250x150.jpg',
        title: 'Килимки в салон Geely Atlas Pro (NL-3) 2020-… I покоління EU Crossover',
        titleKey: 'geely2',
      },
      {
        id: '776',
        model: 'CK',
        year: ['2005', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/geely/4859-250x150.jpg',
        title: 'Килимки в салон Geely CK 2005-2008 I покоління EU Sedan дорест',
        titleKey: 'geely3',
      },
      {
        id: '777',
        model: 'CK',
        year: ['2008', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/geely/4860-250x150.jpg',
        title: 'Килимки в салон Geely CK 2008-2016 I покоління EU Sedan рест',
        titleKey: 'geely4',
      },
      {
        id: '778',
        model: 'Coolray',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/geely/4861-250x150.jpg',
        title: 'Килимки в салон Geely Coolray (SX11) 2018-… I покоління EU Crossover',
        titleKey: 'geely5',
      },
      {
        id: '779',
        model: 'Emgrand',
        year: ['2021'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/geely/4869-250x150.jpg',
        title: 'Килимки в салон Geely Emgrand (SS11) 2021-… IV покоління EU Sedan',
        titleKey: 'geely6',
      },
      {
        id: '780',
        model: 'Emgrand',
        year: ['2009'],
        body: 'Hatchback',
        market: 'EU/China',
        img: '/images/catalog/models/geely/4863-250x150.jpg',
        title: 'Килимки в салон Geely Emgrand 7 (EC7) 2009-… I покоління EU/China Hatchback',
        titleKey: 'geely7',
      },
      {
        id: '781',
        model: 'Emgrand',
        year: ['2009'],
        body: 'Sedan',
        market: 'EU/China',
        img: '/images/catalog/models/geely/4862-250x150.jpg',
        title: 'Килимки в салон Geely Emgrand 7 (EC7) 2009-… I покоління EU/China Sedan',
        titleKey: 'geely8',
      },
      {
        id: '782',
        model: 'Emgrand',
        year: ['2010', '2014'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/geely/4864-250x150.jpg',
        title: 'Килимки в салон Geely Emgrand EC8 2010-2014 I покоління China Sedan',
        titleKey: 'geely9',
      },
      {
        id: '783',
        model: 'Emgrand',
        year: ['2015'],
        body: 'Sedan Electric',
        market: 'EU',
        img: '/images/catalog/models/geely/4866-250x150.jpg',
        title: 'Килимки в салон Geely Emgrand EV 2015-… I покоління EU Sedan Electric',
        titleKey: 'geely10',
      },

      {
        id: '784',
        model: 'Emgrand',
        year: ['2016'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/geely/4868-250x150.jpg',
        title: 'Килимки в салон Geely Emgrand GS 2016-… I покоління China Crossover',
        titleKey: 'geely11',
      },
      {
        id: '785',
        model: 'Emgrand',
        year: ['2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/geely/4867-250x150.jpg',
        title: 'Килимки в салон Geely Emgrand GT 2015-… I покоління EU Sedan',
        titleKey: 'geely12',
      },
      {
        id: '786',
        model: 'Emgrand',
        year: ['2011', '2016'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/geely/4865-250x150.jpg',
        title: 'Килимки в салон Geely Emgrand X7 2011-2016 I покоління China Crossover',
        titleKey: 'geely13',
      },
      {
        id: '787',
        model: 'GC5',
        year: ['2010'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/geely/4870-250x150.jpg',
        title: 'Килимки в салон Geely GC5 2010-… I покоління China Hatchback',
        titleKey: 'geely14',
      },
      {
        id: '788',
        model: 'Geometry',
        year: ['2019'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/geely/4871-250x150.jpg',
        title: 'Килимки в салон Geely Geometry A 2019-… I покоління China Sedan',
        titleKey: 'geely15',
      },
      {
        id: '789',
        model: 'Geometry',
        year: ['2019'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/geely/4872-250x150.jpg',
        title: 'Килимки в салон Geely Geometry C 2019-… I покоління China Crossover',
        titleKey: 'geely16',
      },
      {
        id: '790',
        model: 'LC Cross (GX2, Panda Cross)',
        year: ['2008'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/geely/4873-250x150.jpg',
        title: 'Килимки в салон Geely LC Cross (GX2, Panda Cross) 2008-… I покоління China Crossover',
        titleKey: 'geely17',
      },
      {
        id: '791',
        model: 'Maple',
        year: ['2004', '2016'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/geely/4874-250x150.jpg',
        title: 'Килимки в салон Geely Maple 2004-2016 China Sedan',
        titleKey: 'geely18',
      },
      {
        id: '792',
        model: 'MK',
        year: ['2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/geely/4875-250x150.jpg',
        title: 'Килимки в салон Geely MK 2006-… I покоління EU Sedan',
        titleKey: 'geely19',
      },
      {
        id: '793',
        model: 'MK',
        year: ['2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/geely/4876-250x150.jpg',
        title: 'Килимки в салон Geely MK Cross 2010-… I покоління EU Sedan',
        titleKey: 'geely20',
      },

      {
        id: '794',
        model: 'SL',
        year: ['2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/geely/4877-250x150.jpg',
        title: 'Килимки в салон Geely SL 2011-… I покоління EU Sedan',
        titleKey: 'geely21',
      },
    ],
  },
  /* ----------------------------------- GMC ---------------------------------- */
  Gmc: {
    logo: '/images/catalog/logo/GMC-100x100.png',
    name: 'GMC',
    models: [
      {
        id: '795',
        model: 'Acadia',
        year: ['2006', '2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/gmc/5177-250x150.jpg',
        title: 'Килимки в салон GMC Acadia 2006-2016 USA Crossover 6-ти місна',
        titleKey: 'gmc1',
      },
      {
        id: '796',
        model: 'Acadia',
        year: ['2006', '2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/gmc/5178-250x150.jpg',
        title: 'Килимки в салон GMC Acadia 2006-2016 USA Crossover 8-ми місна',
        titleKey: 'gmc2',
      },
      {
        id: '797',
        model: 'Acadia',
        year: ['2013', '2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/gmc/5179-250x150.jpg',
        title: 'Килимки в салон GMC Acadia 2013-2016 USA Crossover рест 7-ми місна',
        titleKey: 'gmc3',
      },
      {
        id: '798',
        model: 'Acadia',
        year: ['2016', '2023'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/gmc/5180-250x150.jpg',
        title: 'Килимки в салон GMC Acadia 2016-2023 USA Crossover 6-ти місна',
        titleKey: 'gmc4',
      },
      {
        id: '799',
        model: 'Sierra',
        year: ['2013', '2019'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/gmc/5181-250x150.jpg',
        title: 'Килимки в салон GMC Sierra 2013-2019 EU Pickup',
        titleKey: 'gmc5',
      },
      {
        id: '800',
        model: 'Terrain',
        year: ['2009', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/gmc/5182-250x150.jpg',
        title: 'Килимки в салон GMC Terrain 2009-2017 USA Crossover',
        titleKey: 'gmc6',
      },
      {
        id: '801',
        model: 'Terrain',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/gmc/5183-250x150.jpg',
        title: 'Килимки в салон GMC Terrain 2017-… EU Crossover',
        titleKey: 'gmc7',
      },
    ],
  },
  /* -------------------------------- GreatWall ------------------------------- */
  Greatwall: {
    logo: '/images/catalog/logo/great_wall-100x100.png',
    name: 'Great Wall',
    models: [
      {
        id: '802',
        model: 'Haval H3',
        year: ['2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4847-250x150.jpg',
        title: 'Килимки в салон Great Wall Haval H3 2005-… I покоління EU Crossover',
        titleKey: 'greatWall1',
      },
      {
        id: '803',
        model: 'Haval H2',
        year: ['2014', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4853-250x150.jpg',
        title: 'Килимки в салон Great Wall Haval H2 2014-2021 I покоління EU Crossover',
        titleKey: 'greatWall2',
      },
      {
        id: '804',
        model: 'Haval H5',
        year: ['2010', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4848-250x150.jpg',
        title: 'Килимки в салон Great Wall Haval H5 2010-2013 I покоління EU Crossover',
        titleKey: 'greatWall3',
      },
      {
        id: '805',
        model: 'Haval H6',
        year: ['2017', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4854-250x150.jpg',
        title: 'Килимки в салон Great Wall Haval H6 2017-2020 II покоління EU Crossover',
        titleKey: 'greatWall4',
      },
      {
        id: '806',
        model: 'Haval H6',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4855-250x150.jpg',
        title: 'Килимки в салон Great Wall Haval H6 2020-… III покоління EU Crossover',
        titleKey: 'greatWall5',
      },
      {
        id: '807',
        model: 'Haval Jolion',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4856-250x150.jpg',
        title: 'Килимки в салон Great Wall Haval Jolion 2020-… I покоління EU Crossover',
        titleKey: 'greatWall6',
      },
      {
        id: '808',
        model: 'Haval M2',
        year: ['2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4849-250x150.jpg',
        title: 'Килимки в салон Great Wall Haval M2 2010-… I покоління EU Crossover',
        titleKey: 'greatWall7',
      },
      {
        id: '809',
        model: 'Haval M4',
        year: ['2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4852-250x150.jpg',
        title: 'Килимки в салон Great Wall Haval M4 2012-… I покоління EU Crossover',
        titleKey: 'greatWall8',
      },
      {
        id: '810',
        model: 'Pegasus',
        year: ['2003', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4845-250x150.jpg',
        title: 'Килимки в салон Great Wall Pegasus 2003-2008 I покоління EU Crossover',
        titleKey: 'greatWall9',
      },
      {
        id: '811',
        model: 'Safe',
        year: ['2004', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4846-250x150.jpg',
        title: 'Килимки в салон Great Wall Safe 2004-2009 I покоління EU Crossover рест',
        titleKey: 'greatWall10',
      },

      {
        id: '812',
        model: 'Voleex',
        year: ['2010', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/great_wall/4850-250x150.jpg',
        title: 'Килимки в салон Great Wall Voleex C30 2010-2016 I покоління EU Sedan',
        titleKey: 'greatWall11',
      },
      {
        id: '813',
        model: 'Wingle',
        year: ['2011'],
        body: 'Pickup',
        market: '0',
        img: '/images/catalog/models/great_wall/4851-250x150.jpg',
        title: 'Килимки в салон Great Wall Wingle 5 2011-… I покоління 0 Pickup',
        titleKey: 'greatWall12',
      },
    ],
  },
  /* ---------------------------------- Haval --------------------------------- */
  Haval: {
    logo: '/images/catalog/logo/haval-100x100.png',
    name: 'Haval',
    models: [
      {
        id: '814',
        model: 'Dargo',
        year: ['2020'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/haval/4837-250x150.jpg',
        title: 'Килимки в салон Haval Dargo 2020-… I покоління China Crossover',
        titleKey: 'haval1',
      },
      {
        id: '815',
        model: 'H2',
        year: ['2014', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/haval/4838-250x150.jpg',
        title: 'Килимки в салон Haval H2 2014-2021 I покоління EU Crossover',
        titleKey: 'haval2',
      },
      {
        id: '816',
        model: 'H6',
        year: ['2011', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/haval/4839-250x150.jpg',
        title: 'Килимки в салон Haval H6 2011-2017 I покоління EU Crossover',
        titleKey: 'haval3',
      },
      {
        id: '817',
        model: 'H6',
        year: ['2017', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/haval/4840-250x150.jpg',
        title: 'Килимки в салон Haval H6 2017-2020 II покоління EU Crossover',
        titleKey: 'haval4',
      },
      {
        id: '818',
        model: 'H6',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/haval/4841-250x150.jpg',
        title: 'Килимки в салон Haval H6 2020-… III покоління EU Crossover',
        titleKey: 'haval5',
      },
      {
        id: '819',
        model: 'H9',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/haval/4842-250x150.jpg',
        title: 'Килимки в салон Haval H9 2019-… I покоління EU Crossover рест',
        titleKey: 'haval6',
      },
      {
        id: '820',
        model: 'Jolion',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/haval/4844-250x150.jpg',
        title: 'Килимки в салон Haval Jolion 2020-… I покоління EU Crossover',
        titleKey: 'haval7',
      },
      {
        id: '821',
        model: 'Jolion',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/haval/4843-250x150.jpg',
        title: 'Килимки в салон Haval Jolion 2020-… I покоління EU Crossover AWD',
        titleKey: 'haval8',
      },
    ],
  },
  /* ---------------------------------- Honda --------------------------------- */
  Honda: {
    logo: '/images/catalog/logo/honda-100x100.png',
    name: 'Honda',
    models: [
      {
        id: '822',
        model: 'Accord',
        year: ['1989', '1993'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4709-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CB) 1989-1993 IV покоління EU Sedan',
        titleKey: 'honda1',
      },
      {
        id: '823',
        model: 'Accord',
        year: ['1997', '2002'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4721-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CG) 1997-2002 VI покоління USA Coupe',
        titleKey: 'honda2',
      },
      {
        id: '824',
        model: 'Accord',
        year: ['1997', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4718-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CH) 1997-2002 VI покоління EU Sedan',
        titleKey: 'honda3',
      },
      {
        id: '825',
        model: 'Accord',
        year: ['1997', '2002'],
        body: 'Sedan праве кермо',
        market: 'EU',
        img: '/images/catalog/models/honda/4719-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CH) 1997-2002 VI покоління EU Sedan праве кермо',
        titleKey: 'honda4',
      },
      {
        id: '826',
        model: 'Accord',
        year: ['1997', '2002'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4720-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CH) 1997-2002 VI покоління USA Sedan',
        titleKey: 'honda5',
      },
      {
        id: '827',
        model: 'Accord',
        year: ['2012', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4711-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CR) 2012-2017 IX покоління USA Sedan',
        titleKey: 'honda6',
      },
      {
        id: '828',
        model: 'Accord',
        year: ['2012', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4710-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CR) 2012-2017 IX покоління USA Sedan Hybrid',
        titleKey: 'honda7',
      },
      {
        id: '829',
        model: 'Accord',
        year: ['2012', '2017'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/honda/4714-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CT) 2012-2017 IX покоління EU Coupe',
        titleKey: 'honda8',
      },
      {
        id: '830',
        model: 'Accord',
        year: ['2012', '2017'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4715-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CT) 2012-2017 IX покоління USA Coupe',
        titleKey: 'honda9',
      },
      {
        id: '831',
        model: 'Accord',
        year: ['2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4734-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CV) 2017-… X покоління USA Sedan',
        titleKey: 'honda10',
      },

      {
        id: '832',
        model: 'Accord',
        year: ['2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4733-250x150.jpg',
        title: 'Килимки в салон Honda Accord (CV) 2017-… X покоління USA Sedan Hybrid',
        titleKey: 'honda11',
      },
      {
        id: '833',
        model: 'Accord',
        year: ['1993', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4716-250x150.jpg',
        title: 'Килимки в салон Honda Accord 1993-1998 V покоління EU Sedan',
        titleKey: 'honda12',
      },
      {
        id: '834',
        model: 'Accord',
        year: ['1993', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4717-250x150.jpg',
        title: 'Килимки в салон Honda Accord 1993-1998 V покоління EU Sedan праве кермо',
        titleKey: 'honda13',
      },
      {
        id: '835',
        model: 'Accord',
        year: ['2002', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/honda/4726-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2002-2008 VII покоління EU Universal',
        titleKey: 'honda14',
      },
      {
        id: '836',
        model: 'Accord',
        year: ['2002', '2008'],
        body: 'Sedan',
        market: 'UA',
        img: '/images/catalog/models/honda/4722-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2002-2008 VII покоління UA Sedan',
        titleKey: 'honda15',
      },
      {
        id: '837',
        model: 'Accord',
        year: ['2002', '2008'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4724-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2002-2008 VII покоління USA Coupe',
        titleKey: 'honda16',
      },
      {
        id: '838',
        model: 'Accord',
        year: ['2002', '2008'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4723-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2002-2008 VII покоління USA Sedan',
        titleKey: 'honda17',
      },
      {
        id: '839',
        model: 'Accord',
        year: ['2002', '2008'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/honda/4725-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2002-2008 VII покоління USA Universal',
        titleKey: 'honda18',
      },
      {
        id: '840',
        model: 'Accord',
        year: ['2008', '2012'],
        body: 'Sedan',
        market: 'Japan',
        img: '/images/catalog/models/honda/4732-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2008-2012 VIII покоління Japan Sedan',
        titleKey: 'honda19',
      },
      {
        id: '841',
        model: 'Accord',
        year: ['2008', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4728-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2008-2015 VIII покоління EU Sedan',
        titleKey: 'honda20',
      },

      {
        id: '842',
        model: 'Accord',
        year: ['2008', '2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/honda/4731-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2008-2015 VIII покоління EU Universal',
        titleKey: 'honda21',
      },
      {
        id: '843',
        model: 'Accord',
        year: ['2008', '2015'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4729-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2008-2015 VIII покоління USA Coupe',
        titleKey: 'honda22',
      },
      {
        id: '844',
        model: 'Accord',
        year: ['2008', '2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4727-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2008-2015 VIII покоління USA Sedan',
        titleKey: 'honda23',
      },
      {
        id: '845',
        model: 'Accord',
        year: ['2008', '2015'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/honda/4730-250x150.jpg',
        title: 'Килимки в салон Honda Accord 2008-2015 VIII покоління USA Universal',
        titleKey: 'honda24',
      },
      {
        id: '846',
        model: 'Accord',
        year: ['2012', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4713-250x150.jpg',
        title: 'Килимки в салон Honda Accord Sport (CR) 2012-2017 IX покоління USA Sedan',
        titleKey: 'honda25',
      },
      {
        id: '847',
        model: 'City',
        year: ['2002', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4735-250x150.jpg',
        title: 'Килимки в салон Honda City 2002-2008 IV покоління EU Sedan',
        titleKey: 'honda26',
      },
      {
        id: '848',
        model: 'Civic',
        year: ['2011', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4741-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FB) (Turkish Assembly) 2011-2015 IX покоління EU Sedan',
        titleKey: 'honda27',
      },
      {
        id: '849',
        model: 'Civic',
        year: ['2011', '2015'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/honda/4738-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FB) 2011-2015 IX покоління EU/USA Sedan',
        titleKey: 'honda28',
      },
      {
        id: '850',
        model: 'Civic',
        year: ['2011', '2015'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4740-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FB) 2011-2015 IX покоління USA Coupe',
        titleKey: 'honda29',
      },
      {
        id: '851',
        model: 'Civic',
        year: ['2015', '2021'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4757-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FC) 2015-2021 X покоління USA Coupe',
        titleKey: 'honda30',
      },

      {
        id: '852',
        model: 'Civic',
        year: ['2015', '2021'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4758-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FC) 2015-2021 X покоління USA Sedan',
        titleKey: 'honda31',
      },
      {
        id: '853',
        model: 'Civic',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4751-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FD) 2005-2011 VIII покоління EU Sedan',
        titleKey: 'honda32',
      },
      {
        id: '854',
        model: 'Civic',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4752-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FD) 2005-2011 VIII покоління EU Sedan',
        titleKey: 'honda33',
      },
      {
        id: '855',
        model: 'Civic',
        year: ['2005', '2011'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4753-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FG) 2005-2011 VIII покоління USA Coupe',
        titleKey: 'honda34',
      },
      {
        id: '856',
        model: 'Civic',
        year: ['2005', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4750-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FK) 2005-2011 VIII покоління EU Hatchback 5-ти дверна',
        titleKey: 'honda35',
      },
      {
        id: '857',
        model: 'Civic',
        year: ['2011', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4739-250x150.jpg',
        title: 'Килимки в салон Honda Civic (FK) 2011-2014 IX покоління EU Hatchback 5-ти дверна',
        titleKey: 'honda36',
      },
      {
        id: '858',
        model: 'Civic',
        year: ['1987', '1991'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4737-250x150.jpg',
        title: 'Килимки в салон Honda Civic 1987-1991 IV покоління EU Sedan',
        titleKey: 'honda37',
      },
      {
        id: '859',
        model: 'Civic',
        year: ['1991', '1995'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/honda/4743-250x150.jpg',
        title: 'Килимки в салон Honda Civic 1991-1995 V покоління EU Coupe',
        titleKey: 'honda38',
      },
      {
        id: '860',
        model: 'Civic',
        year: ['1991', '1995'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4742-250x150.jpg',
        title: 'Килимки в салон Honda Civic 1991-1995 V покоління EU Sedan',
        titleKey: 'honda39',
      },
      {
        id: '861',
        model: 'Civic',
        year: ['1991', '1995'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4744-250x150.jpg',
        title: 'Килимки в салон Honda Civic 1991-1995 V покоління USA Coupe',
        titleKey: 'honda40',
      },

      {
        id: '862',
        model: 'Civic',
        year: ['1995', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4746-250x150.jpg',
        title: 'Килимки в салон Honda Civic 1995-2000 VI покоління EU Sedan',
        titleKey: 'honda41',
      },
      {
        id: '863',
        model: 'Civic',
        year: ['1995', '2000'],
        body: 'Hatchback',
        market: 'Japan',
        img: '/images/catalog/models/honda/4745-250x150.jpg',
        title: 'Килимки в салон Honda Civic 1995-2000 VI покоління Japan Hatchback 3-х дверна',
        titleKey: 'honda42',
      },
      {
        id: '864',
        model: 'Civic',
        year: ['1998', '2000'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4747-250x150.jpg',
        title: 'Килимки в салон Honda Civic 1998-2000 VI покоління USA Sedan',
        titleKey: 'honda43',
      },
      {
        id: '865',
        model: 'Civic',
        year: ['2000', '2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4749-250x150.jpg',
        title: 'Килимки в салон Honda Civic 2000-2005 VII покоління EU Hatchback 5-ти дверна',
        titleKey: 'honda44',
      },
      {
        id: '866',
        model: 'Civic',
        year: ['2000', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4748-250x150.jpg',
        title: 'Килимки в салон Honda Civic 2000-2005 VII покоління EU Sedan',
        titleKey: 'honda45',
      },
      {
        id: '867',
        model: 'Civic',
        year: ['2015', '2021'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4754-250x150.jpg',
        title: 'Килимки в салон Honda Civic 2015-2021 X покоління EU Sedan',
        titleKey: 'honda46',
      },
      {
        id: '868',
        model: 'Civic',
        year: ['2015', '2021'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/honda/4755-250x150.jpg',
        title: 'Килимки в салон Honda Civic 2015-2021 X покоління EU/USA Hatchback 5-ти дверна',
        titleKey: 'honda47',
      },
      {
        id: '869',
        model: 'Civic',
        year: ['2015', '2021'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/honda/4756-250x150.jpg',
        title: 'Килимки в салон Honda Civic Sport 2015-2021 X покоління USA Coupe',
        titleKey: 'honda48',
      },
      {
        id: '870',
        model: 'Civic',
        year: ['2007', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4736-250x150.jpg',
        title: 'Килимки в салон Honda Civic Type R (FN2) 2007-2011 III покоління EU Hatchback 3-х дверна',
        titleKey: 'honda49',
      },
      {
        id: '871',
        model: 'Clarity',
        year: ['2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4759-250x150.jpg',
        title: 'Килимки в салон Honda Clarity 2016-… II покоління USA Sedan',
        titleKey: 'honda50',
      },

      {
        id: '872',
        model: 'Clarity',
        year: ['2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/honda/4760-250x150.jpg',
        title: 'Килимки в салон Honda Clarity 2016-… II покоління USA Sedan hybrid',
        titleKey: 'honda51',
      },
      {
        id: '873',
        model: 'CR-V',
        year: ['2001', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4766-250x150.jpg',
        title: 'Килимки в салон Honda CR-V (RD) (United Kingdom) 2001-2006 II покоління EU Crossover',
        titleKey: 'honda52',
      },
      {
        id: '874',
        model: 'CR-V',
        year: ['2001', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4765-250x150.jpg',
        title: 'Килимки в салон Honda CR-V (RD) 2001-2005 II покоління EU Crossover дорест',
        titleKey: 'honda53',
      },
      {
        id: '875',
        model: 'CR-V',
        year: ['2001', '2006'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4767-250x150.jpg',
        title: 'Килимки в салон Honda CR-V (RD) 2001-2006 II покоління USA Crossover',
        titleKey: 'honda54',
      },
      {
        id: '876',
        model: 'CR-V',
        year: ['2005', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4768-250x150.jpg',
        title: 'Килимки в салон Honda CR-V (RD) 2005-2006 II покоління EU Crossover рест',
        titleKey: 'honda55',
      },
      {
        id: '877',
        model: 'CR-V',
        year: ['2006', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4770-250x150.jpg',
        title: 'Килимки в салон Honda CR-V (United Kingdom) 2006-2012 III покоління EU Crossover',
        titleKey: 'honda56',
      },
      {
        id: '878',
        model: 'CR-V',
        year: ['1996', '2001'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4764-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 1996-2001 I покоління EU Crossover',
        titleKey: 'honda57',
      },
      {
        id: '879',
        model: 'CR-V',
        year: ['2006', '2010'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4771-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2006-2010 III покоління USA Crossover дорест',
        titleKey: 'honda58',
      },
      {
        id: '880',
        model: 'CR-V',
        year: ['2006', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4769-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2006-2012 III покоління EU Crossover',
        titleKey: 'honda59',
      },
      {
        id: '881',
        model: 'CR-V',
        year: ['2006', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/5995-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2006-2012 III покоління EU Crossover праве кермо',
        titleKey: 'honda60',
      },

      {
        id: '882',
        model: 'CR-V',
        year: ['2010', '2012'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4772-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2010-2012 III покоління USA Crossover рест',
        titleKey: 'honda61',
      },
      {
        id: '883',
        model: 'CR-V',
        year: ['2012', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4774-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2012-2016 IV покоління EU Crossover',
        titleKey: 'honda62',
      },
      {
        id: '884',
        model: 'CR-V',
        year: ['2012', '2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4773-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2012-2016 IV покоління USA Crossover',
        titleKey: 'honda63',
      },
      {
        id: '885',
        model: 'CR-V',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4775-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2016-2020 V покоління USA Crossover дорест',
        titleKey: 'honda64',
      },
      {
        id: '886',
        model: 'CR-V',
        year: ['2016', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4776-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2016-2022 V покоління EU Crossover',
        titleKey: 'honda65',
      },
      {
        id: '887',
        model: 'CR-V',
        year: ['2019', '2022'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/honda/4777-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2019-2022 V покоління EU/USA Crossover',
        titleKey: 'honda66',
      },
      {
        id: '888',
        model: 'CR-V',
        year: ['2020', '2022'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4778-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2020-2022 V покоління USA Crossover рест',
        titleKey: 'honda67',
      },
      {
        id: '889',
        model: 'CR-V',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/5996-250x150.jpg',
        title: 'Килимки в салон Honda CR-V 2022-... VI покоління EU Crossover',
        titleKey: 'honda68',
      },
      {
        id: '890',
        model: 'CR-Z',
        year: ['2010', '2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/honda/4779-250x150.jpg',
        title: 'Килимки в салон Honda CR-Z 2010-2016 I покоління EU Coupe Hybrid',
        titleKey: 'honda69',
      },
      {
        id: '891',
        model: 'Crosstour',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4762-250x150.jpg',
        title: 'Килимки в салон Honda Crosstour 2009-2015 I покоління EU Crossover',
        titleKey: 'honda70',
      },

      {
        id: '892',
        model: 'Crosstour',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/honda/4761-250x150.jpg',
        title: 'Килимки в салон Honda Crosstour 2009-2015 I покоління EU/USA Crossover AWD',
        titleKey: 'honda71',
      },
      {
        id: '893',
        model: 'Crosstour',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4763-250x150.jpg',
        title: 'Килимки в салон Honda Crosstour 2009-2015 I покоління USA Crossover',
        titleKey: 'honda72',
      },
      {
        id: '894',
        model: 'Element',
        year: ['2003', '2011'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/honda/4780-250x150.jpg',
        title: 'Килимки в салон Honda Element 2003-2011 I покоління EU/USA Crossover',
        titleKey: 'honda73',
      },
      {
        id: '895',
        model: 'Elysion',
        year: ['2021'],
        body: 'Minivan',
        market: 'China',
        img: '/images/catalog/models/honda/4781-250x150.jpg',
        title: 'Килимки в салон Honda Elysion (RC) 2021-… II покоління China Minivan 7-ми місна',
        titleKey: 'honda74',
      },
      {
        id: '896',
        model: 'eNP1',
        year: ['2022'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/honda/4782-250x150.jpg',
        title: 'Килимки в салон Honda eNP1 2022-… I покоління China Crossover',
        titleKey: 'honda75',
      },
      {
        id: '897',
        model: 'eNS1',
        year: ['2022'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/honda/4783-250x150.jpg',
        title: 'Килимки в салон Honda eNS1 2022-… I покоління China Crossover',
        titleKey: 'honda76',
      },
      {
        id: '898',
        model: 'Everus VE-2',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/honda/4784-250x150.jpg',
        title: 'Килимки в салон Honda Everus VE-2 2018-… I покоління China Crossover',
        titleKey: 'honda77',
      },
      {
        id: '899',
        model: 'Fit',
        year: ['2001', '2008'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/honda/4785-250x150.jpg',
        title: 'Килимки в салон Honda Fit 2001-2008 I покоління USA Hatchback',
        titleKey: 'honda78',
      },
      {
        id: '900',
        model: 'Fit',
        year: ['2007', '2013'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/honda/4786-250x150.jpg',
        title: 'Килимки в салон Honda Fit 2007-2013 II покоління USA Hatchback',
        titleKey: 'honda79',
      },
      {
        id: '901',
        model: 'Fit',
        year: ['2013', '2020'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/honda/4787-250x150.jpg',
        title: 'Килимки в салон Honda Fit 2013-2020 III покоління USA Hatchback',
        titleKey: 'honda80',
      },

      {
        id: '902',
        model: 'Fit',
        year: ['2020'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/honda/4788-250x150.jpg',
        title: 'Килимки в салон Honda Fit 2020-… IV покоління USA Hatchback Hybrid',
        titleKey: 'honda81',
      },
      {
        id: '903',
        model: 'FR-V',
        year: ['2004', '2009'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/honda/4789-250x150.jpg',
        title: 'Килимки в салон Honda FR-V 2004-2009 I покоління EU Minivan',
        titleKey: 'honda82',
      },
      {
        id: '904',
        model: 'HR-V',
        year: ['1998', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4791-250x150.jpg',
        title: 'Килимки в салон Honda HR-V (GH) 1998-2005 I покоління EU Crossover 3-х дверна',
        titleKey: 'honda83',
      },
      {
        id: '905',
        model: 'HR-V',
        year: ['1998', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4790-250x150.jpg',
        title: 'Килимки в салон Honda HR-V (GH) 1998-2005 I покоління EU Crossover 5-ти дверна',
        titleKey: 'honda84',
      },
      {
        id: '906',
        model: 'HR-V',
        year: ['2015', '2021'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/honda/4792-250x150.jpg',
        title: 'Килимки в салон Honda HR-V (RU) 2015-2021 II покоління EU/USA Crossover',
        titleKey: 'honda85',
      },
      {
        id: '907',
        model: 'HR-V',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/honda/4793-250x150.jpg',
        title: 'Килимки в салон Honda HR-V (RV) 2021-… III покоління EU Crossover hybrid',
        titleKey: 'honda86',
      },
      {
        id: '908',
        model: 'Insight',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/honda/4795-250x150.jpg',
        title: 'Килимки в салон Honda Insight (ZE4) 2018-… III покоління EU/USA Sedan Hybrid',
        titleKey: 'honda87',
      },
      {
        id: '909',
        model: 'Insight',
        year: ['2009', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4794-250x150.jpg',
        title: 'Килимки в салон Honda Insight 2009-2014 II покоління EU Hatchback Hybrid',
        titleKey: 'honda88',
      },
      {
        id: '910',
        model: 'Integra',
        year: ['1985', '1989'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/honda/4796-250x150.jpg',
        title: 'Килимки в салон Honda Integra 1985-1989 I покоління EU Coupe',
        titleKey: 'honda89',
      },
      {
        id: '911',
        model: 'Jazz',
        year: ['2002', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4797-250x150.jpg',
        title: 'Килимки в салон Honda Jazz 2002-2008 II покоління EU Hatchback',
        titleKey: 'honda90',
      },

      {
        id: '912',
        model: 'Jazz',
        year: ['2002', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4798-250x150.jpg',
        title: 'Килимки в салон Honda Jazz 2002-2008 II покоління EU Hatchback праве кермо',
        titleKey: 'honda91',
      },
      {
        id: '913',
        model: 'Jazz',
        year: ['2008', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4799-250x150.jpg',
        title: 'Килимки в салон Honda Jazz 2008-2015 III покоління EU Hatchback',
        titleKey: 'honda92',
      },
      {
        id: '914',
        model: 'Jazz',
        year: ['2013', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4800-250x150.jpg',
        title: 'Килимки в салон Honda Jazz 2013-2020 IV покоління EU Hatchback',
        titleKey: 'honda93',
      },
      {
        id: '915',
        model: 'Jazz',
        year: ['2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/honda/4801-250x150.jpg',
        title: 'Килимки в салон Honda Jazz 2020-… V покоління EU Hatchback Hybrid',
        titleKey: 'honda94',
      },
      {
        id: '916',
        model: 'Legend',
        year: ['1996', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4802-250x150.jpg',
        title: 'Килимки в салон Honda Legend (KA9) 1996-2004 III покоління EU Sedan',
        titleKey: 'honda95',
      },
      {
        id: '917',
        model: 'Legend',
        year: ['2004', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/honda/4803-250x150.jpg',
        title: 'Килимки в салон Honda Legend (KB1) 2004-2012 IV покоління EU Sedan',
        titleKey: 'honda96',
      },
      {
        id: '918',
        model: 'M-NV',
        year: ['2020'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/honda/4804-250x150.jpg',
        title: 'Килимки в салон Honda M-NV 2020-… I покоління China Crossover',
        titleKey: 'honda97',
      },
      {
        id: '919',
        model: 'Odyssey',
        year: ['2008', '2013'],
        body: 'Minivan',
        market: 'Japan',
        img: '/images/catalog/models/honda/4805-250x150.jpg',
        title: 'Килимки в салон Honda Odyssey (RB3) 2008-2013 IV покоління Japan Minivan 8-ми місна',
        titleKey: 'honda98',
      },
      {
        id: '920',
        model: 'Odyssey',
        year: ['2010', '2017'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/honda/4806-250x150.jpg',
        title: 'Килимки в салон Honda Odyssey (RL5) 2010-2017 IV покоління USA Minivan 8-ми місна',
        titleKey: 'honda99',
      },
      {
        id: '921',
        model: 'Odyssey',
        year: ['2017'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/honda/4807-250x150.jpg',
        title: 'Килимки в салон Honda Odyssey 2017-… V покоління USA Minivan 7-ми місна',
        titleKey: 'honda100',
      },

      {
        id: '922',
        model: 'Passport',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4808-250x150.jpg',
        title: 'Килимки в салон Honda Passport 2019-… III покоління USA Crossover',
        titleKey: 'honda101',
      },
      {
        id: '923',
        model: 'Pilot',
        year: ['2002', '2008'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4809-250x150.jpg',
        title: 'Килимки в салон Honda Pilot 2002-2008 I покоління USA Crossover',
        titleKey: 'honda102',
      },
      {
        id: '924',
        model: 'Pilot',
        year: ['2008', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4810-250x150.jpg',
        title: 'Килимки в салон Honda Pilot 2008-2015 II покоління USA Crossover 7-ми місна',
        titleKey: 'honda103',
      },
      {
        id: '925',
        model: 'Pilot',
        year: ['2015', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4813-250x150.jpg',
        title: 'Килимки в салон Honda Pilot 2015-2018 III покоління USA Crossover дорест 8-ми місна',
        titleKey: 'honda104',
      },
      {
        id: '926',
        model: 'Pilot',
        year: ['2018', '2022'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4814-250x150.jpg',
        title: 'Килимки в салон Honda Pilot 2018-2022 III покоління USA Crossover рест 8-ми місна',
        titleKey: 'honda105',
      },
      {
        id: '927',
        model: 'Pilot',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4811-250x150.jpg',
        title: 'Килимки в салон Honda Pilot Elite 2015-2022 III покоління USA Crossover 7-ми місна',
        titleKey: 'honda106',
      },
      {
        id: '928',
        model: 'Pilot',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/honda/4812-250x150.jpg',
        title: 'Килимки в салон Honda Pilot Elite 2015-2022 III покоління USA Crossover 8-ми місна',
        titleKey: 'honda107',
      },
      {
        id: '929',
        model: 'Prelude',
        year: ['1982', '1987'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/honda/4815-250x150.jpg',
        title: 'Килимки в салон Honda Prelude 1982-1987 II покоління EU Coupe 3-х дверна',
        titleKey: 'honda108',
      },
      {
        id: '930',
        model: 'Prelude',
        year: ['1991', '1996'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/honda/4816-250x150.jpg',
        title: 'Килимки в салон Honda Prelude 1991-1996 IV покоління EU Coupe',
        titleKey: 'honda109',
      },
      {
        id: '931',
        model: 'Prelude',
        year: ['1996', '2001'],
        body: 'Coupe',
        market: 'EU/USA',
        img: '/images/catalog/models/honda/4817-250x150.jpg',
        title: 'Килимки в салон Honda Prelude 1996-2001 V покоління EU/USA Coupe',
        titleKey: 'honda110',
      },

      {
        id: '932',
        model: 'Ridgeline',
        year: ['2006', '2014'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/honda/4818-250x150.jpg',
        title: 'Килимки в салон Honda Ridgeline (YK1) 2006-2014 I покоління USA Pickup 4-х дверна Crew Cab',
        titleKey: 'honda111',
      },
      {
        id: '933',
        model: 'S-2000',
        year: ['1999', '2009'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/honda/4819-250x150.jpg',
        title: 'Килимки в салон Honda S-2000 1999-2009 I покоління EU Coupe',
        titleKey: 'honda112',
      },
      {
        id: '934',
        model: 'Stream',
        year: ['2000', '2006'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/honda/4820-250x150.jpg',
        title: 'Килимки в салон Honda Stream 2000-2006 I покоління EU Minivan 7-ми місна',
        titleKey: 'honda113',
      },
      {
        id: '935',
        model: 'XN-V',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/honda/4821-250x150.jpg',
        title: 'Килимки в салон Honda XN-V 2018-… I покоління China Crossover',
        titleKey: 'honda114',
      },
    ],
  },
  /* --------------------------------- Hummer --------------------------------- */
  Hummer: {
    logo: '/images/catalog/logo/hummer-100x100.png',
    name: 'Hummer',
    models: [
      {
        id: '936',
        model: 'H2',
        year: ['2002', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hummer/4707-250x150.jpg',
        title: 'Килимки в салон Hummer H2 2002-2010 I покоління EU Crossover',
        titleKey: 'hummer1',
      },
      {
        id: '937',
        model: 'H3',
        year: ['2005', '2010'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/hummer/4708-250x150.jpg',
        title: 'Килимки в салон Hummer H3 2005-2010 I покоління EU/USA Crossover',
        titleKey: 'hummer2',
      },
    ],
  },
  /* --------------------------------- Hyundai -------------------------------- */
  Hyundai: {
    logo: '/images/catalog/logo/hyundai-100x100.png',
    name: 'Hyundai',
    models: [
      {
        id: '938',
        model: 'Accent',
        year: ['2000', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4575-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (LC) 2000-2005 II покоління EU Sedan',
        titleKey: '1yundai1',
      },
      {
        id: '939',
        model: 'Accent',
        year: ['2005', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4578-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (MC) 2005-2010 III покоління EU Hatchback',
        titleKey: '1yundai2',
      },
      {
        id: '940',
        model: 'Accent',
        year: ['2005', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4579-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (MC) 2005-2010 III покоління EU Sedan',
        titleKey: '1yundai3',
      },
      {
        id: '941',
        model: 'Accent',
        year: ['2005', '2010'],
        body: 'Sedan',
        market: 'TUR',
        img: '/images/catalog/models/hyundai/4577-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (MC) 2005-2010 III покоління TUR Sedan',
        titleKey: '1yundai4',
      },
      {
        id: '942',
        model: 'Accent',
        year: ['2005', '2010'],
        body: 'Sedan',
        market: 'UK',
        img: '/images/catalog/models/hyundai/4576-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (MC) 2005-2010 III покоління UK Sedan',
        titleKey: '1yundai5',
      },
      {
        id: '943',
        model: 'Accent',
        year: ['2010', '2017'],
        body: 'Hatchback',
        market: 'RU',
        img: '/images/catalog/models/hyundai/4581-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (RB) (Solaris) 2010-2017 IV покоління RU Hatchback 4-х дверна',
        titleKey: '1yundai6',
      },
      {
        id: '944',
        model: 'Accent',
        year: ['2010', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4582-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (RB) 2010-2017 IV покоління EU Hatchback',
        titleKey: '1yundai7',
      },
      {
        id: '945',
        model: 'Accent',
        year: ['2010', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4580-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (RB) 2010-2017 IV покоління EU Sedan',
        titleKey: '1yundai8',
      },
      {
        id: '946',
        model: 'Accent',
        year: ['2011', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4584-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (RB) 2011-2017 IV покоління USA Sedan',
        titleKey: '1yundai9',
      },
      {
        id: '947',
        model: 'Accent',
        year: ['2010', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4583-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (RB) Turkish Assembly 2010-2017 IV покоління EU Sedan',
        titleKey: '1yundai10',
      },

      {
        id: '948',
        model: 'Accent',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4585-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (YC) 2017-… V покоління EU Sedan',
        titleKey: '1yundai11',
      },
      {
        id: '949',
        model: 'Accent',
        year: ['2017'],
        body: 'Sedan',
        market: 'IND',
        img: '/images/catalog/models/hyundai/4586-250x150.jpg',
        title: 'Килимки в салон Hyundai Accent (YC) 2017-… V покоління IND Sedan',
        titleKey: '1yundai12',
      },
      {
        id: '950',
        model: 'Cantus',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4587-250x150.jpg',
        title: 'Килимки в салон Hyundai Cantus 2014-2020 I покоління EU Crossover',
        titleKey: '1yundai13',
      },
      {
        id: '951',
        model: 'Coupe',
        year: ['2002', '2009'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4589-250x150.jpg',
        title: 'Килимки в салон Hyundai Coupe (GK) 2002-2009 II покоління EU Coupe',
        titleKey: '1yundai14',
      },
      {
        id: '952',
        model: 'Coupe',
        year: ['1996', '2002'],
        body: 'Coupe',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4588-250x150.jpg',
        title: 'Килимки в салон Hyundai Coupe (RD) 1996-2002 I покоління Korea Coupe',
        titleKey: '1yundai15',
      },
      {
        id: '953',
        model: 'Creta',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4590-250x150.jpg',
        title: 'Килимки в салон Hyundai Creta (GS) 2014-2020 I покоління EU Crossover',
        titleKey: '1yundai16',
      },
      {
        id: '954',
        model: 'Elantra',
        year: ['2015', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4601-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (AD) 2015-2020 VI покоління EU Sedan',
        titleKey: '1yundai17',
      },
      {
        id: '955',
        model: 'Elantra',
        year: ['2015', '2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4599-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (AD) 2015-2020 VI покоління USA Sedan',
        titleKey: '1yundai18',
      },
      {
        id: '956',
        model: 'Elantra',
        year: ['2006', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4595-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (HD) 2006-2011 IV покоління EU Sedan',
        titleKey: '1yundai19',
      },
      {
        id: '957',
        model: 'Elantra',
        year: ['2006', '2011'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4594-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (HD) 2006-2011 IV покоління Korea Sedan',
        titleKey: '1yundai20',
      },

      {
        id: '958',
        model: 'Elantra',
        year: ['1990', '1995'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/5954-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (J1) 1990-1995 I покоління EU Sedan',
        titleKey: '1yundai21',
      },
      {
        id: '959',
        model: 'Elantra',
        year: ['1995', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4591-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (J2/J3) 1995-2000 II покоління EU Sedan',
        titleKey: '1yundai22',
      },
      {
        id: '960',
        model: 'Elantra',
        year: ['1995', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4592-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (J2/J3) 1995-2000 II покоління EU Universal',
        titleKey: '1yundai23',
      },
      {
        id: '961',
        model: 'Elantra',
        year: ['2010', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4596-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (MD) 2010-2015 V покоління EU Sedan',
        titleKey: '1yundai24',
      },
      {
        id: '962',
        model: 'Elantra',
        year: ['2010', '2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4597-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (MD) 2010-2015 V покоління USA Sedan',
        titleKey: '1yundai25',
      },
      {
        id: '963',
        model: 'Elantra',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4593-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra (XD) 2000-2006 III покоління EU Sedan',
        titleKey: '1yundai26',
      },
      {
        id: '964',
        model: 'Elantra',
        year: ['2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4602-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra CN7 2020-… VII покоління EU Sedan',
        titleKey: '1yundai27',
      },
      {
        id: '965',
        model: 'Elantra',
        year: ['2015', '2020'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4600-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra GT (AD) 2015-2020 VI покоління USA Hatchback',
        titleKey: '1yundai28',
      },
      {
        id: '966',
        model: 'Elantra',
        year: ['2010', '2016'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4598-250x150.jpg',
        title: 'Килимки в салон Hyundai Elantra GT (MD) 2010-2016 V покоління USA Hatchback',
        titleKey: '1yundai29',
      },
      {
        id: '967',
        model: 'Encino',
        year: ['2017', '2023'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/hyundai/4603-250x150.jpg',
        title: 'Килимки в салон Hyundai Encino EV 2017-2023 I покоління China Crossover Electric',
        titleKey: '1yundai30',
      },

      {
        id: '968',
        model: 'Equus',
        year: ['2009', '2016'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4604-250x150.jpg',
        title: 'Килимки в салон Hyundai Equus (VI) 2009-2016 II покоління Korea Sedan',
        titleKey: '1yundai31',
      },
      {
        id: '969',
        model: 'EX8',
        year: ['2020'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4605-250x150.jpg',
        title: 'Килимки в салон Hyundai EX8 2020-… I покоління EU VAN',
        titleKey: '1yundai32',
      },
      {
        id: '970',
        model: 'Galloper',
        year: ['1997', '2003'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4606-250x150.jpg',
        title: 'Килимки в салон Hyundai Galloper 1997-2003 II покоління EU Crossover',
        titleKey: '1yundai33',
      },
      {
        id: '971',
        model: 'Genesis',
        year: ['2008', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4607-250x150.jpg',
        title: 'Килимки в салон Hyundai Genesis (BH) 2008-2013 I покоління EU Sedan',
        titleKey: '1yundai34',
      },
      {
        id: '972',
        model: 'Genesis',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4609-250x150.jpg',
        title: 'Килимки в салон Hyundai Genesis (DH) 2013-… II покоління EU Sedan',
        titleKey: '1yundai35',
      },
      {
        id: '973',
        model: 'Genesis',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4610-250x150.jpg',
        title: 'Килимки в салон Hyundai Genesis (DH) 2013-… II покоління EU Sedan AWD',
        titleKey: '1yundai36',
      },
      {
        id: '974',
        model: 'Genesis',
        year: ['2008', '2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4608-250x150.jpg',
        title: 'Килимки в салон Hyundai Genesis Coupe 2008-2016 I покоління EU Coupe',
        titleKey: '1yundai37',
      },
      {
        id: '975',
        model: 'Getz',
        year: ['2002', '2011'],
        body: 'Hatchback 5-ти дверна',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4611-250x150.jpg',
        title: 'Килимки в салон Hyundai Getz 2002-2011 I покоління EU Hatchback 5-ти дверна',
        titleKey: '1yundai38',
      },
      {
        id: '976',
        model: 'Grandeur',
        year: ['2011', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4614-250x150.jpg',
        title: 'Килимки в салон Hyundai Grandeur (HG) 2011-2017 V покоління EU Sedan ГБО',
        titleKey: '1yundai39',
      },
      {
        id: '977',
        model: 'Grandeur',
        year: ['2011', '2017'],
        body: 'Sedan',
        market: 'EU/Korea',
        img: '/images/catalog/models/hyundai/4613-250x150.jpg',
        title: 'Килимки в салон Hyundai Grandeur (HG) 2011-2017 V покоління EU/Korea Sedan',
        titleKey: '1yundai40',
      },

      {
        id: '978',
        model: 'Grandeur',
        year: ['2016', '2022'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4615-250x150.jpg',
        title: 'Килимки в салон Hyundai Grandeur (IG) 2016-2022 VI покоління EU Sedan',
        titleKey: '1yundai41',
      },
      {
        id: '979',
        model: 'Grandeur',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4612-250x150.jpg',
        title: 'Килимки в салон Hyundai Grandeur (TG) 2005-2011 IV покоління EU Sedan',
        titleKey: '1yundai42',
      },
      {
        id: '980',
        model: 'H-1',
        year: ['1997', '2007'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4616-250x150.jpg',
        title: 'Килимки в салон Hyundai H-1 (A1) 1997-2007 I покоління EU Minivan 8-ми місна',
        titleKey: '1yundai43',
      },
      {
        id: '981',
        model: 'H-1',
        year: ['2008', '2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4617-250x150.jpg',
        title: 'Килимки в салон Hyundai H-1/Grand Starex 2008-2020 II покоління EU Minivan 8-ми місна',
        titleKey: '1yundai44',
      },
      {
        id: '982',
        model: 'i10',
        year: ['2013', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4619-250x150.jpg',
        title: 'Килимки в салон Hyundai i10 (IA) 2013-2019 II покоління EU Hatchback',
        titleKey: '1yundai45',
      },
      {
        id: '983',
        model: 'i10',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4620-250x150.jpg',
        title: 'Килимки в салон Hyundai i10 (LA) 2019-… III покоління EU Hatchback',
        titleKey: '1yundai46',
      },
      {
        id: '984',
        model: 'i10',
        year: ['2007', '2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4618-250x150.jpg',
        title: 'Килимки в салон Hyundai i10 2007-2013 I покоління EU Hatchback',
        titleKey: '1yundai47',
      },
      {
        id: '985',
        model: 'i20',
        year: ['2014', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4623-250x150.jpg',
        title: 'Килимки в салон Hyundai i20 (GB) 2014-2020 II покоління EU Hatchback',
        titleKey: '1yundai48',
      },
      {
        id: '986',
        model: 'i20',
        year: ['2008', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4621-250x150.jpg',
        title: 'Килимки в салон Hyundai i20 (PB) 2008-2014 I покоління EU Hatchback 3-х дверна',
        titleKey: '1yundai49',
      },
      {
        id: '987',
        model: 'i20',
        year: ['2008', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4622-250x150.jpg',
        title: 'Килимки в салон Hyundai i20 (PB) 2008-2014 I покоління EU Hatchback 5-ти дверна',
        titleKey: '1yundai50',
      },

      {
        id: '988',
        model: 'i30',
        year: ['2007', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4624-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 (FD) 2007-2010 I покоління EU Hatchback дорест 5-ти дверна',
        titleKey: '1yundai51',
      },
      {
        id: '989',
        model: 'i30',
        year: ['2010', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4625-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 (FD) 2010-2012 I покоління EU Hatchback рест 5-ти дверна',
        titleKey: '1yundai52',
      },
      {
        id: '990',
        model: 'i30',
        year: ['2012', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4629-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 (GD) 2012-2016 II покоління EU Hatchback 5-ти дверна',
        titleKey: '1yundai53',
      },
      {
        id: '991',
        model: 'i30',
        year: ['2012', '2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4627-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 (GD) 2012-2016 II покоління EU Universal',
        titleKey: '1yundai54',
      },
      {
        id: '992',
        model: 'i30',
        year: ['2012', '2016'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4628-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 (GD) 2012-2016 II покоління USA Universal',
        titleKey: '1yundai55',
      },
      {
        id: '993',
        model: 'i30',
        year: ['2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4630-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 (PD) 2016-… III покоління EU Hatchback 5-ти дверна',
        titleKey: '1yundai56',
      },
      {
        id: '994',
        model: 'i30',
        year: ['2016'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4632-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 (PD) 2016-… III покоління EU Liftback',
        titleKey: '1yundai57',
      },
      {
        id: '995',
        model: 'i30',
        year: ['2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4631-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 (PD) 2016-… III покоління EU Universal',
        titleKey: '1yundai58',
      },
      {
        id: '996',
        model: 'i30',
        year: ['2007', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4626-250x150.jpg',
        title: 'Килимки в салон Hyundai i30 FD (CW) 2007-2012 I покоління EU Universal',
        titleKey: '1yundai59',
      },
      {
        id: '997',
        model: 'i40',
        year: ['2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4633-250x150.jpg',
        title: 'Килимки в салон Hyundai i40 2011-… I покоління EU Sedan',
        titleKey: '1yundai60',
      },

      {
        id: '998',
        model: 'i40',
        year: ['2011'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4634-250x150.jpg',
        title: 'Килимки в салон Hyundai i40 2011-… I покоління EU Universal',
        titleKey: '1yundai61',
      },
      {
        id: '999',
        model: 'Ioniq',
        year: ['2016', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4635-250x150.jpg',
        title: 'Килимки в салон Hyundai Ioniq 2016-2019 I покоління EU Hatchback дорест Electric',
        titleKey: '1yundai62',
      },
      {
        id: '1000',
        model: 'Ioniq',
        year: ['2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/5977-250x150.jpg',
        title: 'Килимки в салон Hyundai Ioniq 2016-… I покоління EU Hatchback Hybrid',
        titleKey: '1yundai63',
      },
      {
        id: '1001',
        model: 'Ioniq',
        year: ['2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/5976-250x150.jpg',
        title: 'Килимки в салон Hyundai Ioniq 2016-… I покоління EU Hatchback Plug-in Hybrid',
        titleKey: '1yundai64',
      },
      {
        id: '1002',
        model: 'Ioniq',
        year: ['2016'],
        body: 'Hatchback',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4636-250x150.jpg',
        title: 'Килимки в салон Hyundai Ioniq 2016-… I покоління Korea Hatchback Hybrid',
        titleKey: '1yundai65',
      },
      {
        id: '1003',
        model: 'Ioniq',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4637-250x150.jpg',
        title: 'Килимки в салон Hyundai Ioniq 2019-… I покоління EU Hatchback рест Electric',
        titleKey: '1yundai66',
      },
      {
        id: '1004',
        model: 'Ioniq',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU/Korea',
        img: '/images/catalog/models/hyundai/4638-250x150.jpg',
        title: 'Килимки в салон Hyundai Ioniq 5 2021-… I покоління EU/Korea Crossover',
        titleKey: '1yundai67',
      },
      {
        id: '1005',
        model: 'Ioniq',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU/Korea',
        img: '/images/catalog/models/hyundai/4639-250x150.jpg',
        title: 'Килимки в салон Hyundai Ioniq 5 2021-… I покоління EU/Korea Crossover AWD',
        titleKey: '1yundai68',
      },
      {
        id: '1006',
        model: 'Ioniq',
        year: ['2022'],
        body: 'Liftback',
        market: 'EU/Korea',
        img: '/images/catalog/models/hyundai/5999-250x150.jpg',
        title: 'Килимки в салон Hyundai Ioniq 6 (CE) 2022-… I покоління EU/Korea Liftback electric',
        titleKey: '1yundai69',
      },
      {
        id: '1007',
        model: 'ix25',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4640-250x150.jpg',
        title: 'Килимки в салон Hyundai ix25 2014-2020 I покоління EU Crossover',
        titleKey: '1yundai70',
      },

      {
        id: '1008',
        model: 'ix35',
        year: ['2010', '2013'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4643-250x150.jpg',
        title: 'Килимки в салон Hyundai ix35 (LM) 2010-2013 II покоління USA Crossover дорест',
        titleKey: '1yundai71',
      },
      {
        id: '1009',
        model: 'ix35',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'USA/Korea',
        img: '/images/catalog/models/hyundai/4642-250x150.jpg',
        title: 'Килимки в салон Hyundai ix35 (LM) 2010-2015 II покоління USA/Korea Crossover',
        titleKey: '1yundai72',
      },
      {
        id: '1010',
        model: 'ix35',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4641-250x150.jpg',
        title: 'Килимки в салон Hyundai ix35 (LM) 2010-2017 II покоління EU Crossover',
        titleKey: '1yundai73',
      },
      {
        id: '1011',
        model: 'ix55',
        year: ['2007', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/hyundai/4644-250x150.jpg',
        title: 'Килимки в салон Hyundai ix55 2007-2012 I покоління EU/USA Crossover 7-ми місна',
        titleKey: '1yundai74',
      },
      {
        id: '1012',
        model: 'Kona',
        year: ['2017', '2019'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/hyundai/4645-250x150.jpg',
        title: 'Килимки в салон Hyundai Kona 2017-2019 I покоління EU/USA Crossover дорест',
        titleKey: '1yundai75',
      },
      {
        id: '1013',
        model: 'Kona',
        year: ['2019', '2023'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/hyundai/4647-250x150.jpg',
        title: 'Килимки в салон Hyundai Kona 2019-2023 I покоління EU/USA Crossover рест',
        titleKey: '1yundai76',
      },
      {
        id: '1014',
        model: 'Kona',
        year: ['2017', '2023'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/hyundai/4646-250x150.jpg',
        title: 'Килимки в салон Hyundai Kona EV 2017-2023 I покоління EU/USA Crossover Electric',
        titleKey: '1yundai77',
      },
      {
        id: '1015',
        model: 'Kona',
        year: ['2023'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Hyundai Kona EV 2023-… II покоління EU/USA Crossover Electric',
        titleKey: '1yundai78',
      },
      {
        id: '1016',
        model: 'Lafesta',
        year: ['2018'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/hyundai/4648-250x150.jpg',
        title: 'Килимки в салон Hyundai Lafesta EV 2018-… I покоління China Sedan',
        titleKey: '1yundai79',
      },
      {
        id: '1017',
        model: 'Matrix',
        year: ['2001', '2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4649-250x150.jpg',
        title: 'Килимки в салон Hyundai Matrix 2001-2010 I покоління EU Minivan',
        titleKey: '1yundai80',
      },

      {
        id: '1018',
        model: 'Maxcruz',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4650-250x150.jpg',
        title: 'Килимки в салон Hyundai Maxcruz (NC) 2012-2018 III покоління Korea Crossover 7-ми місна',
        titleKey: '1yundai81',
      },
      {
        id: '1019',
        model: 'Palisade',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4651-250x150.jpg',
        title: 'Килимки в салон Hyundai Palisade 2018-… I покоління EU Crossover 7-ми місна',
        titleKey: '1yundai82',
      },
      {
        id: '1020',
        model: 'Palisade',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4652-250x150.jpg',
        title: 'Килимки в салон Hyundai Palisade 2018-… I покоління EU Crossover 8-ми місна',
        titleKey: '1yundai83',
      },
      {
        id: '1021',
        model: 'Santa Fe',
        year: ['2006', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4654-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (CM) 2006-2010 II покоління EU Crossover дорест 5-ти місна',
        titleKey: '1yundai84',
      },
      {
        id: '1022',
        model: 'Santa Fe',
        year: ['2006', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4655-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (CM) 2006-2010 II покоління EU Crossover дорест 7-ми місна',
        titleKey: '1yundai85',
      },
      {
        id: '1023',
        model: 'Santa Fe',
        year: ['2006', '2010'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4657-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (CM) 2006-2010 II покоління Korea Crossover дорест 5-ти місна',
        titleKey: '1yundai86',
      },
      {
        id: '1024',
        model: 'Santa Fe',
        year: ['2006', '2010'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4656-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (CM) 2006-2010 II покоління Korea Crossover дорест 7-ми місна',
        titleKey: '1yundai87',
      },
      {
        id: '1025',
        model: 'Santa Fe',
        year: ['2006', '2012'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4658-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (CM) 2006-2012 II покоління USA Crossover дорест 5-ти місна',
        titleKey: '1yundai88',
      },
      {
        id: '1026',
        model: 'Santa Fe',
        year: ['2010', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4659-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (CM) 2010-2012 II покоління EU Crossover рест 5-ти місна',
        titleKey: '1yundai89',
      },
      {
        id: '1027',
        model: 'Santa Fe',
        year: ['2010', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4660-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (CM) 2010-2012 II покоління EU Crossover рест 7-ми місна',
        titleKey: '1yundai90',
      },

      {
        id: '1028',
        model: 'Santa Fe',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4661-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (DM) 2012-2018 III покоління EU Crossover',
        titleKey: '1yundai91',
      },
      {
        id: '1029',
        model: 'Santa Fe',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4666-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (DM) 2012-2018 III покоління EU Crossover',
        titleKey: '1yundai92',
      },
      {
        id: '1030',
        model: 'Santa Fe',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4665-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (DM) 2012-2018 III покоління Korea Crossover',
        titleKey: '1yundai93',
      },
      {
        id: '1031',
        model: 'Santa Fe',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4666-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (DM) 2012-2018 III покоління Korea Crossover',
        titleKey: '1yundai94',
      },
      {
        id: '1032',
        model: 'Santa Fe',
        year: ['2016', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4668-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (DM) 2016-2018 III покоління USA Crossover рест',
        titleKey: '1yundai95',
      },
      {
        id: '1033',
        model: 'Santa Fe',
        year: ['2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Hyundai Santa Fe (MX5) 2023-... V покоління EU Crossover',
        titleKey: '1yundai96',
      },
      {
        id: '1034',
        model: 'Santa Fe',
        year: ['2001', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4653-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (SM) 2001-2006 I покоління EU Crossover',
        titleKey: '1yundai97',
      },
      {
        id: '1035',
        model: 'Santa Fe',
        year: ['2018', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4670-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (TM) 2018-2020 IV покоління EU Crossover дорест 5-ти місна',
        titleKey: '1yundai98',
      },
      {
        id: '1036',
        model: 'Santa Fe',
        year: ['2018', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4671-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (TM) 2018-2020 IV покоління EU Crossover дорест 7-ми місна',
        titleKey: '1yundai99',
      },
      {
        id: '1037',
        model: 'Santa Fe',
        year: ['2018', '2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4669-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (TM) 2018-2020 IV покоління USA Crossover дорест 5-ти місна',
        titleKey: '1yundai100',
      },

      {
        id: '1038',
        model: 'Santa Fe',
        year: ['2020', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4672-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (TM) 2020-2023 IV покоління EU Crossover рест 5-ти місна',
        titleKey: '1yundai101',
      },
      {
        id: '1039',
        model: 'Santa Fe',
        year: ['2020', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4673-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (TM) 2020-2023 IV покоління EU Crossover рест 7-ми місна',
        titleKey: '1yundai102',
      },
      {
        id: '1040',
        model: 'Santa Fe',
        year: ['2020', '2023'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/hyundai/5998-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe (TM) 2020-2023 IV покоління USA Crossover рест 5-ти місна Hybrid',
        titleKey: '1yundai103',
      },
      {
        id: '1041',
        model: 'Santa Fe',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4663-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe Grand (NC) 2012-2018 III покоління EU Crossover 7-ми місна',
        titleKey: '1yundai104',
      },
      {
        id: '1042',
        model: 'Santa Fe',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4664-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe Grand (NC) 2012-2018 III покоління USA Crossover 6-ти місна',
        titleKey: '1yundai105',
      },
      {
        id: '1043',
        model: 'Santa Fe',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4662-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe Grand (NC) 2012-2018 III покоління USA Crossover 7-ми місна',
        titleKey: '1yundai106',
      },
      {
        id: '1044',
        model: 'Santa Fe',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4667-250x150.jpg',
        title: 'Килимки в салон Hyundai Santa Fe Sport (DM) 2012-2018 III покоління USA Crossover',
        titleKey: '1yundai107',
      },
      {
        id: '1045',
        model: 'Solaris',
        year: ['2011', '2017'],
        body: 'Hatchback',
        market: 'RU',
        img: '/images/catalog/models/hyundai/4675-250x150.jpg',
        title: 'Килимки в салон Hyundai Solaris 2011-2017 I покоління RU Hatchback',
        titleKey: '1yundai108',
      },
      {
        id: '1046',
        model: 'Solaris',
        year: ['2011', '2017'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/hyundai/4674-250x150.jpg',
        title: 'Килимки в салон Hyundai Solaris 2011-2017 I покоління RU Sedan',
        titleKey: '1yundai109',
      },
      {
        id: '1047',
        model: 'Solaris',
        year: ['2017'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/hyundai/4676-250x150.jpg',
        title: 'Килимки в салон Hyundai Solaris 2017-… II покоління RU Sedan',
        titleKey: '1yundai110',
      },

      {
        id: '1048',
        model: 'Sonata',
        year: ['2019'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4688-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (DN8) 2019-… VIII покоління Korea Sedan',
        titleKey: '1yundai111',
      },
      {
        id: '1049',
        model: 'Sonata',
        year: ['2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4687-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (DN8) 2019-… VIII покоління USA Sedan Hybrid',
        titleKey: '1yundai112',
      },
      {
        id: '1050',
        model: 'Sonata',
        year: ['2001', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4678-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (EF) 2001-2004 IV покоління EU Sedan рест',
        titleKey: '1yundai113',
      },
      {
        id: '1051',
        model: 'Sonata',
        year: ['2014', '2019'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/hyundai/4684-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (LF) 2014-2019 VII покоління EU/USA Sedan',
        titleKey: '1yundai114',
      },
      {
        id: '1052',
        model: 'Sonata',
        year: ['2014', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4685-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (LF) 2014-2019 VII покоління USA Sedan Hybrid',
        titleKey: '1yundai115',
      },
      {
        id: '1053',
        model: 'Sonata',
        year: ['2004', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4679-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (NF) 2004-2010 V покоління EU Sedan',
        titleKey: '1yundai116',
      },
      {
        id: '1054',
        model: 'Sonata',
        year: ['1993', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4677-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (Y3) 1993-1998 III покоління EU Sedan',
        titleKey: '1yundai117',
      },
      {
        id: '1055',
        model: 'Sonata',
        year: ['2009', '2014'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/hyundai/4681-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (YF) 2009-2014 VI покоління EU/USA Sedan',
        titleKey: '1yundai118',
      },
      {
        id: '1056',
        model: 'Sonata',
        year: ['2009', '2014'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4682-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (YF) 2009-2014 VI покоління Korea Sedan',
        titleKey: '1yundai119',
      },
      {
        id: '1057',
        model: 'Sonata',
        year: ['2009', '2014'],
        body: 'Sedan',
        market: 'UAE',
        img: '/images/catalog/models/hyundai/4680-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (YF) 2009-2014 VI покоління UAE Sedan',
        titleKey: '1yundai120',
      },

      {
        id: '1058',
        model: 'Sonata',
        year: ['2009', '2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4683-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata (YF) 2009-2014 VI покоління USA Sedan',
        titleKey: '1yundai121',
      },
      {
        id: '1059',
        model: 'Sonata',
        year: ['2014', '2019'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4686-250x150.jpg',
        title: 'Килимки в салон Hyundai Sonata LPI (LF) 2014-2019 VII покоління Korea Sedan ГБО',
        titleKey: '1yundai122',
      },
      {
        id: '1060',
        model: 'Staria',
        year: ['2021'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4689-250x150.jpg',
        title: 'Килимки в салон Hyundai Staria 2021-… I покоління EU VAN 7-ми місна AWD',
        titleKey: '1yundai123',
      },
      {
        id: '1061',
        model: 'Staria',
        year: ['2021'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4690-250x150.jpg',
        title: 'Килимки в салон Hyundai Staria 2021-… I покоління EU VAN 9-ти місна',
        titleKey: '1yundai124',
      },
      {
        id: '1062',
        model: 'Terracan',
        year: ['2001', '2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4691-250x150.jpg',
        title: 'Килимки в салон Hyundai Terracan 2001-2007 I покоління EU Crossover',
        titleKey: '1yundai125',
      },
      {
        id: '1063',
        model: 'Tiburon',
        year: ['2002', '2009'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4693-250x150.jpg',
        title: 'Килимки в салон Hyundai Tiburon (GK) 2002-2009 II покоління EU Coupe',
        titleKey: '1yundai126',
      },
      {
        id: '1064',
        model: 'Tiburon',
        year: ['1996', '2002'],
        body: 'Coupe',
        market: 'Korea',
        img: '/images/catalog/models/hyundai/4692-250x150.jpg',
        title: 'Килимки в салон Hyundai Tiburon (RD) 1996-2002 I покоління Korea Coupe',
        titleKey: '1yundai127',
      },
      {
        id: '1065',
        model: 'Trajet',
        year: ['1999', '2007'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4694-250x150.jpg',
        title: 'Килимки в салон Hyundai Trajet 1999-2007 I покоління EU Minivan',
        titleKey: '1yundai128',
      },
      {
        id: '1066',
        model: 'Tucson',
        year: ['2004', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4695-250x150.jpg',
        title: 'Килимки в салон Hyundai Tucson (JM) 2004-2010 I покоління EU Crossover',
        titleKey: '1yundai129',
      },
      {
        id: '1067',
        model: 'Tucson',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4698-250x150.jpg',
        title: 'Килимки в салон Hyundai Tucson (NX4) 2021-… IV покоління EU Crossover',
        titleKey: '1yundai130',
      },

      {
        id: '1068',
        model: 'Tucson',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4699-250x150.jpg',
        title: 'Килимки в салон Hyundai Tucson (NX4) 2021-… IV покоління EU Crossover',
        titleKey: '1yundai131',
      },
      {
        id: '1069',
        model: 'Tucson',
        year: ['2015', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4696-250x150.jpg',
        title: 'Килимки в салон Hyundai Tucson (TL) 2015-2021 III покоління EU Crossover',
        titleKey: '1yundai132',
      },
      {
        id: '1070',
        model: 'Tucson',
        year: ['2015', '2021'],
        body: 'Crossover',
        market: 'USA/Korea',
        img: '/images/catalog/models/hyundai/4697-250x150.jpg',
        title: 'Килимки в салон Hyundai Tucson (TL) 2015-2021 III покоління USA/Korea Crossover',
        titleKey: '1yundai133',
      },
      {
        id: '1071',
        model: 'Veloster',
        year: ['2011', '2018'],
        body: 'Coupe',
        market: 'EU/USA',
        img: '/images/catalog/models/hyundai/4700-250x150.jpg',
        title: 'Килимки в салон Hyundai Veloster 2011-2018 I покоління EU/USA Coupe',
        titleKey: '1yundai134',
      },
      {
        id: '1072',
        model: 'Veloster',
        year: ['2018'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/hyundai/4701-250x150.jpg',
        title: 'Килимки в салон Hyundai Veloster 2018-… II покоління USA Coupe',
        titleKey: '1yundai135',
      },
      {
        id: '1073',
        model: 'Venue',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/hyundai/4702-250x150.jpg',
        title: 'Килимки в салон Hyundai Venue 2019-… I покоління EU Crossover',
        titleKey: '1yundai136',
      },
      {
        id: '1074',
        model: 'Veracruz',
        year: ['2007', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/hyundai/4700-250x150.jpg',
        title: 'Килимки в салон Hyundai Veracruz 2007-2012 I покоління EU/USA Crossover 7-ми місна',
        titleKey: '1yundai137',
      },
    ],
  },
  /* -------------------------------- Infiniti -------------------------------- */
  Infiniti: {
    logo: '/images/catalog/logo/infiniti-100x100.png',
    name: 'Infiniti',
    models: [
      {
        id: '1075',
        model: 'EX35',
        year: ['2007', '2013'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/infiniti/4447-250x150.jpg',
        title: 'Килимки в салон Infiniti EX35 (J50) 2007-2013 I покоління EU/USA Crossover',
        titleKey: 'infiniti1',
      },
      {
        id: '1076',
        model: 'FX35',
        year: ['2003', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4448-250x150.jpg',
        title: 'Килимки в салон Infiniti FX35 (S50) 2003-2008 I покоління EU Crossover',
        titleKey: 'infiniti2',
      },
      {
        id: '1077',
        model: 'FX37',
        year: ['2008', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4449-250x150.jpg',
        title: 'Килимки в салон Infiniti FX37 (S51) 2008-2013 II покоління EU Crossover',
        titleKey: 'infiniti3',
      },
      {
        id: '1078',
        model: 'G',
        year: ['2010', '2015'],
        body: 'Sedan рест',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4455-250x150.jpg',
        title: 'Килимки в салон Infiniti G25 2010-2015 IV покоління EU Sedan рест',
        titleKey: 'infiniti4',
      },
      {
        id: '1079',
        model: 'G',
        year: ['2006', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4452-250x150.jpg',
        title: 'Килимки в салон Infiniti G25-G35 2006-2015 IV покоління EU Sedan',
        titleKey: 'infiniti5',
      },
      {
        id: '1080',
        model: 'G',
        year: ['2003', '2005'],
        body: 'Sedan дорест',
        market: 'USA',
        img: '/images/catalog/models/infiniti/4451-250x150.jpg',
        title: 'Килимки в салон Infiniti G35 2003-2005 III покоління USA Sedan дорест',
        titleKey: 'infiniti6',
      },
      {
        id: '1081',
        model: 'G',
        year: ['2003', '2007'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4450-250x150.jpg',
        title: 'Килимки в салон Infiniti G35 2003-2007 III покоління EU Coupe',
        titleKey: 'infiniti7',
      },
      {
        id: '1082',
        model: 'G',
        year: ['2006', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4453-250x150.jpg',
        title: 'Килимки в салон Infiniti G35 2006-2010 IV покоління EU Sedan',
        titleKey: 'infiniti8',
      },
      {
        id: '1083',
        model: 'G',
        year: ['2009', '2013'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/infiniti/4454-250x150.jpg',
        title: 'Килимки в салон Infiniti G37 X 2009-2013 IV покоління USA Sedan AWD',
        titleKey: 'infiniti9',
      },
      {
        id: '1084',
        model: 'M (Q70)',
        year: ['2010', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4457-250x150.jpg',
        title: 'Килимки в салон Infiniti M (Q70) (Y51) 2010-2019 III покоління EU Sedan',
        titleKey: 'infiniti10',
      },

      {
        id: '1085',
        model: 'M (Q70)',
        year: ['2010', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/infiniti/6001-250x150.jpg',
        title: 'Килимки в салон Infiniti M (Q70) (Y51) 2010-2019 III покоління EU Sedan long',
        titleKey: 'infiniti11',
      },
      {
        id: '1086',
        model: 'M (Q70)',
        year: ['2003', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4456-250x150.jpg',
        title: 'Килимки в салон Infiniti M(Q70) (Y50) 2003-2010 II покоління EU Sedan',
        titleKey: 'infiniti12',
      },
      {
        id: '1087',
        model: 'Q50',
        year: ['2013', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4459-250x150.jpg',
        title: 'Килимки в салон Infiniti Q50 2013-2017 I покоління EU Sedan дорест',
        titleKey: 'infiniti13',
      },
      {
        id: '1088',
        model: 'Q50',
        year: ['2013', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/infiniti/4458-250x150.jpg',
        title: 'Килимки в салон Infiniti Q50 2013-2018 I покоління USA Sedan Hybrid',
        titleKey: 'infiniti14',
      },
      {
        id: '1089',
        model: 'Q50',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4460-250x150.jpg',
        title: 'Килимки в салон Infiniti Q50 2017-… I покоління EU Sedan рест',
        titleKey: 'infiniti15',
      },
      {
        id: '1090',
        model: 'Q60',
        year: ['2013', '2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Infiniti Q60 (V36) 2013 - 2016 I покоління EU Coupe',
        titleKey: 'infiniti16',
      },
      {
        id: '1091',
        model: 'Q60',
        year: ['2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4461-250x150.jpg',
        title: 'Килимки в салон Infiniti Q60 (V37) 2016-… II покоління EU Coupe',
        titleKey: 'infiniti17',
      },
      {
        id: '1092',
        model: 'QX30',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4463-250x150.jpg',
        title: 'Килимки в салон Infiniti QX30 2016-… I покоління EU Crossover',
        titleKey: 'infiniti18',
      },
      {
        id: '1093',
        model: 'QX30',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/infiniti/4462-250x150.jpg',
        title: 'Килимки в салон Infiniti QX30 2016-… I покоління USA Crossover',
        titleKey: 'infiniti19',
      },
      {
        id: '1094',
        model: 'QX50',
        year: ['2007', '2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/infiniti/4464-250x150.jpg',
        title: 'Килимки в салон Infiniti QX50 (J50) 2007-2014 I покоління EU/USA Crossover дорест',
        titleKey: 'infiniti20',
      },

      {
        id: '1095',
        model: 'QX50',
        year: ['2014', '2017'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/infiniti/4465-250x150.jpg',
        title: 'Килимки в салон Infiniti QX50 (J50) 2014-2017 I покоління EU/USA Crossover рест',
        titleKey: 'infiniti21',
      },
      {
        id: '1096',
        model: 'QX50',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/infiniti/4466-250x150.jpg',
        title: 'Килимки в салон Infiniti QX50 2017-… II покоління EU/USA Crossover',
        titleKey: 'infiniti22',
      },
      {
        id: '1097',
        model: 'QX56',
        year: ['2004', '2010'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/infiniti/4467-250x150.jpg',
        title: 'Килимки в салон Infiniti QX56 (JA60) 2004-2010 II покоління EU/USA Crossover 7-ми місна',
        titleKey: 'infiniti23',
      },
      {
        id: '1098',
        model: 'QX56',
        year: ['2010', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4469-250x150.jpg',
        title: 'Килимки в салон Infiniti QX56 (Z62) 2010-2013 III покоління EU Crossover 7-ми місна',
        titleKey: 'infiniti24',
      },
      {
        id: '1099',
        model: 'QX60',
        year: ['2012', '2021'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/infiniti/4470-250x150.jpg',
        title: 'Килимки в салон Infiniti QX60 (JX35) 2012-2021 I покоління EU/USA Crossover 7-ми місна',
        titleKey: 'infiniti25',
      },
      {
        id: '1100',
        model: 'QX70',
        year: ['2008', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4471-250x150.jpg',
        title: 'Килимки в салон Infiniti QX70 (S51) 2008-2017 II покоління EU Crossover',
        titleKey: 'infiniti26',
      },
      {
        id: '1101',
        model: 'QX80',
        year: ['2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/infiniti/4472-250x150.jpg',
        title: 'Килимки в салон Infiniti QX80 2013-… III покоління EU Crossover 8-ми місна',
        titleKey: 'infiniti27',
      },
      {
        id: '1102',
        model: 'QX80',
        year: ['2018'],
        body: 'Crossover',
        market: 'Japan',
        img: '/images/catalog/models/infiniti/4473-250x150.jpg',
        title: 'Килимки в салон Infiniti QX80 2018-… III покоління Japan Crossover рест 7-ми місна',
        titleKey: 'infiniti28',
      },
    ],
  },
  /* ---------------------------------- Isuzu --------------------------------- */
  Isuzu: {
    logo: '/images/catalog/logo/isuzu-100x100.png',
    name: 'Isuzu',
    models: [
      {
        id: '1103',
        model: 'D-Max',
        year: ['2019', '2023'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/isuzu/4445-250x150.jpg',
        title: 'Килимки в салон Isuzu D-Max (RG) 2019-… III покоління EU Pickup 4-х дверна',
        titleKey: 'isuzu1',
      },
      {
        id: '1104',
        model: 'VehiCross',
        year: ['1997', '2001'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/isuzu/4446-250x150.jpg',
        title: 'Килимки в салон Isuzu VehiCross 1997-2001 I покоління EU Crossover',
        titleKey: 'isuzu2',
      },
    ],
  },
  /* ---------------------------------- Iveco --------------------------------- */
  Iveco: {
    logo: '/images/catalog/logo/iveco-100x100.png',
    name: 'Iveco',
    models: [
      {
        id: '1105',
        model: 'Iveco',
        year: ['1999', '2006'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/iveco/6027-250x150.jpg',
        title: 'Килимки в салон Iveco Daily 3 1999 - 2006 III покоління EU VAN',
        titleKey: 'iveco1',
      },
      {
        id: '1106',
        model: 'Iveco',
        year: ['2011', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/iveco/6028-250x150.jpg',
        title: 'Килимки в салон Iveco Daily 5 2011 - 2014 V покоління EU VAN',
        titleKey: 'iveco2',
      },
      {
        id: '1107',
        model: 'Iveco',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/iveco/6029-250x150.jpg',
        title: 'Килимки в салон Iveco Daily 6 2014 - ... VI покоління EU VAN',
        titleKey: 'iveco3',
      },
    ],
  },
  /* ----------------------------------- JAC ---------------------------------- */
  Jac: {
    logo: '/images/catalog/logo/jac-100x100.png',
    name: 'JAC',
    models: [
      {
        id: '1108',
        model: 'iEV7S',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/jac/4437-250x150.jpg',
        title: 'Килимки в салон JAC iEV7S 2018-… I покоління China Crossover Electric',
        titleKey: 'jac1',
      },
      {
        id: '1109',
        model: 'iEVS4',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/jac/4438-250x150.jpg',
        title: 'Килимки в салон JAC iEVS4 2018-… I покоління China Crossover Electric',
        titleKey: 'jac2',
      },
      {
        id: '1110',
        model: 'J5',
        year: ['2011', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jac/4439-250x150.jpg',
        title: 'Килимки в салон JAC J5 2011-2017 I покоління EU Sedan',
        titleKey: 'jac3',
      },
      {
        id: '1111',
        model: 'J7',
        year: ['2020'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/jac/4440-250x150.jpg',
        title: 'Килимки в салон JAC J7 2020-… I покоління EU Liftback',
        titleKey: 'jac4',
      },
      {
        id: '1112',
        model: 'S2',
        year: ['2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jac/4441-250x150.jpg',
        title: 'Килимки в салон JAC S2 2015-… I покоління EU Crossover',
        titleKey: 'jac5',
      },
      {
        id: '1113',
        model: 'S3',
        year: ['2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jac/4442-250x150.jpg',
        title: 'Килимки в салон JAC S3 2013-… I покоління EU Crossover',
        titleKey: 'jac6',
      },
      {
        id: '1114',
        model: 'S4',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jac/4443-250x150.jpg',
        title: 'Килимки в салон JAC S4 2018-… I покоління EU Crossover',
        titleKey: 'jac7',
      },
      {
        id: '1115',
        model: 'T8',
        year: ['2018'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/jac/4444-250x150.jpg',
        title: 'Килимки в салон JAC T8 2018-… I покоління EU Pickup 4-х дверна',
        titleKey: 'jac8',
      },
    ],
  },
  /* --------------------------------- Jaguar --------------------------------- */
  Jaguar: {
    logo: '/images/catalog/logo/jaguar-100x100.png',
    name: 'Jaguar',
    models: [
      {
        id: '1116',
        model: 'E-Pace',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4421-250x150.jpg',
        title: 'Килимки в салон Jaguar E-Pace 2017-… I покоління EU Crossover',
        titleKey: 'jaguar1',
      },
      {
        id: '1117',
        model: 'F-Pace',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4422-250x150.jpg',
        title: 'Килимки в салон Jaguar F-Pace (X761) 2016-… I покоління EU Crossover',
        titleKey: 'jaguar2',
      },
      {
        id: '1118',
        model: 'I-Pace',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4423-250x150.jpg',
        title: 'Килимки в салон Jaguar I-Pace 2018-… I покоління EU Crossover Еlectro',
        titleKey: 'jaguar3',
      },
      {
        id: '1119',
        model: 'S-type',
        year: ['1999', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4424-250x150.jpg',
        title: 'Килимки в салон Jaguar S-type 1999-2008 I покоління EU Sedan',
        titleKey: 'jaguar4',
      },
      {
        id: '1120',
        model: 'X-Type',
        year: ['2001', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4435-250x150.jpg',
        title: 'Килимки в салон Jaguar X-Type X400 2001-2007 I покоління EU Sedan дорест',
        titleKey: 'jaguar5',
      },
      {
        id: '1121',
        model: 'X-Type',
        year: ['2007', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4436-250x150.jpg',
        title: 'Килимки в салон Jaguar X-Type X400 2007-2009 I покоління EU Sedan рест',
        titleKey: 'jaguar6',
      },
      {
        id: '1122',
        model: 'XE',
        year: ['2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4425-250x150.jpg',
        title: 'Килимки в салон Jaguar XE (SV Project 8) 2014-… I покоління EU Sedan',
        titleKey: 'jaguar7',
      },
      {
        id: '1123',
        model: 'XE',
        year: ['2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/jaguar/4426-250x150.jpg',
        title: 'Килимки в салон Jaguar XE 2014-… I покоління USA Sedan',
        titleKey: 'jaguar8',
      },
      {
        id: '1124',
        model: 'XF',
        year: ['2007', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4427-250x150.jpg',
        title: 'Килимки в салон Jaguar XF (X250) 2007-2015 I покоління EU Sedan AWD',
        titleKey: 'jaguar9',
      },
      {
        id: '1125',
        model: 'XF',
        year: ['2008', '2011'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/jaguar/4428-250x150.jpg',
        title: 'Килимки в салон Jaguar XF (X250) 2008-2011 I покоління USA Sedan дорест',
        titleKey: 'jaguar10',
      },

      {
        id: '1126',
        model: 'XF',
        year: ['2011', '2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/jaguar/4429-250x150.jpg',
        title: 'Килимки в салон Jaguar XF (X250) 2011-2015 I покоління USA Sedan рест',
        titleKey: 'jaguar11',
      },
      {
        id: '1127',
        model: 'XF',
        year: ['2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/jaguar/4430-250x150.jpg',
        title: 'Килимки в салон Jaguar XF (X260) 2015-… II покоління USA Sedan',
        titleKey: 'jaguar12',
      },
      {
        id: '1128',
        model: 'XJ',
        year: ['2009', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4434-250x150.jpg',
        title: 'Килимки в салон Jaguar XJ (X351) 2009-2019 IV покоління EU Sedan',
        titleKey: 'jaguar13',
      },
      {
        id: '1129',
        model: 'XJ',
        year: ['2009', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4433-250x150.jpg',
        title: 'Килимки в салон Jaguar XJ (X351) 2009-2019 IV покоління EU Sedan AWD',
        titleKey: 'jaguar14',
      },
      {
        id: '1130',
        model: 'XJ',
        year: ['2009', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4431-250x150.jpg',
        title: 'Килимки в салон Jaguar XJ (X351) 2009-2019 IV покоління EU Sedan Long',
        titleKey: 'jaguar15',
      },
      {
        id: '1131',
        model: 'XJ',
        year: ['2009', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/jaguar/4432-250x150.jpg',
        title: 'Килимки в салон Jaguar XJ (X351) 2009-2019 IV покоління EU Sedan Long/AWD',
        titleKey: 'jaguar16',
      },
    ],
  },
  /* ---------------------------------- Jeep ---------------------------------- */
  Jeep: {
    logo: '/images/catalog/logo/jeep-100x100.png',
    name: 'Jeep',
    models: [
      {
        id: '1132',
        model: 'Cherokee',
        year: ['2001', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4390-250x150.jpg',
        title: 'Килимки в салон Jeep Cherokee (KJ) 2001-2008 III покоління EU Crossover',
        titleKey: 'jeep1',
      },
      {
        id: '1133',
        model: 'Cherokee',
        year: ['2001', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4390-250x150.jpg',
        title: 'Килимки в салон Jeep Cherokee (KJ) 2001-2008 III покоління EU Crossover праве кермо',
        titleKey: 'jeep2',
      },
      {
        id: '1134',
        model: 'Cherokee',
        year: ['2008', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4391-250x150.jpg',
        title: 'Килимки в салон Jeep Cherokee (KK) 2008-2013 IV покоління EU Crossover',
        titleKey: 'jeep3',
      },
      {
        id: '1135',
        model: 'Cherokee',
        year: ['2013', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4392-250x150.jpg',
        title: 'Килимки в салон Jeep Cherokee (KL) 2013-2018 V покоління USA Crossover дорест',
        titleKey: 'jeep4',
      },
      {
        id: '1136',
        model: 'Cherokee',
        year: ['2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4393-250x150.jpg',
        title: 'Килимки в салон Jeep Cherokee (KL) 2013-… V покоління EU Crossover',
        titleKey: 'jeep5',
      },
      {
        id: '1137',
        model: 'Cherokee',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4395-250x150.jpg',
        title: 'Килимки в салон Jeep Cherokee (KL) 2018-… V покоління USA Crossover рест',
        titleKey: 'jeep6',
      },
      {
        id: '1138',
        model: 'Cherokee',
        year: ['2013'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4394-250x150.jpg',
        title: 'Килимки в салон Jeep Cherokee (KL) Trailhawk 2013-… V покоління USA Crossover',
        titleKey: 'jeep7',
      },
      {
        id: '1139',
        model: 'Cherokee',
        year: ['1984', '2001'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4389-250x150.jpg',
        title: 'Килимки в салон Jeep Cherokee (XJ) 1984-2001 II покоління EU Crossover',
        titleKey: 'jeep8',
      },
      {
        id: '1140',
        model: 'Commander',
        year: ['2005', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4396-250x150.jpg',
        title: 'Килимки в салон Jeep Commander 2005-2010 I покоління EU Crossover',
        titleKey: 'jeep9',
      },
      {
        id: '1141',
        model: 'Compass',
        year: ['2006', '2011'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4397-250x150.jpg',
        title: 'Килимки в салон Jeep Compass 2006-2011 I покоління USA Crossover дорест',
        titleKey: 'jeep10',
      },

      {
        id: '1142',
        model: 'Compass',
        year: ['2011', '2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4398-250x150.jpg',
        title: 'Килимки в салон Jeep Compass 2011-2016 I покоління USA Crossover рест',
        titleKey: 'jeep11',
      },
      {
        id: '1143',
        model: 'Compass',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4399-250x150.jpg',
        title: 'Килимки в салон Jeep Compass 2016-… II покоління USA Crossover',
        titleKey: 'jeep12',
      },
      {
        id: '1144',
        model: 'Compass',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4400-250x150.jpg',
        title: 'Килимки в салон Jeep Compass Trailhawk 2016-… II покоління USA Crossover',
        titleKey: 'jeep13',
      },
      {
        id: '1145',
        model: 'Grand Cherokee',
        year: ['1998', '2004'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4402-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee (WJ) 1998-2004 II покоління EU Crossover',
        titleKey: 'jeep14',
      },
      {
        id: '1146',
        model: 'Grand Cherokee',
        year: ['2005', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4403-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee (WK) 2005-2010 III покоління EU Crossover',
        titleKey: 'jeep15',
      },
      {
        id: '1147',
        model: 'Grand Cherokee',
        year: ['2010', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4406-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee (WK2) 2010-2013 IV покоління EU Crossover дорест',
        titleKey: 'jeep16',
      },
      {
        id: '1148',
        model: 'Grand Cherokee',
        year: ['2010', '2013'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4404-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee (WK2) 2010-2013 IV покоління USA Crossover дорест',
        titleKey: 'jeep17',
      },
      {
        id: '1149',
        model: 'Grand Cherokee',
        year: ['2013', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4410-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee (WK2) 2013-2021 IV покоління EU Crossover рест',
        titleKey: 'jeep18',
      },
      {
        id: '1150',
        model: 'Grand Cherokee',
        year: ['1993', '1998'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4401-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee (ZJ) 1993-1998 I покоління USA Crossover',
        titleKey: 'jeep19',
      },
      {
        id: '1151',
        model: 'Grand Cherokee',
        year: ['2010', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4405-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee Laredo (WK2) 2010-2013 IV покоління EU Crossover дорест',
        titleKey: 'jeep20',
      },

      {
        id: '1152',
        model: 'Grand Cherokee',
        year: ['2013', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/jeep/4409-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee Laredo (WK2) 2013-2021 IV покоління EU Crossover рест',
        titleKey: 'jeep21',
      },
      {
        id: '1153',
        model: 'Grand Cherokee',
        year: ['2013', '2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4408-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee Limited (WK2) 2013-2021 IV покоління USA Crossover рест',
        titleKey: 'jeep22',
      },
      {
        id: '1154',
        model: 'Grand Cherokee',
        year: ['2011', '2021'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/jeep/4407-250x150.jpg',
        title: 'Килимки в салон Jeep Grand Cherokee Overland (WK2) 2011-2021 IV покоління China Crossover',
        titleKey: 'jeep23',
      },
      {
        id: '1155',
        model: 'Liberty',
        year: ['2001', '2008'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Jeep Liberty (KJ) 2001-2008 III покоління USA Crossover',
        titleKey: 'jeep24',
      },
      {
        id: '1156',
        model: 'Patriot',
        year: ['2006', '2011'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4411-250x150.jpg',
        title: 'Килимки в салон Jeep Patriot (MK74) 2006-2011 I покоління USA Crossover дорест',
        titleKey: 'jeep25',
      },
      {
        id: '1157',
        model: 'Patriot',
        year: ['2011', '2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4412-250x150.jpg',
        title: 'Килимки в салон Jeep Patriot (MK74) 2011-2016 I покоління USA Crossover рест',
        titleKey: 'jeep26',
      },
      {
        id: '1158',
        model: 'Renegade',
        year: ['2014'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4413-250x150.jpg',
        title: 'Килимки в салон Jeep Renegade 2014-… I покоління USA Crossover',
        titleKey: 'jeep27',
      },
      {
        id: '1159',
        model: 'Wrangler',
        year: ['2007', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4415-250x150.jpg',
        title: 'Килимки в салон Jeep Wrangler (JK) 2007-2018 III покоління USA Crossover 2-х дверна',
        titleKey: 'jeep28',
      },
      {
        id: '1160',
        model: 'Wrangler',
        year: ['2007', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4414-250x150.jpg',
        title: 'Килимки в салон Jeep Wrangler (JK) 2007-2018 III покоління USA Crossover 5-ти дверна',
        titleKey: 'jeep29',
      },
      {
        id: '1161',
        model: 'Wrangler',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4418-250x150.jpg',
        title: 'Килимки в салон Jeep Wrangler Rubicon Unlimited (JL) 2017-… IV покоління USA Crossover 5-ти дверна',
        titleKey: 'jeep30',
      },
      {
        id: '1162',
        model: 'Wrangler',
        year: ['2010', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4416-250x150.jpg',
        title: 'Килимки в салон Jeep Wrangler Unlimeted (JK) 2010-2018 III покоління USA Crossover рест',
        titleKey: 'jeep31',
      },
      {
        id: '1163',
        model: 'Wrangler',
        year: ['2010', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/jeep/4417-250x150.jpg',
        title:
          'Килимки в салон Jeep Wrangler Unlimeted Sahara (JK) 2010-2018 III покоління USA Crossover рест 4-х дверна',
        titleKey: 'jeep32',
      },
    ],
  },
  /* --------------------------------- Jetour --------------------------------- */
  Jetour: {
    logo: '/images/catalog/logo/Jetour-100x100.png',
    name: 'Jetour',
    models: [
      {
        id: '1164',
        model: 'Dashing',
        year: ['2022'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/jetour/5955-250x150.jpg',
        title: 'Килимки в салон Jetour Dashing 2022-… I покоління China Crossover',
        titleKey: 'jetour1',
      },
      {
        id: '1165',
        model: 'X70',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/jetour/5176-250x150.jpg',
        title: 'Килимки в салон Jetour X70 2018-… I покоління China Crossover 7-ми місна',
        titleKey: 'jetour2',
      },
      {
        id: '1166',
        model: 'X70',
        year: ['2020'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Jetour X70 Plus 2020-… I покоління China Crossover 7-ми місна',
        titleKey: 'jetour3',
      },
    ],
  },
  /* ----------------------------------- KIA ---------------------------------- */
  Kia: {
    logo: '/images/catalog/logo/kia-100x100.png',
    name: 'KIA',
    models: [
      {
        id: '1167',
        model: 'Avella',
        year: ['1995', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4261-250x150.jpg',
        title: 'Килимки в салон Kia Avella 1995-2000 EU Sedan',
        titleKey: 'kia1',
      },
      {
        id: '1168',
        model: 'Cadenza',
        year: ['2016', '2019'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/kia/4262-250x150.jpg',
        title: 'Килимки в салон Kia Cadenza (K7) 2016-2019 II покоління Korea Sedan дорест',
        titleKey: 'kia2',
      },
      {
        id: '1169',
        model: 'Cadenza',
        year: ['2019'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/kia/4264-250x150.jpg',
        title: 'Килимки в салон Kia Cadenza (K7) 2019-… II покоління Korea Sedan рест',
        titleKey: 'kia3',
      },
      {
        id: '1170',
        model: 'Cadenza',
        year: ['2009', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4262-250x150.jpg',
        title: 'Килимки в салон Kia Cadenza (VG) 2009-2016 I покоління EU Sedan',
        titleKey: 'kia4',
      },
      {
        id: '1171',
        model: 'Carens',
        year: ['2012', '2019'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4269-250x150.jpg',
        title: 'Килимки в салон Kia Carens (RP) 2012-2019 III покоління EU Minivan',
        titleKey: 'kia5',
      },
      {
        id: '1172',
        model: 'Carens',
        year: ['1999', '2006'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4266-250x150.jpg',
        title: 'Килимки в салон Kia Carens (RS) 1999-2006 I покоління EU Minivan 5-ти місна',
        titleKey: 'kia6',
      },
      {
        id: '1173',
        model: 'Carens',
        year: ['1999', '2006'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4265-250x150.jpg',
        title: 'Килимки в салон Kia Carens (RS) 1999-2006 I покоління EU Minivan 7-ми місна',
        titleKey: 'kia7',
      },
      {
        id: '1174',
        model: 'Carens',
        year: ['2006', '2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4268-250x150.jpg',
        title: 'Килимки в салон Kia Carens (UN) 2006-2012 II покоління EU Minivan 5-ти місна',
        titleKey: 'kia8',
      },
      {
        id: '1175',
        model: 'Carens',
        year: ['2006', '2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4267-250x150.jpg',
        title: 'Килимки в салон Kia Carens (UN) 2006-2012 II покоління EU Minivan 7-ми місна',
        titleKey: 'kia9',
      },
      {
        id: '1176',
        model: 'Carnival',
        year: ['2014', '2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4273-250x150.jpg',
        title: 'Килимки в салон Kia Carnival (Hi-Limousine) 2014-2020 III покоління EU Minivan 7-ми місна',
        titleKey: 'kia10',
      },

      {
        id: '1177',
        model: 'Carnival',
        year: ['1999', '2006'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4270-250x150.jpg',
        title: 'Килимки в салон Kia Carnival (KV-II) 1999-2006 I покоління EU Minivan',
        titleKey: 'kia11',
      },
      {
        id: '1178',
        model: 'Carnival',
        year: ['2006', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4271-250x150.jpg',
        title: 'Килимки в салон Kia Carnival (VQ) 2006-2014 II покоління EU Minivan 7-ми місна',
        titleKey: 'kia12',
      },
      {
        id: '1179',
        model: 'Carnival',
        year: ['2014', '2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/kia/4272-250x150.jpg',
        title: 'Килимки в салон Kia Carnival (YP) 2014-2020 III покоління EU Minivan 7-ми місна',
        titleKey: 'kia13',
      },
      {
        id: '1180',
        model: 'Ceed',
        year: ['2018', '2021'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4282-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (CD) 2018-2021 III покоління EU Hatchback дорест',
        titleKey: 'kia14',
      },
      {
        id: '1181',
        model: 'Ceed',
        year: ['2018', '2021'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/kia/4281-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (CD) 2018-2021 III покоління EU Universal дорест',
        titleKey: 'kia15',
      },
      {
        id: '1182',
        model: 'Ceed',
        year: ['2021'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4284-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (CD) 2021-… III покоління EU Hatchback рест',
        titleKey: 'kia16',
      },
      {
        id: '1183',
        model: 'Ceed',
        year: ['2021'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/kia/4283-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (CD) 2021-… III покоління EU Universal рест',
        titleKey: 'kia17',
      },
      {
        id: '1184',
        model: 'Ceed',
        year: ['2006', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4275-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (ED) 2006-2009 I покоління EU Hatchback дорест',
        titleKey: 'kia18',
      },
      {
        id: '1185',
        model: 'Ceed',
        year: ['2006', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/kia/4274-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (ED) 2006-2009 I покоління EU Universal дорест',
        titleKey: 'kia19',
      },
      {
        id: '1186',
        model: 'Ceed',
        year: ['2009', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4276-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (ED) 2009-2012 I покоління EU Hatchback рест',
        titleKey: 'kia20',
      },

      {
        id: '1187',
        model: 'Ceed',
        year: ['2009', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/kia/4277-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (ED) 2009-2012 I покоління EU Universal рест',
        titleKey: 'kia21',
      },
      {
        id: '1188',
        model: 'Ceed',
        year: ['2012', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4280-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (JD) 2012-2018 II покоління EU Hatchback',
        titleKey: 'kia22',
      },
      {
        id: '1189',
        model: 'Ceed',
        year: ['2012', '2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/kia/4279-250x150.jpg',
        title: 'Килимки в салон Kia Ceed (JD) 2012-2018 II покоління EU Universal',
        titleKey: 'kia23',
      },
      {
        id: '1190',
        model: 'Cerato',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4293-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (BD) 2018-… IV покоління EU Sedan',
        titleKey: 'kia24',
      },
      {
        id: '1191',
        model: 'Cerato',
        year: ['2003', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4286-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (LD) 2003-2008 I покоління EU Hatchback',
        titleKey: 'kia25',
      },
      {
        id: '1192',
        model: 'Cerato',
        year: ['2003', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4285-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (LD) 2003-2008 I покоління EU Sedan',
        titleKey: 'kia26',
      },
      {
        id: '1193',
        model: 'Cerato',
        year: ['2008', '2012'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/kia/4287-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (TD) 2008-2012 II покоління EU Coupe',
        titleKey: 'kia27',
      },
      {
        id: '1194',
        model: 'Cerato',
        year: ['2008', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4289-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (TD) 2008-2012 II покоління EU Hatchback',
        titleKey: 'kia28',
      },
      {
        id: '1195',
        model: 'Cerato',
        year: ['2008', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4288-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (TD) 2008-2012 II покоління EU Sedan',
        titleKey: 'kia29',
      },
      {
        id: '1196',
        model: 'Cerato',
        year: ['2012', '2018'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/kia/4291-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (YD) 2012-2018 III покоління EU Coupe',
        titleKey: 'kia30',
      },

      {
        id: '1197',
        model: 'Cerato',
        year: ['2012', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4292-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (YD) 2012-2018 III покоління EU Hatchback',
        titleKey: 'kia31',
      },
      {
        id: '1198',
        model: 'Cerato',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4290-250x150.jpg',
        title: 'Килимки в салон Kia Cerato (YD) 2012-2018 III покоління EU Sedan',
        titleKey: 'kia32',
      },
      {
        id: '1199',
        model: 'Clarus',
        year: ['1996', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4294-250x150.jpg',
        title: 'Килимки в салон Kia Clarus 1996-1998 I покоління EU Sedan дорест',
        titleKey: 'kia33',
      },
      {
        id: '1200',
        model: 'Forte',
        year: ['2018', '2021'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/4298-250x150.jpg',
        title: 'Килимки в салон Kia Forte (BD) 2018-2021 IV покоління USA Sedan',
        titleKey: 'kia34',
      },
      {
        id: '1201',
        model: 'Forte',
        year: ['2012', '2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/4296-250x150.jpg',
        title: 'Килимки в салон Kia Forte (YD) 2012-2016 III покоління USA Sedan дорест',
        titleKey: 'kia35',
      },
      {
        id: '1202',
        model: 'Forte',
        year: ['2016', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/4297-250x150.jpg',
        title: 'Килимки в салон Kia Forte (YD) 2016-2018 III покоління USA Sedan рест',
        titleKey: 'kia36',
      },
      {
        id: '1203',
        model: 'Forte',
        year: ['2008', '2012'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/kia/4295-250x150.jpg',
        title: 'Килимки в салон Kia Forte 2008-2012 II покоління USA Hatchback',
        titleKey: 'kia37',
      },
      {
        id: '1204',
        model: 'Grand',
        year: ['1994', '2002'],
        body: 'Crossover Long',
        market: 'EU',
        img: '/images/catalog/models/kia/4358-250x150.jpg',
        title: 'Килимки в салон Kia Grand Sportage (JA) 1994-2002 I покоління EU Crossover Long',
        titleKey: 'kia38',
      },
      {
        id: '1205',
        model: 'K2500',
        year: ['2012'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Kia K2500 2012-... IV покоління EU VAN',
        titleKey: 'kia39',
      },
      {
        id: '1206',
        model: 'K5',
        year: ['2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/6002-250x150.jpg',
        title: 'Килимки в салон Kia K5 (DL3) 2020-... V покоління USA Sedan',
        titleKey: 'kia40',
      },

      {
        id: '1207',
        model: 'K5',
        year: ['2015', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4302-250x150.jpg',
        title: 'Килимки в салон Kia K5 (JF) 2015-2020 IV покоління EU Sedan',
        titleKey: 'kia41',
      },
      {
        id: '1208',
        model: 'K5',
        year: ['2015', '2020'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/kia/4303-250x150.jpg',
        title: 'Килимки в салон Kia K5 (JF) 2015-2020 IV покоління Korea Sedan',
        titleKey: 'kia42',
      },
      {
        id: '1209',
        model: 'K5',
        year: ['2010', '2015'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/kia/4300-250x150.jpg',
        title: 'Килимки в салон Kia K5 (TF) 2010-2015 III покоління Korea Sedan',
        titleKey: 'kia43',
      },
      {
        id: '1210',
        model: 'K5',
        year: ['2010', '2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/4299-250x150.jpg',
        title: 'Килимки в салон Kia K5 (TF) 2010-2015 III покоління USA Sedan',
        titleKey: 'kia44',
      },
      {
        id: '1211',
        model: 'K5',
        year: ['2011', '2015'],
        body: 'Sedan Hybrid',
        market: 'USA',
        img: '/images/catalog/models/kia/4301-250x150.jpg',
        title: 'Килимки в салон Kia K5 (TF) 2011-2015 III покоління USA Sedan Hybrid',
        titleKey: 'kia45',
      },
      {
        id: '1212',
        model: 'K7',
        year: ['2009', '2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/4305-250x150.jpg',
        title: 'Килимки в салон Kia K7 (VG) 2009-2016 USA Sedan',
        titleKey: 'kia46',
      },
      {
        id: '1213',
        model: 'K7',
        year: ['2009', '2016'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/kia/4304-250x150.jpg',
        title: 'Килимки в салон Kia K7 (VG) 2009-2016 I покоління Korea Sedan',
        titleKey: 'kia47',
      },
      {
        id: '1214',
        model: 'KX3',
        year: ['2015', '2019'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/kia/4306-250x150.jpg',
        title: 'Килимки в салон Kia KX3 EV (KC) 2015-2019 I покоління China Crossover',
        titleKey: 'kia48',
      },
      {
        id: '1215',
        model: 'Magentis',
        year: ['2001', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4307-250x150.jpg',
        title: 'Килимки в салон Kia Magentis Kia Optima (EF) 2001-2005 I покоління EU Sedan',
        titleKey: 'kia49',
      },
      {
        id: '1216',
        model: 'Magentis',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4308-250x150.jpg',
        title: 'Килимки в салон Kia Magentis Kia Optima (MG) 2005-2011 II покоління EU Sedan',
        titleKey: 'kia50',
      },

      {
        id: '1217',
        model: 'Mohave',
        year: ['2008', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4309-250x150.jpg',
        title: 'Килимки в салон Kia Mohave 2008-2019 I покоління EU Crossover 7-ми місна',
        titleKey: 'kia51',
      },
      {
        id: '1218',
        model: 'Morning',
        year: ['2011', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4310-250x150.jpg',
        title: 'Килимки в салон Kia Morning (TA) 2011-2016 II покоління EU Hatchback',
        titleKey: 'kia52',
      },
      {
        id: '1219',
        model: 'Niro',
        year: ['2016', '2021'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/kia/4312-250x150.jpg',
        title: 'Килимки в салон Kia Niro (DE) 2016-2021 I покоління Korea Crossover Hybrid',
        titleKey: 'kia53',
      },
      {
        id: '1220',
        model: 'Niro',
        year: ['2016', '2021'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/kia/4313-250x150.jpg',
        title: 'Килимки в салон Kia Niro (DE) 2016-2021 I покоління Korea Crossover Plug-in Hybrid',
        titleKey: 'kia54',
      },
      {
        id: '1221',
        model: 'Niro',
        year: ['2016', '2021'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/kia/4311-250x150.jpg',
        title: 'Килимки в салон Kia Niro EV (DE) 2016-2021 I покоління Korea Crossover Electro',
        titleKey: 'kia55',
      },
      {
        id: '1222',
        model: 'Opirus',
        year: ['2003', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4314-250x150.jpg',
        title: 'Килимки в салон Kia Opirus 2003-2010 I покоління EU Sedan',
        titleKey: 'kia56',
      },
      {
        id: '1223',
        model: 'Optima',
        year: ['2015', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4318-250x150.jpg',
        title: 'Килимки в салон Kia Optima (JF) 2015-2020 IV покоління EU Sedan',
        titleKey: 'kia57',
      },
      {
        id: '1224',
        model: 'Optima',
        year: ['2015', '2020'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/kia/4319-250x150.jpg',
        title: 'Килимки в салон Kia Optima (JF) 2015-2020 IV покоління EU Universal',
        titleKey: 'kia58',
      },
      {
        id: '1225',
        model: 'Optima',
        year: ['2015', '2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/4317-250x150.jpg',
        title: 'Килимки в салон Kia Optima (JF) 2015-2020 IV покоління USA Sedan',
        titleKey: 'kia59',
      },
      {
        id: '1226',
        model: 'Optima',
        year: ['2010', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4316-250x150.jpg',
        title: 'Килимки в салон Kia Optima (TF) 2010-2016 III покоління EU Sedan',
        titleKey: 'kia60',
      },

      {
        id: '1227',
        model: 'Optima',
        year: ['2010', '2016'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/4315-250x150.jpg',
        title: 'Килимки в салон Kia Optima (TF) 2010-2016 III покоління USA Sedan',
        titleKey: 'kia61',
      },
      {
        id: '1228',
        model: 'Picanto',
        year: ['2004', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4320-250x150.jpg',
        title: 'Килимки в салон Kia Picanto (SA) 2004-2009 I покоління EU Hatchback дорест',
        titleKey: 'kia62',
      },
      {
        id: '1229',
        model: 'Picanto',
        year: ['2009', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4321-250x150.jpg',
        title: 'Килимки в салон Kia Picanto (SA) 2009-2011 I покоління EU Hatchback рест',
        titleKey: 'kia63',
      },
      {
        id: '1230',
        model: 'Picanto',
        year: ['2011', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4322-250x150.jpg',
        title: 'Килимки в салон Kia Picanto (TA) 2011-2017 II покоління EU Hatchback',
        titleKey: 'kia64',
      },
      {
        id: '1231',
        model: 'Picanto',
        year: ['2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4323-250x150.jpg',
        title: 'Килимки в салон Kia Picanto 2017-… III покоління EU Hatchback',
        titleKey: 'kia65',
      },
      {
        id: '1232',
        model: 'ProCeed',
        year: ['2006', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4278-250x150.jpg',
        title: 'Килимки в салон Kia ProCeed 2006-2012 II покоління EU Hatchback 3-х дверна',
        titleKey: 'kia66',
      },
      {
        id: '1233',
        model: 'Ray',
        year: ['2011'],
        body: 'Hatchback',
        market: 'Korea',
        img: '/images/catalog/models/kia/4324-250x150.jpg',
        title: 'Килимки в салон Kia Ray 2011-… I покоління Korea Hatchback',
        titleKey: 'kia67',
      },
      {
        id: '1234',
        model: 'Ray',
        year: ['2011'],
        body: 'Minivan',
        market: 'Korea',
        img: '/images/catalog/models/kia/4325-250x150.jpg',
        title: 'Килимки в салон Kia Ray EV 2011-… I покоління Korea Minivan Electro',
        titleKey: 'kia68',
      },
      {
        id: '1235',
        model: 'Rio',
        year: ['2000', '2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4326-250x150.jpg',
        title: 'Килимки в салон Kia Rio (DC) 2000-2005 I покоління EU Hatchback',
        titleKey: 'kia69',
      },
      {
        id: '1236',
        model: 'Rio',
        year: ['2000', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4327-250x150.jpg',
        title: 'Килимки в салон Kia Rio (DC) 2000-2005 I покоління EU Sedan',
        titleKey: 'kia70',
      },

      {
        id: '1237',
        model: 'Rio',
        year: ['2005', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4330-250x150.jpg',
        title: 'Килимки в салон Kia Rio (DE) 2005-2011 II покоління EU Hatchback',
        titleKey: 'kia71',
      },
      {
        id: '1238',
        model: 'Rio',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4334-250x150.jpg',
        title: 'Килимки в салон Kia Rio (DE) 2005-2011 II покоління EU Sedan',
        titleKey: 'kia72',
      },
      {
        id: '1239',
        model: 'Rio',
        year: ['2017'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/kia/4334-250x150.jpg',
        title: 'Килимки в салон Kia Rio (FB) 2017-… IV покоління USA Hatchback',
        titleKey: 'kia73',
      },
      {
        id: '1240',
        model: 'Rio',
        year: ['2017'],
        body: 'Hatchback',
        market: 'Korea',
        img: '/images/catalog/models/kia/4337-250x150.jpg',
        title: 'Килимки в салон Kia Rio (YB) 2017-… IV покоління Korea Hatchback',
        titleKey: 'kia74',
      },
      {
        id: '1241',
        model: 'Rio',
        year: ['2017'],
        body: 'Sedan',
        market: 'Korea',
        img: '/images/catalog/models/kia/4336-250x150.jpg',
        title: 'Килимки в салон Kia Rio (YB) 2017-… IV покоління Korea Sedan',
        titleKey: 'kia75',
      },
      {
        id: '1242',
        model: 'Rio',
        year: ['2011', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4332-250x150.jpg',
        title: 'Килимки в салон Kia Rio 2011-2017 III покоління EU Hatchback',
        titleKey: 'kia76',
      },
      {
        id: '1243',
        model: 'Rio',
        year: ['2011', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4333-250x150.jpg',
        title: 'Килимки в салон Kia Rio 2011-2017 III покоління EU Sedan',
        titleKey: 'kia77',
      },
      {
        id: '1244',
        model: 'Rio',
        year: ['2011', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/kia/4331-250x150.jpg',
        title: 'Килимки в салон Kia Rio 2011-2017 III покоління USA Sedan',
        titleKey: 'kia78',
      },
      {
        id: '1245',
        model: 'Rio',
        year: ['2017'],
        body: 'Hatchback',
        market: 'RU',
        img: '/images/catalog/models/kia/4335-250x150.jpg',
        title: 'Килимки в салон Kia Rio 2017-… IV покоління RU Hatchback',
        titleKey: 'kia79',
      },
      {
        id: '1246',
        model: 'Rio',
        year: ['2017'],
        body: 'Hatchback',
        market: 'RU',
        img: '/images/catalog/models/kia/4328-250x150.jpg',
        title: 'Килимки в салон Kia Rio X-Line 2017-… I покоління RU Hatchback',
        titleKey: 'kia80',
      },

      {
        id: '1247',
        model: 'Sedona',
        year: ['2014'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/kia/4338-250x150.jpg',
        title: 'Килимки в салон Kia Sedona 2014-… III покоління USA Minivan 8-ми місна',
        titleKey: 'kia81',
      },
      {
        id: '1248',
        model: 'Seltos',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/kia/4339-250x150.jpg',
        title: 'Килимки в салон Kia Seltos SX 2019-… II покоління USA Crossover',
        titleKey: 'kia82',
      },
      {
        id: '1249',
        model: 'Sephia',
        year: ['1993', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4340-250x150.jpg',
        title: 'Килимки в салон Kia Sephia 1993-1998 I покоління EU Sedan',
        titleKey: 'kia83',
      },
      {
        id: '1250',
        model: 'Shuma',
        year: ['1996', '2001'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4341-250x150.jpg',
        title: 'Килимки в салон Kia Shuma (Sephia II) 1996-2001 I покоління EU Hatchback',
        titleKey: 'kia84',
      },
      {
        id: '1251',
        model: 'Shuma',
        year: ['2001', '2004'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4342-250x150.jpg',
        title: 'Килимки в салон Kia Shuma 2001-2004 II покоління EU Hatchback',
        titleKey: 'kia85',
      },
      {
        id: '1252',
        model: 'Sorento',
        year: ['2002', '2009'],
        body: 'Crossover',
        market: 'Korea/EU',
        img: '/images/catalog/models/kia/4343-250x150.jpg',
        title: 'Килимки в салон Kia Sorento (BL) 2002-2009 I покоління Korea/EU Crossover 5-ти місна',
        titleKey: 'kia86',
      },
      {
        id: '1253',
        model: 'Sorento',
        year: ['2002', '2009'],
        body: 'Crossover',
        market: 'Korea/EU',
        img: '/images/catalog/models/kia/4343-250x150.jpg',
        title: 'Килимки в салон Kia Sorento (BL) 2002-2009 I покоління Korea/EU Crossover 5-ти місна праве кермо',
        titleKey: 'kia87',
      },
      {
        id: '1254',
        model: 'Sorento',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU/USA/Korea',
        img: '/images/catalog/models/kia/4348-250x150.jpg',
        title: 'Килимки в салон Kia Sorento (UM) 2014-2020 III покоління EU/USA/Korea Crossover 5-ти місна',
        titleKey: 'kia88',
      },
      {
        id: '1255',
        model: 'Sorento',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU/USA/Korea',
        img: '/images/catalog/models/kia/4349-250x150.jpg',
        title: 'Килимки в салон Kia Sorento (UM) 2014-2020 III покоління EU/USA/Korea Crossover 7-ми місна',
        titleKey: 'kia89',
      },
      {
        id: '1256',
        model: 'Sorento',
        year: ['2009', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/kia/4345-250x150.jpg',
        title: 'Килимки в салон Kia Sorento (XM) 2009-2012 II покоління EU/USA Crossover дорест 5-ти місна',
        titleKey: 'kia90',
      },

      {
        id: '1257',
        model: 'Sorento',
        year: ['2009', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/kia/4344-250x150.jpg',
        title: 'Килимки в салон Kia Sorento (XM) 2009-2012 II покоління EU/USA Crossover дорест 7-ми місна',
        titleKey: 'kia91',
      },
      {
        id: '1258',
        model: 'Sorento',
        year: ['2012', '2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/kia/4347-250x150.jpg',
        title: 'Килимки в салон Kia Sorento (XM) 2012-2014 II покоління EU/USA Crossover рест 5-ти місна',
        titleKey: 'kia92',
      },
      {
        id: '1259',
        model: 'Sorento',
        year: ['2012', '2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/kia/4346-250x150.jpg',
        title: 'Килимки в салон Kia Sorento (XM) 2012-2014 II покоління EU/USA Crossover рест 7-ми місна',
        titleKey: 'kia93',
      },
      {
        id: '1260',
        model: 'Soul',
        year: ['2008', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4350-250x150.jpg',
        title: 'Килимки в салон Kia Soul (AM) 2008-2013 I покоління EU Crossover',
        titleKey: 'kia94',
      },
      {
        id: '1261',
        model: 'Soul',
        year: ['2014', '2016'],
        body: 'Crossover',
        market: 'EU/USA/Korea',
        img: '/images/catalog/models/kia/4351-250x150.jpg',
        title: 'Килимки в салон Kia Soul (PS) 2014-2016 II покоління EU/USA/Korea Crossover дорест',
        titleKey: 'kia95',
      },
      {
        id: '1262',
        model: 'Soul',
        year: ['2016', '2018'],
        body: 'Crossover',
        market: 'EU/USA/Korea',
        img: '/images/catalog/models/kia/4353-250x150.jpg',
        title: 'Килимки в салон Kia Soul (PS) 2016-2018 II покоління EU/USA/Korea Crossover рест',
        titleKey: 'kia96',
      },
      {
        id: '1263',
        model: 'Soul',
        year: ['2018', '2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Kia Soul (SK3) 2018-2021 III покоління USA Crossover дорест',
        titleKey: 'kia97',
      },
      {
        id: '1264',
        model: 'Soul',
        year: ['2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/kia/4355-250x150.jpg',
        title: 'Килимки в салон Kia Soul (SK3) 2021-… III покоління USA Crossover рест',
        titleKey: 'kia98',
      },
      {
        id: '1265',
        model: 'Soul EV',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU/USA/Korea',
        img: '/images/catalog/models/kia/4352-250x150.jpg',
        title: 'Килимки в салон Kia Soul EV (PS) 2014-2020 II покоління EU/USA/Korea Crossover Electro',
        titleKey: 'kia99',
      },
      {
        id: '1266',
        model: 'Soul EV',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA/Korea',
        img: '/images/catalog/models/kia/4354-250x150.jpg',
        title: 'Килимки в салон Kia Soul EV (SK3) 2018-… III покоління USA/Korea Crossover Electro',
        titleKey: 'kia100',
      },

      {
        id: '1267',
        model: 'Spectra',
        year: ['2000', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/kia/4356-250x150.jpg',
        title: 'Килимки в салон Kia Spectra 2000-2011 I покоління EU Sedan',
        titleKey: 'kia101',
      },
      {
        id: '1268',
        model: 'Sportage',
        year: ['1993', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4357-250x150.jpg',
        title: 'Килимки в салон Kia Sportage (JA) 1993-2002 I покоління EU Crossover',
        titleKey: 'kia102',
      },
      {
        id: '1269',
        model: 'Sportage',
        year: ['2004', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4359-250x150.jpg',
        title: 'Килимки в салон Kia Sportage (JE) 2004-2010 II покоління EU Crossover',
        titleKey: 'kia103',
      },
      {
        id: '1270',
        model: 'Sportage',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4365-250x150.jpg',
        title: 'Килимки в салон Kia Sportage (NQ5) 2021-… V покоління EU Crossover',
        titleKey: 'kia104',
      },
      {
        id: '1271',
        model: 'Sportage',
        year: ['2015', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4363-250x150.jpg',
        title: 'Килимки в салон Kia Sportage (QL) 2015-2021 IV покоління EU Crossover',
        titleKey: 'kia105',
      },
      {
        id: '1272',
        model: 'Sportage',
        year: ['2015', '2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/kia/4364-250x150.jpg',
        title: 'Килимки в салон Kia Sportage (QL) 2015-2021 IV покоління USA Crossover',
        titleKey: 'kia106',
      },
      {
        id: '1273',
        model: 'Sportage',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4360-250x150.jpg',
        title: 'Килимки в салон Kia Sportage (SL) 2010-2015 III покоління EU Crossover',
        titleKey: 'kia107',
      },
      {
        id: '1274',
        model: 'Sportage',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'Korea',
        img: '/images/catalog/models/kia/4362-250x150.jpg',
        title: 'Килимки в салон Kia Sportage (SL) 2010-2015 III покоління Korea Crossover',
        titleKey: 'kia108',
      },
      {
        id: '1275',
        model: 'Sportage',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/kia/4361-250x150.jpg',
        title: 'Килимки в салон Kia Sportage (SL) 2010-2015 III покоління USA Crossover',
        titleKey: 'kia109',
      },
      {
        id: '1276',
        model: 'Stonic',
        year: ['2017', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4366-250x150.jpg',
        title: 'Килимки в салон Kia Stonic 2017-2019 I покоління EU Crossover дорест',
        titleKey: 'kia110',
      },

      {
        id: '1277',
        model: 'Stonic',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4367-250x150.jpg',
        title: 'Килимки в салон Kia Stonic 2019-… I покоління EU Crossover рест',
        titleKey: 'kia111',
      },
      {
        id: '1278',
        model: 'Telluride',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/kia/4368-250x150.jpg',
        title: 'Килимки в салон Kia Telluride 2019-… I покоління USA Crossover 7-ми місна',
        titleKey: 'kia112',
      },
      {
        id: '1279',
        model: 'Venga',
        year: ['2010', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/kia/4369-250x150.jpg',
        title: 'Килимки в салон Kia Venga 2010-2017 I покоління EU Hatchback',
        titleKey: 'kia113',
      },
      {
        id: '1280',
        model: 'XCeed',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/kia/4370-250x150.jpg',
        title: 'Килимки в салон Kia XCeed 2019-… III покоління EU Crossover',
        titleKey: 'kia114',
      },
    ],
  },
  /* ---------------------------------- Lada ---------------------------------- */
  Lada: {
    logo: '/images/catalog/logo/lada-100x100.png',
    name: 'Lada',
    models: [
      {
        id: '1281',
        model: '2110',
        year: ['1995', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lada/5888-250x150.jpg',
        title: 'Килимки в салон LADA 2110 1995-2015 I покоління EU Sedan',
        titleKey: 'lada1',
      },
      {
        id: '1282',
        model: '2111',
        year: ['1998', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/lada/5889-250x150.jpg',
        title: 'Килимки в салон LADA 2111 1998-2009 I покоління EU Universal',
        titleKey: 'lada2',
      },
      {
        id: '1283',
        model: '2112',
        year: ['1995', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/lada/5890-250x150.jpg',
        title: 'Килимки в салон LADA 2112 1995-2015 I покоління EU Hatchback',
        titleKey: 'lada3',
      },
      {
        id: '1284',
        model: '2113',
        year: ['2004', '2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/lada/5891-250x150.jpg',
        title: 'Килимки в салон LADA 2113 2004-2013 I покоління EU Hatchback 3-х дверна',
        titleKey: 'lada4',
      },
      {
        id: '1285',
        model: '2114',
        year: ['2001', '2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/lada/5892-250x150.jpg',
        title: 'Килимки в салон LADA 2114 2001-2013 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'lada5',
      },
      {
        id: '1286',
        model: '2115',
        year: ['1997', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lada/5893-250x150.jpg',
        title: 'Килимки в салон LADA 2115 1997-2012 I покоління EU Sedan',
        titleKey: 'lada6',
      },
      {
        id: '1287',
        model: 'Granta',
        year: ['2011', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lada/5894-250x150.jpg',
        title: 'Килимки в салон LADA Granta (2190) 2011-2018 I покоління EU Sedan',
        titleKey: 'lada7',
      },
      {
        id: '1288',
        model: 'Kalina',
        year: ['2004', '2013'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/lada/5895-250x150.jpg',
        title: 'Килимки в салон LADA Kalina 1117 2004-2013 I покоління EU Universal',
        titleKey: 'lada8',
      },
      {
        id: '1289',
        model: 'Kalina',
        year: ['2004', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lada/5896-250x150.jpg',
        title: 'Килимки в салон LADA Kalina 1118 2004-2013 I покоління EU Sedan',
        titleKey: 'lada9',
      },
      {
        id: '1290',
        model: 'Kalina',
        year: ['2004', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/lada/5897-250x150.jpg',
        title: 'Килимки в салон LADA Kalina 1119 2004-2011 I покоління EU Hatchback',
        titleKey: 'lada10',
      },

      {
        id: '1291',
        model: 'Largus',
        year: ['2012'],
        body: 'Universal 7-ми місна',
        market: 'EU',
        img: '/images/catalog/models/lada/5898-250x150.jpg',
        title: 'Килимки в салон LADA Largus 2012-… I покоління EU Universal 7-ми місна',
        titleKey: 'lada11',
      },
      {
        id: '1292',
        model: 'Niva Urban',
        year: ['2015', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lada/5899-250x150.jpg',
        title: 'Килимки в салон LADA Niva 4x4 Urban 2015-2020 I покоління EU Crossover',
        titleKey: 'lada12',
      },
      {
        id: '1293',
        model: 'Priora',
        year: ['2007', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lada/5900-250x150.jpg',
        title: 'Килимки в салон LADA Priora 2170 2007-2018 I покоління EU Sedan',
        titleKey: 'lada13',
      },
      {
        id: '1294',
        model: 'Priora',
        year: ['2007', '2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/lada/5901-250x150.jpg',
        title: 'Килимки в салон LADA Priora 2171 2007-2018 I покоління EU Universal',
        titleKey: 'lada14',
      },
      {
        id: '1295',
        model: 'Priora',
        year: ['2007', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/lada/5902-250x150.jpg',
        title: 'Килимки в салон LADA Priora 2172 2007-2018 I покоління EU Hatchback',
        titleKey: 'lada15',
      },
      {
        id: '1296',
        model: 'Vesta',
        year: ['2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lada/5903-250x150.jpg',
        title: 'Килимки в салон LADA Vesta 2015-… I покоління EU Sedan',
        titleKey: 'lada16',
      },
      {
        id: '1297',
        model: 'Vesta',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lada/5904-250x150.jpg',
        title: 'Килимки в салон LADA Vesta SW Cross 2017-… I покоління EU Crossover',
        titleKey: 'lada17',
      },
    ],
  },
  /* ------------------------------- Lamborghini ------------------------------ */
  Lamborghini: {
    logo: '/images/catalog/logo/Lamborghini-100x100.png',
    name: 'Lamborghini',
    models: [
      {
        id: '1298',
        model: 'Huracan',
        year: ['2014'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/lamborghini/5175-250x150.jpg',
        title: 'Килимки в салон Lamborghini Huracan 2014-… II покоління EU Coupe',
        titleKey: 'lamborghini1',
      },
    ],
  },
  /* --------------------------------- Lancia --------------------------------- */
  Lancia: {
    logo: '/images/catalog/logo/Lancia-100x100.png',
    name: 'Lancia',
    models: [
      {
        id: '1299',
        model: 'Dedra',
        year: ['1989', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lancia/5169-250x150.jpg',
        title: 'Килимки в салон Lancia Dedra 1989-2000 I покоління EU Sedan',
        titleKey: 'lancia1',
      },
      {
        id: '1300',
        model: 'Delta',
        year: ['2008', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/lancia/5170-250x150.jpg',
        title: 'Килимки в салон Lancia Delta 2008-2014 III покоління EU Hatchback',
        titleKey: 'lancia2',
      },
      {
        id: '1301',
        model: 'Lybra',
        year: ['1999', '2005'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/lancia/5171-250x150.jpg',
        title: 'Килимки в салон Lancia Lybra 1999-2005 I покоління EU Universal',
        titleKey: 'lancia3',
      },
      {
        id: '1302',
        model: 'Musa',
        year: ['2004', '2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/lancia/5173-250x150.jpg',
        title: 'Килимки в салон Lancia Musa 2004-2012 I покоління EU Minivan',
        titleKey: 'lancia4',
      },
      {
        id: '1303',
        model: 'Thesis',
        year: ['2002', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lancia/5172-250x150.jpg',
        title: 'Килимки в салон Lancia Thesis (841) 2002-2009 I покоління EU Sedan',
        titleKey: 'lancia5',
      },
      {
        id: '1304',
        model: 'Ypsilon',
        year: ['1995', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Lancia Ypsilon (840) 1995-2003 I покоління EU Hatchback',
        titleKey: 'lancia6',
      },
      {
        id: '1305',
        model: 'Ypsilon',
        year: ['2003', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Lancia Ypsilon (843) 2003-2011 II покоління EU Hatchback',
        titleKey: 'lancia7',
      },
      {
        id: '1306',
        model: 'Ypsilon',
        year: ['2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/lancia/5174-250x150.jpg',
        title: 'Килимки в салон Lancia Ypsilon (846) 2011-… III покоління EU Hatchback',
        titleKey: 'lancia8',
      },
    ],
  },
  /* -------------------------------- LandRover ------------------------------- */
  Landrover: {
    logo: '/images/catalog/logo/land_rover-100x100.png',
    name: 'Land Rover',
    models: [
      {
        id: '1307',
        model: 'Defender',
        year: ['1990', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4229-250x150.jpg',
        title: 'Килимки в салон Land Rover Defender (L316) 1990-2016 I покоління EU Crossover',
        titleKey: 'landRover1',
      },
      {
        id: '1308',
        model: 'Defender',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/landrover/4230-250x150.jpg',
        title: 'Килимки в салон Land Rover Defender (L663) 2019-… II покоління USA Crossover 5-ти дверна',
        titleKey: 'landRover2',
      },
      {
        id: '1309',
        model: 'Discovery',
        year: ['1989', '1999'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4231-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery 1 1989-1999 I покоління EU Crossover',
        titleKey: 'landRover3',
      },
      {
        id: '1310',
        model: 'Discovery',
        year: ['1998', '2004'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4236-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery 2 1998-2004 II покоління EU Crossover',
        titleKey: 'landRover4',
      },
      {
        id: '1311',
        model: 'Discovery',
        year: ['2004', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4237-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery 3 2004-2009 III покоління EU Crossover',
        titleKey: 'landRover5',
      },
      {
        id: '1312',
        model: 'Discovery',
        year: ['2009', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4238-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery 4 2009-2017 IV покоління EU Crossover',
        titleKey: 'landRover6',
      },
      {
        id: '1313',
        model: 'Discovery',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4239-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery 5 2017-… V покоління EU Crossover 5-ти місна',
        titleKey: 'landRover7',
      },
      {
        id: '1314',
        model: 'Discovery',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4240-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery 5 2017-… V покоління EU Crossover 7-ми місна',
        titleKey: 'landRover8',
      },
      {
        id: '1315',
        model: 'Discovery',
        year: ['2014', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4232-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery Sport 2014-2019 I покоління EU Crossover дорест 7-ми місна',
        titleKey: 'landRover9',
      },
      {
        id: '1316',
        model: 'Discovery',
        year: ['2014', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/landrover/4233-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery Sport 2014-2019 I покоління USA Crossover дорест',
        titleKey: 'landRover10',
      },

      {
        id: '1317',
        model: 'Discovery',
        year: ['2019'],
        body: 'Crossover Hybrid',
        market: 'EU',
        img: '/images/catalog/models/landrover/4234-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery Sport 2019-… I покоління EU Crossover Hybrid',
        titleKey: 'landRover11',
      },
      {
        id: '1318',
        model: 'Discovery',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4235-250x150.jpg',
        title: 'Килимки в салон Land Rover Discovery Sport 2019-… I покоління EU Crossover рест',
        titleKey: 'landRover12',
      },
      {
        id: '1319',
        model: 'Freelander',
        year: ['1997', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4243-250x150.jpg',
        title: 'Килимки в салон Land Rover Freelander (L314) 1997-2006 I покоління EU Crossover 3-х дверна',
        titleKey: 'landRover13',
      },
      {
        id: '1320',
        model: 'Freelander',
        year: ['1997', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4242-250x150.jpg',
        title:
          'Килимки в салон Land Rover Freelander (L314) 1997-2006 I покоління EU Crossover 5-ти дверна без зони відпочинку лівої ноги',
        titleKey: 'landRover14',
      },
      {
        id: '1321',
        model: 'Freelander',
        year: ['1997', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4242-250x150.jpg',
        title:
          'Килимки в салон Land Rover Freelander (L314) 1997-2006 I покоління EU Crossover 5-ти дверна без зони відпочинку лівої ноги праве кермо',
        titleKey: 'landRover15',
      },
      {
        id: '1322',
        model: 'Freelander',
        year: ['1997', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4241-250x150.jpg',
        title:
          'Килимки в салон Land Rover Freelander (L314) 1997-2006 I покоління EU Crossover 5-ти дверна з зоною відпочинку лівої ноги',
        titleKey: 'landRover16',
      },
      {
        id: '1323',
        model: 'Freelander',
        year: ['2006', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4244-250x150.jpg',
        title: 'Килимки в салон Land Rover Freelander (L359) 2006-2014 II покоління EU Crossover',
        titleKey: 'landRover17',
      },
      {
        id: '1324',
        model: 'Range Rover',
        year: ['2002', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4246-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover (L322) 2002-2010 III покоління EU Crossover',
        titleKey: 'landRover18',
      },
      {
        id: '1325',
        model: 'Range Rover',
        year: ['2010', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4247-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover (L322) 2010-2012 III покоління EU Crossover рест',
        titleKey: 'landRover19',
      },
      {
        id: '1326',
        model: 'Range Rover',
        year: ['2012', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4248-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover (L405) 2012-2021 IV покоління EU Crossover Short',
        titleKey: 'landRover20',
      },

      {
        id: '1327',
        model: 'Range Rover',
        year: ['2013', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4250-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover (L405) 2013-2021 IV покоління EU Crossover Hybrid/Long',
        titleKey: 'landRover21',
      },
      {
        id: '1328',
        model: 'Range Rover',
        year: ['1994', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4245-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover (P38A) 1994-2002 II покоління EU Crossover',
        titleKey: 'landRover22',
      },
      {
        id: '1329',
        model: 'Range Rover Evoque',
        year: ['2011', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4253-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover Evoque (L538) 2011-2018 I покоління EU Crossover 5-ти дверна',
        titleKey: 'landRover23',
      },
      {
        id: '1330',
        model: 'Range Rover Evoque',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4254-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover Evoque (L551) 2018-… II покоління EU Crossover',
        titleKey: 'landRover24',
      },
      {
        id: '1331',
        model: 'Range Rover',
        year: ['2012', '2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/landrover/4249-250x150.jpg',
        title:
          'Килимки в салон Land Rover Range Rover LWB (L405) 2012-2021 IV покоління USA Crossover 5-ти дверна Long',
        titleKey: 'landRover25',
      },
      {
        id: '1332',
        model: 'Range Rover',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4251-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover LWB (L460) 2021-… V покоління EU Crossover long',
        titleKey: 'landRover26',
      },
      {
        id: '1333',
        model: 'Range Rover Sport',
        year: ['2005', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4255-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover Sport (L320) 2005-2009 I покоління EU Crossover дорест',
        titleKey: 'landRover27',
      },
      {
        id: '1334',
        model: 'Range Rover Sport',
        year: ['2009', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4256-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover Sport (L320) 2009-2013 I покоління EU Crossover рест',
        titleKey: 'landRover28',
      },
      {
        id: '1335',
        model: 'Range Rover Sport',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Land Rover Range Rover Sport (L461) 2022-… III покоління EU Crossover',
        titleKey: 'landRover29',
      },
      {
        id: '1336',
        model: 'Range Rover Sport',
        year: ['2013', '2022'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/landrover/4257-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover Sport (L494) 2013-2022 II покоління EU/USA Crossover',
        titleKey: 'landRover30',
      },

      {
        id: '1337',
        model: 'Range Rover',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4252-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover SWB (L460) 2021-… V покоління EU Crossover Short',
        titleKey: 'landRover31',
      },
      {
        id: '1338',
        model: 'Range Rover Velar',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/landrover/4258-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover Velar 2017-… I покоління USA Crossover',
        titleKey: 'landRover32',
      },
      {
        id: '1339',
        model: 'Range Rover Vogue',
        year: ['2013', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4260-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover Vogue (L405) 2013-2021 IV покоління EU Crossover',
        titleKey: 'landRover33',
      },
      {
        id: '1340',
        model: 'Range Rover Vogue',
        year: ['2005', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/landrover/4259-250x150.jpg',
        title: 'Килимки в салон Land Rover Range Rover Vogue (LM) 2005-2009 III покоління EU Crossover',
        titleKey: 'landRover34',
      },
    ],
  },
  /* -------------------------------- Leopaard -------------------------------- */
  Leopaard: {
    logo: '/images/catalog/logo/Leopaard-100x100.png',
    name: 'Leopaard',
    models: [
      {
        id: '1341',
        model: 'Leopaard',
        year: ['2017'],
        body: 'China',
        market: 'EU',
        img: '/images/catalog/models/leopaard/5078-250x150.jpg',
        title: 'Килимки в салон Leopaard CS9EV 2017-… I покоління China Crossover 6-ти місна electro',
        titleKey: 'leopaard',
      },
    ],
  },
  /* ---------------------------------- Lexus --------------------------------- */
  Lexus: {
    logo: '/images/catalog/logo/lexus-100x100.png',
    name: 'Lexus',
    models: [
      {
        id: '1342',
        model: 'ES',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4164-250x150.jpg',
        title: 'Килимки в салон Lexus ES 250 (XV60) 2012-2018 VI покоління EU Sedan',
        titleKey: 'lexus1',
      },
      {
        id: '1343',
        model: 'ES',
        year: ['2015', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lexus/4166-250x150.jpg',
        title: 'Килимки в салон Lexus ES 250 (XV60) 2015-2018 VI покоління USA Sedan',
        titleKey: 'lexus2',
      },
      {
        id: '1344',
        model: 'ES',
        year: ['2021'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4171-250x150.jpg',
        title: 'Килимки в салон Lexus ES 250 (XZ10) 2021-… VII покоління EU Sedan рест',
        titleKey: 'lexus3',
      },
      {
        id: '1345',
        model: 'ES',
        year: ['2018', '2021'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4169-250x150.jpg',
        title: 'Килимки в салон Lexus ES 300 (XZ10) 2018-2021 VII покоління EU Sedan дорест',
        titleKey: 'lexus4',
      },
      {
        id: '1346',
        model: 'ES',
        year: ['2001', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4162-250x150.jpg',
        title: 'Килимки в салон Lexus ES 300 2001-2006 IV покоління EU Sedan',
        titleKey: 'lexus5',
      },
      {
        id: '1347',
        model: 'ES',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lexus/4165-250x150.jpg',
        title: 'Килимки в салон Lexus ES 300h (GSV50) 2012-2018 VI покоління USA Sedan Hybrid',
        titleKey: 'lexus6',
      },
      {
        id: '1348',
        model: 'ES',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4168-250x150.jpg',
        title: 'Килимки в салон Lexus ES 300h (XZ10) 2018-… VII покоління EU Sedan Hybrid',
        titleKey: 'lexus7',
      },
      {
        id: '1349',
        model: 'ES',
        year: ['2006', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4163-250x150.jpg',
        title: 'Килимки в салон Lexus ES 350 (XV40) 2006-2012 V покоління EU Sedan',
        titleKey: 'lexus8',
      },
      {
        id: '1350',
        model: 'ES',
        year: ['2018', '2021'],
        body: 'Sedan',
        market: 'Japan',
        img: '/images/catalog/models/lexus/4170-250x150.jpg',
        title: 'Килимки в салон Lexus ES 350 (XZ10) 2018-2021 VII покоління Japan Sedan дорест',
        titleKey: 'lexus9',
      },
      {
        id: '1351',
        model: 'ES',
        year: ['2018', '2021'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lexus/4167-250x150.jpg',
        title: 'Килимки в салон Lexus ES 350 (XZ10) 2018-2021 VII покоління USA Sedan дорест',
        titleKey: 'lexus10',
      },

      {
        id: '1352',
        model: 'GS',
        year: ['1991', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4172-250x150.jpg',
        title: 'Килимки в салон Lexus GS (JZS147) 1991-1997 I покоління EU Sedan',
        titleKey: 'lexus11',
      },
      {
        id: '1353',
        model: 'GS',
        year: ['2011', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4177-250x150.jpg',
        title: 'Килимки в салон Lexus GS (L10) 2011-2020 IV покоління EU Sedan',
        titleKey: 'lexus12',
      },
      {
        id: '1354',
        model: 'GS',
        year: ['2011', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4176-250x150.jpg',
        title: 'Килимки в салон Lexus GS (L10) 2011-2020 IV покоління EU Sedan AWD',
        titleKey: 'lexus13',
      },
      {
        id: '1355',
        model: 'GS',
        year: ['2011', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/6003-250x150.jpg',
        title: 'Килимки в салон Lexus GS (L10) 2011-2020 IV покоління EU Sedan Hybrid',
        titleKey: 'lexus14',
      },
      {
        id: '1356',
        model: 'GS',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4174-250x150.jpg',
        title: 'Килимки в салон Lexus GS 300 (GSR 190) 2005-2011 III покоління EU Sedan',
        titleKey: 'lexus15',
      },
      {
        id: '1357',
        model: 'GS',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4175-250x150.jpg',
        title: 'Килимки в салон Lexus GS 300 (GSR 190) 2005-2011 III покоління EU Sedan AWD',
        titleKey: 'lexus16',
      },
      {
        id: '1358',
        model: 'GS',
        year: ['1997', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4173-250x150.jpg',
        title: 'Килимки в салон Lexus GS 300 (JZS160-JZS161) 1997-2005 II покоління EU Sedan',
        titleKey: 'lexus17',
      },
      {
        id: '1359',
        model: 'GX',
        year: ['2009', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4179-250x150.jpg',
        title: 'Килимки в салон Lexus GX 460 (URJ150) 2009-2013 II покоління EU Crossover дорест 7-ми місна',
        titleKey: 'lexus18',
      },
      {
        id: '1360',
        model: 'GX',
        year: ['2012', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lexus/4180-250x150.jpg',
        title: 'Килимки в салон Lexus GX 460 (URJ150) 2012-2019 II покоління USA Crossover 6-ти місна',
        titleKey: 'lexus19',
      },
      {
        id: '1361',
        model: 'GX',
        year: ['2013', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4182-250x150.jpg',
        title: 'Килимки в салон Lexus GX 460 (URJ150) 2013-2023 II покоління EU Crossover рест 5-ти місна',
        titleKey: 'lexus20',
      },

      {
        id: '1362',
        model: 'GX',
        year: ['2013', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4181-250x150.jpg',
        title: 'Килимки в салон Lexus GX 460 (URJ150) 2013-2023 II покоління EU Crossover рест 7-ми місна',
        titleKey: 'lexus21',
      },
      {
        id: '1363',
        model: 'GX',
        year: ['2002', '2009'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lexus/4178-250x150.jpg',
        title: 'Килимки в салон Lexus GX 470 (UZJ120) 2002-2009 I покоління USA Crossover',
        titleKey: 'lexus22',
      },
      {
        id: '1364',
        model: 'HS',
        year: ['2009', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4183-250x150.jpg',
        title: 'Килимки в салон Lexus HS 250h 2009-2017 I покоління EU Sedan Hybrid',
        titleKey: 'lexus23',
      },
      {
        id: '1365',
        model: 'IS',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4184-250x150.jpg',
        title: 'Килимки в салон Lexus IS 200 (XE1) 1998-2005 I покоління EU Sedan',
        titleKey: 'lexus24',
      },
      {
        id: '1366',
        model: 'IS',
        year: ['2005', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4187-250x150.jpg',
        title: 'Килимки в салон Lexus IS 250 (XE2) 2005-2013 II покоління EU Sedan AWD',
        titleKey: 'lexus25',
      },
      {
        id: '1367',
        model: 'IS',
        year: ['2005', '2013'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/lexus/4185-250x150.jpg',
        title: 'Килимки в салон Lexus IS 250 (XE2) 2005-2013 II покоління EU/USA Sedan FWD',
        titleKey: 'lexus26',
      },
      {
        id: '1368',
        model: 'IS',
        year: ['2005', '2013'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lexus/4186-250x150.jpg',
        title: 'Килимки в салон Lexus IS 250 (XE2) 2005-2013 II покоління USA Sedan AWD',
        titleKey: 'lexus27',
      },
      {
        id: '1369',
        model: 'IS',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4189-250x150.jpg',
        title: 'Килимки в салон Lexus IS 250 (XE3) 2013-… III покоління EU Sedan AWD',
        titleKey: 'lexus28',
      },
      {
        id: '1370',
        model: 'IS',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4188-250x150.jpg',
        title: 'Килимки в салон Lexus IS 300 (XE3) 2013-… III покоління EU Sedan FWD',
        titleKey: 'lexus29',
      },
      {
        id: '1371',
        model: 'LS',
        year: ['1995', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4190-250x150.jpg',
        title: 'Килимки в салон Lexus LS 400 (UCF20) 1995-2000 II покоління EU Sedan',
        titleKey: 'lexus30',
      },

      {
        id: '1372',
        model: 'LS',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4191-250x150.jpg',
        title: 'Килимки в салон Lexus LS 430 (UCF30) 2000-2006 III покоління EU Sedan',
        titleKey: 'lexus31',
      },
      {
        id: '1373',
        model: 'LS',
        year: ['2006', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lexus/4192-250x150.jpg',
        title: 'Килимки в салон Lexus LS 460 (UCF40) 2006-2017 IV покоління USA Sedan',
        titleKey: 'lexus32',
      },
      {
        id: '1374',
        model: 'LS',
        year: ['2007', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4193-250x150.jpg',
        title: 'Килимки в салон Lexus LS 460 L (UCF40) 2007-2017 IV покоління EU Sedan',
        titleKey: 'lexus33',
      },
      {
        id: '1375',
        model: 'LS',
        year: ['2007', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4194-250x150.jpg',
        title: 'Килимки в салон Lexus LS 460 L (UCF40) 2007-2017 IV покоління EU Sedan AWD',
        titleKey: 'lexus34',
      },
      {
        id: '1376',
        model: 'LS',
        year: ['2007', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lexus/4195-250x150.jpg',
        title: 'Килимки в салон Lexus LS 600 h L (UVF46) 2007-2017 IV покоління EU Sedan Hybrid/AWD',
        titleKey: 'lexus35',
      },
      {
        id: '1377',
        model: 'LX',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4201-250x150.jpg',
        title: 'Килимки в салон Lexus LX 450d (J200) 2015-2022 III покоління EU Crossover рест',
        titleKey: 'lexus36',
      },
      {
        id: '1378',
        model: 'LX',
        year: ['1998', '2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4196-250x150.jpg',
        title: 'Килимки в салон Lexus LX 470 (UZJ100) 1998-2007 II покоління EU Crossover 7-ми місна',
        titleKey: 'lexus37',
      },
      {
        id: '1379',
        model: 'LX',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4203-250x150.jpg',
        title: 'Килимки в салон Lexus LX 500d (J310) 2022-… IV покоління EU Crossover',
        titleKey: 'lexus38',
      },
      {
        id: '1380',
        model: 'LX',
        year: ['2008', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4197-250x150.jpg',
        title: 'Килимки в салон Lexus LX 570 (URJ200) 2008-2012 III покоління EU Crossover дорест 5-ти місна',
        titleKey: 'lexus39',
      },
      {
        id: '1381',
        model: 'LX',
        year: ['2008', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4198-250x150.jpg',
        title: 'Килимки в салон Lexus LX 570 (URJ200) 2008-2012 III покоління EU Crossover дорест 7-ми місна',
        titleKey: 'lexus40',
      },

      {
        id: '1382',
        model: 'LX',
        year: ['2012', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4200-250x150.jpg',
        title: 'Килимки в салон Lexus LX 570 (URJ200) 2012-2022 III покоління EU Crossover рест 5-ти місна',
        titleKey: 'lexus41',
      },
      {
        id: '1383',
        model: 'LX',
        year: ['2012', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4199-250x150.jpg',
        title: 'Килимки в салон Lexus LX 570 (URJ200) 2012-2022 III покоління EU Crossover рест 7-ми місна',
        titleKey: 'lexus42',
      },
      {
        id: '1384',
        model: 'LX',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lexus/4202-250x150.jpg',
        title: 'Килимки в салон Lexus LX 570 (URJ200) 2015-2022 III покоління USA Crossover рест 7-ми місна',
        titleKey: 'lexus43',
      },
      {
        id: '1385',
        model: 'NX',
        year: ['2014', '2021'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/lexus/4204-250x150.jpg',
        title: 'Килимки в салон Lexus NX 200 (AZ10) 2014-2021 I покоління EU/USA Crossover',
        titleKey: 'lexus44',
      },
      {
        id: '1386',
        model: 'NX',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4206-250x150.jpg',
        title: 'Килимки в салон Lexus NX 200 (AZ20) 2021-… II покоління EU Crossover',
        titleKey: 'lexus45',
      },
      {
        id: '1387',
        model: 'NX',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4205-250x150.jpg',
        title: 'Килимки в салон Lexus NX 250 (AZ20) 2021-… II покоління EU Crossover',
        titleKey: 'lexus46',
      },
      {
        id: '1388',
        model: 'RC',
        year: ['2014'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/lexus/4207-250x150.jpg',
        title: 'Килимки в салон Lexus RC 2014-… I покоління USA Coupe AWD',
        titleKey: 'lexus47',
      },
      {
        id: '1389',
        model: 'RC',
        year: ['2014'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/lexus/4208-250x150.jpg',
        title: 'Килимки в салон Lexus RC 2014-… I покоління USA Coupe FWD',
        titleKey: 'lexus48',
      },
      {
        id: '1390',
        model: 'RX',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4226-250x150.jpg',
        title: 'Килимки в салон Lexus RX (AL 30) 2022-… V покоління EU Crossover',
        titleKey: 'lexus49',
      },
      {
        id: '1391',
        model: 'RX',
        year: ['2015', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4220-250x150.jpg',
        title: 'Килимки в салон Lexus RX 200t F-Sport 2015-2017 IV покоління EU Crossover',
        titleKey: 'lexus50',
      },

      {
        id: '1392',
        model: 'RX',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4217-250x150.jpg',
        title: 'Килимки в салон Lexus RX 270 (AGL 10) 2009-2015 III покоління EU Crossover',
        titleKey: 'lexus51',
      },
      {
        id: '1393',
        model: 'RX',
        year: ['1997', '2003'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4209-250x150.jpg',
        title: 'Килимки в салон Lexus RX 300 (XU10) 1997-2003 I покоління EU Crossover',
        titleKey: 'lexus52',
      },
      {
        id: '1394',
        model: 'RX',
        year: ['2003', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4210-250x150.jpg',
        title: 'Килимки в салон Lexus RX 300 (XU30) 2003-2009 II покоління EU Crossover',
        titleKey: 'lexus53',
      },
      {
        id: '1395',
        model: 'RX',
        year: ['2007', '2009'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lexus/4213-250x150.jpg',
        title: 'Килимки в салон Lexus RX 300 (XU30) 2007-2009 II покоління USA Crossover рест',
        titleKey: 'lexus54',
      },
      {
        id: '1396',
        model: 'RX',
        year: ['2003', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4212-250x150.jpg',
        title: 'Килимки в салон Lexus RX 300 H (XU30) 2003-2009 II покоління EU Crossover Hybrid',
        titleKey: 'lexus55',
      },
      {
        id: '1397',
        model: 'RX',
        year: ['2009', '2011'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lexus/4214-250x150.jpg',
        title: 'Килимки в салон Lexus RX 350 (AL 10) 2009-2011 III покоління USA Crossover дорест',
        titleKey: 'lexus56',
      },
      {
        id: '1398',
        model: 'RX',
        year: ['2009', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4215-250x150.jpg',
        title: 'Килимки в салон Lexus RX 350 (AL 10) 2009-2015 III покоління EU Crossover',
        titleKey: 'lexus57',
      },
      {
        id: '1399',
        model: 'RX',
        year: ['2011', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lexus/4218-250x150.jpg',
        title: 'Килимки в салон Lexus RX 350 (AL 10) 2011-2015 III покоління USA Crossover рест',
        titleKey: 'lexus58',
      },
      {
        id: '1400',
        model: 'RX',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4223-250x150.jpg',
        title: 'Килимки в салон Lexus RX 350 (AL 20) 2015-2022 IV покоління EU Crossover 5-ти місна',
        titleKey: 'lexus59',
      },
      {
        id: '1401',
        model: 'RX',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lexus/4222-250x150.jpg',
        title: 'Килимки в салон Lexus RX 350 (AL 20) 2015-2022 IV покоління USA Crossover 5-ти місна',
        titleKey: 'lexus60',
      },

      {
        id: '1402',
        model: 'RX',
        year: ['2017', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4224-250x150.jpg',
        title: 'Килимки в салон Lexus RX 350 L (AL 20) 2017-2019 IV покоління EU Crossover дорест 7-ми місна',
        titleKey: 'lexus61',
      },
      {
        id: '1403',
        model: 'RX',
        year: ['2019', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4225-250x150.jpg',
        title: 'Килимки в салон Lexus RX 350 L (AL 20) 2019-2022 IV покоління EU Crossover рест 7-ми місна',
        titleKey: 'lexus62',
      },
      {
        id: '1404',
        model: 'RX',
        year: ['2003', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4211-250x150.jpg',
        title: 'Килимки в салон Lexus RX 400 H (XU30) 2003-2009 II покоління EU Crossover Hybrid',
        titleKey: 'lexus63',
      },
      {
        id: '1405',
        model: 'RX',
        year: ['2009', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4216-250x150.jpg',
        title: 'Килимки в салон Lexus RX 450 H (AL 10) 2009-2012 III покоління EU Crossover дорест Hybrid',
        titleKey: 'lexus64',
      },
      {
        id: '1406',
        model: 'RX',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4219-250x150.jpg',
        title: 'Килимки в салон Lexus RX 450h (AL 20) 2015-2022 IV покоління EU Crossover Hybrid',
        titleKey: 'lexus65',
      },
      {
        id: '1407',
        model: 'RX',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lexus/4221-250x150.jpg',
        title: 'Килимки в салон Lexus RX 450hL (AL 20) 2015-2022 IV покоління USA Crossover Hybrid/Long',
        titleKey: 'lexus66',
      },
      {
        id: '1408',
        model: 'UX',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/4227-250x150.jpg',
        title: 'Килимки в салон Lexus UX 250 H 2018-… I покоління EU Crossover Hybrid',
        titleKey: 'lexus67',
      },
      {
        id: '1409',
        model: 'UX',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lexus/6004-250x150.jpg',
        title: 'Килимки в салон Lexus UX 300e 2019-… I покоління EU Crossover Electro',
        titleKey: 'lexus68',
      },
      {
        id: '1410',
        model: 'CT',
        year: ['2010', '2018'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/lexus/4228-250x150.jpg',
        title: 'Килимки в салон Lexus СT 200h 2010-2018 I покоління USA Hatchback Hybrid',
        titleKey: 'lexus69',
      },
    ],
  },
  /* ---------------------------------- Lifan --------------------------------- */
  Lifan: {
    logo: '/images/catalog/logo/lifan-100x100.png',
    name: 'Lifan',
    models: [
      {
        id: '1411',
        model: '320',
        year: ['2008', '2023'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/lifan/5074-250x150.jpg',
        title: 'Килимки в салон Lifan 320/Smily 2008-… I покоління EU Hatchback',
        titleKey: 'lifan1',
      },
      {
        id: '1412',
        model: '520',
        year: ['2006', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lifan/5075-250x150.jpg',
        title: 'Килимки в салон Lifan 520 2006-2012 I покоління EU Sedan',
        titleKey: 'lifan2',
      },
      {
        id: '1413',
        model: '620',
        year: ['2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/lifan/5076-250x150.jpg',
        title: 'Килимки в салон Lifan 620 2007-… I покоління EU Sedan',
        titleKey: 'lifan3',
      },
      {
        id: '1414',
        model: 'X60',
        year: ['2011'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/lifan/5077-250x150.jpg',
        title: 'Килимки в салон Lifan X60 2011-… I покоління EU Crossover',
        titleKey: 'lifan4',
      },
    ],
  },
  /* --------------------------------- Lincoln -------------------------------- */
  Lincoln: {
    logo: '/images/catalog/logo/lincoln-100x100.png',
    name: 'Lincoln',
    models: [
      {
        id: '1415',
        model: 'Continental',
        year: ['2016', '2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4146-250x150.jpg',
        title: 'Килимки в салон Lincoln Continental (D544) 2016-2020 X покоління USA Sedan',
        titleKey: 'lincoln1',
      },
      {
        id: '1416',
        model: 'Continental',
        year: ['1988', '1994'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4145-250x150.jpg',
        title: 'Килимки в салон Lincoln Continental 1988-1994 VIII покоління USA Sedan',
        titleKey: 'lincoln2',
      },
      {
        id: '1417',
        model: 'MKC',
        year: ['2014', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4147-250x150.jpg',
        title: 'Килимки в салон Lincoln MKC 2014-2019 I покоління USA Crossover',
        titleKey: 'lincoln3',
      },
      {
        id: '1418',
        model: 'MKT',
        year: ['2012', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4148-250x150.jpg',
        title: 'Килимки в салон Lincoln MKT 2012-2019 I покоління USA Crossover 6-ти місна',
        titleKey: 'lincoln4',
      },
      {
        id: '1419',
        model: 'MKX',
        year: ['2007', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4149-250x150.jpg',
        title: 'Килимки в салон Lincoln MKX 2007-2015 I покоління USA Crossover',
        titleKey: 'lincoln5',
      },
      {
        id: '1420',
        model: 'MKX',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4150-250x150.jpg',
        title: 'Килимки в салон Lincoln MKX 2016-… II покоління USA Crossover',
        titleKey: 'lincoln6',
      },
      {
        id: '1421',
        model: 'MKZ',
        year: ['2006', '2012'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4151-250x150.jpg',
        title: 'Килимки в салон Lincoln MKZ 2006-2012 I покоління USA Sedan',
        titleKey: 'lincoln7',
      },
      {
        id: '1422',
        model: 'MKZ',
        year: ['2012', '2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4152-250x150.jpg',
        title: 'Килимки в салон Lincoln MKZ 2012-2015 II покоління USA Sedan дорест',
        titleKey: 'lincoln8',
      },
      {
        id: '1423',
        model: 'MKZ',
        year: ['2015', '2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4153-250x150.jpg',
        title: 'Килимки в салон Lincoln MKZ 2015-2020 II покоління USA Sedan рест',
        titleKey: 'lincoln9',
      },
      {
        id: '1424',
        model: 'Nautilus',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4155-250x150.jpg',
        title: 'Килимки в салон Lincoln Nautilus 2018-… I покоління USA Crossover',
        titleKey: 'lincoln10',
      },

      {
        id: '1425',
        model: 'Nautilus',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4154-250x150.jpg',
        title: 'Килимки в салон Lincoln Nautilus 2018-… I покоління USA Crossover',
        titleKey: 'lincoln11',
      },
      {
        id: '1426',
        model: 'Nautilus',
        year: ['2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4156-250x150.jpg',
        title: 'Килимки в салон Lincoln Nautilus 2018-… I покоління USA Crossover',
        titleKey: 'lincoln12',
      },
      {
        id: '1427',
        model: 'Navigator',
        year: ['2003', '2006'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4158-250x150.jpg',
        title: 'Килимки в салон Lincoln Navigator (U228) 2003-2006 II покоління USA Crossover 7-ми місна',
        titleKey: 'lincoln13',
      },
      {
        id: '1428',
        model: 'Navigator',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4160-250x150.jpg',
        title: 'Килимки в салон Lincoln Navigator (U554) 2018-… IV покоління USA Crossover 7-ми місна',
        titleKey: 'lincoln14',
      },
      {
        id: '1429',
        model: 'Navigator',
        year: ['1997', '2002'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4157-250x150.jpg',
        title: 'Килимки в салон Lincoln Navigator 1997-2002 I покоління USA Crossover 7-ми місна',
        titleKey: 'lincoln15',
      },
      {
        id: '1430',
        model: 'Navigator L',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/lincoln/4159-250x150.jpg',
        title: 'Килимки в салон Lincoln Navigator L (U554) 2018-… IV покоління USA Crossover 7-ми місна Long',
        titleKey: 'lincoln16',
      },
      {
        id: '1431',
        model: 'Town Car',
        year: ['1998', '2011'],
        body: 'Limousine',
        market: 'EU',
        img: '/images/catalog/models/lincoln/4161-250x150.jpg',
        title: 'Килимки в салон Lincoln Town Car 1998-2011 II покоління EU Limousine',
        titleKey: 'lincoln17',
      },
    ],
  },
  /* --------------------------------- Maserati -------------------------------- */
  Maserati: {
    logo: '/images/catalog/logo/maserati-100x100.png',
    name: 'Maserati',
    models: [
      {
        id: '1432',
        model: 'Ghibli',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/maserati/6005-250x150.jpg',
        title: 'Килимки в салон Maserati Ghibli 2013-... III покоління EU Sedan',
        titleKey: 'maserati1',
      },
      {
        id: '1433',
        model: 'Levante',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/maserati/4143-250x150.jpg',
        title: 'Килимки в салон Maserati Levante 2016-... I покоління EU Crossover',
        titleKey: 'maserati2',
      },
      {
        id: '1434',
        model: 'Quattroporte',
        year: ['2013'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/maserati/4144-250x150.jpg',
        title: 'Килимки в салон Maserati Quattroporte 2013-... VI покоління USA Sedan',
        titleKey: 'maserati3',
      },
    ],
  },
  /* ---------------------------------- Maxus --------------------------------- */
  Maxus: {
    logo: '/images/catalog/logo/Maxus-100x100.png',
    name: 'Maxus',
    models: [
      {
        id: '1435',
        model: 'EV30',
        year: ['2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/maxus/5073-250x150.jpg',
        title: 'Килимки в салон Maxus EV30 2018-… I покоління EU Minivan',
        titleKey: 'maxus',
      },
    ],
  },
  /* ---------------------------------- Mazda --------------------------------- */
  Mazda: {
    logo: '/images/catalog/logo/mazda-100x100.png',
    name: 'Mazda',
    models: [
      {
        id: '1436',
        model: '2',
        year: ['2007', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4076-250x150.jpg',
        title: 'Килимки в салон Mazda 2 (DE) 2007 - 2014 II покоління EU Hatchback',
        titleKey: 'mazda1',
      },
      {
        id: '1437',
        model: '2',
        year: ['2007', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4075-250x150.jpg',
        title: 'Килимки в салон Mazda 2 (DE) 2007 - 2014 II покоління EU Sedan',
        titleKey: 'mazda2',
      },
      {
        id: '1438',
        model: '2',
        year: ['2002', '2007'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mazda/858-250x150.jpg',
        title: 'Килимки в салон Mazda 2 (DY) 2002 - 2007 I покоління EU Hatchback',
        titleKey: 'mazda3',
      },
      {
        id: '1439',
        model: '3',
        year: ['2003', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4078-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BK) 2003 - 2009 I покоління EU Hatchback',
        titleKey: 'mazda4',
      },
      {
        id: '1440',
        model: '3',
        year: ['2003', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4077-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BK) 2003 - 2009 I покоління EU Sedan',
        titleKey: 'mazda5',
      },
      {
        id: '1441',
        model: '3',
        year: ['2003', '2009'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mazda/4079-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BK) 2003 - 2009 I покоління EU Sedan',
        titleKey: 'mazda6',
      },
      {
        id: '1442',
        model: '3',
        year: ['2009', '2013'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4081-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BL) 2009 - 2013 II покоління EU/USA Hatchback',
        titleKey: 'mazda7',
      },
      {
        id: '1443',
        model: '3',
        year: ['2009', '2013'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4080-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BL) 2009 - 2013 II покоління EU/USA Sedan',
        titleKey: 'mazda8',
      },
      {
        id: '1444',
        model: '3',
        year: ['2009', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4084-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BM) 2013 - 2019 III покоління EU Hatchback',
        titleKey: 'mazda9',
      },
      {
        id: '1445',
        model: '3',
        year: ['2009', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4083-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BM) 2013 - 2019 III покоління EU Sedan',
        titleKey: 'mazda10',
      },

      {
        id: '1446',
        model: '3',
        year: ['2009', '2019'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/mazda/4085-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BM) 2013 - 2019 III покоління USA Hatchback',
        titleKey: 'mazda11',
      },
      {
        id: '1447',
        model: '3',
        year: ['2013', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mazda/4082-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BM) 2013 - 2019 III покоління USA Sedan',
        titleKey: 'mazda12',
      },
      {
        id: '1448',
        model: '3',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4086-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BP) 2019 - … IV покоління EU/USA Hatchback',
        titleKey: 'mazda13',
      },
      {
        id: '1449',
        model: '3',
        year: ['2019'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4087-250x150.jpg',
        title: 'Килимки в салон Mazda 3 (BP) 2019 - … IV покоління EU/USA Sedan',
        titleKey: 'mazda14',
      },
      {
        id: '1450',
        model: '323',
        year: ['1985', '1989'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4103-250x150.jpg',
        title: 'Килимки в салон Mazda 323 (BF) 1985 - 1989 III покоління EU Sedan 5-ти дверна',
        titleKey: 'mazda15',
      },
      {
        id: '1451',
        model: '323',
        year: ['1989', '1994'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4104-250x150.jpg',
        title: 'Килимки в салон Mazda 323 BG 1989 - 1994 IV покоління EU Sedan',
        titleKey: 'mazda16',
      },
      {
        id: '1452',
        model: '323',
        year: ['1994', '2000'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mazda/4105-250x150.jpg',
        title: 'Килимки в салон Mazda 323 C (BH/BA) 1994 - 2000 V покоління EU Coupe',
        titleKey: 'mazda17',
      },
      {
        id: '1453',
        model: '323',
        year: ['1994', '2000'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4106-250x150.jpg',
        title: 'Килимки в салон Mazda 323 F (BH/BA) 1994 - 2000 V покоління EU Hatchback 5-ти дверна',
        titleKey: 'mazda18',
      },
      {
        id: '1454',
        model: '323',
        year: ['1998', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4109-250x150.jpg',
        title: 'Килимки в салон Mazda 323 F (BJ) 1998 - 2003 VI покоління EU Hatchback',
        titleKey: 'mazda19',
      },
      {
        id: '1455',
        model: '323',
        year: ['1994', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4107-250x150.jpg',
        title: 'Килимки в салон Mazda 323 S (BH/BA) 1994 - 2000 V покоління EU Sedan',
        titleKey: 'mazda20',
      },

      {
        id: '1456',
        model: '323',
        year: ['1998', '2003'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4108-250x150.jpg',
        title: 'Килимки в салон Mazda 323 S (BJ) 1998 - 2003 VI покоління EU Sedan',
        titleKey: 'mazda21',
      },
      {
        id: '1457',
        model: '5',
        year: ['2005', '2009'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4088-250x150.jpg',
        title: 'Килимки в салон Mazda 5 (CR) 2005 - 2009 II покоління EU Minivan 7-ми місна',
        titleKey: 'mazda22',
      },
      {
        id: '1458',
        model: '5',
        year: ['2010', '2018'],
        body: 'Minivan',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4089-250x150.jpg',
        title: 'Килимки в салон Mazda 5 (CW) 2010 - 2018 III покоління EU/USA Minivan 7-ми місна',
        titleKey: 'mazda23',
      },
      {
        id: '1459',
        model: '6',
        year: ['2002', '2008'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4096-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GG) 2002 - 2008 I покоління EU Liftback',
        titleKey: 'mazda24',
      },
      {
        id: '1460',
        model: '6',
        year: ['2002', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4090-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GG) 2002 - 2008 I покоління EU Sedan',
        titleKey: 'mazda25',
      },
      {
        id: '1461',
        model: '6',
        year: ['2002', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mazda/4093-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GG) 2002 - 2008 I покоління EU Universal',
        titleKey: 'mazda26',
      },
      {
        id: '1462',
        model: '6',
        year: ['2002', '2008'],
        body: 'Liftback',
        market: 'USA',
        img: '/images/catalog/models/mazda/4095-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GG) 2002 - 2008 I покоління USA Liftback',
        titleKey: 'mazda27',
      },
      {
        id: '1463',
        model: '6',
        year: ['2002', '2008'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mazda/4091-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GG) 2002 - 2008 I покоління USA Sedan',
        titleKey: 'mazda28',
      },
      {
        id: '1464',
        model: '6',
        year: ['2002', '2008'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/mazda/4094-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GG) 2002 - 2008 I покоління USA Universal',
        titleKey: 'mazda29',
      },
      {
        id: '1465',
        model: '6',
        year: ['2008', '2012'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mazda/4097-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GH) 2008 - 2012 II покоління EU Sedan',
        titleKey: 'mazda30',
      },

      {
        id: '1466',
        model: '6',
        year: ['2008', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mazda/4099-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GH) 2008 - 2012 II покоління EU Universal',
        titleKey: 'mazda31',
      },
      {
        id: '1467',
        model: '6',
        year: ['2008', '2012'],
        body: 'Liftback',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4100-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GH) 2008 - 2012 II покоління EU/USA Liftback',
        titleKey: 'mazda32',
      },
      {
        id: '1468',
        model: '6',
        year: ['2008', '2012'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mazda/4098-250x150.jpg',
        title: 'Килимки в салон Mazda 6 (GH) 2008 - 2012 II покоління USA Sedan',
        titleKey: 'mazda33',
      },
      {
        id: '1469',
        model: '6',
        year: ['2002', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4092-250x150.jpg',
        title: 'Килимки в салон Mazda 6 GG (MPS) 2002 - 2008 I покоління EU Sedan AWD',
        titleKey: 'mazda34',
      },
      {
        id: '1470',
        model: '6',
        year: ['2012'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4101-250x150.jpg',
        title: 'Килимки в салон Mazda 6 GJ 2012 - … III покоління EU/USA Sedan',
        titleKey: 'mazda35',
      },
      {
        id: '1471',
        model: '6',
        year: ['2012'],
        body: 'Universal',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4102-250x150.jpg',
        title: 'Килимки в салон Mazda 6 GJ 2012 - … III покоління EU/USA Universal',
        titleKey: 'mazda36',
      },
      {
        id: '1472',
        model: '626',
        year: ['1983', '1987'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mazda/4111-250x150.jpg',
        title: 'Килимки в салон Mazda 626 (GC) 1983 - 1987 II покоління EU Coupe',
        titleKey: 'mazda37',
      },
      {
        id: '1473',
        model: '626',
        year: ['1983', '1987'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4110-250x150.jpg',
        title: 'Килимки в салон Mazda 626 (GC) 1983 - 1987 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'mazda38',
      },
      {
        id: '1474',
        model: '626',
        year: ['1987', '1991'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4112-250x150.jpg',
        title: 'Килимки в салон Mazda 626 (GD/GV) 1987 - 1991 III покоління EU Sedan',
        titleKey: 'mazda39',
      },
      {
        id: '1475',
        model: '626',
        year: ['1991', '1997'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4113-250x150.jpg',
        title: 'Килимки в салон Mazda 626 (GE) 1991 - 1997 IV покоління EU Liftback',
        titleKey: 'mazda40',
      },

      {
        id: '1476',
        model: '626',
        year: ['1991', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4114-250x150.jpg',
        title: 'Килимки в салон Mazda 626 (GE) 1991 - 1997 IV покоління EU Sedan',
        titleKey: 'mazda41',
      },
      {
        id: '1477',
        model: '626',
        year: ['1997', '2002'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/mazda/4117-250x150.jpg',
        title: 'Килимки в салон Mazda 626 GF/GW 1997 - 2002 V покоління EU Liftback',
        titleKey: 'mazda42',
      },
      {
        id: '1478',
        model: '626',
        year: ['1997', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4115-250x150.jpg',
        title: 'Килимки в салон Mazda 626 GF/GW 1997 - 2002 V покоління EU Sedan',
        titleKey: 'mazda43',
      },
      {
        id: '1479',
        model: '626',
        year: ['1997', '2002'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mazda/4116-250x150.jpg',
        title: 'Килимки в салон Mazda 626 GF/GW 1997 - 2002 V покоління EU Universal',
        titleKey: 'mazda44',
      },
      {
        id: '1480',
        model: 'B-Series',
        year: ['1998', '2006'],
        body: 'Pickup',
        market: 'Japan',
        img: '/images/catalog/models/mazda/4118-250x150.jpg',
        title: 'Килимки в салон Mazda B-Series B2500 1998 - 2006 V покоління Japan Pickup 4-х дверна AWD/праве кермо',
        titleKey: 'mazda45',
      },
      {
        id: '1481',
        model: 'CX-3',
        year: ['2015'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4119-250x150.jpg',
        title: 'Килимки в салон Mazda CX-3 (DK) 2015 - … I покоління EU/USA Crossover',
        titleKey: 'mazda46',
      },
      {
        id: '1482',
        model: 'CX-3',
        year: ['2015'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4120-250x150.jpg',
        title: 'Килимки в салон Mazda CX-3 (DK) 2015 - … I покоління EU/USA Crossover AWD',
        titleKey: 'mazda47',
      },
      {
        id: '1483',
        model: 'CX-30',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4121-250x150.jpg',
        title: 'Килимки в салон Mazda CX-30 2019 - … I покоління EU/USA Crossover',
        titleKey: 'mazda48',
      },
      {
        id: '1484',
        model: 'CX-5',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4122-250x150.jpg',
        title: 'Килимки в салон Mazda CX-5 (KE) 2012 - 2017 I покоління EU Crossover',
        titleKey: 'mazda49',
      },
      {
        id: '1485',
        model: 'CX-5',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mazda/4123-250x150.jpg',
        title: 'Килимки в салон Mazda CX-5 (KE) 2012 - 2017 I покоління USA Crossover',
        titleKey: 'mazda50',
      },

      {
        id: '1486',
        model: 'CX-5',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4124-250x150.jpg',
        title: 'Килимки в салон Mazda CX-5 (KF) 2017 - … II покоління EU Crossover',
        titleKey: 'mazda51',
      },
      {
        id: '1487',
        model: 'CX-5',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mazda/4125-250x150.jpg',
        title: 'Килимки в салон Mazda CX-5 (KF) 2017 - … II покоління USA Crossover',
        titleKey: 'mazda52',
      },
      {
        id: '1488',
        model: 'CX-60',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4126-250x150.jpg',
        title: 'Килимки в салон Mazda CX-60 Skyactiv 2022 - … I покоління EU Crossover AWD',
        titleKey: 'mazda53',
      },
      {
        id: '1489',
        model: 'CX-7',
        year: ['2006', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4127-250x150.jpg',
        title: 'Килимки в салон Mazda CX-7 2006 - 2012 I покоління EU Crossover',
        titleKey: 'mazda54',
      },
      {
        id: '1490',
        model: 'CX-9',
        year: ['2007', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4128-250x150.jpg',
        title: 'Килимки в салон Mazda CX-9 (TB) 2007 - 2016 I покоління EU Crossover 7-ми місна',
        titleKey: 'mazda55',
      },
      {
        id: '1491',
        model: 'CX-9',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4129-250x150.jpg',
        title: 'Килимки в салон Mazda CX-9 (TC) 2016 - … II покоління EU Crossover 7-ми місна',
        titleKey: 'mazda56',
      },
      {
        id: '1492',
        model: 'CX-9',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/mazda/4131-250x150.jpg',
        title: 'Килимки в салон Mazda CX-9 (TC) 2016 - … II покоління EU/USA Crossover 6-ти місна',
        titleKey: 'mazda57',
      },
      {
        id: '1493',
        model: 'CX-9',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mazda/4130-250x150.jpg',
        title: 'Килимки в салон Mazda CX-9 (TC) 2016 - … II покоління USA Crossover 7-ми місна',
        titleKey: 'mazda58',
      },
      {
        id: '1494',
        model: 'CX-90',
        year: ['2023'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Mazda CX-90 2023 - … I покоління USA Crossover 6-ми місна',
        titleKey: 'mazda59',
      },
      {
        id: '1495',
        model: 'CX-90',
        year: ['2023'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Mazda CX-90 2023 - … I покоління USA Crossover 7-ми місна',
        titleKey: 'mazda60',
      },

      {
        id: '1496',
        model: 'Demio',
        year: ['1996', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4132-250x150.jpg',
        title: 'Килимки в салон Mazda Demio 1996 - 2002 I покоління EU Crossover',
        titleKey: 'mazda61',
      },
      {
        id: '1497',
        model: 'MPV',
        year: ['1999', '2006'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4133-250x150.jpg',
        title: 'Килимки в салон Mazda MPV 1999 - 2006 II покоління EU Minivan 6-ти місна',
        titleKey: 'mazda62',
      },
      {
        id: '1498',
        model: 'MX-30',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4134-250x150.jpg',
        title: 'Килимки в салон Mazda MX-30 EV 2020 - … I покоління EU Crossover Electric',
        titleKey: 'mazda63',
      },
      {
        id: '1499',
        model: 'MX-30',
        year: ['2020'],
        body: 'Crossover',
        market: 'Japan',
        img: '/images/catalog/models/mazda/4135-250x150.jpg',
        title: 'Килимки в салон Mazda MX-30 EV 2020 - … I покоління Japan Crossover Electric',
        titleKey: 'mazda64',
      },
      {
        id: '1500',
        model: 'MX-5',
        year: ['1997', '2005'],
        body: 'Roadster',
        market: 'EU',
        img: '/images/catalog/models/mazda/4136-250x150.jpg',
        title: 'Килимки в салон Mazda MX-5 (NB) 1997 - 2005 II покоління EU Roadster 2-х дверна',
        titleKey: 'mazda65',
      },
      {
        id: '1501',
        model: 'MX-5',
        year: ['2014'],
        body: 'Roadster',
        market: 'USA',
        img: '/images/catalog/models/mazda/4137-250x150.jpg',
        title: 'Килимки в салон Mazda MX-5 (ND) 2014 - … IV покоління USA Roadster',
        titleKey: 'mazda66',
      },
      {
        id: '1502',
        model: 'Premacy',
        year: ['1999', '2005'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4138-250x150.jpg',
        title: 'Килимки в салон Mazda Premacy (CP) 1999 - 2005 I покоління EU Minivan',
        titleKey: 'mazda67',
      },
      {
        id: '1503',
        model: 'RX-8',
        year: ['2003', '2012'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mazda/4139-250x150.jpg',
        title: 'Килимки в салон Mazda RX-8 2003 - 2012 I покоління EU Coupe',
        titleKey: 'mazda68',
      },
      {
        id: '1504',
        model: 'Tribute',
        year: ['2000', '2011'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mazda/4140-250x150.jpg',
        title: 'Килимки в салон Mazda Tribute 2000 - 2011 I покоління EU Crossover',
        titleKey: 'mazda69',
      },
      {
        id: '1505',
        model: 'Xedos',
        year: ['1992', '1999'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4141-250x150.jpg',
        title: 'Килимки в салон Mazda Xedos 6 1992 - 1999 I покоління EU Sedan',
        titleKey: 'mazda70',
      },

      {
        id: '1506',
        model: 'Xedos',
        year: ['1993', '2003'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mazda/4142-250x150.jpg',
        title: 'Килимки в салон Mazda Xedos 9 1993 - 2003 I покоління EU Sedan',
        titleKey: 'mazda71',
      },
    ],
  },
  /* ------------------------------ MercedesBenz ------------------------------ */
  Mercedesbenz: {
    logo: '/images/catalog/logo/mercedes-benz-100x100.png',
    name: 'Mercedes Benz',
    models: [
      {
        id: '1507',
        model: 'V-Class',
        year: ['2003', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4067-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Viano (W639) V-Class 2003 - 2014 EU VAN',
        titleKey: 'mercedesBenz1',
      },
      {
        id: '1508',
        model: 'V-Class',
        year: ['1996', '2003'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4065-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Vito (W638) V-Class 1996 - 2003 EU VAN',
        titleKey: 'mercedesBenz2',
      },
      {
        id: '1509',
        model: 'CLS-Class',
        year: ['2010', '2018'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3985-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz CLS-Class 2010 - 2018 EU Coupe 4-х дверна AWD',
        titleKey: 'mercedesBenz3',
      },
      {
        id: '1510',
        model: 'CLS-Class',
        year: ['2010', '2018'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/mercedes/3986-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz CLS-Class 2010 - 2018 USA Coupe',
        titleKey: 'mercedesBenz4',
      },
      {
        id: '1511',
        model: 'CLA-Class',
        year: ['2013', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mercedes/3977-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz CLA-Class 2013 - 2019 USA Sedan AWD',
        titleKey: 'mercedesBenz5',
      },
      {
        id: '1512',
        model: 'CLA-Class',
        year: ['2013', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3979-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz CLA-Class 2013 - 2019 EU Sedan',
        titleKey: 'mercedesBenz6',
      },
      {
        id: '1513',
        model: 'CLA-Class',
        year: ['2013', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3978-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz C117 CLA-Class 2013 - 2019 I покоління EU Sedan',
        titleKey: 'mercedesBenz7',
      },
      {
        id: '1514',
        model: 'GLE-Class',
        year: ['2019', '2023'],
        body: 'Coupe',
        market: 'Европа',
        img: '/images/catalog/models/mercedes/4025-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz C167 GLE Coupe 2019 - … II покоління Европа Coupe',
        titleKey: 'mercedesBenz8',
      },
      {
        id: '1515',
        model: 'CL-Class',
        year: ['1999', '2006'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/mercedes/3980-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz C215 CL-Class 1999 - 2006 II покоління USA Coupe',
        titleKey: 'mercedesBenz9',
      },
      {
        id: '1516',
        model: 'CL-Class',
        year: ['2006', '2013'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/mercedes/3981-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz C216 CL-Class 2006 - 2013 III покоління USA Coupe',
        titleKey: 'mercedesBenz10',
      },

      {
        id: '1517',
        model: 'GLC-Class',
        year: ['2015', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4014-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz C253 GLC-Class Coupe 2015 - 2023 I покоління EU Crossover',
        titleKey: 'mercedesBenz11',
      },
      {
        id: '1518',
        model: 'GLC-Class',
        year: ['2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/6006-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz C254 GLC-Class Coupe 2023-... II покоління EU Crossover',
        titleKey: 'mercedesBenz12',
      },
      {
        id: '1519',
        model: 'GLE-Class',
        year: ['2015', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4022-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz C292 GLE-Class Coupe 2015 - 2019 I покоління EU Crossover',
        titleKey: 'mercedesBenz13',
      },
      {
        id: '1520',
        model: 'EQA-Class',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4004-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz EQA-Class (H243) 2021 - … I покоління EU Crossover',
        titleKey: 'mercedesBenz14',
      },
      {
        id: '1521',
        model: 'EQC-Class',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4005-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz EQC-Class (N293) 2018 - … I покоління EU Crossover',
        titleKey: 'mercedesBenz15',
      },
      {
        id: '1522',
        model: 'EQE-Class',
        year: ['2022'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4006-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz EQE-Class (V295) 2022 - … I покоління EU Sedan',
        titleKey: 'mercedesBenz16',
      },
      {
        id: '1523',
        model: 'EQE-Class',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Mercedes-Benz EQE-Class 2022 - … I покоління EU Crossover',
        titleKey: 'mercedesBenz17',
      },
      {
        id: '1524',
        model: 'EQS-Class',
        year: ['2021'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/6007-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz EQS-Class (V297) 2021 - … I покоління EU Liftback',
        titleKey: 'mercedesBenz18',
      },
      {
        id: '1525',
        model: 'GLS-Class',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4028-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Maybach GLS 600 (X167) 2019 - … III покоління EU Crossover',
        titleKey: 'mercedesBenz19',
      },
      {
        id: '1526',
        model: 'SL-Class',
        year: ['2001', '2011'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4051-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz R107 SL-Class 2001 - 2011 IV покоління EU Coupe',
        titleKey: 'mercedesBenz20',
      },

      {
        id: '1527',
        model: 'SLK-Class',
        year: ['1996', '2004'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4055-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz R170 SLK-Class 1996 - 2004 I покоління EU Cabriolet',
        titleKey: 'mercedesBenz21',
      },
      {
        id: '1528',
        model: 'SLK-Class',
        year: ['1996', '2004'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4054-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz R170 SLK-Class 1996 - 2004 I покоління EU Coupe',
        titleKey: 'mercedesBenz22',
      },
      {
        id: '1529',
        model: 'SLK-Class',
        year: ['2004', '2011'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4056-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz R171 SLK-Class 2004 - 2011 II покоління EU Coupe',
        titleKey: 'mercedesBenz23',
      },
      {
        id: '1530',
        model: 'SLK-Class',
        year: ['2011', '2016'],
        body: 'Roadster',
        market: 'USA',
        img: '/images/catalog/models/mercedes/4057-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz R172 SLK-Class 2011 - 2016 III покоління USA Roadster',
        titleKey: 'mercedesBenz24',
      },
      {
        id: '1531',
        model: 'SL-Class',
        year: ['2001', '2011'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4052-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz R230 SL 500 2001 - 2011 VI покоління EU Coupe',
        titleKey: 'mercedesBenz25',
      },
      {
        id: '1532',
        model: 'SL-Class',
        year: ['2016', '2020'],
        body: 'Cabriolet',
        market: 'USA',
        img: '/images/catalog/models/mercedes/4053-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz R231 SL-Class 2016 - 2020 VII покоління USA Cabriolet рест',
        titleKey: 'mercedesBenz26',
      },
      {
        id: '1533',
        model: 'Sprinter',
        year: ['1995', '2006'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4058-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Sprinter (W901-905) 1995 - 2006 I покоління EU VAN',
        titleKey: 'mercedesBenz27',
      },
      {
        id: '1534',
        model: 'Sprinter',
        year: ['2006', '2018'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4059-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Sprinter (W906) 2006 - 2018 II покоління EU VAN',
        titleKey: 'mercedesBenz28',
      },
      {
        id: '1535',
        model: 'Sprinter',
        year: ['2018'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4060-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Sprinter (W907/910) 2018 - … III покоління EU VAN',
        titleKey: 'mercedesBenz29',
      },
      {
        id: '1536',
        model: 'T2',
        year: ['1986', '1996'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Mercedes-Benz T2 (609D) 1986 - 1996 II покоління EU VAN',
        titleKey: 'mercedesBenz30',
      },

      {
        id: '1537',
        model: 'TN-Class',
        year: ['1977', '1995'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4061-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz TN-Class (T1) 1977 - 1995 I покоління EU VAN',
        titleKey: 'mercedesBenz31',
      },
      {
        id: '1538',
        model: 'Tourismo',
        year: ['2006'],
        body: 'BUS',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4062-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Tourismo 2006 - … II покоління EU BUS',
        titleKey: 'mercedesBenz32',
      },
      {
        id: '1539',
        model: 'A-Class',
        year: ['1997', '2004'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3947-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz V168/A160 A-Class 1997 - 2004 I покоління EU Hatchback Long',
        titleKey: 'mercedesBenz33',
      },
      {
        id: '1540',
        model: 'A-Class',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3954-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz V177 A-Class 2018 - … IV покоління EU Sedan Long',
        titleKey: 'mercedesBenz34',
      },
      {
        id: '1541',
        model: 'Vaneo',
        year: ['2001', '2005'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4063-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Vaneo (W414 ) 2001 - 2005 I покоління EU Minivan',
        titleKey: 'mercedesBenz35',
      },
      {
        id: '1542',
        model: 'Vario',
        year: ['1996', '2013'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4064-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Vario T2W 1996 - 2013 I покоління EU VAN грузовий',
        titleKey: 'mercedesBenz36',
      },
      {
        id: '1543',
        model: 'V-Class',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4071-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Viano (W447) V-Class 2014 - … III покоління EU VAN',
        titleKey: 'mercedesBenz37',
      },
      {
        id: '1544',
        model: 'V-Class',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4068-250x150.jpg',
        title:
          'Килимки в салон Mercedes-Benz Viano Long (W447) V-Class 2014 - … III покоління EU VAN 6-ти місна зі столиком',
        titleKey: 'mercedesBenz38',
      },
      {
        id: '1545',
        model: 'V-Class',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4069-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Vito (114) (W447) V-Class 2014 - … III покоління EU VAN',
        titleKey: 'mercedesBenz39',
      },
      {
        id: '1546',
        model: 'V-Class',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4070-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Vito (W447) V-Class 2014 - … III покоління EU VAN',
        titleKey: 'mercedesBenz40',
      },

      {
        id: '1547',
        model: 'V-Class',
        year: ['2003', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4066-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz Vito (W639) V-Class 2003 - 2014 II покоління EU VAN',
        titleKey: 'mercedesBenz41',
      },
      {
        id: '1548',
        model: 'E-Class',
        year: ['1975', '1986'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3987-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W123 E-Class 1975 - 1986 I покоління EU Sedan',
        titleKey: 'mercedesBenz42',
      },
      {
        id: '1549',
        model: 'E-Class',
        year: ['1984', '1997'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3990-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W124 (C124) E-Class 1984 - 1997 I покоління EU Coupe',
        titleKey: 'mercedesBenz43',
      },
      {
        id: '1550',
        model: 'E-Class',
        year: ['1984', '1997'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3989-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W124 (S124) E-Class 1984 - 1997 I покоління EU Universal',
        titleKey: 'mercedesBenz44',
      },
      {
        id: '1551',
        model: 'E-Class',
        year: ['1984', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3988-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W124 E-Class 1984 - 1997 I покоління EU Sedan',
        titleKey: 'mercedesBenz45',
      },
      {
        id: '1552',
        model: 'S-Class',
        year: ['1979', '1991'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4038-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W126 S-Class 1979 - 1991 II покоління EU Sedan Short',
        titleKey: 'mercedesBenz46',
      },
      {
        id: '1553',
        model: 'S-Class',
        year: ['1991', '1998'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4041-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W140 (C140) S-Class 1991 - 1998 III покоління EU Coupe',
        titleKey: 'mercedesBenz47',
      },
      {
        id: '1554',
        model: 'S-Class',
        year: ['1991', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4039-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W140 (V140) S-Class 1991 - 1998 III покоління EU Sedan Long',
        titleKey: 'mercedesBenz48',
      },
      {
        id: '1555',
        model: 'S-Class',
        year: ['1991', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4040-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W140 S-Class 1991 - 1998 III покоління EU Sedan Short',
        titleKey: 'mercedesBenz49',
      },
      {
        id: '1556',
        model: 'ML-Class',
        year: ['1997', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4030-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W163 ML-Class 1997 - 2005 I покоління EU Crossover',
        titleKey: 'mercedesBenz50',
      },

      {
        id: '1557',
        model: 'ML-Class',
        year: ['2005', '2011'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4032-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W164 ML-Class 2005 - 2011 II покоління EU Crossover',
        titleKey: 'mercedesBenz51',
      },
      {
        id: '1558',
        model: 'ML-Class',
        year: ['2005', '2011'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mercedes/4031-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W164 ML-Class 2005 - 2011 II покоління USA Crossover',
        titleKey: 'mercedesBenz52',
      },
      {
        id: '1559',
        model: 'GLE-Class',
        year: ['2015', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4023-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W166 GLE-Class 2015 - 2018 I покоління EU Crossover',
        titleKey: 'mercedesBenz53',
      },
      {
        id: '1560',
        model: 'GLE-Class',
        year: ['2015', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4021-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W166 GLE-Class 2015 - 2018 I покоління EU Crossover AWD',
        titleKey: 'mercedesBenz54',
      },
      {
        id: '1561',
        model: 'ML-Class',
        year: ['2011', '2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/mercedes/4033-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W166 ML-Class 2011 - 2018 III покоління EU/USA Crossover',
        titleKey: 'mercedesBenz55',
      },
      {
        id: '1562',
        model: 'GLE-Class',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4024-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W167 GLE-Class 2018 - … II покоління EU Crossover',
        titleKey: 'mercedesBenz56',
      },
      {
        id: '1563',
        model: 'A-Class',
        year: ['1997', '2004'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3948-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W168/A160 A-Class 1997 - 2004 I покоління EU Hatchback',
        titleKey: 'mercedesBenz57',
      },
      {
        id: '1564',
        model: 'A-Class',
        year: ['1997', '2004'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3949-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W168/A190 A-Class 1997 - 2004 I покоління EU Hatchback',
        titleKey: 'mercedesBenz58',
      },
      {
        id: '1565',
        model: 'A-Class',
        year: ['2004', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3950-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W169 A-Class 2004 - 2008 II покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'mercedesBenz59',
      },
      {
        id: '1566',
        model: 'A-Class',
        year: ['2008', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3951-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W169 A-Class 2008 - 2012 II покоління EU Hatchback рест 5-ти дверна',
        titleKey: 'mercedesBenz60',
      },

      {
        id: '1567',
        model: 'A-Class',
        year: ['2012', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3952-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W176 A-Class 2012 - 2018 III покоління EU Hatchback',
        titleKey: 'mercedesBenz61',
      },
      {
        id: '1568',
        model: 'A-Class',
        year: ['2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3953-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W177 A-Class 2018 - … IV покоління EU Hatchback 5-ти дверна',
        titleKey: 'mercedesBenz62',
      },
      {
        id: '1569',
        model: 'C-Class',
        year: ['1982', '1993'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3959-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W201 C-Class 1982 - 1993 I покоління EU Sedan',
        titleKey: 'mercedesBenz63',
      },
      {
        id: '1570',
        model: 'C-Class',
        year: ['1982', '1993'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3960-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W201 C-Class 1982 - 1993 I покоління EU Universal',
        titleKey: 'mercedesBenz64',
      },
      {
        id: '1571',
        model: 'C-Class',
        year: ['1993', '2001'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3961-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W202 C-Class 1993 - 2001 I покоління EU Sedan',
        titleKey: 'mercedesBenz65',
      },
      {
        id: '1572',
        model: 'C-Class',
        year: ['1993', '2001'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3962-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W202 C-Class 1993 - 2001 I покоління EU Universal',
        titleKey: 'mercedesBenz66',
      },
      {
        id: '1573',
        model: 'C-Class',
        year: ['2000', '2007'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3967-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W203 (CL203) C-Class 2001 - 2007 II покоління EU Coupe',
        titleKey: 'mercedesBenz67',
      },
      {
        id: '1574',
        model: 'C-Class',
        year: ['2000', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3965-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W203 (S203) C-Class 2000 - 2007 II покоління EU Universal AWD',
        titleKey: 'mercedesBenz68',
      },
      {
        id: '1575',
        model: 'C-Class',
        year: ['2001', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3966-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W203 (S203) C-Class 2001 - 2007 II покоління EU Universal',
        titleKey: 'mercedesBenz69',
      },
      {
        id: '1576',
        model: 'C-Class',
        year: ['2000', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3963-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W203 C-Class 2000 - 2007 II покоління EU Sedan',
        titleKey: 'mercedesBenz70',
      },

      {
        id: '1577',
        model: 'C-Class',
        year: ['2000', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3964-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W203 C-Class 2000 - 2007 II покоління EU Sedan AWD',
        titleKey: 'mercedesBenz71',
      },
      {
        id: '1578',
        model: 'C-Class',
        year: ['2000', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/5979-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W203 C-Class 2000 - 2007 II покоління EU Sedan праве кермо',
        titleKey: 'mercedesBenz72',
      },
      {
        id: '1579',
        model: 'C-Class',
        year: ['2011', '2014'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3970-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W204 (C63 AMG) C-Class 2011 - 2014 III покоління EU Coupe',
        titleKey: 'mercedesBenz73',
      },
      {
        id: '1580',
        model: 'C-Class',
        year: ['2007', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3969-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W204 (S204) C-Class 2007 - 2014 III покоління EU Universal',
        titleKey: 'mercedesBenz74',
      },
      {
        id: '1581',
        model: 'C-Class',
        year: ['2007', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3968-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W204 C-Class 2007 - 2014 III покоління EU Sedan',
        titleKey: 'mercedesBenz75',
      },
      {
        id: '1582',
        model: 'C-Class',
        year: ['2014', '2021'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3973-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W205 (C205) C-Class 2014 - 2021 IV покоління EU Coupe',
        titleKey: 'mercedesBenz76',
      },
      {
        id: '1583',
        model: 'C-Class',
        year: ['2014', '2021'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3971-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W205 C-Class 2014 - 2021 IV покоління EU Sedan',
        titleKey: 'mercedesBenz77',
      },
      {
        id: '1584',
        model: 'C-Class',
        year: ['2014', '2021'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3972-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W205 C-Class 2014 - 2021 IV покоління EU Universal',
        titleKey: 'mercedesBenz78',
      },
      {
        id: '1585',
        model: 'C-Class',
        year: ['2021'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3974-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W206 C-Class 2021 - … V покоління EU Sedan',
        titleKey: 'mercedesBenz79',
      },
      {
        id: '1586',
        model: 'CLK-Class',
        year: ['1997', '2002'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3982-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W208 (C208) CLK-Class 1997 - 2002 I покоління EU Coupe',
        titleKey: 'mercedesBenz80',
      },

      {
        id: '1587',
        model: 'CLK-Class',
        year: ['2002', '2009'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3983-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W209 CLK-Class 2002 - 2009 II покоління EU Coupe',
        titleKey: 'mercedesBenz81',
      },
      {
        id: '1588',
        model: 'CLK-Class',
        year: ['2002', '2009'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3983-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W209 CLK-Class 2002 - 2009 II покоління EU Coupe праве кермо',
        titleKey: 'mercedesBenz82',
      },
      {
        id: '1589',
        model: 'E-Class',
        year: ['1995', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3991-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W210 E-Class 1995 - 2002 II покоління EU Sedan',
        titleKey: 'mercedesBenz83',
      },
      {
        id: '1590',
        model: 'E-Class',
        year: ['1995', '2002'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3992-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W210 E-Class 1995 - 2002 II покоління EU Universal',
        titleKey: 'mercedesBenz84',
      },
      {
        id: '1591',
        model: 'E-Class',
        year: ['2002', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3993-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W211 E-Class 2002 - 2009 III покоління EU Sedan',
        titleKey: 'mercedesBenz85',
      },
      {
        id: '1592',
        model: 'E-Class',
        year: ['2002', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3995-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W211 E-Class 2002 - 2009 III покоління EU Sedan AWD',
        titleKey: 'mercedesBenz86',
      },
      {
        id: '1593',
        model: 'E-Class',
        year: ['2002', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3994-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W211 E-Class 2002 - 2009 III покоління EU Universal',
        titleKey: 'mercedesBenz87',
      },
      {
        id: '1594',
        model: 'E-Class',
        year: ['2002', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3996-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W211 E-Class 2002 - 2009 III покоління EU Universal AWD',
        titleKey: 'mercedesBenz88',
      },
      {
        id: '1595',
        model: 'E-Class',
        year: ['2009', '2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3999-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W212 (C207) E-Class 2009 - 2016 IV покоління EU Coupe',
        titleKey: 'mercedesBenz89',
      },
      {
        id: '1596',
        model: 'E-Class',
        year: ['2009', '2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3997-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W212 (S212) E-Class 2009 - 2016 IV покоління EU Universal',
        titleKey: 'mercedesBenz90',
      },

      {
        id: '1597',
        model: 'E-Class',
        year: ['2009', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4000-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W212 E-Class 2009 - 2016 EU Sedan',
        titleKey: 'mercedesBenz91',
      },
      {
        id: '1598',
        model: 'E-Class',
        year: ['2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4003-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W213 (E220) E-Class 2020 - … EU Sedan рест',
        titleKey: 'mercedesBenz92',
      },
      {
        id: '1599',
        model: 'E-Class',
        year: ['2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mercedes/6026-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W213 (S213) E-Class 2016 - … EU Universal',
        titleKey: 'mercedesBenz93',
      },
      {
        id: '1600',
        model: 'E-Class',
        year: ['2016', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4001-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W213 E-Class 2016 - 2020 EU Sedan дорест',
        titleKey: 'mercedesBenz94',
      },
      {
        id: '1601',
        model: 'E-Class',
        year: ['2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4001-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W213 E-Class 2016 - … EU Sedan дорест праве кермо',
        titleKey: 'mercedesBenz95',
      },
      {
        id: '1602',
        model: 'E-Class',
        year: ['2016'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/mercedes/4002-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W213 E-Class 2016 - … USA Coupe',
        titleKey: 'mercedesBenz96',
      },
      {
        id: '1603',
        model: 'S-Class',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4043-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W220 S-Class 1998 - 2005 EU Sedan Long',
        titleKey: 'mercedesBenz97',
      },
      {
        id: '1604',
        model: 'S-Class',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4044-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W220 S-Class 1998 - 2005 EU Sedan Long/AWD',
        titleKey: 'mercedesBenz98',
      },
      {
        id: '1605',
        model: 'S-Class',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4042-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W220 S-Class 1998 - 2005 EU Sedan Short',
        titleKey: 'mercedesBenz99',
      },
      {
        id: '1606',
        model: 'S-Class',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/5956-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W220 S-Class 1998 - 2005 EU Sedan Short/AWD',
        titleKey: 'mercedesBenz100',
      },

      {
        id: '1607',
        model: 'S-Class',
        year: ['2005', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4046-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W221 S-Class 2005 - 2013 EU Sedan Long',
        titleKey: 'mercedesBenz101',
      },
      {
        id: '1608',
        model: 'S-Class',
        year: ['2005', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4045-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W221 S-Class 2005 - 2013 EU Sedan Short',
        titleKey: 'mercedesBenz102',
      },
      {
        id: '1609',
        model: 'S-Class',
        year: ['2013', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4047-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W222 S-Class 2013 - 2020 EU Sedan Long',
        titleKey: 'mercedesBenz103',
      },
      {
        id: '1610',
        model: 'S-Class',
        year: ['2013', '2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4048-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W222 S-Class 2013 - 2020 EU Sedan Short',
        titleKey: 'mercedesBenz104',
      },
      {
        id: '1611',
        model: 'S-Class',
        year: ['2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4050-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W223 S-Class 2020 - ... EU Sedan Long',
        titleKey: 'mercedesBenz105',
      },
      {
        id: '1612',
        model: 'S-Class',
        year: ['2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4049-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W223 S-Class 2020 - ... EU Sedan Short',
        titleKey: 'mercedesBenz106',
      },
      {
        id: '1613',
        model: 'B-Class',
        year: ['2005', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3955-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W245 B-Class 2005 - 2011 EU Hatchback',
        titleKey: 'mercedesBenz107',
      },
      {
        id: '1614',
        model: 'B-Class',
        year: ['2005', '2011'],
        body: 'Hatchback',
        market: 'Japan',
        img: '/images/catalog/models/mercedes/3956-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W245 B-Class 2005 - 2011 Japan Hatchback',
        titleKey: 'mercedesBenz108',
      },
      {
        id: '1615',
        model: 'B-Class',
        year: ['2011', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3957-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W246 B-Class 2011 - 2018 II покоління EU Hatchback',
        titleKey: 'mercedesBenz109',
      },
      {
        id: '1616',
        model: 'B-Class',
        year: ['2011', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3958-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W246 B-Class 2011 - 2018 II покоління EU Hatchback Electric',
        titleKey: 'mercedesBenz110',
      },

      {
        id: '1617',
        model: 'R-Class',
        year: ['2005', '2017'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4037-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W251 R-Class 2005 - 2017 I покоління EU Minivan Long',
        titleKey: 'mercedesBenz111',
      },
      {
        id: '1618',
        model: 'R-Class',
        year: ['2005', '2017'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4035-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W251 R-Class 2005 - 2017 I покоління EU Minivan 6-ти місна',
        titleKey: 'mercedesBenz112',
      },
      {
        id: '1619',
        model: 'R-Class',
        year: ['2005', '2017'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4034-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W251 R-Class 2005 - 2017 I покоління EU Minivan 6-ти місна зі столиком',
        titleKey: 'mercedesBenz113',
      },
      {
        id: '1620',
        model: 'R-Class',
        year: ['2005', '2017'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4036-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W251 R-Class 2005 - 2017 I покоління EU Minivan 7-ми місна Short',
        titleKey: 'mercedesBenz114',
      },
      {
        id: '1621',
        model: 'Citan',
        year: ['2012', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3975-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W415 Citan 2012 - 2021 I покоління EU Minivan 5-ти місна Short',
        titleKey: 'mercedesBenz115',
      },
      {
        id: '1622',
        model: 'Citan',
        year: ['2012', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3976-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W415 Citan Maxi 2012 - 2021 I покоління EU Minivan Long',
        titleKey: 'mercedesBenz116',
      },
      {
        id: '1623',
        model: 'G-Class',
        year: ['1990', '2001'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4007-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W463 G-Class (Gelandewagen) 1990 - 2001 II покоління EU Crossover',
        titleKey: 'mercedesBenz117',
      },
      {
        id: '1624',
        model: 'G-Class',
        year: ['2002', '2004'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4008-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W463 G-Class (Gelandewagen) 2002 - 2004 II покоління EU Crossover',
        titleKey: 'mercedesBenz118',
      },
      {
        id: '1625',
        model: 'G-Class',
        year: ['2004', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4009-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W463 G-Class (Gelandewagen) 2004 - 2008 II покоління EU Crossover',
        titleKey: 'mercedesBenz119',
      },
      {
        id: '1626',
        model: 'G-Class',
        year: ['2012', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4010-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W463 G-Class (Gelandewagen) 2012 - 2015 II покоління EU Crossover',
        titleKey: 'mercedesBenz120',
      },

      {
        id: '1627',
        model: 'G-Class',
        year: ['2018', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4011-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz W463 G-Class (Gelandewagen) 2018 - … III покоління EU Crossover',
        titleKey: 'mercedesBenz121',
      },
      {
        id: '1628',
        model: 'GLA-Class',
        year: ['2013', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4012-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X156 GLA-Class 2013 - 2019 I покоління EU Crossover',
        titleKey: 'mercedesBenz122',
      },
      {
        id: '1629',
        model: 'GL-Class',
        year: ['2006', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/mercedes/4018-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X164 GL-Class 2006 - 2012 I покоління EU/USA Crossover 5-ти місна',
        titleKey: 'mercedesBenz123',
      },
      {
        id: '1630',
        model: 'GL-Class',
        year: ['2006', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/mercedes/4017-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X164 GL-Class 2006 - 2012 I покоління EU/USA Crossover 7-ми місна',
        titleKey: 'mercedesBenz124',
      },
      {
        id: '1631',
        model: 'GL-Class',
        year: ['2012', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4020-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X166 GL-Class 2012 - 2015 II покоління EU Crossover дорест 7-ми місна',
        titleKey: 'mercedesBenz125',
      },
      {
        id: '1632',
        model: 'GL-Class',
        year: ['2012', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mercedes/4019-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X166 GL-Class 2012 - 2015 II покоління USA Crossover дорест 7-ми місна',
        titleKey: 'mercedesBenz126',
      },
      {
        id: '1633',
        model: 'GLS-Class',
        year: ['2015', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4027-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X166 GLS-Class 2015 - 2019 II покоління EU Crossover рест 7-ми місна',
        titleKey: 'mercedesBenz127',
      },
      {
        id: '1634',
        model: 'GLS-Class',
        year: ['2019', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4029-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X167 GLS-Class 2019 - … III покоління EU Crossover',
        titleKey: 'mercedesBenz128',
      },
      {
        id: '1635',
        model: 'GLK-Class',
        year: ['2008', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4026-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X204 GLK-Class 2008 - 2015 I покоління EU Crossover',
        titleKey: 'mercedesBenz129',
      },
      {
        id: '1636',
        model: 'GLB-Class',
        year: ['2019', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4013-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X247 GLB-Class 4MATIC 2019 - … I покоління EU Crossover',
        titleKey: 'mercedesBenz130',
      },

      {
        id: '1637',
        model: 'GLC-Class',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4015-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X253 GLC-Class 2015 - 2022 I покоління EU Crossover',
        titleKey: 'mercedesBenz131',
      },
      {
        id: '1638',
        model: 'GLC-Class',
        year: ['2015', '2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4015-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X253 GLC-Class 2015 - 2022 I покоління EU Crossover праве кермо',
        titleKey: 'mercedesBenz132',
      },
      {
        id: '1639',
        model: 'GLC-Class',
        year: ['2022', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/4016-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz X254 GLC-Class 2022 - … II покоління EU Crossover',
        titleKey: 'mercedesBenz133',
      },
      {
        id: '1640',
        model: 'CLS-Class',
        year: ['2004', '2010'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/mercedes/3984-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz С 219 CLS-Class 2004 - 2010 I покоління EU Coupe 4-х дверна',
        titleKey: 'mercedesBenz134',
      },
      {
        id: '1641',
        model: 'GLS-Class',
        year: ['2024'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mercedes/6027-250x150.jpg',
        title: 'Килимки в салон Mercedes-Benz GLS-Class GLS 450 2024 - … EU Crossover',
        titleKey: 'mercedesBenz135',
      },
    ],
  },
  /* --------------------------------- Mercury -------------------------------- */
  Mercury: {
    logo: '/images/catalog/logo/mercury-100x100.png',
    name: 'Mercury',
    models: [
      {
        id: '1642',
        model: 'Sable',
        year: ['1996', '1999'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mercury/5072-250x150.jpg',
        title: 'Килимки в салон Mercury Sable GS 1996-1999 III покоління USA Sedan',
        titleKey: 'mercury',
      },
    ],
  },
  /* ----------------------------------- MG ----------------------------------- */
  Mg: {
    logo: '/images/catalog/logo/MG-100x100.png',
    name: 'MG',
    models: [
      {
        id: '1643',
        model: 'HS',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/mg/5069-250x150.jpg',
        title: 'Килимки в салон MG Motor HS 2018-… I покоління China Crossover',
        titleKey: 'mg1',
      },
      {
        id: '1644',
        model: 'Marvel R',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mg/5070-250x150.jpg',
        title: 'Килимки в салон MG Motor Marvel R 2020-… I покоління EU Crossover',
        titleKey: 'mg2',
      },
      {
        id: '1645',
        model: '3',
        year: ['2011'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mg/5060-250x150.jpg',
        title: 'Килимки в салон MG Motor MG 3 Xross 2011-… II покоління EU Crossover',
        titleKey: 'mg3',
      },
      {
        id: '1646',
        model: '350/Roewe 350',
        year: ['2011', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mg/5067-250x150.jpg',
        title: 'Килимки в салон MG Motor MG 350/Roewe 350 2011-2015 I покоління EU Sedan',
        titleKey: 'mg4',
      },
      {
        id: '1647',
        model: '4',
        year: ['2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mg/5061-250x150.jpg',
        title: 'Килимки в салон MG Motor MG 4 EV (EH32) 2020-… I покоління EU Hatchback',
        titleKey: 'mg5',
      },
      {
        id: '1648',
        model: '5',
        year: ['2012', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mg/5062-250x150.jpg',
        title: 'Килимки в салон MG Motor MG 5 (AP12) 2012-2018 I покоління EU Hatchback',
        titleKey: 'mg6',
      },
      {
        id: '1649',
        model: '5',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mg/5063-250x150.jpg',
        title: 'Килимки в салон MG Motor MG 5 (AP12) 2012-2018 I покоління EU Sedan',
        titleKey: 'mg7',
      },
      {
        id: '1650',
        model: '5',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mg/5064-250x150.jpg',
        title: 'Килимки в салон MG Motor MG 5 2018-… II покоління EU Sedan',
        titleKey: 'mg8',
      },
      {
        id: '1651',
        model: 'Extender',
        year: ['2019'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mg/5068-250x150.jpg',
        title: 'Килимки в салон MG Motor MG Extender (Maxus T70) 2019-… I покоління EU Pickup 4-х дверна',
        titleKey: 'mg9',
      },
      {
        id: '1652',
        model: '6',
        year: ['2009', '2016'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/mg/5065-250x150.jpg',
        title: 'Килимки в салон MG Motor MG-6 2009-2016 I покоління EU Liftback',
        titleKey: 'mg10',
      },

      {
        id: '1653',
        model: '9',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mg/5066-250x150.jpg',
        title: 'Килимки в салон MG Motor MG-9 2012-2018 I покоління EU Sedan',
        titleKey: 'mg11',
      },
      {
        id: '1654',
        model: 'ZS',
        year: ['2017'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/mg/5071-250x150.jpg',
        title: 'Килимки в салон MG Motor ZS (ZS11) 2017-… I покоління China Crossover',
        titleKey: 'mg12',
      },
      {
        id: '1655',
        model: 'ZS',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/mg/6008-250x150.jpg',
        title: 'Килимки в салон MG Motor ZS EV 2018-… I покоління China Crossover',
        titleKey: 'mg13',
      },
    ],
  },
  /* ------------------------------- MiniCooper ------------------------------- */
  Minicooper: {
    logo: '/images/catalog/logo/mini_cooper-100x100.png',
    name: 'Mini Cooper',
    models: [
      {
        id: '1656',
        model: 'Clubman (F54)',
        year: ['2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mini/3931-250x150.jpg',
        title: 'Килимки в салон Mini Clubman (F54) 2014 - … II покоління EU Hatchback',
        titleKey: 'miniCooper1',
      },
      {
        id: '1657',
        model: 'Clubman',
        year: ['2007', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mini/3932-250x150.jpg',
        title: 'Килимки в салон Mini Clubman (R55) 2007 - 2014 I покоління EU Hatchback',
        titleKey: 'miniCooper2',
      },
      {
        id: '1658',
        model: 'Cooper',
        year: ['2014'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/mini/3933-250x150.jpg',
        title: 'Килимки в салон Mini Cooper (F55) 2014 - … III покоління USA Hatchback 5-ти дверна',
        titleKey: 'miniCooper3',
      },
      {
        id: '1659',
        model: 'Cooper',
        year: ['2014'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/mini/3934-250x150.jpg',
        title: 'Килимки в салон Mini Cooper (F56) 2014 - … III покоління USA Hatchback 3-х дверна',
        titleKey: 'miniCooper4',
      },
      {
        id: '1660',
        model: 'Cooper',
        year: ['2006', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mini/3944-250x150.jpg',
        title: 'Килимки в салон Mini Cooper (R56) 2006 - 2014 II покоління EU Hatchback 3-х дверна',
        titleKey: 'miniCooper5',
      },
      {
        id: '1661',
        model: 'Cooper',
        year: ['2006', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mini/3936-250x150.jpg',
        title: 'Килимки в салон Mini Cooper (R56) 2006 - 2014 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'miniCooper6',
      },
      {
        id: '1662',
        model: 'Cooper',
        year: ['2001', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mini/3935-250x150.jpg',
        title: 'Килимки в салон Mini Cooper R50 2001 - 2006 I покоління EU Hatchback',
        titleKey: 'miniCooper7',
      },
      {
        id: '1663',
        model: 'Cooper S',
        year: ['2014'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/mini/3937-250x150.jpg',
        title: 'Килимки в салон Mini Cooper S (F55) 2014 - … III покоління USA Hatchback 5-ти дверна',
        titleKey: 'miniCooper8',
      },
      {
        id: '1664',
        model: 'Cooper S',
        year: ['2014'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/mini/3938-250x150.jpg',
        title: 'Килимки в салон Mini Cooper S (F56) 2014 - … III покоління USA Hatchback 3-х дверна',
        titleKey: 'miniCooper9',
      },
      {
        id: '1665',
        model: 'Countryman',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mini/3939-250x150.jpg',
        title: 'Килимки в салон Mini Countryman (SE ALL4) 2017 - … II покоління EU Crossover',
        titleKey: 'miniCooper10',
      },
      {
        id: '1666',
        model: 'Countryman',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mini/3942-250x150.jpg',
        title: 'Килимки в салон Mini Countryman (SE ALL4) 2017 - … II покоління EU Crossover Plug-in Hybrid',
        titleKey: 'miniCooper11',
      },
      {
        id: '1667',
        model: 'Countryman',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mini/3940-250x150.jpg',
        title: 'Килимки в салон Mini Countryman R60 2010 - 2017 I покоління EU Crossover без підстаканника',
        titleKey: 'miniCooper12',
      },
      {
        id: '1668',
        model: 'Countryman',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mini/3941-250x150.jpg',
        title: 'Килимки в салон Mini Countryman R60 2010 - 2017 I покоління EU Crossover з підстаканником',
        titleKey: 'miniCooper13',
      },
      {
        id: '1669',
        model: 'Paceman',
        year: ['2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mini/3943-250x150.jpg',
        title: 'Килимки в салон Mini Paceman (R61) 2013 - … I покоління EU Crossover',
        titleKey: 'miniCooper14',
      },
    ],
  },
  /* ------------------------------- Mitsubishi ------------------------------- */
  Mitsubishi: {
    logo: '/images/catalog/logo/mitsubishi-100x100.png',
    name: 'Mitsubishi',
    models: [
      {
        id: '1670',
        model: 'ASX',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3865-250x150.jpg',
        title: 'Килимки в салон Mitsubishi ASX 2010 - 2017 I покоління EU Crossover',
        titleKey: 'mitsubishi1',
      },
      {
        id: '1671',
        model: 'ASX',
        year: ['2017', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3866-250x150.jpg',
        title: 'Килимки в салон Mitsubishi ASX 2017 - 2019 I покоління EU Crossover рест',
        titleKey: 'mitsubishi2',
      },
      {
        id: '1672',
        model: 'ASX',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3864-250x150.jpg',
        title: 'Килимки в салон Mitsubishi ASX NEW 2019 - … I покоління EU Crossover рест',
        titleKey: 'mitsubishi3',
      },
      {
        id: '1673',
        model: 'Carisma',
        year: ['1995', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3867-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Carisma 1995 - 2004 I покоління EU Sedan',
        titleKey: 'mitsubishi4',
      },
      {
        id: '1674',
        model: 'Carisma',
        year: ['1999', '2004'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3868-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Carisma 1999 - 2004 I покоління EU Liftback',
        titleKey: 'mitsubishi5',
      },
      {
        id: '1675',
        model: 'Colt',
        year: ['2004', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3870-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Colt 2004 - 2012 IX покоління EU Hatchback 3-х дверна',
        titleKey: 'mitsubishi6',
      },
      {
        id: '1676',
        model: 'Colt',
        year: ['2004', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3869-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Colt 2004 - 2012 IX покоління EU Hatchback 5-ти дверна',
        titleKey: 'mitsubishi7',
      },
      {
        id: '1677',
        model: 'Eclipse',
        year: ['1989', '1994'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3871-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Eclipse 1989 - 1994 I покоління USA Coupe',
        titleKey: 'mitsubishi8',
      },
      {
        id: '1678',
        model: 'Eclipse',
        year: ['2005', '2011'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3874-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Eclipse 4G 2005 - 2011 IV покоління USA Coupe',
        titleKey: 'mitsubishi9',
      },
      {
        id: '1679',
        model: 'Eclipse',
        year: ['2017', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3872-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Eclipse Cross 2017 - 2020 I покоління EU Crossover дорест',
        titleKey: 'mitsubishi10',
      },

      {
        id: '1680',
        model: 'Eclipse',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3873-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Eclipse Cross 2020 - … I покоління EU Crossover рест',
        titleKey: 'mitsubishi11',
      },
      {
        id: '1681',
        model: 'Eclipse',
        year: ['2005', '2008'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3875-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Eclipse Spyder 2005 - 2008 IV покоління USA Cabriolet дорест',
        titleKey: 'mitsubishi12',
      },
      {
        id: '1682',
        model: 'Endeavor',
        year: ['2003', '2011'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3876-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Endeavor 2003 - 2011 I покоління USA Crossover',
        titleKey: 'mitsubishi13',
      },
      {
        id: '1683',
        model: 'Galant',
        year: ['1987', '1992'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3878-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Galant 6 1987 - 1992 VI покоління EU Sedan дорест',
        titleKey: 'mitsubishi14',
      },
      {
        id: '1684',
        model: 'Galant',
        year: ['1992', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3879-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Galant 7 (E50) 1992 - 1998 VII покоління EU Sedan',
        titleKey: 'mitsubishi15',
      },
      {
        id: '1685',
        model: 'Galant',
        year: ['1996', '2003'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3880-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Galant 8 (EA0) 1996 - 2003 VIII покоління EU Sedan',
        titleKey: 'mitsubishi16',
      },
      {
        id: '1686',
        model: 'Galant',
        year: ['1996', '2003'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3881-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Galant 8 (EA0) 1996 - 2003 VIII покоління EU Universal',
        titleKey: 'mitsubishi17',
      },
      {
        id: '1687',
        model: 'Galant',
        year: ['2003', '2012'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/mitsubishi/3877-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Galant 9 2003 - 2012 IX покоління EU/USA Sedan',
        titleKey: 'mitsubishi18',
      },
      {
        id: '1688',
        model: 'Grandis',
        year: ['2003', '2011'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3883-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Grandis 2003 - 2011 I покоління EU Minivan 6-ти місна',
        titleKey: 'mitsubishi19',
      },
      {
        id: '1689',
        model: 'Grandis',
        year: ['2003', '2011'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3882-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Grandis 2003 - 2011 I покоління EU Minivan 7-ми місна',
        titleKey: 'mitsubishi20',
      },

      {
        id: '1690',
        model: 'i-MiEV',
        year: ['2009'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/mitsubishi/3884-250x150.jpg',
        title: 'Килимки в салон Mitsubishi i-MiEV 2009 - … I покоління EU/USA Hatchback',
        titleKey: 'mitsubishi21',
      },
      {
        id: '1691',
        model: 'L200',
        year: ['2006', '2011'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3888-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L200 (KAOT) 2006 - 2011 IV покоління EU Pickup дорест 4-х дверна Short',
        titleKey: 'mitsubishi22',
      },
      {
        id: '1692',
        model: 'L200',
        year: ['2006', '2011'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3888-250x150.jpg',
        title:
          'Килимки в салон Mitsubishi L200 (KAOT) 2006 - 2011 IV покоління EU Pickup дорест 4-х дверна Short/праве кермо',
        titleKey: 'mitsubishi23',
      },
      {
        id: '1693',
        model: 'L200',
        year: ['2011', '2015'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3886-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L200 (KAOT) 2011 - 2015 IV покоління EU Pickup рест 4-х дверна Short',
        titleKey: 'mitsubishi24',
      },
      {
        id: '1694',
        model: 'L200',
        year: ['2015', '2023'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3889-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L200 (V) 2015 - 2023 V покоління EU Pickup 4-х дверна Long',
        titleKey: 'mitsubishi25',
      },
      {
        id: '1695',
        model: 'L200',
        year: ['2015', '2023'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3890-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L200 (V) 2015 - 2023 V покоління EU Pickup 4-х дверна Short',
        titleKey: 'mitsubishi26',
      },
      {
        id: '1696',
        model: 'L200',
        year: ['1996', '2006'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3885-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L200 (К60Т) 1996 - 2006 III покоління EU Pickup 4-х дверна',
        titleKey: 'mitsubishi27',
      },
      {
        id: '1697',
        model: 'L200',
        year: ['1996', '2006'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/6009-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L200 (К60Т) 1996 - 2006 III покоління EU Pickup 4-х дверна праве кермо',
        titleKey: 'mitsubishi28',
      },
      {
        id: '1698',
        model: 'L200',
        year: ['2006', '2015'],
        body: 'Pickup',
        market: 'UAE',
        img: '/images/catalog/models/mitsubishi/3887-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L200 2006 - 2015 IV покоління UAE Pickup',
        titleKey: 'mitsubishi29',
      },
      {
        id: '1699',
        model: 'L200',
        year: ['2023'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/6010-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L200 Triton (VI) 2023 - ... VI покоління EU Pickup 4-х дверна',
        titleKey: 'mitsubishi30',
      },

      {
        id: '1700',
        model: 'L400',
        year: ['1994', '2007'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3891-250x150.jpg',
        title: 'Килимки в салон Mitsubishi L400/Delica Space Gear 1994 - 2007 IV покоління EU Minivan',
        titleKey: 'mitsubishi31',
      },
      {
        id: '1701',
        model: 'Lancer',
        year: ['2005', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3892-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer Evolution 2005 - 2007 IX покоління EU Sedan',
        titleKey: 'mitsubishi32',
      },
      {
        id: '1702',
        model: 'Lancer',
        year: ['2005', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3894-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer Evolution 2005 - 2007 IX покоління EU Universal',
        titleKey: 'mitsubishi33',
      },
      {
        id: '1703',
        model: 'Lancer',
        year: ['2007', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3899-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer Evolution 2007 - 2015 X покоління EU Sedan',
        titleKey: 'mitsubishi34',
      },
      {
        id: '1704',
        model: 'Lancer',
        year: ['2005', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3893-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer Evolution Ralliart 2005 - 2007 IX покоління EU Sedan',
        titleKey: 'mitsubishi35',
      },
      {
        id: '1705',
        model: 'Lancer',
        year: ['2000', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3895-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer IX 2000 - 2009 IX покоління EU Sedan',
        titleKey: 'mitsubishi36',
      },
      {
        id: '1706',
        model: 'Lancer',
        year: ['2000', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3896-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer IX 2000 - 2009 IX покоління EU Universal',
        titleKey: 'mitsubishi37',
      },
      {
        id: '1707',
        model: 'Lancer',
        year: ['1991', '1995'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3897-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer VII 1991 - 1995 VII покоління EU Sedan',
        titleKey: 'mitsubishi38',
      },
      {
        id: '1708',
        model: 'Lancer',
        year: ['1995', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3898-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer VIII 1995 - 2000 VIII покоління EU Sedan',
        titleKey: 'mitsubishi39',
      },
      {
        id: '1709',
        model: 'Lancer',
        year: ['2007', '2015'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/mitsubishi/3900-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer X 2007 - 2015 X покоління EU/USA Sedan',
        titleKey: 'mitsubishi40',
      },

      {
        id: '1710',
        model: 'Lancer',
        year: ['2007', '2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3902-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer X 2007 - 2015 X покоління USA Sedan',
        titleKey: 'mitsubishi41',
      },
      {
        id: '1711',
        model: 'Lancer',
        year: ['2007', '2015'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/mitsubishi/3901-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Lancer X Sportback 2007 - 2015 X покоління EU/USA Hatchback',
        titleKey: 'mitsubishi42',
      },
      {
        id: '1712',
        model: 'Mirage',
        year: ['2012'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/5967-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Mirage 2012 - ... IV покоління USA Sedan',
        titleKey: 'mitsubishi43',
      },
      {
        id: '1713',
        model: 'Mirage',
        year: ['2012'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3903-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Mirage 2012 - ... VI покоління USA Hatchback',
        titleKey: 'mitsubishi44',
      },
      {
        id: '1714',
        model: 'Outlander',
        year: ['2006', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3908-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander (XL) 2006 - 2012 II покоління EU Crossover 7-ми місна',
        titleKey: 'mitsubishi45',
      },
      {
        id: '1715',
        model: 'Outlander',
        year: ['2001', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3906-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander 2001 - 2008 I покоління EU Crossover',
        titleKey: 'mitsubishi46',
      },
      {
        id: '1716',
        model: 'Outlander',
        year: ['2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/5974-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander 2012 - ... III покоління EU Crossover 5-ти місна',
        titleKey: 'mitsubishi47',
      },
      {
        id: '1717',
        model: 'Outlander',
        year: ['2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3909-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander 2012 - ... III покоління EU Crossover 7-ми місна',
        titleKey: 'mitsubishi48',
      },
      {
        id: '1718',
        model: 'Outlander',
        year: ['2012'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3910-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander 2012 - ... III покоління USA Crossover 7-ми місна',
        titleKey: 'mitsubishi49',
      },
      {
        id: '1719',
        model: 'Outlander',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3912-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander 2021 - ... IV покоління EU Crossover',
        titleKey: 'mitsubishi50',
      },

      {
        id: '1720',
        model: 'Outlander',
        year: ['2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3913-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander 2021 - ... IV покоління USA Crossover',
        titleKey: 'mitsubishi51',
      },
      {
        id: '1721',
        model: 'Outlander',
        year: ['2013'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/mitsubishi/3911-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander PHEV 2013 - ... III покоління EU/USA Crossover Hybrid',
        titleKey: 'mitsubishi52',
      },
      {
        id: '1722',
        model: 'Outlander',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3905-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander Sport 2010 - 2017 I покоління USA Crossover',
        titleKey: 'mitsubishi53',
      },
      {
        id: '1723',
        model: 'Outlander',
        year: ['2017', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3907-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander Sport 2017 - 2019 I покоління USA Crossover рест',
        titleKey: 'mitsubishi54',
      },
      {
        id: '1724',
        model: 'Outlander',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3904-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Outlander Sport 2019 - ... I покоління USA Crossover рест',
        titleKey: 'mitsubishi55',
      },
      {
        id: '1725',
        model: 'Pajero',
        year: ['1998', '2003'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3914-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Pinin 1998 - 2003 I покоління USA Crossover дорест',
        titleKey: 'mitsubishi56',
      },
      {
        id: '1726',
        model: 'Pajero',
        year: ['1998', '2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3915-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Pinin 1998 - 2007 I покоління EU Crossover 5-ти дверна',
        titleKey: 'mitsubishi57',
      },
      {
        id: '1727',
        model: 'Pajero Sport',
        year: ['1996', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3916-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Sport 1996 - 2008 I покоління EU Crossover',
        titleKey: 'mitsubishi58',
      },
      {
        id: '1728',
        model: 'Pajero Sport',
        year: ['2008', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3919-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Sport 2008 - 2016 II покоління EU Crossover',
        titleKey: 'mitsubishi59',
      },
      {
        id: '1729',
        model: 'Pajero Sport',
        year: ['2015', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3920-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Sport 2015 - 2019 III покоління EU Crossover дорест',
        titleKey: 'mitsubishi60',
      },

      {
        id: '1730',
        model: 'Pajero Sport',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3924-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Sport 2019 - ... III покоління EU Crossover рест',
        titleKey: 'mitsubishi61',
      },
      {
        id: '1731',
        model: 'Pajero Wagon',
        year: ['1991', '1999'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3918-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Wagon (V20) 1991 - 1999 II покоління EU Crossover 3-х дверна',
        titleKey: 'mitsubishi62',
      },
      {
        id: '1732',
        model: 'Pajero Wagon',
        year: ['1991', '1999'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3917-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Wagon (V20) 1991 - 1999 II покоління EU Crossover 5-ти дверна',
        titleKey: 'mitsubishi63',
      },
      {
        id: '1733',
        model: 'Pajero Wagon',
        year: ['1999', '2006'],
        body: 'Crossover',
        market: 'Japan',
        img: '/images/catalog/models/mitsubishi/3922-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Wagon (V60) 1999 - 2006 III покоління Japan Crossover 3-х дверна',
        titleKey: 'mitsubishi64',
      },
      {
        id: '1734',
        model: 'Pajero Wagon',
        year: ['1999', '2006'],
        body: 'Crossover',
        market: 'Japan',
        img: '/images/catalog/models/mitsubishi/3921-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Wagon (V60) 1999 - 2006 III покоління Japan Crossover 5-ти дверна',
        titleKey: 'mitsubishi65',
      },
      {
        id: '1735',
        model: 'Pajero Wagon',
        year: ['1999', '2006'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/mitsubishi/3923-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Pajero Wagon (V60) 1999 - 2006 III покоління USA Crossover 5-ти дверна',
        titleKey: 'mitsubishi66',
      },
      {
        id: '1736',
        model: 'Pajero Wagon',
        year: ['2006', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3925-250x150.jpg',
        title:
          'Килимки в салон Mitsubishi Pajero Wagon (V80) 2006 - 2021 IV покоління EU Crossover 5-ти дверна 7 місна',
        titleKey: 'mitsubishi67',
      },
      {
        id: '1737',
        model: 'Pajero Wagon',
        year: ['2006', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/6011-250x150.jpg',
        title:
          'Килимки в салон Mitsubishi Pajero Wagon (V80) 2006 - 2021 IV покоління EU Crossover 5-ти дверна 5-ти місна',
        titleKey: 'mitsubishi68',
      },
      {
        id: '1738',
        model: 'Sigma',
        year: ['1990', '1996'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3927-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Sigma 1990 - 1996 I покоління EU Sedan',
        titleKey: 'mitsubishi69',
      },
      {
        id: '1739',
        model: 'Sigma',
        year: ['1990', '1996'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3926-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Sigma 1990 - 1996 I покоління EU Universal',
        titleKey: 'mitsubishi70',
      },

      {
        id: '1740',
        model: 'Space',
        year: ['1998', '2005'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3928-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Space Star 1998 - 2005 I покоління EU Minivan',
        titleKey: 'mitsubishi71',
      },
      {
        id: '1741',
        model: 'Space',
        year: ['2012', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3929-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Space Star 2012 - 2016 II покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'mitsubishi72',
      },
      {
        id: '1742',
        model: 'Space',
        year: ['1997', '2003'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/mitsubishi/3930-250x150.jpg',
        title: 'Килимки в салон Mitsubishi Space Wagon 1997 - 2003 III покоління EU Minivan',
        titleKey: 'mitsubishi73',
      },
    ],
  },
  /* ---------------------------------- Neta ---------------------------------- */
  Neta: {
    logo: '/images/catalog/logo/NETA-100x100.png',
    name: 'Neta',
    models: [
      {
        id: '1743',
        model: 'U Pro',
        year: ['2020'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/neta/5059-250x150.jpg',
        title: 'Килимки в салон Neta U Pro 2020-… I покоління China Crossover',
        titleKey: 'neta',
      },
    ],
  },
  /* --------------------------------- Nissan --------------------------------- */
  Nissan: {
    logo: '/images/catalog/logo/nissan-100x100.png',
    name: 'Nissan',
    models: [
      {
        id: '1744',
        model: '350Z',
        year: ['2002', '2009'],
        body: 'Coupe',
        market: 'Japan',
        img: '/images/catalog/models/nissan/3736-250x150.jpg',
        title: 'Килимки в салон Nissan 350Z 2002 - 2009 I покоління Japan Coupe',
        titleKey: 'nissan1',
      },
      {
        id: '1745',
        model: '370Z',
        year: ['2009', '2020'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/nissan/3737-250x150.jpg',
        title: 'Килимки в салон Nissan 370Z 2009 - 2020 I покоління USA Coupe',
        titleKey: 'nissan2',
      },
      {
        id: '1746',
        model: 'Almera',
        year: ['1995', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3739-250x150.jpg',
        title: 'Килимки в салон Nissan Almera (N15) 1995 - 2000 I покоління EU Sedan',
        titleKey: 'nissan3',
      },
      {
        id: '1747',
        model: 'Almera',
        year: ['2000', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3742-250x150.jpg',
        title: 'Килимки в салон Nissan Almera (N16) 2000 - 2006 II покоління EU Hatchback',
        titleKey: 'nissan4',
      },
      {
        id: '1748',
        model: 'Almera',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3741-250x150.jpg',
        title: 'Килимки в салон Nissan Almera (N16) 2000 - 2006 II покоління EU Sedan',
        titleKey: 'nissan5',
      },
      {
        id: '1749',
        model: 'Almera',
        year: ['2006', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3738-250x150.jpg',
        title: 'Килимки в салон Nissan Almera Classic (B10) 2006 - 2012 I покоління EU Sedan',
        titleKey: 'nissan6',
      },
      {
        id: '1750',
        model: 'Almera',
        year: ['2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3743-250x150.jpg',
        title: 'Килимки в салон Nissan Almera NEW G11/15 2012 - … III покоління EU Sedan',
        titleKey: 'nissan7',
      },
      {
        id: '1751',
        model: 'Almera',
        year: ['1998', '2006'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3740-250x150.jpg',
        title: 'Килимки в салон Nissan Almera Tino 1998 - 2006 I покоління EU Minivan',
        titleKey: 'nissan8',
      },
      {
        id: '1752',
        model: 'Altima',
        year: ['2007', '2013'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/nissan/3744-250x150.jpg',
        title: 'Килимки в салон Nissan Altima D32 2007 - 2013 IV покоління USA Coupe',
        titleKey: 'nissan9',
      },
      {
        id: '1753',
        model: 'Altima',
        year: ['2007', '2013'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/nissan/3744-250x150.jpg',
        title: 'Килимки в салон Nissan Altima D32 2007 - 2013 IV покоління USA Sedan hybrid',
        titleKey: 'nissan10',
      },

      {
        id: '1754',
        model: 'Altima',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/nissan/3745-250x150.jpg',
        title: 'Килимки в салон Nissan Altima L33 2012 - 2018 V покоління USA Sedan',
        titleKey: 'nissan11',
      },
      {
        id: '1755',
        model: 'Altima',
        year: ['2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/nissan/3746-250x150.jpg',
        title: 'Килимки в салон Nissan Altima L34 2018 - … VI покоління USA Sedan',
        titleKey: 'nissan12',
      },
      {
        id: '1756',
        model: 'Ariya',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/6024-250x150.jpg',
        title: 'Килимки в салон Nissan Ariya (FE0) 2022 - … I покоління EU Crossover',
        titleKey: 'nissan13',
      },
      {
        id: '1757',
        model: 'Armada',
        year: ['2004', '2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3747-250x150.jpg',
        title: 'Килимки в салон Nissan Armada 2004 - 2015 I покоління USA Crossover',
        titleKey: 'nissan14',
      },
      {
        id: '1758',
        model: 'Bluebird',
        year: ['1987', '1992'],
        body: 'Sedan',
        market: 'Japan',
        img: '/images/catalog/models/nissan/3749-250x150.jpg',
        title: 'Килимки в салон Nissan Bluebird (U12) 1987 - 1992 VIII покоління Japan Sedan',
        titleKey: 'nissan15',
      },
      {
        id: '1759',
        model: 'Bluebird',
        year: ['1991', '1997'],
        body: 'Sedan праве кермо',
        market: 'Japan',
        img: '/images/catalog/models/nissan/3748-250x150.jpg',
        title: 'Килимки в салон Nissan Bluebird ARX (U13) 1991 - 1997 IX покоління Japan Sedan праве кермо',
        titleKey: 'nissan16',
      },
      {
        id: '1760',
        model: 'e-NV200',
        year: ['2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3786-250x150.jpg',
        title: 'Килимки в салон Nissan e-NV200 2012 - … покоління EU Minivan',
        titleKey: 'nissan17',
      },
      {
        id: '1761',
        model: 'Juke',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3755-250x150.jpg',
        title: 'Килимки в салон Nissan Juke (N-Connecta) 2019 - … II покоління EU Crossover',
        titleKey: 'nissan18',
      },
      {
        id: '1762',
        model: 'Juke',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3754-250x150.jpg',
        title: 'Килимки в салон Nissan Juke (Tekna) 2019 - … II покоління EU Crossover',
        titleKey: 'nissan19',
      },
      {
        id: '1763',
        model: 'Juke',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3751-250x150.jpg',
        title: 'Килимки в салон Nissan Juke 2010 - 2015 I покоління EU Crossover дорест',
        titleKey: 'nissan20',
      },

      {
        id: '1764',
        model: 'Juke',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3750-250x150.jpg',
        title: 'Килимки в салон Nissan Juke 2010 - 2015 I покоління EU Crossover дорест повний привід',
        titleKey: 'nissan21',
      },
      {
        id: '1765',
        model: 'Juke',
        year: ['2015', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3752-250x150.jpg',
        title: 'Килимки в салон Nissan Juke 2015 - 2019 I покоління EU Crossover рест',
        titleKey: 'nissan22',
      },
      {
        id: '1766',
        model: 'Juke',
        year: ['2015', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3753-250x150.jpg',
        title: 'Килимки в салон Nissan Juke 2015 - 2019 I покоління USA Crossover рест повний привід',
        titleKey: 'nissan23',
      },
      {
        id: '1767',
        model: 'Kicks',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3756-250x150.jpg',
        title: 'Килимки в салон Nissan Kicks 2018 - … I покоління USA Crossover',
        titleKey: 'nissan24',
      },
      {
        id: '1768',
        model: 'Leaf',
        year: ['2010', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3757-250x150.jpg',
        title: 'Килимки в салон Nissan Leaf (ZE0) 2010 - 2017 I покоління EU Hatchback',
        titleKey: 'nissan25',
      },
      {
        id: '1769',
        model: 'Leaf',
        year: ['2010', '2017'],
        body: 'Hatchback',
        market: 'Japan',
        img: '/images/catalog/models/nissan/3759-250x150.jpg',
        title: 'Килимки в салон Nissan Leaf (ZE0) 2010 - 2017 I покоління Japan Hatchback праве кермо',
        titleKey: 'nissan26',
      },
      {
        id: '1770',
        model: 'Leaf',
        year: ['2010', '2017'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/nissan/3758-250x150.jpg',
        title: 'Килимки в салон Nissan Leaf (ZE0) 2010 - 2017 I покоління USA Hatchback',
        titleKey: 'nissan27',
      },
      {
        id: '1771',
        model: 'Leaf',
        year: ['2017'],
        body: 'Hatchback',
        market: 'EU/USA',
        img: '/images/catalog/models/nissan/3760-250x150.jpg',
        title: 'Килимки в салон Nissan Leaf (ZE1) 2017 - … II покоління EU/USA Hatchback',
        titleKey: 'nissan28',
      },
      {
        id: '1772',
        model: 'Maxima',
        year: ['2004', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3763-250x150.jpg',
        title: 'Килимки в салон Nissan Maxima A34 2004 - 2008 VI покоління EU Sedan',
        titleKey: 'nissan29',
      },
      {
        id: '1773',
        model: 'Maxima',
        year: ['2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/nissan/3764-250x150.jpg',
        title: 'Килимки в салон Nissan Maxima A36 2015 - … VIII покоління USA Sedan',
        titleKey: 'nissan30',
      },

      {
        id: '1774',
        model: 'Maxima',
        year: ['1994', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3761-250x150.jpg',
        title: 'Килимки в салон Nissan Maxima QX A32 1994 - 2000 IV покоління EU Sedan',
        titleKey: 'nissan31',
      },
      {
        id: '1775',
        model: 'Maxima',
        year: ['2000', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3762-250x150.jpg',
        title: 'Килимки в салон Nissan Maxima QX A33 2000 - 2004 V покоління EU Sedan',
        titleKey: 'nissan32',
      },
      {
        id: '1776',
        model: 'Micra',
        year: ['2003', '2010'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/nissan/3768-250x150.jpg',
        title: 'Килимки в салон Nissan Micra C+C 2003 - 2010 III покоління EU Coupe',
        titleKey: 'nissan33',
      },
      {
        id: '1777',
        model: 'Micra',
        year: ['1993', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3765-250x150.jpg',
        title: 'Килимки в салон Nissan Micra K11 1993 - 2003 II покоління EU Hatchback',
        titleKey: 'nissan34',
      },
      {
        id: '1778',
        model: 'Micra',
        year: ['2003', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3767-250x150.jpg',
        title: 'Килимки в салон Nissan Micra K12 2003 - 2010 III покоління EU Hatchback 3-х дверна',
        titleKey: 'nissan35',
      },
      {
        id: '1779',
        model: 'Micra',
        year: ['2003', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3766-250x150.jpg',
        title: 'Килимки в салон Nissan Micra K12 2003 - 2010 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'nissan36',
      },
      {
        id: '1780',
        model: 'Micra',
        year: ['2010', '2016'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/nissan/3769-250x150.jpg',
        title: 'Килимки в салон Nissan Micra K13 2010 - 2016 IV покоління USA Hatchback',
        titleKey: 'nissan37',
      },
      {
        id: '1781',
        model: 'Micra',
        year: ['2013', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3770-250x150.jpg',
        title: 'Килимки в салон Nissan Micra K13 2013 - 2016 IV покоління EU Hatchback рест',
        titleKey: 'nissan38',
      },
      {
        id: '1782',
        model: 'Murano',
        year: ['2002', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3771-250x150.jpg',
        title: 'Килимки в салон Nissan Murano Z50 2002 - 2008 I покоління EU Crossover',
        titleKey: 'nissan39',
      },
      {
        id: '1783',
        model: 'Murano',
        year: ['2008', '2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/nissan/3772-250x150.jpg',
        title: 'Килимки в салон Nissan Murano Z51 2008 - 2014 II покоління EU/USA Crossover',
        titleKey: 'nissan40',
      },

      {
        id: '1784',
        model: 'Murano',
        year: ['2014', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3774-250x150.jpg',
        title: 'Килимки в салон Nissan Murano Z52 2014 - 2019 III покоління EU Crossover дорест',
        titleKey: 'nissan41',
      },
      {
        id: '1785',
        model: 'Murano',
        year: ['2014', '2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3773-250x150.jpg',
        title: 'Килимки в салон Nissan Murano Z52 2014 - 2019 III покоління USA Crossover дорест',
        titleKey: 'nissan42',
      },
      {
        id: '1786',
        model: 'Murano',
        year: ['2019'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3775-250x150.jpg',
        title: 'Килимки в салон Nissan Murano Z52 2019 - … III покоління USA Crossover рест',
        titleKey: 'nissan43',
      },
      {
        id: '1787',
        model: 'Navara',
        year: ['2005', '2010'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/nissan/3776-250x150.jpg',
        title: 'Килимки в салон Nissan Navara (Frontier) 2005 - 2010 III покоління USA Pickup дорест',
        titleKey: 'nissan44',
      },
      {
        id: '1788',
        model: 'Navara',
        year: ['2005', '2010'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/nissan/3777-250x150.jpg',
        title: 'Килимки в салон Nissan Navara D40 2005 - 2010 III покоління EU Pickup дорест 4-х дверна Double Cab',
        titleKey: 'nissan45',
      },
      {
        id: '1789',
        model: 'Navara',
        year: ['2005', '2021'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/nissan/3779-250x150.jpg',
        title: 'Килимки в салон Nissan Navara D40 2005 - 2021 III покоління EU Pickup 4-х дверна King Cab',
        titleKey: 'nissan46',
      },
      {
        id: '1790',
        model: 'Navara',
        year: ['2010', '2021'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/nissan/3778-250x150.jpg',
        title: 'Килимки в салон Nissan Navara D40 2010 - 2021 III покоління EU Pickup рест 4-х дверна Double Cab',
        titleKey: 'nissan47',
      },
      {
        id: '1791',
        model: 'Navara',
        year: ['1998'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/nissan/6014-250x150.jpg',
        title: 'Килимки в салон Nissan Navara Navara D22 1998 - … II покоління EU Pickup 4-х дверна Double Cab',
        titleKey: 'nissan48',
      },
      {
        id: '1792',
        model: 'Navara',
        year: ['2014'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/nissan/3780-250x150.jpg',
        title: 'Килимки в салон Nissan Navara NP300 D23 2014 - … IV покоління EU Pickup 4-х дверна Hybrid',
        titleKey: 'nissan49',
      },
      {
        id: '1793',
        model: 'Note',
        year: ['2016', '2020'],
        body: 'Minivan',
        market: 'Japan',
        img: '/images/catalog/models/nissan/3784-250x150.jpg',
        title: 'Килимки в салон Nissan Note e-Power E12 2016 - 2020 II покоління Japan Minivan Hybrid',
        titleKey: 'nissan50',
      },

      {
        id: '1794',
        model: 'Note',
        year: ['2004', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3781-250x150.jpg',
        title: 'Килимки в салон Nissan Note E11 2004 - 2013 I покоління EU Minivan Long',
        titleKey: 'nissan51',
      },
      {
        id: '1795',
        model: 'Note',
        year: ['2005', '2013'],
        body: 'Minivan',
        market: 'Japan',
        img: '/images/catalog/models/nissan/3782-250x150.jpg',
        title: 'Килимки в салон Nissan Note E11 2005 - 2013 I покоління Japan Minivan King Cab',
        titleKey: 'nissan52',
      },
      {
        id: '1796',
        model: 'Note',
        year: ['2012', '2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3783-250x150.jpg',
        title: 'Килимки в салон Nissan Note E12 2012 - 2020 II покоління EU Minivan Hybrid',
        titleKey: 'nissan53',
      },
      {
        id: '1797',
        model: 'NV200',
        year: ['2009'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3785-250x150.jpg',
        title: 'Килимки в салон Nissan NV200 2009 - … I покоління EU Minivan',
        titleKey: 'nissan54',
      },
      {
        id: '1798',
        model: 'NV400',
        year: ['2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3787-250x150.jpg',
        title: 'Килимки в салон Nissan NV400 2010 - … I покоління EU Minivan',
        titleKey: 'nissan55',
      },
      {
        id: '1799',
        model: 'Pathfinder',
        year: ['1996', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3788-250x150.jpg',
        title: 'Килимки в салон Nissan Pathfinder R50 1996 - 2005 II покоління EU Crossover',
        titleKey: 'nissan56',
      },
      {
        id: '1800',
        model: 'Pathfinder',
        year: ['2004', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3792-250x150.jpg',
        title: 'Килимки в салон Nissan Pathfinder R51 2004 - 2010 III покоління EU Crossover дорест 5-ти місна',
        titleKey: 'nissan57',
      },
      {
        id: '1801',
        model: 'Pathfinder',
        year: ['2004', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3791-250x150.jpg',
        title: 'Килимки в салон Nissan Pathfinder R51 2004 - 2010 III покоління EU Crossover дорест 7-ми місна',
        titleKey: 'nissan58',
      },
      {
        id: '1802',
        model: 'Pathfinder',
        year: ['2010', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3793-250x150.jpg',
        title: 'Килимки в салон Nissan Pathfinder R51 2010 - 2014 III покоління EU Crossover рест 5-ти місна',
        titleKey: 'nissan59',
      },
      {
        id: '1803',
        model: 'Pathfinder',
        year: ['2010', '2014'],
        body: 'Crossover рест 7-ми місна',
        market: 'EU',
        img: '/images/catalog/models/nissan/3789-250x150.jpg',
        title: 'Килимки в салон Nissan Pathfinder R51 2010 - 2014 III покоління EU Crossover рест 7-ми місна',
        titleKey: 'nissan60',
      },

      {
        id: '1804',
        model: 'Pathfinder',
        year: ['2004', '2014'],
        body: 'Crossover',
        market: 'UK',
        img: '/images/catalog/models/nissan/3790-250x150.jpg',
        title:
          'Килимки в салон Nissan Pathfinder R51 Right hand Drive 2004 - 2014 III покоління UK Crossover праве кермо',
        titleKey: 'nissan61',
      },
      {
        id: '1805',
        model: 'Pathfinder',
        year: ['2012', '2021'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/nissan/3795-250x150.jpg',
        title: 'Килимки в салон Nissan Pathfinder R52 2012 - 2021 IV покоління EU/USA Crossover 5-ти місна',
        titleKey: 'nissan62',
      },
      {
        id: '1806',
        model: 'Pathfinder',
        year: ['2012', '2021'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/nissan/3794-250x150.jpg',
        title: 'Килимки в салон Nissan Pathfinder R52 2012 - 2021 IV покоління EU/USA Crossover 7-ми місна',
        titleKey: 'nissan63',
      },
      {
        id: '1807',
        model: 'Patrol',
        year: ['1998', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3797-250x150.jpg',
        title: 'Килимки в салон Nissan Patrol Y-61 1998 - 2010 V покоління EU Crossover 5-ти дверна 5-ти місна',
        titleKey: 'nissan64',
      },
      {
        id: '1808',
        model: 'Patrol',
        year: ['1998', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3796-250x150.jpg',
        title: 'Килимки в салон Nissan Patrol Y-61 1998 - 2010 V покоління EU Crossover 5-ти дверна 7-ми місна',
        titleKey: 'nissan65',
      },
      {
        id: '1809',
        model: 'Patrol',
        year: ['2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3798-250x150.jpg',
        title: 'Килимки в салон Nissan Patrol Y-62 2010 - … VI покоління EU Crossover',
        titleKey: 'nissan66',
      },
      {
        id: '1810',
        model: 'Pixo',
        year: ['2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3799-250x150.jpg',
        title: 'Килимки в салон Nissan Pixo 2009 - … покоління EU Hatchback',
        titleKey: 'nissan67',
      },
      {
        id: '1811',
        model: 'Primastar',
        year: ['2006', '2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/nissan/3801-250x150.jpg',
        title: 'Килимки в салон Nissan Primastar 2006 - 2016 I покоління EU VAN',
        titleKey: 'nissan68',
      },
      {
        id: '1812',
        model: 'Primera',
        year: ['1990', '1996'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3802-250x150.jpg',
        title: 'Килимки в салон Nissan Primera P10 1990 - 1996 I покоління EU Sedan',
        titleKey: 'nissan69',
      },
      {
        id: '1813',
        model: 'Primera',
        year: ['1990', '1996'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/nissan/3803-250x150.jpg',
        title: 'Килимки в салон Nissan Primera P10 1990 - 1996 I покоління EU Universal',
        titleKey: 'nissan70',
      },

      {
        id: '1814',
        model: 'Primera',
        year: ['1996', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3804-250x150.jpg',
        title: 'Килимки в салон Nissan Primera P11 1996 - 2002 II покоління EU Sedan',
        titleKey: 'nissan71',
      },
      {
        id: '1815',
        model: 'Primera',
        year: ['1996', '2002'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/nissan/3805-250x150.jpg',
        title: 'Килимки в салон Nissan Primera P11 1996 - 2002 II покоління EU Universal',
        titleKey: 'nissan72',
      },
      {
        id: '1816',
        model: 'Primera',
        year: ['2002', '2007'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3807-250x150.jpg',
        title: 'Килимки в салон Nissan Primera P12 2002 - 2007 III покоління EU Liftback',
        titleKey: 'nissan73',
      },
      {
        id: '1817',
        model: 'Primera',
        year: ['2002', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3806-250x150.jpg',
        title: 'Килимки в салон Nissan Primera P12 2002 - 2007 III покоління EU Sedan',
        titleKey: 'nissan74',
      },
      {
        id: '1818',
        model: 'Primera',
        year: ['2002', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/nissan/3808-250x150.jpg',
        title: 'Килимки в салон Nissan Primera P12 2002 - 2007 III покоління EU Universal',
        titleKey: 'nissan75',
      },
      {
        id: '1819',
        model: 'Qashqai',
        year: ['2008', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3816-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai +2 2008 - 2013 I покоління EU Crossover 7-ми місна',
        titleKey: 'nissan76',
      },
      {
        id: '1820',
        model: 'Qashqai',
        year: ['2007', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3809-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai J10 2007 - 2010 I покоління EU Crossover дорест',
        titleKey: 'nissan77',
      },
      {
        id: '1821',
        model: 'Qashqai',
        year: ['2007', '2013'],
        body: 'Crossover',
        market: 'Arabic',
        img: '/images/catalog/models/nissan/3810-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai J10 2007 - 2013 I покоління Arabic Crossover',
        titleKey: 'nissan78',
      },
      {
        id: '1822',
        model: 'Qashqai',
        year: ['2010', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3811-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai J10 2010 - 2013 I покоління EU Crossover рест',
        titleKey: 'nissan79',
      },
      {
        id: '1823',
        model: 'Qashqai',
        year: ['2013', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3813-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai J11 2013 - 2017 II покоління EU Crossover дорест',
        titleKey: 'nissan80',
      },

      {
        id: '1824',
        model: 'Qashqai',
        year: ['2014', '2017'],
        body: 'Crossover',
        market: 'RU',
        img: '/images/catalog/models/nissan/3814-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai J11 2014 - 2017 II покоління RU Crossover',
        titleKey: 'nissan81',
      },
      {
        id: '1825',
        model: 'Qashqai',
        year: ['2017', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3812-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai J11 2017 - 2021 II покоління EU Crossover рест',
        titleKey: 'nissan82',
      },
      {
        id: '1826',
        model: 'Qashqai',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3815-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai J12 2021 - … III покоління EU Crossover',
        titleKey: 'nissan83',
      },
      {
        id: '1827',
        model: 'Qashqai',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3815-250x150.jpg',
        title: 'Килимки в салон Nissan Qashqai J12 2021 - … III покоління EU Crossover Hybrid',
        titleKey: 'nissan84',
      },
      {
        id: '1828',
        model: 'Quest',
        year: ['2010', '2016'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/nissan/3817-250x150.jpg',
        title: 'Килимки в салон Nissan Quest 2010 - 2016 IV покоління USA Minivan 7-ми місна',
        titleKey: 'nissan85',
      },
      {
        id: '1829',
        model: 'Quest',
        year: ['2010'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/nissan/3818-250x150.jpg',
        title: 'Килимки в салон Nissan Quest 2010 - … IV покоління USA Minivan',
        titleKey: 'nissan86',
      },
      {
        id: '1830',
        model: 'Rogue',
        year: ['2007', '2013'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3819-250x150.jpg',
        title: 'Килимки в салон Nissan Rogue 2007 - 2013 I покоління USA Crossover',
        titleKey: 'nissan87',
      },
      {
        id: '1831',
        model: 'Rogue',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3820-250x150.jpg',
        title: 'Килимки в салон Nissan Rogue Sport 2016 - … I покоління USA Crossover',
        titleKey: 'nissan88',
      },
      {
        id: '1832',
        model: 'Rogue',
        year: ['2013', '2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3821-250x150.jpg',
        title: 'Килимки в салон Nissan Rogue T32 2013 - 2020 II покоління USA Crossover',
        titleKey: 'nissan89',
      },
      {
        id: '1833',
        model: 'Rogue',
        year: ['2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3822-250x150.jpg',
        title: 'Килимки в салон Nissan Rogue T33 2020 - … III покоління USA Crossover',
        titleKey: 'nissan90',
      },

      {
        id: '1834',
        model: 'Sentra',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/5957-250x150.jpg',
        title: 'Килимки в салон Nissan Sentra B15 2000 - 2006 V покоління EU Sedan',
        titleKey: 'nissan91',
      },
      {
        id: '1835',
        model: 'Sentra',
        year: ['2006', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Nissan Sentra B16 2006 - 2012 VI покоління EU Sedan',
        titleKey: 'nissan92',
      },
      {
        id: '1836',
        model: 'Sentra',
        year: ['2012', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3823-250x150.jpg',
        title: 'Килимки в салон Nissan Sentra B17 2012 - 2019 VII покоління EU Sedan',
        titleKey: 'nissan93',
      },
      {
        id: '1837',
        model: 'Sentra',
        year: ['2014', '2017'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/nissan/3824-250x150.jpg',
        title: 'Килимки в салон Nissan Sentra B17 2014 - 2017 VII покоління RU Sedan',
        titleKey: 'nissan94',
      },
      {
        id: '1838',
        model: 'Sentra',
        year: ['2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/nissan/3825-250x150.jpg',
        title: 'Килимки в салон Nissan Sentra B18 2019 - … VIII покоління USA Sedan',
        titleKey: 'nissan95',
      },
      {
        id: '1839',
        model: 'Silvia',
        year: ['1984', '1988'],
        body: 'Coupe',
        market: 'Japan',
        img: '/images/catalog/models/nissan/3826-250x150.jpg',
        title: 'Килимки в салон Nissan Silvia S12 1984 - 1988 IV покоління Japan Coupe',
        titleKey: 'nissan96',
      },
      {
        id: '1840',
        model: 'Sunny',
        year: ['2006', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3830-250x150.jpg',
        title: 'Килимки в салон Nissan Sunny 2006 - 2012 покоління EU Sedan',
        titleKey: 'nissan97',
      },
      {
        id: '1841',
        model: 'Sunny',
        year: ['1985', '1990'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3829-250x150.jpg',
        title: 'Килимки в салон Nissan Sunny B12 1985 - 1990 VI покоління EU Hatchback',
        titleKey: 'nissan98',
      },
      {
        id: '1842',
        model: 'Sunny',
        year: ['1990', '1995'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3828-250x150.jpg',
        title: 'Килимки в салон Nissan Sunny N14 1990 - 1995 II покоління EU Hatchback',
        titleKey: 'nissan99',
      },
      {
        id: '1843',
        model: 'Sunny',
        year: ['2010'],
        body: 'Sedan',
        market: 'UAE',
        img: '/images/catalog/models/nissan/3827-250x150.jpg',
        title: 'Килимки в салон Nissan Sunny N17 2010 - … I покоління UAE Sedan',
        titleKey: 'nissan100',
      },

      {
        id: '1844',
        model: 'Sylphy',
        year: ['2019'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/nissan/3831-250x150.jpg',
        title: 'Килимки в салон Nissan Sylphy e-Power (B18) 2019 - … VIII покоління China Sedan',
        titleKey: 'nissan101',
      },
      {
        id: '1845',
        model: 'Teana',
        year: ['2003', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3832-250x150.jpg',
        title: 'Килимки в салон Nissan Teana J31 2003 - 2008 I покоління EU Sedan',
        titleKey: 'nissan102',
      },
      {
        id: '1846',
        model: 'Teana',
        year: ['2008', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3833-250x150.jpg',
        title: 'Килимки в салон Nissan Teana J32 2008 - 2013 II покоління EU Sedan',
        titleKey: 'nissan103',
      },
      {
        id: '1847',
        model: 'Teana',
        year: ['2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3834-250x150.jpg',
        title: 'Килимки в салон Nissan Teana L33 2013 - … III покоління EU Sedan',
        titleKey: 'nissan104',
      },
      {
        id: '1848',
        model: 'Terrano',
        year: ['1985', '1995'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/nissan/3835-250x150.jpg',
        title: 'Килимки в салон Nissan Terrano 1985 - 1995 I покоління USA Crossover',
        titleKey: 'nissan105',
      },
      {
        id: '1849',
        model: 'Terrano',
        year: ['1996', '2004'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3836-250x150.jpg',
        title: 'Килимки в салон Nissan Terrano R50 1996 - 2004 II покоління EU Crossover',
        titleKey: 'nissan106',
      },
      {
        id: '1850',
        model: 'Tiida',
        year: ['2004', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3837-250x150.jpg',
        title: 'Килимки в салон Nissan Tiida C11 2004 - 2011 I покоління EU Hatchback',
        titleKey: 'nissan107',
      },
      {
        id: '1851',
        model: 'Tiida',
        year: ['2004', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/nissan/3838-250x150.jpg',
        title: 'Килимки в салон Nissan Tiida C11 2004 - 2011 I покоління EU Sedan',
        titleKey: 'nissan108',
      },
      {
        id: '1852',
        model: 'Tiida',
        year: ['2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/nissan/3839-250x150.jpg',
        title: 'Килимки в салон Nissan Tiida C12 2011 - … II покоління EU Hatchback',
        titleKey: 'nissan109',
      },
      {
        id: '1853',
        model: 'Titan',
        year: ['2004', '2015'],
        body: 'Рickup',
        market: 'USA',
        img: '/images/catalog/models/nissan/3840-250x150.jpg',
        title: 'Килимки в салон Nissan Titan TA60 2004 - 2015 I покоління USA Рickup 4-х дверна King Cab',
        titleKey: 'nissan110',
      },

      {
        id: '1854',
        model: 'Titan',
        year: ['2015', '2020'],
        body: 'Рickup',
        market: 'USA',
        img: '/images/catalog/models/nissan/3841-250x150.jpg',
        title: 'Килимки в салон Nissan Titan TA61 2015 - 2020 II покоління USA Рickup дорест 2-х дверна',
        titleKey: 'nissan111',
      },
      {
        id: '1855',
        model: 'Versa',
        year: ['2014', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/nissan/3842-250x150.jpg',
        title: 'Килимки в салон Nissan Versa C17 2014 - 2019 II покоління USA Sedan рест',
        titleKey: 'nissan112',
      },
      {
        id: '1856',
        model: 'Versa Note',
        year: ['2013', '2019'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/nissan/3843-250x150.jpg',
        title: 'Килимки в салон Nissan Versa Note 2013 - 2019 II покоління USA Hatchback',
        titleKey: 'nissan113',
      },
      {
        id: '1857',
        model: 'X-Trail',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/6013-250x150.jpg',
        title: 'Килимки в салон Nissan X-Trail (T33) e-Power 2021 - … IV покоління EU Crossover 5-ти місна',
        titleKey: 'nissan114',
      },
      {
        id: '1858',
        model: 'X-Trail',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/6012-250x150.jpg',
        title: 'Килимки в салон Nissan X-Trail (T33) e-Power 2021 - … IV покоління EU Crossover 7-ми місна',
        titleKey: 'nissan115',
      },
      {
        id: '1859',
        model: 'X-Trail',
        year: ['2001', '2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3844-250x150.jpg',
        title: 'Килимки в салон Nissan X-Trail T30 2001 - 2007 I покоління EU Crossover',
        titleKey: 'nissan116',
      },
      {
        id: '1860',
        model: 'X-Trail',
        year: ['2007', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3845-250x150.jpg',
        title: 'Килимки в салон Nissan X-Trail T31 2007 - 2014 II покоління EU Crossover',
        titleKey: 'nissan117',
      },
      {
        id: '1861',
        model: 'X-Trail',
        year: ['2013', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3846-250x150.jpg',
        title: 'Килимки в салон Nissan X-Trail T32 2013 - 2017 III покоління EU Crossover дорест',
        titleKey: 'nissan118',
      },
      {
        id: '1862',
        model: 'X-Trail',
        year: ['2017', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3847-250x150.jpg',
        title: 'Килимки в салон Nissan X-Trail T32 2017 - 2021 III покоління EU Crossover рест',
        titleKey: 'nissan119',
      },
      {
        id: '1863',
        model: 'X-Trail',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3849-250x150.jpg',
        title: 'Килимки в салон Nissan X-Trail T33 2021 - … IV покоління EU Crossover 5-ти місна Hybrid',
        titleKey: 'nissan120',
      },
      {
        id: '1864',
        model: 'X-Trail',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/nissan/3848-250x150.jpg',
        title: 'Килимки в салон Nissan X-Trail T33 2021 - … IV покоління EU Crossover 7-ми місна Hybrid',
        titleKey: 'nissan121',
      },
    ],
  },
  /* ---------------------------------- Opel ---------------------------------- */
  Opel: {
    logo: '/images/catalog/logo/opel-100x100.png',
    name: 'Opel',
    models: [
      {
        id: '1865',
        model: 'Agila B',
        year: ['2007', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4489-250x150.jpg',
        title: 'Килимки в салон Opel Agila B 2007 - 2015 II покоління EU Hatchback',
        titleKey: 'opel1',
      },
      {
        id: '1866',
        model: 'Ampera',
        year: ['2011', '2016'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/opel/4490-250x150.jpg',
        title: 'Килимки в салон Opel Ampera 2011 - 2016 I покоління EU Liftback Hybrid',
        titleKey: 'opel2',
      },
      {
        id: '1867',
        model: 'Ampera',
        year: ['2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/6025-250x150.jpg',
        title: 'Килимки в салон Opel Ampera-е 2016 - ... I покоління EU Hatchback Electro',
        titleKey: 'opel3',
      },
      {
        id: '1868',
        model: 'Antara',
        year: ['2010', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4491-250x150.jpg',
        title: 'Килимки в салон Opel Antara 2010 - 2017 I покоління EU Crossover рест',
        titleKey: 'opel4',
      },
      {
        id: '1869',
        model: 'Antara',
        year: ['2006', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4492-250x150.jpg',
        title: 'Килимки в салон Opel Antara 2006 - 2010 I покоління EU Crossover дорест',
        titleKey: 'opel5',
      },
      {
        id: '1870',
        model: 'Ascona',
        year: ['1986', '1988'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4493-250x150.jpg',
        title: 'Килимки в салон Opel Ascona C3 1986 - 1988 III покоління EU Sedan рест',
        titleKey: 'opel6',
      },
      {
        id: '1871',
        model: 'Astra',
        year: ['1991', '1998'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4494-250x150.jpg',
        title: 'Килимки в салон Opel Astra F 1991 - 1998 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'opel7',
      },
      {
        id: '1872',
        model: 'Astra',
        year: ['1991', '1998'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4495-250x150.jpg',
        title: 'Килимки в салон Opel Astra F 1991 - 1998 I покоління EU Universal',
        titleKey: 'opel8',
      },
      {
        id: '1873',
        model: 'Astra',
        year: ['1991', '1998'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4496-250x150.jpg',
        title: 'Килимки в салон Opel Astra F 1991 - 1998 I покоління EU Hatchback 3-х дверна',
        titleKey: 'opel9',
      },
      {
        id: '1874',
        model: 'Astra',
        year: ['1998', '2009'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/opel/4497-250x150.jpg',
        title: 'Килимки в салон Opel Аstra G 1998 - 2009 II покоління EU Cabriolet 2-х дверна',
        titleKey: 'opel10',
      },

      {
        id: '1875',
        model: 'Astra',
        year: ['1998', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4498-250x150.jpg',
        title: 'Килимки в салон Opel Astra G 1998 - 2009 II покоління EU Sedan',
        titleKey: 'opel11',
      },
      {
        id: '1876',
        model: 'Astra',
        year: ['1998', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4499-250x150.jpg',
        title: 'Килимки в салон Opel Astra G 1998 - 2009 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'opel12',
      },
      {
        id: '1877',
        model: 'Astra',
        year: ['1998', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4500-250x150.jpg',
        title: 'Килимки в салон Opel Astra G 1998 - 2009 II покоління EU Universal',
        titleKey: 'opel13',
      },
      {
        id: '1878',
        model: 'Astra',
        year: ['1998', '2009'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/opel/4501-250x150.jpg',
        title: 'Килимки в салон Opel Astra G Bertone 1998 - 2009 II покоління EU Coupe',
        titleKey: 'opel14',
      },
      {
        id: '1879',
        model: 'Astra',
        year: ['2004', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4502-250x150.jpg',
        title: 'Килимки в салон Opel Astra GTC 2004 - 2014 II покоління EU Hatchback',
        titleKey: 'opel15',
      },
      {
        id: '1880',
        model: 'Astra',
        year: ['2004', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4503-250x150.jpg',
        title: 'Килимки в салон Opel Astra H 2004 - 2014 III покоління EU Sedan',
        titleKey: 'opel16',
      },
      {
        id: '1881',
        model: 'Astra',
        year: ['2006', '2010'],
        body: 'Cabrio',
        market: 'EU',
        img: '/images/catalog/models/opel/6015-250x150.jpg',
        title: 'Килимки в салон Opel Astra H TwinTop 2006 - 2010 III покоління EU Cabrio',
        titleKey: 'opel17',
      },
      {
        id: '1882',
        model: 'Astra',
        year: ['2007', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4504-250x150.jpg',
        title: 'Килимки в салон Opel Astra H 2007 - 2014 III покоління EU Universal рест',
        titleKey: 'opel18',
      },
      {
        id: '1883',
        model: 'Astra',
        year: ['2007', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4505-250x150.jpg',
        title: 'Килимки в салон Opel Astra H 2007 - 2014 III покоління EU Hatchback рест 5-ти дверна',
        titleKey: 'opel19',
      },
      {
        id: '1884',
        model: 'Astra',
        year: ['2004', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4506-250x150.jpg',
        title: 'Килимки в салон Opel Astra H 2004 - 2007 III покоління EU Universal дорест',
        titleKey: 'opel20',
      },

      {
        id: '1885',
        model: 'Astra',
        year: ['2004', '2007'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4507-250x150.jpg',
        title: 'Килимки в салон Opel Astra H 2004 - 2007 III покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'opel21',
      },
      {
        id: '1886',
        model: 'Astra',
        year: ['2012', '2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4508-250x150.jpg',
        title: 'Килимки в салон Opel Astra J 2012 - 2015 IV покоління EU Universal рест 5-ти дверна',
        titleKey: 'opel22',
      },
      {
        id: '1887',
        model: 'Astra',
        year: ['2009', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4509-250x150.jpg',
        title: 'Килимки в салон Opel Astra J 2009 - 2015 IV покоління EU Hatchback 5-ти дверна',
        titleKey: 'opel23',
      },
      {
        id: '1888',
        model: 'Astra',
        year: ['2012', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4510-250x150.jpg',
        title: 'Килимки в салон Opel Astra J GTC 2012 - 2018 IV покоління EU Hatchback 3-х дверна',
        titleKey: 'opel24',
      },
      {
        id: '1889',
        model: 'Astra',
        year: ['2009', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4511-250x150.jpg',
        title: 'Килимки в салон Opel Astra J 2009 - 2012 IV покоління EU Universal дорест 5-ти дверна',
        titleKey: 'opel25',
      },
      {
        id: '1890',
        model: 'Astra',
        year: ['2009', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4512-250x150.jpg',
        title: 'Килимки в салон Opel Astra J 2009 - 2015 IV покоління EU Sedan',
        titleKey: 'opel26',
      },
      {
        id: '1891',
        model: 'Astra',
        year: ['2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4513-250x150.jpg',
        title: 'Килимки в салон Opel Astra K 2015 - … V покоління EU Universal',
        titleKey: 'opel27',
      },
      {
        id: '1892',
        model: 'Astra',
        year: ['2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4514-250x150.jpg',
        title: 'Килимки в салон Opel Astra K 2015 - … V покоління EU Hatchback',
        titleKey: 'opel28',
      },
      {
        id: '1893',
        model: 'Calibra',
        year: ['1989', '1997'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/opel/4515-250x150.jpg',
        title: 'Килимки в салон Opel Calibra 1989 - 1997 I покоління EU Coupe',
        titleKey: 'opel29',
      },
      {
        id: '1894',
        model: 'Combo',
        year: ['1994', '2001'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4516-250x150.jpg',
        title: 'Килимки в салон Opel Combo B 1994 - 2001 I покоління EU Minivan',
        titleKey: 'opel30',
      },

      {
        id: '1895',
        model: 'Combo',
        year: ['2001', '2011'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4517-250x150.jpg',
        title: 'Килимки в салон Opel Combo C 2001 - 2011 II покоління EU Minivan',
        titleKey: 'opel31',
      },
      {
        id: '1896',
        model: 'Combo',
        year: ['2011', '2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4518-250x150.jpg',
        title: 'Килимки в салон Opel Combo D 2011 - 2018 IV покоління EU Minivan',
        titleKey: 'opel32',
      },
      {
        id: '1897',
        model: 'Combo',
        year: ['2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4519-250x150.jpg',
        title: 'Килимки в салон Opel Combo E Life 2018 - … V покоління EU Minivan short',
        titleKey: 'opel33',
      },
      {
        id: '1898',
        model: 'Corsa',
        year: ['1982', '1993'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/opel/4520-250x150.jpg',
        title: 'Килимки в салон Opel Corsa A 1982 - 1993 I покоління EU Coupe',
        titleKey: 'opel34',
      },
      {
        id: '1899',
        model: 'Corsa',
        year: ['1993', '2000'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4521-250x150.jpg',
        title: 'Килимки в салон Opel Corsa B 1993 - 2000 II покоління EU Hatchback 3-х дверна',
        titleKey: 'opel35',
      },
      {
        id: '1900',
        model: 'Corsa',
        year: ['2000', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4522-250x150.jpg',
        title: 'Килимки в салон Opel Corsa C 2000 - 2006 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'opel36',
      },
      {
        id: '1901',
        model: 'Corsa',
        year: ['2006', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4523-250x150.jpg',
        title: 'Килимки в салон Opel Corsa D 2006 - 2014 IV покоління EU Hatchback 5-ти дверна',
        titleKey: 'opel37',
      },
      {
        id: '1902',
        model: 'Corsa',
        year: ['2006', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4524-250x150.jpg',
        title: 'Килимки в салон Opel Corsa D 2006 - 2014 IV покоління EU Hatchback 3-х дверна',
        titleKey: 'opel38',
      },
      {
        id: '1903',
        model: 'Corsa',
        year: ['2014', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4525-250x150.jpg',
        title: 'Килимки в салон Opel Corsa Е 2014 - 2019 V покоління EU Hatchback 5-ти дверна',
        titleKey: 'opel39',
      },
      {
        id: '1904',
        model: 'Corsa',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4526-250x150.jpg',
        title: 'Килимки в салон Opel Corsa F 2019 - … VI покоління EU Hatchback 5-ти дверна',
        titleKey: 'opel40',
      },

      {
        id: '1905',
        model: 'Crossland X',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4527-250x150.jpg',
        title: 'Килимки в салон Opel Crossland X 2017 - … I покоління EU Crossover дорест',
        titleKey: 'opel41',
      },
      {
        id: '1906',
        model: 'Frontera A',
        year: ['1991', '1998'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4528-250x150.jpg',
        title: 'Килимки в салон Opel Frontera A 1991 - 1998 I покоління EU Crossover 5-ти дверна',
        titleKey: 'opel42',
      },
      {
        id: '1907',
        model: 'Frontera A',
        year: ['1989', '1998'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4529-250x150.jpg',
        title: 'Килимки в салон Opel Frontera A Sport 1989 - 1998 I покоління EU Crossover 3-х дверна',
        titleKey: 'opel43',
      },
      {
        id: '1908',
        model: 'Frontera B',
        year: ['1998', '2004'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4530-250x150.jpg',
        title: 'Килимки в салон Opel Frontera B 1998 - 2004 II покоління EU Crossover 5-ти дверна',
        titleKey: 'opel44',
      },
      {
        id: '1909',
        model: 'Grandland X',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4531-250x150.jpg',
        title: 'Килимки в салон Opel Grandland X 2017 - … I покоління EU Crossover',
        titleKey: 'opel45',
      },
      {
        id: '1910',
        model: 'Insignia',
        year: ['2008', '2013'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4532-250x150.jpg',
        title: 'Килимки в салон Opel Insignia G09 2008 - 2013 I покоління EU Universal дорест',
        titleKey: 'opel46',
      },
      {
        id: '1911',
        model: 'Insignia',
        year: ['2008', '2017'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/opel/4533-250x150.jpg',
        title: 'Килимки в салон Opel Insignia G09 2008 - 2017 I покоління EU Liftback',
        titleKey: 'opel47',
      },
      {
        id: '1912',
        model: 'Insignia',
        year: ['2013', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4534-250x150.jpg',
        title: 'Килимки в салон Opel Insignia G09 2013 - 2017 I покоління EU Sedan рест',
        titleKey: 'opel48',
      },
      {
        id: '1913',
        model: 'Insignia',
        year: ['2013', '2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4535-250x150.jpg',
        title: 'Килимки в салон Opel Insignia G09 2013 - 2017 I покоління EU Universal рест',
        titleKey: 'opel49',
      },
      {
        id: '1914',
        model: 'Insignia',
        year: ['2008', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4536-250x150.jpg',
        title: 'Килимки в салон Opel Insignia G09 2008 - 2013 I покоління EU Sedan дорест',
        titleKey: 'opel50',
      },

      {
        id: '1915',
        model: 'Insignia',
        year: ['2017'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/opel/4537-250x150.jpg',
        title: 'Килимки в салон Opel Insignia 2017 - … II покоління EU Liftback',
        titleKey: 'opel51',
      },
      {
        id: '1916',
        model: 'Insignia',
        year: ['2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4538-250x150.jpg',
        title: 'Килимки в салон Opel Insignia Country Tourer 2017 - … II покоління EU Universal',
        titleKey: 'opel52',
      },
      {
        id: '1917',
        model: 'Kadett E',
        year: ['1985', '1991'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4539-250x150.jpg',
        title: 'Килимки в салон Opel Kadett E 1985 - 1991 VI покоління EU Sedan',
        titleKey: 'opel53',
      },
      {
        id: '1918',
        model: 'Kadett E',
        year: ['1984', '1989'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4540-250x150.jpg',
        title: 'Килимки в салон Opel Kadett E 1984 - 1989 VI покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'opel54',
      },
      {
        id: '1919',
        model: 'Kadett E',
        year: ['1984', '1989'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4541-250x150.jpg',
        title: 'Килимки в салон Opel Kadett E 1984 - 1989 VI покоління EU Hatchback дорест 3-х дверна',
        titleKey: 'opel55',
      },
      {
        id: '1920',
        model: 'Meriva',
        year: ['2002', '2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4542-250x150.jpg',
        title: 'Килимки в салон Opel Meriva A 2002 - 2010 I покоління EU Minivan',
        titleKey: 'opel56',
      },
      {
        id: '1921',
        model: 'Meriva',
        year: ['2010', '2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4543-250x150.jpg',
        title: 'Килимки в салон Opel Meriva B 2010 - 2018 II покоління EU Minivan',
        titleKey: 'opel57',
      },
      {
        id: '1922',
        model: 'Mokka',
        year: ['2012', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4544-250x150.jpg',
        title: 'Килимки в салон Opel Mokka/ Mokka X 2012 - 2021 I покоління EU Crossover',
        titleKey: 'opel58',
      },
      {
        id: '1923',
        model: 'Mokka',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4545-250x150.jpg',
        title: 'Килимки в салон Opel Mokka 2021 - … II покоління EU Crossover',
        titleKey: 'opel59',
      },
      {
        id: '1924',
        model: 'Mokka',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4546-250x150.jpg',
        title: 'Килимки в салон Opel Mokka-e 2021 - … II покоління EU Crossover',
        titleKey: 'opel60',
      },

      {
        id: '1925',
        model: 'Monterey',
        year: ['1992', '1998'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/opel/4547-250x150.jpg',
        title: 'Килимки в салон Opel Monterey 1992 - 1998 II покоління EU Crossover дорест 5-ти дверна',
        titleKey: 'opel61',
      },
      {
        id: '1926',
        model: 'Movano',
        year: ['1998', '2010'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/opel/4548-250x150.jpg',
        title: 'Килимки в салон Opel Movano A 1998 - 2010 I покоління EU VAN',
        titleKey: 'opel62',
      },
      {
        id: '1927',
        model: 'Movano',
        year: ['2010'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/opel/4549-250x150.jpg',
        title: 'Килимки в салон Opel Movano B 2010 - … II покоління EU VAN',
        titleKey: 'opel63',
      },
      {
        id: '1928',
        model: 'Omega',
        year: ['1986', '1993'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4550-250x150.jpg',
        title: 'Килимки в салон Opel Omega A 1986 - 1993 I покоління EU Sedan',
        titleKey: 'opel64',
      },
      {
        id: '1929',
        model: 'Omega',
        year: ['1986', '1993'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4551-250x150.jpg',
        title: 'Килимки в салон Opel Omega A 1986 - 1993 I покоління EU Universal дорест',
        titleKey: 'opel65',
      },
      {
        id: '1930',
        model: 'Omega',
        year: ['1994', '2003'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4552-250x150.jpg',
        title: 'Килимки в салон Opel Omega B 1994 - 2003 II покоління EU Sedan',
        titleKey: 'opel66',
      },
      {
        id: '1931',
        model: 'Omega',
        year: ['1994', '2003'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4553-250x150.jpg',
        title: 'Килимки в салон Opel Omega B 1994 - 2003 II покоління EU Universal',
        titleKey: 'opel67',
      },
      {
        id: '1932',
        model: 'Rekord E2',
        year: ['1982', '1986'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4554-250x150.jpg',
        title: 'Килимки в салон Opel Rekord E2 1982 - 1986 VII покоління EU Sedan',
        titleKey: 'opel68',
      },
      {
        id: '1933',
        model: 'Signum',
        year: ['2003', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4555-250x150.jpg',
        title: 'Килимки в салон Opel Signum 2003 - 2008 I покоління EU Hatchback',
        titleKey: 'opel69',
      },
      {
        id: '1934',
        model: 'Sintra',
        year: ['1996', '1999'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4556-250x150.jpg',
        title: 'Килимки в салон Opel Sintra 1996 - 1999 I покоління EU Minivan',
        titleKey: 'opel70',
      },

      {
        id: '1935',
        model: 'Tigra',
        year: ['2004', '2009'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/opel/4557-250x150.jpg',
        title: 'Килимки в салон Opel Tigra B 2004 - 2009 II покоління EU Coupe',
        titleKey: 'opel71',
      },
      {
        id: '1936',
        model: 'Vectra',
        year: ['1988', '1995'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/opel/4558-250x150.jpg',
        title: 'Килимки в салон Opel Vectra A 1988 - 1995 I покоління EU Hatchback',
        titleKey: 'opel72',
      },
      {
        id: '1937',
        model: 'Vectra',
        year: ['1988', '1995'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4559-250x150.jpg',
        title: 'Килимки в салон Opel Vectra A 1988 - 1995 I покоління EU Sedan',
        titleKey: 'opel73',
      },
      {
        id: '1938',
        model: 'Vectra',
        year: ['1995', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4560-250x150.jpg',
        title: 'Килимки в салон Opel Vectra B 1995 - 2002 II покоління EU Sedan',
        titleKey: 'opel74',
      },
      {
        id: '1939',
        model: 'Vectra',
        year: ['1995', '2002'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/opel/4561-250x150.jpg',
        title: 'Килимки в салон Opel Vectra B 1995 - 2002 II покоління EU Liftback',
        titleKey: 'opel75',
      },
      {
        id: '1940',
        model: 'Vectra',
        year: ['1995', '2002'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4562-250x150.jpg',
        title: 'Килимки в салон Opel Vectra B 1995 - 2002 II покоління EU Universal',
        titleKey: 'opel76',
      },
      {
        id: '1941',
        model: 'Vectra',
        year: ['2002', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/opel/4563-250x150.jpg',
        title: 'Килимки в салон Opel Vectra C 2002 - 2008 III покоління EU Sedan',
        titleKey: 'opel77',
      },
      {
        id: '1942',
        model: 'Vectra',
        year: ['2002', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/opel/4564-250x150.jpg',
        title: 'Килимки в салон Opel Vectra C 2002 - 2008 III покоління EU Universal',
        titleKey: 'opel78',
      },
      {
        id: '1943',
        model: 'Vectra',
        year: ['2002', '2008'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/opel/4565-250x150.jpg',
        title: 'Килимки в салон Opel Vectra C 2002 - 2008 III покоління EU Liftback',
        titleKey: 'opel79',
      },
      {
        id: '1944',
        model: 'Vivaro',
        year: ['2001', '2014'],
        body: 'VAN 1+1',
        market: 'EU',
        img: '/images/catalog/models/opel/4566-250x150.jpg',
        title: 'Килимки в салон Opel Vivaro A 2001 - 2014 I покоління EU VAN 1+1',
        titleKey: 'opel80',
      },

      {
        id: '1945',
        model: 'Vivaro',
        year: ['2001', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/opel/4567-250x150.jpg',
        title: 'Килимки в салон Opel Vivaro A 2001 - 2014 I покоління EU VAN 1+1,5',
        titleKey: 'opel81',
      },
      {
        id: '1946',
        model: 'Vivaro',
        year: ['2014', '2019'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/opel/4568-250x150.jpg',
        title: 'Килимки в салон Opel Vivaro 2014 - 2019 II покоління EU VAN 1+1,5',
        titleKey: 'opel82',
      },
      {
        id: '1947',
        model: 'Vivaro',
        year: ['2019'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models//placeholder-250x150.png',
        title: 'Килимки в салон Opel Vivaro C 2019 - ... III покоління EU VAN',
        titleKey: 'opel83',
      },
      {
        id: '1948',
        model: 'Zafira',
        year: ['1999', '2003'],
        body: 'Minivan дорест 7-ми місна',
        market: 'EU',
        img: '/images/catalog/models/opel/4569-250x150.jpg',
        title: 'Килимки в салон Opel Zafira A 1999 - 2003 I покоління EU Minivan дорест 7-ми місна',
        titleKey: 'opel84',
      },
      {
        id: '1949',
        model: 'Zafira',
        year: ['2003', '2005'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4570-250x150.jpg',
        title: 'Килимки в салон Opel Zafira A 2003 - 2005 I покоління EU Minivan рест 7-ми місна',
        titleKey: 'opel85',
      },
      {
        id: '1950',
        model: 'Zafira',
        year: ['2005', '2008'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4571-250x150.jpg',
        title: 'Килимки в салон Opel Zafira B 2005 - 2008 II покоління EU Minivan дорест 7-ми місна',
        titleKey: 'opel86',
      },
      {
        id: '1951',
        model: 'Zafira',
        year: ['2008', '2011'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4572-250x150.jpg',
        title: 'Килимки в салон Opel Zafira B 2008 - 2011 II покоління EU Minivan рест 7-ми місна',
        titleKey: 'opel87',
      },
      {
        id: '1952',
        model: 'Zafira',
        year: ['2011', '2019'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/opel/4573-250x150.jpg',
        title: 'Килимки в салон Opel Zafira Tourer C 2011 - 2019 III покоління EU Minivan 7-ми місна',
        titleKey: 'opel88',
      },
      {
        id: '1953',
        model: 'Zafira',
        year: ['2019'],
        body: 'Microbus',
        market: 'EU',
        img: '/images/catalog/models/opel/4574-250x150.jpg',
        title: 'Килимки в салон Opel Zafira Life 2019 - … IV покоління EU Microbus 7-ми місна',
        titleKey: 'opel89',
      },
    ],
  },
  /* ----------------------------------- ORA ---------------------------------- */
  Ora: {
    logo: '/images/catalog/logo/ORA-100x100.png',
    name: 'ORA',
    models: [
      {
        id: '1954',
        model: 'Good Cat GT',
        year: ['2020'],
        body: 'Hatchback',
        market: 'China',
        img: '/images/catalog/models/ora/5058-250x150.jpg',
        title: 'Килимки в салон ORA Good Cat GT 2020-… I покоління China Hatchback',
        titleKey: 'ora',
      },
    ],
  },
  /* --------------------------------- Peugeot -------------------------------- */
  Peugeot: {
    logo: '/images/catalog/logo/peugeot-100x100.png',
    name: 'Peugeot',
    models: [
      {
        id: '1955',
        model: '1007',
        year: ['2005', '2009'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3568-250x150.jpg',
        title: 'Килимки в салон Peugeot 1007 2005 - 2009 I покоління EU VAN',
        titleKey: 'peugeot1',
      },
      {
        id: '1956',
        model: '106',
        year: ['1991', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/2742-250x150.jpg',
        title: 'Килимки в салон Peugeot 106 1991 - 2003 I покоління EU Hatchback',
        titleKey: 'peugeot2',
      },
      {
        id: '1957',
        model: '107',
        year: ['2005', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3521-250x150.jpg',
        title: 'Килимки в салон Peugeot 107 2005 - 2014 I покоління EU Hatchback',
        titleKey: 'peugeot3',
      },
      {
        id: '1958',
        model: '108',
        year: ['2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3522-250x150.jpg',
        title: 'Килимки в салон Peugeot 108 2014 - ... I покоління EU Hatchback',
        titleKey: 'peugeot4',
      },
      {
        id: '1959',
        model: '2008',
        year: ['2013', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3569-250x150.jpg',
        title: 'Килимки в салон Peugeot 2008 2013 - 2019 I покоління EU Crossover',
        titleKey: 'peugeot5',
      },
      {
        id: '1960',
        model: '2008',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3570-250x150.jpg',
        title: 'Килимки в салон Peugeot 2008 2019 - ... II покоління EU Crossover',
        titleKey: 'peugeot6',
      },
      {
        id: '1961',
        model: '206',
        year: ['1998', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3523-250x150.jpg',
        title: 'Килимки в салон Peugeot 206 1998 - 2012 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'peugeot7',
      },
      {
        id: '1962',
        model: '206',
        year: ['1998', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3527-250x150.jpg',
        title: 'Килимки в салон Peugeot 206 1998 - 2012 I покоління EU Sedan',
        titleKey: 'peugeot8',
      },
      {
        id: '1963',
        model: '206',
        year: ['1998', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3526-250x150.jpg',
        title: 'Килимки в салон Peugeot 206 1998 - 2012 I покоління EU Universal',
        titleKey: 'peugeot9',
      },
      {
        id: '1964',
        model: '206',
        year: ['2000', '2007'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3524-250x150.jpg',
        title: 'Килимки в салон Peugeot 206 CC 2000 - 2007 I покоління EU Cabriolet (Coupe)',
        titleKey: 'peugeot10',
      },

      {
        id: '1965',
        model: '206',
        year: ['2009', '2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3525-250x150.jpg',
        title: 'Килимки в салон Peugeot 206+ 2009 - 2013 I покоління EU Hatchback рест 3-х дверна',
        titleKey: 'peugeot11',
      },
      {
        id: '1966',
        model: '207',
        year: ['2006', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3528-250x150.jpg',
        title: 'Килимки в салон Peugeot 207 2006 - 2012 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'peugeot12',
      },
      {
        id: '1967',
        model: '207',
        year: ['2006', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3530-250x150.jpg',
        title: 'Килимки в салон Peugeot 207 2006 - 2012 I покоління EU Universal',
        titleKey: 'peugeot13',
      },
      {
        id: '1968',
        model: '207',
        year: ['2007', '2009'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3529-250x150.jpg',
        title: 'Килимки в салон Peugeot 207 CC 2007 - 2009 I покоління EU Cabriolet дорест',
        titleKey: 'peugeot14',
      },
      {
        id: '1969',
        model: '208',
        year: ['2012', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3531-250x150.jpg',
        title: 'Килимки в салон Peugeot 208 2012 - 2015 I покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'peugeot15',
      },
      {
        id: '1970',
        model: '208',
        year: ['2015', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3533-250x150.jpg',
        title: 'Килимки в салон Peugeot 208 2015 - 2019 I покоління EU Hatchback рест 5-ти дверна',
        titleKey: 'peugeot16',
      },
      {
        id: '1971',
        model: '208',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3532-250x150.jpg',
        title: 'Килимки в салон Peugeot 208 2019 - … II покоління EU Hatchback',
        titleKey: 'peugeot17',
      },
      {
        id: '1972',
        model: '3008',
        year: ['2009', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3571-250x150.jpg',
        title: 'Килимки в салон Peugeot 3008 2009 - 2016 I покоління EU Crossover',
        titleKey: 'peugeot18',
      },
      {
        id: '1973',
        model: '3008',
        year: ['2009', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3572-250x150.jpg',
        title: 'Килимки в салон Peugeot 3008 2009 - 2016 I покоління EU Crossover Hybrid',
        titleKey: 'peugeot19',
      },
      {
        id: '1974',
        model: '3008',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3574-250x150.jpg',
        title: 'Килимки в салон Peugeot 3008 2016 - 2020 II покоління EU Crossover дорест',
        titleKey: 'peugeot20',
      },

      {
        id: '1975',
        model: '3008',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3573-250x150.jpg',
        title: 'Килимки в салон Peugeot 3008 2020 - … II покоління EU Crossover рест',
        titleKey: 'peugeot21',
      },
      {
        id: '1976',
        model: '3008',
        year: ['2016'],
        body: 'II покоління EU Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3575-250x150.jpg',
        title: 'Килимки в салон Peugeot 3008 GT PACK 2016 - … II покоління EU Crossover',
        titleKey: 'peugeot22',
      },
      {
        id: '1977',
        model: '301',
        year: ['2012', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3534-250x150.jpg',
        title: 'Килимки в салон Peugeot 301 2012 - 2017 I покоління EU Sedan дорест',
        titleKey: 'peugeot23',
      },
      {
        id: '1978',
        model: '301',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3535-250x150.jpg',
        title: 'Килимки в салон Peugeot 301 2017 - … I покоління EU Sedan рест',
        titleKey: 'peugeot24',
      },
      {
        id: '1979',
        model: '306',
        year: ['1993', '2001'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3536-250x150.jpg',
        title: 'Килимки в салон Peugeot 306 1993 - 2001 покоління EU Hatchback',
        titleKey: 'peugeot25',
      },
      {
        id: '1980',
        model: '307',
        year: ['2001', '2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3537-250x150.jpg',
        title: 'Килимки в салон Peugeot 307 2001 - 2005 I покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'peugeot26',
      },
      {
        id: '1981',
        model: '307',
        year: ['2001', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3541-250x150.jpg',
        title: 'Килимки в салон Peugeot 307 2001 - 2008 I покоління EU Sedan',
        titleKey: 'peugeot27',
      },
      {
        id: '1982',
        model: '307',
        year: ['2005', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3538-250x150.jpg',
        title: 'Килимки в салон Peugeot 307 2005 - 2008 I покоління EU Hatchback рест 5-ти дверна',
        titleKey: 'peugeot28',
      },
      {
        id: '1983',
        model: '307',
        year: ['2003', '2008'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3539-250x150.jpg',
        title: 'Килимки в салон Peugeot 307 CC 2003 - 2008 I покоління EU Cabriolet (Coupe)',
        titleKey: 'peugeot29',
      },
      {
        id: '1984',
        model: '307',
        year: ['2002', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3540-250x150.jpg',
        title: 'Килимки в салон Peugeot 307 SW 2002 - 2008 I покоління EU Universal',
        titleKey: 'peugeot30',
      },

      {
        id: '1985',
        model: '308',
        year: ['2007', '2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3545-250x150.jpg',
        title: 'Килимки в салон Peugeot 308 2007 - 2013 I покоління EU Hatchback 3-х дверна',
        titleKey: 'peugeot31',
      },
      {
        id: '1986',
        model: '308',
        year: ['2007', '2013'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3544-250x150.jpg',
        title: 'Килимки в салон Peugeot 308 2007 - 2013 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'peugeot32',
      },
      {
        id: '1987',
        model: '308',
        year: ['2007', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3546-250x150.jpg',
        title: 'Килимки в салон Peugeot 308 2007 - 2013 I покоління EU Sedan',
        titleKey: 'peugeot33',
      },
      {
        id: '1988',
        model: '308',
        year: ['2013', '2021'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3547-250x150.jpg',
        title: 'Килимки в салон Peugeot 308 2013 - 2021 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'peugeot34',
      },
      {
        id: '1989',
        model: '308',
        year: ['2007', '2013'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3543-250x150.jpg',
        title: 'Килимки в салон Peugeot 308 CC 2007 - 2013 I покоління EU Cabriolet (Coupe)',
        titleKey: 'peugeot35',
      },
      {
        id: '1990',
        model: '308',
        year: ['2007', '2013'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3542-250x150.jpg',
        title: 'Килимки в салон Peugeot 308 SW 2007 - 2013 I покоління EU Universal 7-ми місна',
        titleKey: 'peugeot36',
      },
      {
        id: '1991',
        model: '308',
        year: ['2013', '2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3548-250x150.jpg',
        title: 'Килимки в салон Peugeot 308 SW 2013 - 2017 II покоління EU Universal дорест',
        titleKey: 'peugeot37',
      },
      {
        id: '1992',
        model: '308',
        year: ['2017', '2021'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3549-250x150.jpg',
        title: 'Килимки в салон Peugeot 308 SW 2017 - 2021 II покоління EU Universal рест',
        titleKey: 'peugeot38',
      },
      {
        id: '1993',
        model: '309',
        year: ['1985', '1989'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3550-250x150.jpg',
        title: 'Килимки в салон Peugeot 309 1985 - 1989 I покоління EU Hatchback',
        titleKey: 'peugeot39',
      },
      {
        id: '1994',
        model: '4007',
        year: ['2007', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3576-250x150.jpg',
        title: 'Килимки в салон Peugeot 4007 2007 - 2013 I покоління EU Crossover',
        titleKey: 'peugeot40',
      },

      {
        id: '1995',
        model: '4008',
        year: ['2012', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3577-250x150.jpg',
        title: 'Килимки в салон Peugeot 4008 2012 - 2016 I покоління EU Crossover',
        titleKey: 'peugeot41',
      },
      {
        id: '1996',
        model: '405',
        year: ['1992', '1996'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3551-250x150.jpg',
        title: 'Килимки в салон Peugeot 405 1992 - 1996 I покоління EU Sedan рест',
        titleKey: 'peugeot42',
      },
      {
        id: '1997',
        model: '406',
        year: ['1995', '2004'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3553-250x150.jpg',
        title: 'Килимки в салон Peugeot 406 1995 - 2004 I покоління EU Coupe',
        titleKey: 'peugeot43',
      },
      {
        id: '1998',
        model: '406',
        year: ['1995', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3552-250x150.jpg',
        title: 'Килимки в салон Peugeot 406 1995 - 2004 I покоління EU Sedan',
        titleKey: 'peugeot44',
      },
      {
        id: '1999',
        model: '406',
        year: ['1995', '2004'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3554-250x150.jpg',
        title: 'Килимки в салон Peugeot 406 1995 - 2004 I покоління EU Universal',
        titleKey: 'peugeot45',
      },
      {
        id: '2000',
        model: '407',
        year: ['2004', '2010'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3557-250x150.jpg',
        title: 'Килимки в салон Peugeot 407 2004 - 2010 I покоління EU Coupe',
        titleKey: 'peugeot46',
      },
      {
        id: '2001',
        model: '407',
        year: ['2004', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3556-250x150.jpg',
        title: 'Килимки в салон Peugeot 407 2004 - 2010 I покоління EU Sedan',
        titleKey: 'peugeot47',
      },
      {
        id: '2002',
        model: '407',
        year: ['2004', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3555-250x150.jpg',
        title: 'Килимки в салон Peugeot 407 SW 2004 - 2010 I покоління EU Universal',
        titleKey: 'peugeot48',
      },
      {
        id: '2003',
        model: '408',
        year: ['2010', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3558-250x150.jpg',
        title: 'Килимки в салон Peugeot 408 2010 - 2014 I покоління EU Sedan',
        titleKey: 'peugeot49',
      },
      {
        id: '2004',
        model: '408',
        year: ['2022'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3559-250x150.jpg',
        title: 'Килимки в салон Peugeot 408 GT (P54) 2022 - … II покоління EU Sedan',
        titleKey: 'peugeot50',
      },

      {
        id: '2005',
        model: '5008',
        year: ['2009', '2017'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3578-250x150.jpg',
        title: 'Килимки в салон Peugeot 5008 2009 - 2017 I покоління EU Minivan 7-ми місна',
        titleKey: 'peugeot51',
      },
      {
        id: '2006',
        model: '5008',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3579-250x150.jpg',
        title: 'Килимки в салон Peugeot 5008 2016 - 2020 II покоління EU Crossover дорест',
        titleKey: 'peugeot52',
      },
      {
        id: '2007',
        model: '5008',
        year: ['2020', '2023'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3580-250x150.jpg',
        title: 'Килимки в салон Peugeot 5008 2020 - 2023 II покоління EU Crossover рест',
        titleKey: 'peugeot53',
      },
      {
        id: '2008',
        model: '508',
        year: ['2010', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3560-250x150.jpg',
        title: 'Килимки в салон Peugeot 508 2010 - 2018 I покоління EU Sedan',
        titleKey: 'peugeot54',
      },
      {
        id: '2009',
        model: '508',
        year: ['2010', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3560-250x150.jpg',
        title: 'Килимки в салон Peugeot 508 2010 - 2018 I покоління EU Sedan hybrid',
        titleKey: 'peugeot55',
      },
      {
        id: '2010',
        model: '508',
        year: ['2018'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3563-250x150.jpg',
        title: 'Килимки в салон Peugeot 508 2018 - … II покоління EU Liftback',
        titleKey: 'peugeot56',
      },
      {
        id: '2011',
        model: '508',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3564-250x150.jpg',
        title: 'Килимки в салон Peugeot 508 RXH 2012 - 2018 I покоління EU Crossover Hybrid',
        titleKey: 'peugeot57',
      },
      {
        id: '2012',
        model: '508',
        year: ['2010', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3562-250x150.jpg',
        title: 'Килимки в салон Peugeot 508 SW 2010 - 2014 I покоління EU Universal дорест',
        titleKey: 'peugeot58',
      },
      {
        id: '2013',
        model: '508',
        year: ['2014', '2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3561-250x150.jpg',
        title: 'Килимки в салон Peugeot 508 SW 2014 - 2018 I покоління EU Universal рест',
        titleKey: 'peugeot59',
      },
      {
        id: '2014',
        model: '605',
        year: ['1989', '1999'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3565-250x150.jpg',
        title: 'Килимки в салон Peugeot 605 1989 - 1999 I покоління EU Sedan',
        titleKey: 'peugeot60',
      },

      {
        id: '2015',
        model: '607',
        year: ['1999', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3566-250x150.jpg',
        title: 'Килимки в салон Peugeot 607 1999 - 2010 I покоління EU Sedan',
        titleKey: 'peugeot61',
      },
      {
        id: '2016',
        model: '806',
        year: ['1994', '2002'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/6017-250x150.jpg',
        title: 'Килимки в салон Peugeot 806 1994-2002 I покоління EU Minivan',
        titleKey: 'peugeot62',
      },
      {
        id: '2017',
        model: '807',
        year: ['2002', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3567-250x150.jpg',
        title: 'Килимки в салон Peugeot 807 2002 - 2014 I покоління EU Minivan 7-ми місна',
        titleKey: 'peugeot63',
      },
      {
        id: '2018',
        model: 'Bipper',
        year: ['2008'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3581-250x150.jpg',
        title: 'Килимки в салон Peugeot Bipper 2008 - … I покоління EU VAN',
        titleKey: 'peugeot64',
      },
      {
        id: '2019',
        model: 'Boxer',
        year: ['1994', '2006'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3582-250x150.jpg',
        title: 'Килимки в салон Peugeot Boxer 1994 - 2006 I покоління EU VAN',
        titleKey: 'peugeot65',
      },
      {
        id: '2020',
        model: 'Boxer',
        year: ['2006'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3583-250x150.jpg',
        title: 'Килимки в салон Peugeot Boxer 2006 - … II покоління EU VAN',
        titleKey: 'peugeot66',
      },
      {
        id: '2021',
        model: 'e-2008',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3584-250x150.jpg',
        title: 'Килимки в салон Peugeot e-2008 2019 - … II покоління EU Crossover Electric',
        titleKey: 'peugeot67',
      },
      {
        id: '2022',
        model: 'e-208',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/5965-250x150.jpg',
        title: 'Килимки в салон Peugeot e-208 2019 - ... II покоління EU Hatchback Electro',
        titleKey: 'peugeot68',
      },
      {
        id: '2023',
        model: 'Expert',
        year: ['1995', '2004'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3585-250x150.jpg',
        title: 'Килимки в салон Peugeot Expert 1995 - 2004 I покоління EU VAN дорест',
        titleKey: 'peugeot69',
      },
      {
        id: '2024',
        model: 'Expert',
        year: ['2004', '2007'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3586-250x150.jpg',
        title: 'Килимки в салон Peugeot Expert 2004 - 2007 I покоління EU VAN рест',
        titleKey: 'peugeot70',
      },

      {
        id: '2025',
        model: 'Expert',
        year: ['2007', '2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3587-250x150.jpg',
        title: 'Килимки в салон Peugeot Expert 2007 - 2016 II покоління EU VAN',
        titleKey: 'peugeot71',
      },
      {
        id: '2026',
        model: 'Expert',
        year: ['2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3590-250x150.jpg',
        title: 'Килимки в салон Peugeot Expert 2016 - … III покоління EU VAN',
        titleKey: 'peugeot72',
      },
      {
        id: '2027',
        model: 'Expert',
        year: ['2007', '2012'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3588-250x150.jpg',
        title: 'Килимки в салон Peugeot Expert Tepee 2007 - 2012 II покоління EU VAN дорест',
        titleKey: 'peugeot73',
      },
      {
        id: '2028',
        model: 'Expert',
        year: ['2012', '2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3588-250x150.jpg',
        title: 'Килимки в салон Peugeot Expert Tepee 2012 - 2016 II покоління EU VAN рест',
        titleKey: 'peugeot74',
      },
      {
        id: '2029',
        model: 'Expert',
        year: ['2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3591-250x150.jpg',
        title: 'Килимки в салон Peugeot Expert Tepee 2016 - … III покоління EU VAN Long',
        titleKey: 'peugeot75',
      },
      {
        id: '2030',
        model: 'iOn',
        year: ['2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/peugeot/5973-250x150.jpg',
        title: 'Килимки в салон Peugeot iOn 2009 - ... I покоління EU Hatchback',
        titleKey: 'peugeot76',
      },
      {
        id: '2031',
        model: 'Landtrek',
        year: ['2020'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/peugeot/6016-250x150.jpg',
        title: 'Килимки в салон Peugeot Landtrek 2020-... I покоління EU Pickup 4-х дверна',
        titleKey: 'peugeot77',
      },
      {
        id: '2032',
        model: 'Partner',
        year: ['1996', '2008'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3592-250x150.jpg',
        title: 'Килимки в салон Peugeot Partner 1996 - 2008 I покоління EU VAN',
        titleKey: 'peugeot78',
      },
      {
        id: '2033',
        model: 'Partner',
        year: ['2008', '2018'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3594-250x150.jpg',
        title: 'Килимки в салон Peugeot Partner 2008 - 2018 II покоління EU VAN',
        titleKey: 'peugeot79',
      },
      {
        id: '2034',
        model: 'Partner',
        year: ['2008', '2018'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/5981-250x150.jpg',
        title: 'Килимки в салон Peugeot Partner 2008 - 2018 II покоління EU VAN грузова',
        titleKey: 'peugeot80',
      },

      {
        id: '2035',
        model: 'Partner',
        year: ['2018'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3595-250x150.jpg',
        title: 'Килимки в салон Peugeot Partner 2018 - … III покоління EU VAN',
        titleKey: 'peugeot81',
      },
      {
        id: '2036',
        model: 'Partner',
        year: ['2008', '2018'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3593-250x150.jpg',
        title: 'Килимки в салон Peugeot Partner Tepee 2008 - 2018 II покоління EU VAN',
        titleKey: 'peugeot82',
      },
      {
        id: '2037',
        model: 'RCZ',
        year: ['2010', '2015'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3596-250x150.jpg',
        title: 'Килимки в салон Peugeot RCZ 2010 - 2015 I покоління EU Coupe',
        titleKey: 'peugeot83',
      },
      {
        id: '2038',
        model: 'Rifter',
        year: ['2018'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3597-250x150.jpg',
        title: 'Килимки в салон Peugeot Rifter L1 2018 - … I покоління EU VAN 5-ти місна Short',
        titleKey: 'peugeot84',
      },
      {
        id: '2039',
        model: 'Rifter',
        year: ['2018'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3598-250x150.jpg',
        title: 'Килимки в салон Peugeot Rifter L2 2018 - … I покоління EU VAN 7-ми місна Long',
        titleKey: 'peugeot85',
      },
      {
        id: '2040',
        model: 'Traveller',
        year: ['2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/peugeot/3599-250x150.jpg',
        title: 'Килимки в салон Peugeot Traveller 2016 - … I покоління EU VAN 7-ми місна',
        titleKey: 'peugeot86',
      },
    ],
  },
  /* --------------------------------- Pontiac -------------------------------- */
  Pontiac: {
    logo: '/images/catalog/logo/pontiac-100x100.png',
    name: 'Pontiac',
    models: [
      {
        id: '2041',
        model: 'Vibe ZZE',
        year: ['2004', '2010'],
        body: 'Cabriolet',
        market: 'UA',
        img: '/images/catalog/models/pontiac/3520-250x150.jpg',
        title: 'Килимки в салон Pontiac Solstice 2004 - 2010 I покоління UA Cabriolet',
        titleKey: 'pontiac1',
      },
      {
        id: '2042',
        model: 'Solstice',
        year: ['2004', '2010'],
        body: 'Coupe',
        market: 'UA',
        img: '/images/catalog/models/pontiac/1672-250x150.jpg',
        title: 'Килимки в салон Pontiac Solstice 2004 - 2010 I покоління UA Coupe',
        titleKey: 'pontiac2',
      },
    ],
  },
  /* --------------------------------- Porsche -------------------------------- */
  Porsche: {
    logo: '/images/catalog/logo/porsche-100x100.png',
    name: 'Porsche',
    models: [
      {
        id: '2043',
        model: '924',
        year: ['1976', '1988'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/porsche/3507-250x150.jpg',
        title: 'Килимки в салон Porsche 924 1976 - 1988 I покоління EU Coupe',
        titleKey: 'porsche1',
      },
      {
        id: '2044',
        model: 'Cayenne',
        year: ['2017'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/porsche/3513-250x150.jpg',
        title: 'Килимки в салон Porsche Cayenne 2017 - … III покоління EU Coupe',
        titleKey: 'porsche2',
      },
      {
        id: '2045',
        model: 'Cayenne',
        year: ['2010', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/porsche/3510-250x150.jpg',
        title: 'Килимки в салон Porsche Cayenne 92A 2010 - 2014 II покоління EU Crossover дорест',
        titleKey: 'porsche3',
      },
      {
        id: '2046',
        model: 'Cayenne',
        year: ['2014', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/porsche/3511-250x150.jpg',
        title: 'Килимки в салон Porsche Cayenne 92A 2014 - 2017 II покоління EU Crossover рест',
        titleKey: 'porsche4',
      },
      {
        id: '2047',
        model: 'Cayenne',
        year: ['2003', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/porsche/3509-250x150.jpg',
        title: 'Килимки в салон Porsche Cayenne 9PA 2003 - 2010 I покоління EU Crossover',
        titleKey: 'porsche5',
      },
      {
        id: '2048',
        model: 'Cayenne',
        year: ['2003', '2010'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/porsche/3508-250x150.jpg',
        title: 'Килимки в салон Porsche Cayenne 9PA 2003 - 2010 I покоління USA Crossover',
        titleKey: 'porsche6',
      },
      {
        id: '2049',
        model: 'Cayenne',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/porsche/3512-250x150.jpg',
        title: 'Килимки в салон Porsche Cayenne P0536 2017 - … III покоління EU Crossover',
        titleKey: 'porsche7',
      },
      {
        id: '2050',
        model: 'Macan',
        year: ['2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/porsche/3514-250x150.jpg',
        title: 'Килимки в салон Porsche Macan 2014 - … I покоління EU/USA Crossover',
        titleKey: 'porsche8',
      },
      {
        id: '2051',
        model: 'Panamera',
        year: ['2009', '2013'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/porsche/3516-250x150.jpg',
        title: 'Килимки в салон Porsche Panamera 970 2009 - 2013 I покоління EU Liftback дорест 5-ти дверна',
        titleKey: 'porsche9',
      },
      {
        id: '2052',
        model: 'Panamera',
        year: ['2009', '2016'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/porsche/3517-250x150.jpg',
        title: 'Килимки в салон Porsche Panamera 970 2009 - 2016 I покоління EU Liftback Long',
        titleKey: 'porsche10',
      },
      {
        id: '2053',
        model: 'Panamera',
        year: ['2013', '2016'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/porsche/3515-250x150.jpg',
        title: 'Килимки в салон Porsche Panamera 970 2013 - 2016 I покоління EU Liftback рест 5-ти дверна',
        titleKey: 'porsche11',
      },
      {
        id: '2054',
        model: 'Taycan',
        year: ['2019'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/porsche/3518-250x150.jpg',
        title: 'Килимки в салон Porsche Taycan Cross Turismo 2019 - … I покоління EU Universal',
        titleKey: 'porsche12',
      },
      {
        id: '2055',
        model: 'Taycan',
        year: ['2021'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/porsche/3519-250x150.jpg',
        title: 'Килимки в салон Porsche Taycan Turbo S 2021 - … I покоління EU Sedan рест',
        titleKey: 'porsche13',
      },
    ],
  },
  /* ---------------------------------- Ravon --------------------------------- */
  Ravon: {
    logo: '/images/catalog/logo/ravon-100x100.png',
    name: 'Ravon',
    models: [
      {
        id: '2056',
        model: 'Ravon R2',
        year: ['2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/ravon/3505-250x150.jpg',
        title: 'Килимки в салон Ravon R2 2016 - … I покоління EU Hatchback',
        titleKey: 'ravon1',
      },
      {
        id: '2057',
        model: 'Ravon R4',
        year: ['2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/ravon/3506-250x150.jpg',
        title: 'Килимки в салон Ravon R4 2016 - … I покоління EU Sedan',
        titleKey: 'ravon2',
      },
    ],
  },

  /* --------------------------------- Renault -------------------------------- */
  Renault: {
    logo: '/images/catalog/logo/renault-100x100.png',
    name: 'Renault',
    models: [
      {
        id: '2058',
        model: 'Arkana',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/2489-250x150.jpg',
        title: 'Килимки в салон Renault Arkana 2019 - ... I покоління EU Crossover',
        titleKey: 'renault1',
      },
      {
        id: '2059',
        model: 'Captur',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3379-250x150.jpg',
        title: 'Килимки в салон Renault Captur 2019 - ... II покоління EU Crossover',
        titleKey: 'renault2',
      },
      {
        id: '2060',
        model: 'Captur',
        year: ['2013', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3378-250x150.jpg',
        title: 'Килимки в салон Renault Captur J87 2013 - 2019 I покоління EU Crossover',
        titleKey: 'renault3',
      },
      {
        id: '2061',
        model: 'City K-ZE',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3380-250x150.jpg',
        title: 'Килимки в салон Renault City K-ZE 2019 - ... I покоління EU Crossover',
        titleKey: 'renault4',
      },
      {
        id: '2062',
        model: 'Clio',
        year: ['1998', '2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3381-250x150.jpg',
        title: 'Килимки в салон Renault Clio 1998 - 2005 II покоління EU Hatchback',
        titleKey: 'renault5',
      },
      {
        id: '2063',
        model: 'Clio',
        year: ['2005', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3384-250x150.jpg',
        title: 'Килимки в салон Renault Clio 2005 - 2009 III покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'renault6',
      },
      {
        id: '2064',
        model: 'Clio',
        year: ['2005', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3386-250x150.jpg',
        title: 'Килимки в салон Renault Clio 2005 - 2012 III покоління EU Hatchback 3-х дверна',
        titleKey: 'renault7',
      },
      {
        id: '2065',
        model: 'Clio',
        year: ['2005', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3385-250x150.jpg',
        title: 'Килимки в салон Renault Clio 2005 - 2012 III покоління EU Universal',
        titleKey: 'renault8',
      },
      {
        id: '2066',
        model: 'Clio',
        year: ['2009', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3383-250x150.jpg',
        title: 'Килимки в салон Renault Clio 2009 - 2012 III покоління EU Hatchback рест 5-ти дверна',
        titleKey: 'renault9',
      },
      {
        id: '2067',
        model: 'Clio',
        year: ['2012', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3388-250x150.jpg',
        title: 'Килимки в салон Renault Clio 2012 - 2019 IV покоління EU Hatchback 5-ти дверна',
        titleKey: 'renault10',
      },

      {
        id: '2068',
        model: 'Clio',
        year: ['2012', '2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3389-250x150.jpg',
        title: 'Килимки в салон Renault Clio 2012 - 2019 IV покоління EU Sedan 5-ти дверна',
        titleKey: 'renault11',
      },
      {
        id: '2069',
        model: 'Clio',
        year: ['2012', '2019'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3387-250x150.jpg',
        title: 'Килимки в салон Renault Clio 2012 - 2019 IV покоління EU Universal 5-ти дверна',
        titleKey: 'renault12',
      },
      {
        id: '2070',
        model: 'Clio',
        year: ['2000', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3382-250x150.jpg',
        title: 'Килимки в салон Renault Clio Symbol 2000 - 2012 II покоління EU Sedan',
        titleKey: 'renault13',
      },
      {
        id: '2071',
        model: 'Dokker',
        year: ['2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3390-250x150.jpg',
        title: 'Килимки в салон Renault Dokker 2012 - … I покоління EU Minivan',
        titleKey: 'renault14',
      },
      {
        id: '2072',
        model: 'Duster',
        year: ['2010', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3391-250x150.jpg',
        title: 'Килимки в салон Renault Duster 2010 - 2015 I покоління EU Crossover дорест',
        titleKey: 'renault15',
      },
      {
        id: '2073',
        model: 'Duster',
        year: ['2015', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3393-250x150.jpg',
        title: 'Килимки в салон Renault Duster 2015 - 2018 I покоління EU Crossover рест',
        titleKey: 'renault16',
      },
      {
        id: '2074',
        model: 'Duster',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3394-250x150.jpg',
        title: 'Килимки в салон Renault Duster 2018 - … II покоління EU Crossover',
        titleKey: 'renault17',
      },
      {
        id: '2075',
        model: 'Espace',
        year: ['2014', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3398-250x150.jpg',
        title: 'Килимки в салон Renault Espace 2014 - 2021 V покоління EU Minivan 5-ти місна',
        titleKey: 'renault18',
      },
      {
        id: '2076',
        model: 'Espace',
        year: ['1997', '2002'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3395-250x150.jpg',
        title: 'Килимки в салон Renault Espace JEO 1997 - 2002 III покоління EU Minivan',
        titleKey: 'renault19',
      },
      {
        id: '2077',
        model: 'Espace',
        year: ['2002', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3396-250x150.jpg',
        title: 'Килимки в салон Renault Espace JK 2002 - 2014 IV покоління EU Minivan 7-ми місна',
        titleKey: 'renault20',
      },

      {
        id: '2078',
        model: 'Express',
        year: ['2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3399-250x150.jpg',
        title: 'Килимки в салон Renault Express 2021 - … II покоління EU Minivan',
        titleKey: 'renault21',
      },
      {
        id: '2079',
        model: 'Fluence',
        year: ['2009', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3400-250x150.jpg',
        title: 'Килимки в салон Renault Fluence 2009 - 2017 I покоління EU Sedan',
        titleKey: 'renault22',
      },
      {
        id: '2080',
        model: 'Espace',
        year: ['2002', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3397-250x150.jpg',
        title: 'Килимки в салон Renault Grand Espace JK 2002 - 2014 IV покоління EU Minivan',
        titleKey: 'renault23',
      },
      {
        id: '2081',
        model: 'Modus',
        year: ['2004', '2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3462-250x150.jpg',
        title: 'Килимки в салон Renault Grand Modus 2004 - 2012 I покоління EU Minivan Long',
        titleKey: 'renault24',
      },
      {
        id: '2082',
        model: 'Scenic',
        year: ['2003', '2009'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3473-250x150.jpg',
        title: 'Килимки в салон Renault Grand Scenic 2003 - 2009 II покоління EU Minivan 7-ми місна',
        titleKey: 'renault25',
      },
      {
        id: '2083',
        model: 'Scenic',
        year: ['2009', '2016'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3476-250x150.jpg',
        title: 'Килимки в салон Renault Grand Scenic 2009 - 2016 III покоління EU Minivan 5-ти місна',
        titleKey: 'renault26',
      },
      {
        id: '2084',
        model: 'Scenic',
        year: ['2009', '2016'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3475-250x150.jpg',
        title: 'Килимки в салон Renault Grand Scenic 2009 - 2016 III покоління EU Minivan 7-ми місна',
        titleKey: 'renault27',
      },
      {
        id: '2085',
        model: 'Scenic',
        year: ['2016'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3478-250x150.jpg',
        title: 'Килимки в салон Renault Grand Scenic 2016 - … IV покоління EU Minivan 7-ми місна',
        titleKey: 'renault28',
      },
      {
        id: '2086',
        model: 'Kadjar',
        year: ['2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3401-250x150.jpg',
        title: 'Килимки в салон Renault Kadjar 2015 - … I покоління EU Crossover',
        titleKey: 'renault29',
      },
      {
        id: '2087',
        model: 'Kangoo',
        year: ['1998', '2008'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3403-250x150.jpg',
        title: 'Килимки в салон Renault Kangoo 1998 - 2008 I покоління EU Minivan 4-х дверна',
        titleKey: 'renault30',
      },

      {
        id: '2088',
        model: 'Kangoo',
        year: ['1998', '2008'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3402-250x150.jpg',
        title: 'Килимки в салон Renault Kangoo 1998 - 2008 I покоління EU Minivan 5-ти дверна',
        titleKey: 'renault31',
      },
      {
        id: '2089',
        model: 'Kangoo',
        year: ['2008', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3408-250x150.jpg',
        title: 'Килимки в салон Renault Kangoo 2008 - 2013 II покоління EU Minivan дорест 4-х дверна вантажний',
        titleKey: 'renault32',
      },
      {
        id: '2090',
        model: 'Kangoo',
        year: ['2008', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3404-250x150.jpg',
        title:
          'Килимки в салон Renault Kangoo 2008 - 2013 II покоління EU Minivan дорест 5-ти дверна 5-ти місна пасажирський',
        titleKey: 'renault33',
      },
      {
        id: '2091',
        model: 'Kangoo',
        year: ['2008', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3405-250x150.jpg',
        title:
          'Килимки в салон Renault Kangoo 2008 - 2013 II покоління EU Minivan дорест 5-ти дверна 7-ми місна пасажирський',
        titleKey: 'renault34',
      },
      {
        id: '2092',
        model: 'Kangoo',
        year: ['2013', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3410-250x150.jpg',
        title: 'Килимки в салон Renault Kangoo 2013 - 2021 II покоління EU Minivan рест 4-х дверна вантажний',
        titleKey: 'renault35',
      },
      {
        id: '2093',
        model: 'Kangoo',
        year: ['2013', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3406-250x150.jpg',
        title:
          'Килимки в салон Renault Kangoo 2013 - 2021 II покоління EU Minivan рест 5-ти дверна 5-ти місна пасажирський',
        titleKey: 'renault36',
      },
      {
        id: '2094',
        model: 'Kangoo',
        year: ['2008', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3409-250x150.jpg',
        title: 'Килимки в салон Renault Kangoo Maxi 2008 - 2013 II покоління EU Minivan дорест',
        titleKey: 'renault37',
      },
      {
        id: '2095',
        model: 'Kangoo',
        year: ['2013', '2021'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3407-250x150.jpg',
        title: 'Килимки в салон Renault Kangoo Maxi 2013 - 2021 II покоління EU Minivan рест',
        titleKey: 'renault38',
      },
      {
        id: '2096',
        model: 'Kaptur',
        year: ['2016'],
        body: 'Crossover',
        market: 'RU',
        img: '/images/catalog/models/renault/3411-250x150.jpg',
        title: 'Килимки в салон Renault Kaptur 2016 - … I покоління RU Crossover',
        titleKey: 'renault39',
      },
      {
        id: '2097',
        model: 'Koleos',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3414-250x150.jpg',
        title: 'Килимки в салон Renault Koleos (Turkish Assembly) 2016 - … II покоління EU Crossover',
        titleKey: 'renault40',
      },

      {
        id: '2098',
        model: 'Koleos',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3413-250x150.jpg',
        title: 'Килимки в салон Renault Koleos HC 2016 - … II покоління EU Crossover',
        titleKey: 'renault41',
      },
      {
        id: '2099',
        model: 'Koleos',
        year: ['2008', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3412-250x150.jpg',
        title: 'Килимки в салон Renault Koleos HY 2008 - 2016 I покоління EU Crossover',
        titleKey: 'renault42',
      },
      {
        id: '2100',
        model: 'Laguna',
        year: ['1994', '2000'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/renault/3415-250x150.jpg',
        title: 'Килимки в салон Renault Laguna B56 1994 - 2000 I покоління EU Liftback 5-ти дверна',
        titleKey: 'renault43',
      },
      {
        id: '2101',
        model: 'Laguna',
        year: ['2000', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3417-250x150.jpg',
        title: 'Килимки в салон Renault Laguna G 2000 - 2007 II покоління EU Universal',
        titleKey: 'renault44',
      },
      {
        id: '2102',
        model: 'Laguna',
        year: ['2001', '2007'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/renault/3418-250x150.jpg',
        title: 'Килимки в салон Renault Laguna G 2001 - 2007 II покоління EU Liftback 5-ти дверна',
        titleKey: 'renault45',
      },
      {
        id: '2103',
        model: 'Laguna',
        year: ['1994', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3416-250x150.jpg',
        title: 'Килимки в салон Renault Laguna K56 1994 - 2000 I покоління EU Universal',
        titleKey: 'renault46',
      },
      {
        id: '2104',
        model: 'Laguna',
        year: ['2007', '2015'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/renault/3421-250x150.jpg',
        title: 'Килимки в салон Renault Laguna K56 2007 - 2015 III покоління EU Coupe',
        titleKey: 'renault47',
      },
      {
        id: '2105',
        model: 'Laguna',
        year: ['2007', '2015'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/renault/3419-250x150.jpg',
        title: 'Килимки в салон Renault Laguna T 2007 - 2015 III покоління EU Liftback',
        titleKey: 'renault48',
      },
      {
        id: '2106',
        model: 'Laguna',
        year: ['2007', '2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3420-250x150.jpg',
        title: 'Килимки в салон Renault Laguna T 2007 - 2015 III покоління EU Universal',
        titleKey: 'renault49',
      },
      {
        id: '2107',
        model: 'Latitude',
        year: ['2010', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3422-250x150.jpg',
        title: 'Килимки в салон Renault Latitude 2010 - 2015 I покоління EU Sedan',
        titleKey: 'renault50',
      },

      {
        id: '2108',
        model: 'Lodgy',
        year: ['2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3424-250x150.jpg',
        title: 'Килимки в салон Renault Lodgy 2012 - … I покоління EU Minivan 5-ти місна',
        titleKey: 'renault51',
      },
      {
        id: '2109',
        model: 'Lodgy',
        year: ['2012'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3423-250x150.jpg',
        title: 'Килимки в салон Renault Lodgy 2012 - … I покоління EU Minivan 7-ми місна',
        titleKey: 'renault52',
      },
      {
        id: '2110',
        model: 'Logan',
        year: ['2004', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3425-250x150.jpg',
        title: 'Килимки в салон Renault Logan 2004 - 2012 I покоління EU Sedan',
        titleKey: 'renault53',
      },
      {
        id: '2111',
        model: 'Logan',
        year: ['2012', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3429-250x150.jpg',
        title: 'Килимки в салон Renault Logan 2012 - 2016 II покоління EU Sedan дорест',
        titleKey: 'renault54',
      },
      {
        id: '2112',
        model: 'Logan',
        year: ['2016', '2022'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3430-250x150.jpg',
        title: 'Килимки в салон Renault Logan 2016 - 2022 II покоління EU Sedan рест',
        titleKey: 'renault55',
      },
      {
        id: '2113',
        model: 'Logan',
        year: ['2004', '2008'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3427-250x150.jpg',
        title: 'Килимки в салон Renault Logan MCV 2004 - 2008 I покоління EU Universal дорест 7-ми місна',
        titleKey: 'renault56',
      },
      {
        id: '2114',
        model: 'Logan',
        year: ['2004', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3426-250x150.jpg',
        title: 'Килимки в салон Renault Logan MCV 2004 - 2012 I покоління EU Universal 5-ти місна',
        titleKey: 'renault57',
      },
      {
        id: '2115',
        model: 'Logan',
        year: ['2008', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3428-250x150.jpg',
        title: 'Килимки в салон Renault Logan MCV 2008 - 2012 I покоління EU Universal рест 7-ми місна',
        titleKey: 'renault58',
      },
      {
        id: '2116',
        model: 'Logan',
        year: ['2012', '2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3431-250x150.jpg',
        title: 'Килимки в салон Renault Logan MCV 2012 - 2016 II покоління EU Universal дорест 5-ти місна',
        titleKey: 'renault59',
      },
      {
        id: '2117',
        model: 'Logan',
        year: ['2016', '2022'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3432-250x150.jpg',
        title: 'Килимки в салон Renault Logan MCV 2016 - 2022 II покоління EU Universal рест 5-ти місна',
        titleKey: 'renault60',
      },

      {
        id: '2118',
        model: 'Mascott',
        year: ['1999', '2010'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/3433-250x150.jpg',
        title: 'Килимки в салон Renault Mascott 1999 - 2010 I покоління EU VAN',
        titleKey: 'renault61',
      },
      {
        id: '2119',
        model: 'Master',
        year: ['1998', '2003'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/3435-250x150.jpg',
        title: 'Килимки в салон Renault Master 1998 - 2003 II покоління EU VAN дорест',
        titleKey: 'renault62',
      },
      {
        id: '2120',
        model: 'Master',
        year: ['2003', '2010'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/3434-250x150.jpg',
        title: 'Килимки в салон Renault Master 2003 - 2010 II покоління EU VAN рест',
        titleKey: 'renault63',
      },
      {
        id: '2121',
        model: 'Master',
        year: ['2010', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/3436-250x150.jpg',
        title: 'Килимки в салон Renault Master 2010 - 2014 III покоління EU VAN дорест',
        titleKey: 'renault64',
      },
      {
        id: '2122',
        model: 'Master',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/3437-250x150.jpg',
        title: 'Килимки в салон Renault Master 2014 - … III покоління EU VAN рест',
        titleKey: 'renault65',
      },
      {
        id: '2123',
        model: 'Megane',
        year: ['1995', '2002'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/renault/3439-250x150.jpg',
        title: 'Килимки в салон Renault Megane 1995 - 2002 I покоління EU Cabriolet',
        titleKey: 'renault66',
      },
      {
        id: '2124',
        model: 'Megane',
        year: ['1995', '2002'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3440-250x150.jpg',
        title: 'Килимки в салон Renault Megane 1995 - 2002 I покоління EU Hatchback',
        titleKey: 'renault67',
      },
      {
        id: '2125',
        model: 'Megane',
        year: ['1995', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3438-250x150.jpg',
        title: 'Килимки в салон Renault Megane 1995 - 2002 I покоління EU Sedan',
        titleKey: 'renault68',
      },
      {
        id: '2126',
        model: 'Megane',
        year: ['2002', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3443-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2002 - 2006 II покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'renault69',
      },
      {
        id: '2127',
        model: 'Megane',
        year: ['2002', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3441-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2002 - 2006 II покоління EU Sedan дорест',
        titleKey: 'renault70',
      },

      {
        id: '2128',
        model: 'Megane',
        year: ['2002', '2006'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3445-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2002 - 2006 II покоління EU Universal дорест',
        titleKey: 'renault71',
      },
      {
        id: '2129',
        model: 'Megane',
        year: ['2002', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3447-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2002 - 2009 II покоління EU Hatchback 3-х дверна',
        titleKey: 'renault72',
      },
      {
        id: '2130',
        model: 'Megane',
        year: ['2006', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3444-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2006 - 2008 II покоління EU Hatchback рест 5-ти дверна',
        titleKey: 'renault73',
      },
      {
        id: '2131',
        model: 'Megane',
        year: ['2006', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3442-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2006 - 2009 II покоління EU Sedan рест',
        titleKey: 'renault74',
      },
      {
        id: '2132',
        model: 'Megane',
        year: ['2006', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3446-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2006 - 2009 II покоління EU Universal рест',
        titleKey: 'renault75',
      },
      {
        id: '2133',
        model: 'Megane',
        year: ['2008', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3451-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2008 - 2014 III покоління EU Universal дорест',
        titleKey: 'renault76',
      },
      {
        id: '2134',
        model: 'Megane',
        year: ['2008', '2016'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/renault/3455-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2008 - 2016 III покоління EU Coupe',
        titleKey: 'renault77',
      },
      {
        id: '2135',
        model: 'Megane',
        year: ['2008', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3457-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2008 - 2016 III покоління EU Hatchback 3-х дверна',
        titleKey: 'renault78',
      },
      {
        id: '2136',
        model: 'Megane',
        year: ['2008', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3454-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2008 - 2016 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'renault79',
      },
      {
        id: '2137',
        model: 'Megane',
        year: ['2008', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3452-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2008 - 2016 III покоління EU Sedan',
        titleKey: 'renault80',
      },

      {
        id: '2138',
        model: 'Megane',
        year: ['2014', '2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3456-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2014 - 2016 III покоління EU Universal рест',
        titleKey: 'renault81',
      },
      {
        id: '2139',
        model: 'Megane',
        year: ['2016', '2022'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3460-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2016 - 2022 IV покоління EU Hatchback',
        titleKey: 'renault82',
      },
      {
        id: '2140',
        model: 'Megane',
        year: ['2016', '2022'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3458-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2016 - 2022 IV покоління EU Sedan',
        titleKey: 'renault83',
      },
      {
        id: '2141',
        model: 'Megane',
        year: ['2016', '2022'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3459-250x150.jpg',
        title: 'Килимки в салон Renault Megane 2016 - 2022 IV покоління EU Universal',
        titleKey: 'renault84',
      },
      {
        id: '2142',
        model: 'Megane',
        year: ['2008', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3450-250x150.jpg',
        title: 'Килимки в салон Renault Megane BOSE 2008 - 2016 III покоління EU Hatchback 3-х дверна',
        titleKey: 'renault85',
      },
      {
        id: '2143',
        model: 'Megane',
        year: ['2008', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3453-250x150.jpg',
        title: 'Килимки в салон Renault Megane BOSE 2008 - 2016 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'renault86',
      },
      {
        id: '2144',
        model: 'Megane',
        year: ['2008', '2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3449-250x150.jpg',
        title: 'Килимки в салон Renault Megane BOSE 2008 - 2016 III покоління EU Universal',
        titleKey: 'renault87',
      },
      {
        id: '2145',
        model: 'Megane',
        year: ['2002', '2009'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/renault/3448-250x150.jpg',
        title: 'Килимки в салон Renault Megane CC 2002 - 2009 II покоління EU Coupe',
        titleKey: 'renault88',
      },
      {
        id: '2146',
        model: 'Megane',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3461-250x150.jpg',
        title: 'Килимки в салон Renault Megane E-Tech 2022 - … V покоління EU Crossover Electric',
        titleKey: 'renault89',
      },
      {
        id: '2147',
        model: 'Modus',
        year: ['2004', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3463-250x150.jpg',
        title: 'Килимки в салон Renault Modus 2004 - 2012 I покоління EU Hatchback',
        titleKey: 'renault90',
      },

      {
        id: '2148',
        model: 'Renault 19',
        year: ['1988', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3464-250x150.jpg',
        title: 'Килимки в салон Renault Renault 19 1988 - 1997 I покоління EU Sedan',
        titleKey: 'renault91',
      },
      {
        id: '2149',
        model: 'SaFrane',
        year: ['1992', '2000'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/renault/3465-250x150.jpg',
        title: 'Килимки в салон Renault SaFrane 1992 - 2000 I покоління EU Liftback',
        titleKey: 'renault92',
      },
      {
        id: '2150',
        model: 'Sandero',
        year: ['2012', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3468-250x150.jpg',
        title: 'Килимки в салон Renault Sandero B52 2012 - 2020 II покоління EU Hatchback',
        titleKey: 'renault93',
      },
      {
        id: '2151',
        model: 'Sandero',
        year: ['2007', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3466-250x150.jpg',
        title: 'Килимки в салон Renault Sandero B90 2007 - 2012 I покоління EU Hatchback',
        titleKey: 'renault94',
      },
      {
        id: '2152',
        model: 'Sandero',
        year: ['2008', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3467-250x150.jpg',
        title: 'Килимки в салон Renault Sandero Stepway 2008 - 2012 I покоління EU Crossover',
        titleKey: 'renault95',
      },
      {
        id: '2153',
        model: 'Sandero',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3469-250x150.jpg',
        title: 'Килимки в салон Renault Sandero Stepway 2012 - 2017 II покоління EU Crossover дорест',
        titleKey: 'renault96',
      },
      {
        id: '2154',
        model: 'Sandero',
        year: ['2017', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/renault/3470-250x150.jpg',
        title: 'Килимки в салон Renault Sandero Stepway 2017 - 2020 II покоління EU Crossover рест',
        titleKey: 'renault97',
      },
      {
        id: '2155',
        model: 'Scenic',
        year: ['1996', '1999'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3471-250x150.jpg',
        title: 'Килимки в салон Renault Scenic 1996 - 1999 I покоління EU Minivan дорест',
        titleKey: 'renault98',
      },
      {
        id: '2156',
        model: 'Scenic',
        year: ['1999', '2003'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3472-250x150.jpg',
        title: 'Килимки в салон Renault Scenic 1999 - 2003 I покоління EU Minivan рест',
        titleKey: 'renault99',
      },
      {
        id: '2157',
        model: 'Scenic',
        year: ['2003', '2009'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3474-250x150.jpg',
        title: 'Килимки в салон Renault Scenic 2003 - 2009 II покоління EU Minivan 5-ти місна',
        titleKey: 'renault100',
      },

      {
        id: '2158',
        model: 'Scenic',
        year: ['2009', '2016'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3477-250x150.jpg',
        title: 'Килимки в салон Renault Scenic 2009 - 2016 III покоління EU Minivan 5-ти місна',
        titleKey: 'renault101',
      },
      {
        id: '2159',
        model: 'Scenic',
        year: ['2016'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/renault/3479-250x150.jpg',
        title: 'Килимки в салон Renault Scenic 2016 - … IV покоління EU Minivan 5-ти місна',
        titleKey: 'renault102',
      },
      {
        id: '2160',
        model: 'Symbol',
        year: ['1999', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3480-250x150.jpg',
        title: 'Килимки в салон Renault Symbol 1999 - 2008 I покоління EU Sedan',
        titleKey: 'renault103',
      },
      {
        id: '2161',
        model: 'Symbol',
        year: ['2008', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3481-250x150.jpg',
        title: 'Килимки в салон Renault Symbol 2008 - 2013 II покоління EU Sedan',
        titleKey: 'renault104',
      },
      {
        id: '2162',
        model: 'Talisman',
        year: ['2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/renault/3483-250x150.jpg',
        title: 'Килимки в салон Renault Talisman 2015 - … покоління EU Sedan',
        titleKey: 'renault105',
      },
      {
        id: '2163',
        model: 'Talisman',
        year: ['2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3482-250x150.jpg',
        title: 'Килимки в салон Renault Talisman 2015 - … покоління EU Universal',
        titleKey: 'renault106',
      },
      {
        id: '2164',
        model: 'Talisman',
        year: ['2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/renault/3484-250x150.jpg',
        title: 'Килимки в салон Renault Talisman ZEN 2015 - … покоління EU Universal',
        titleKey: 'renault107',
      },
      {
        id: '2165',
        model: 'Trafic',
        year: ['2001', '2007'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/5958-250x150.jpg',
        title: 'Килимки в салон Renault Trafic 2001 - 2007 II покоління EU VAN дорест 1+1,5',
        titleKey: 'renault108',
      },
      {
        id: '2166',
        model: 'Trafic',
        year: ['2007', '2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/5959-250x150.jpg',
        title: 'Килимки в салон Renault Trafic 2007-2014 II покоління EU VAN рест 1+1',
        titleKey: 'renault109',
      },
      {
        id: '2167',
        model: 'Trafic',
        year: ['2014'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/5960-250x150.jpg',
        title: 'Килимки в салон Renault Trafic 2014-... III покоління EU VAN 1+1',
        titleKey: 'renault110',
      },

      {
        id: '2168',
        model: 'Trafic',
        year: ['2014', '2019'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/5961-250x150.jpg',
        title: 'Килимки в салон Renault Trafic 2014-2019 III покоління EU VAN дорест 1+1,5',
        titleKey: 'renault111',
      },
      {
        id: '2169',
        model: 'Trafic',
        year: ['2019'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/renault/5962-250x150.jpg',
        title: 'Килимки в салон Renault Trafic 2019-… III покоління EU VAN рест 1+1,5',
        titleKey: 'renault112',
      },
      {
        id: '2170',
        model: 'Twingo',
        year: ['1992', '2007'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3485-250x150.jpg',
        title: 'Килимки в салон Renault Twingo 1992 - 2007 I покоління EU Hatchback',
        titleKey: 'renault113',
      },
      {
        id: '2171',
        model: 'Twingo',
        year: ['2007', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3486-250x150.jpg',
        title: 'Килимки в салон Renault Twingo 2007 - 2014 II покоління EU Hatchback',
        titleKey: 'renault114',
      },
      {
        id: '2172',
        model: 'Vel Satis',
        year: ['2001', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3487-250x150.jpg',
        title: 'Килимки в салон Renault Vel Satis 2001 - 2009 I покоління EU Hatchback',
        titleKey: 'renault115',
      },
      {
        id: '2173',
        model: 'Zoé',
        year: ['2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3488-250x150.jpg',
        title: 'Килимки в салон Renault Zoé (ZE) 2012 - … I покоління EU Hatchback',
        titleKey: 'renault116',
      },
      {
        id: '2174',
        model: 'Zoé',
        year: ['2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3490-250x150.jpg',
        title: 'Килимки в салон Renault Zoé (ZE40) 2017 - … I покоління EU Hatchback',
        titleKey: 'renault117',
      },
      {
        id: '2175',
        model: 'Zoé',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/renault/3489-250x150.jpg',
        title: 'Килимки в салон Renault Zoé (ZE50) 2019 - … I покоління EU Hatchback',
        titleKey: 'renault118',
      },
    ],
  },
  /* ---------------------------------- SAAB ---------------------------------- */
  Saab: {
    logo: '/images/catalog/logo/SAAB-100x100.png',
    name: 'SAAB',
    models: [
      {
        id: '2176',
        model: '9-3',
        year: ['2002', '2007'],
        body: 'Sedan дорест',
        market: 'EU',
        img: '/images/catalog/models/saab/5050-250x150.jpg',
        title: 'Килимки в салон Saab 9-3 II 2002-2007 II покоління EU Sedan дорест',
        titleKey: 'saab1',
      },
      {
        id: '2177',
        model: '9-3',
        year: ['2002', '2013'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/saab/5052-250x150.jpg',
        title: 'Килимки в салон Saab 9-3 II 2002-2013 II покоління EU Universal',
        titleKey: 'saab2',
      },
      {
        id: '2178',
        model: '9-3',
        year: ['2007', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/saab/5051-250x150.jpg',
        title: 'Килимки в салон Saab 9-3 II 2007-2013 II покоління EU Sedan рест',
        titleKey: 'saab3',
      },
      {
        id: '2179',
        model: '9-5',
        year: ['1997', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/saab/5053-250x150.jpg',
        title: 'Килимки в салон Saab 9-5 I 1997-2010 I покоління EU Sedan',
        titleKey: 'saab4',
      },
      {
        id: '2180',
        model: '9-5',
        year: ['1997', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/saab/5054-250x150.jpg',
        title: 'Килимки в салон Saab 9-5 I 1997-2010 I покоління EU Universal',
        titleKey: 'saab5',
      },
      {
        id: '2181',
        model: '9-5',
        year: ['2010', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/saab/5055-250x150.jpg',
        title: 'Килимки в салон Saab 9-5 II 2010-2012 II покоління EU Sedan',
        titleKey: 'saab6',
      },
      {
        id: '2182',
        model: '9-5',
        year: ['2010', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/saab/5056-250x150.jpg',
        title: 'Килимки в салон Saab 9-5 II 2010-2012 II покоління EU Universal',
        titleKey: 'saab7',
      },
    ],
  },
  /* ---------------------------------- Saipa --------------------------------- */
  Saipa: {
    logo: '/images/catalog/logo/Saipa-100x100.png',
    name: 'Saipa',
    models: [
      {
        id: '2183',
        model: 'Tiba',
        year: ['2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/saipa/5049-250x150.jpg',
        title: 'Килимки в салон Saipa Tiba 2009-… I покоління EU Sedan',
        titleKey: 'saipa',
      },
    ],
  },
  /* --------------------------------- Samand --------------------------------- */
  Samand: {
    logo: '/images/catalog/logo/Samand-100x100.png',
    name: 'Samand',
    models: [
      {
        id: '2184',
        model: 'Samand',
        year: ['2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/samand/5047-250x150.jpg',
        title: 'Килимки в салон Samand Samand LX 2002-… I покоління EU Sedan',
        titleKey: 'samand1',
      },
      {
        id: '2185',
        model: 'Samand',
        year: ['2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/samand/5048-250x150.jpg',
        title: 'Килимки в салон Samand Samand Soren 2007-… EU Sedan',
        titleKey: 'samand2',
      },
    ],
  },
  /* --------------------------------- Samsung -------------------------------- */
  Samsung: {
    logo: '/images/catalog/logo/Samsung-100x100.png',
    name: 'Samsung',
    models: [
      {
        id: '2186',
        model: 'SM5',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/samsung/5046-250x150.jpg',
        title: 'Килимки в салон Samsung SM5 (А32) 1998-2005 I покоління EU Sedan',
        titleKey: 'samsung',
      },
    ],
  },
  /* ---------------------------------- Seat ---------------------------------- */
  Seat: {
    logo: '/images/catalog/logo/seat-100x100.png',
    name: 'Seat',
    models: [
      {
        id: '2187',
        model: 'Alhambra',
        year: ['2000', '2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/seat/2472-250x150.jpg',
        title: 'Килимки в салон Seat Alhambra 2000 - 2010 I покоління EU Minivan рест 7-ми місна',
        titleKey: 'seat1',
      },
      {
        id: '2188',
        model: 'Alhambra',
        year: ['2010', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/seat/3347-250x150.jpg',
        title: 'Килимки в салон Seat Alhambra 2010 - 2015 II покоління EU Minivan дорест 7-ми місна',
        titleKey: 'seat2',
      },
      {
        id: '2189',
        model: 'Alhambra',
        year: ['2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/seat/3348-250x150.jpg',
        title: 'Килимки в салон Seat Alhambra 2015 - … II покоління EU Minivan рест 7-ми місна',
        titleKey: 'seat3',
      },
      {
        id: '2190',
        model: 'Altea',
        year: ['2004', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/seat/3349-250x150.jpg',
        title: 'Килимки в салон Seat Altea 2004 - 2015 I покоління EU Minivan',
        titleKey: 'seat4',
      },
      {
        id: '2191',
        model: 'Altea',
        year: ['2006', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/seat/3350-250x150.jpg',
        title: 'Килимки в салон Seat Altea 2006 - 2015 I покоління EU Minivan',
        titleKey: 'seat5',
      },
      {
        id: '2192',
        model: 'Altea',
        year: ['2007', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/seat/3351-250x150.jpg',
        title: 'Килимки в салон Seat Altea 2007 - 2015 I покоління EU Minivan',
        titleKey: 'seat6',
      },
      {
        id: '2193',
        model: 'Arona',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/seat/3352-250x150.jpg',
        title: 'Килимки в салон Seat Arona 2017 - … I покоління EU Crossover',
        titleKey: 'seat7',
      },
      {
        id: '2194',
        model: 'Ateca',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/seat/3353-250x150.jpg',
        title: 'Килимки в салон Seat Ateca 2016 - … I покоління EU Crossover',
        titleKey: 'seat8',
      },
      {
        id: '2195',
        model: 'Córdoba',
        year: ['1993', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/seat/3354-250x150.jpg',
        title: 'Килимки в салон Seat Córdoba 1993 - 2002 I покоління EU Sedan',
        titleKey: 'seat9',
      },
      {
        id: '2196',
        model: 'Córdoba',
        year: ['2002', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/seat/3355-250x150.jpg',
        title: 'Килимки в салон Seat Córdoba 2002 - 2006 II покоління EU Sedan дорест',
        titleKey: 'seat10',
      },

      {
        id: '2197',
        model: 'Córdoba',
        year: ['2006', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/seat/3356-250x150.jpg',
        title: 'Килимки в салон Seat Córdoba 2006 - 2008 II покоління EU Sedan рест',
        titleKey: 'seat11',
      },
      {
        id: '2198',
        model: 'Exeo',
        year: ['2008', '2013'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/seat/3357-250x150.jpg',
        title: 'Килимки в салон Seat Exeo 2008 - 2013 I покоління EU Sedan',
        titleKey: 'seat12',
      },
      {
        id: '2199',
        model: 'Exeo',
        year: ['2008', '2013'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/seat/3358-250x150.jpg',
        title: 'Килимки в салон Seat Exeo 2008 - 2013 I покоління EU Universal',
        titleKey: 'seat13',
      },
      {
        id: '2200',
        model: 'Ibiza',
        year: ['1984', '1993'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/seat/3359-250x150.jpg',
        title: 'Килимки в салон Seat Ibiza 1984 - 1993 I покоління EU Universal',
        titleKey: 'seat14',
      },
      {
        id: '2201',
        model: 'Ibiza',
        year: ['1993', '2002'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/seat/3360-250x150.jpg',
        title: 'Килимки в салон Seat Ibiza 1993 - 2002 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'seat15',
      },
      {
        id: '2202',
        model: 'Ibiza',
        year: ['2002', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/seat/3361-250x150.jpg',
        title: 'Килимки в салон Seat Ibiza 2002 - 2008 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'seat16',
      },
      {
        id: '2203',
        model: 'Ibiza',
        year: ['2008', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/seat/3362-250x150.jpg',
        title: 'Килимки в салон Seat Ibiza 2008 - 2012 IV покоління EU Hatchback дорест 5-ти дверна',
        titleKey: 'seat17',
      },
      {
        id: '2204',
        model: 'Ibiza',
        year: ['2012', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/seat/3363-250x150.jpg',
        title: 'Килимки в салон Seat Ibiza 2012 - 2017 IV покоління EU Hatchback рест 5-ти дверна',
        titleKey: 'seat18',
      },
      {
        id: '2205',
        model: 'Ibiza',
        year: ['2008', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/seat/3364-250x150.jpg',
        title: 'Килимки в салон Seat Ibiza 2008 - 2012 IV покоління EU Universal дорест',
        titleKey: 'seat19',
      },
      {
        id: '2206',
        model: 'Ibiza',
        year: ['2012', '2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/seat/3365-250x150.jpg',
        title: 'Килимки в салон Seat Ibiza 2012 - 2017 IV покоління EU Universal рест',
        titleKey: 'seat20',
      },

      {
        id: '2207',
        model: 'Leon',
        year: ['1998', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/seat/3366-250x150.jpg',
        title: 'Килимки в салон Seat Leon 1998 - 2006 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'seat21',
      },
      {
        id: '2208',
        model: 'Leon',
        year: ['2005', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/seat/3367-250x150.jpg',
        title: 'Килимки в салон Seat Leon 2005 - 2012 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'seat22',
      },
      {
        id: '2209',
        model: 'Leon',
        year: ['2012', '2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/seat/3368-250x150.jpg',
        title: 'Килимки в салон Seat Leon 2012 - 2016 III покоління EU Universal дорест',
        titleKey: 'seat23',
      },
      {
        id: '2210',
        model: 'Leon',
        year: ['2016', '2020'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/seat/3369-250x150.jpg',
        title: 'Килимки в салон Seat Leon 2016 - 2020 III покоління EU Universal рест',
        titleKey: 'seat24',
      },
      {
        id: '2211',
        model: 'Leon',
        year: ['2012', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/seat/3370-250x150.jpg',
        title: 'Килимки в салон Seat Leon 2012 - 2020 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'seat25',
      },
      {
        id: '2212',
        model: 'Tarraco',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/seat/3371-250x150.jpg',
        title: 'Килимки в салон Seat Tarraco 2018 - … I покоління EU Crossover 7-місна',
        titleKey: 'seat26',
      },
      {
        id: '2213',
        model: 'Toledo',
        year: ['1999', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/seat/3372-250x150.jpg',
        title: 'Килимки в салон Seat Toledo 1999 - 2004 II покоління EU Sedan',
        titleKey: 'seat27',
      },
      {
        id: '2214',
        model: 'Toledo',
        year: ['2004', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/seat/3373-250x150.jpg',
        title: 'Килимки в салон Seat Toledo 2004 - 2009 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'seat28',
      },
      {
        id: '2215',
        model: 'Toledo',
        year: ['2012', '2019'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/seat/3374-250x150.jpg',
        title: 'Килимки в салон Seat Toledo 2012 - 2019 IV покоління EU Liftback',
        titleKey: 'seat29',
      },
    ],
  },
  /* ---------------------------------- Sehol --------------------------------- */
  Sehol: {
    logo: '/images/catalog/logo/Sehol-100x100.png',
    name: 'Sehol',
    models: [
      {
        id: '2216',
        model: 'E20X',
        year: ['2018'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/sehol/5045-250x150.jpg',
        title: 'Килимки в салон Sehol E20X 2018-… I покоління China Crossover',
        titleKey: 'sehol',
      },
    ],
  },
  /* ---------------------------------- Skoda --------------------------------- */
  Skoda: {
    logo: '/images/catalog/logo/skoda-100x100.png',
    name: 'Skoda',
    models: [
      {
        id: '2217',
        model: 'Citigo',
        year: ['2011', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3301-250x150.jpg',
        title: 'Килимки в салон Skoda Citigo 2011 - 2020 I покоління EU Hatchback 3-х дверна',
        titleKey: 'skoda1',
      },
      {
        id: '2218',
        model: 'Citigo',
        year: ['2011', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/2461-250x150.jpg',
        title: 'Килимки в салон Skoda Citigo 2011 - 2020 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'skoda2',
      },
      {
        id: '2219',
        model: 'Fabia',
        year: ['1999', '2007'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3305-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 1999 - 2007 I покоління EU Hatchback',
        titleKey: 'skoda3',
      },
      {
        id: '2220',
        model: 'Fabia',
        year: ['1999', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/skoda/3302-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 1999 - 2007 I покоління EU Sedan',
        titleKey: 'skoda4',
      },
      {
        id: '2221',
        model: 'Fabia',
        year: ['2000', '2004'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3303-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 2000 - 2004 I покоління EU Universal дорест',
        titleKey: 'skoda5',
      },
      {
        id: '2222',
        model: 'Fabia',
        year: ['2004', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3304-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 2004 - 2007 I покоління EU Universal рест',
        titleKey: 'skoda6',
      },
      {
        id: '2223',
        model: 'Fabia',
        year: ['2007', '2010'],
        body: 'Universal дорест',
        market: 'EU',
        img: '/images/catalog/models/skoda/3307-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 2007 - 2010 II покоління EU Universal дорест',
        titleKey: 'skoda7',
      },
      {
        id: '2224',
        model: 'Fabia',
        year: ['2007', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3306-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 2007 - 2014 II покоління EU Hatchback',
        titleKey: 'skoda8',
      },
      {
        id: '2225',
        model: 'Fabia',
        year: ['2010', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3308-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 2010 - 2014 II покоління EU Universal рест',
        titleKey: 'skoda9',
      },
      {
        id: '2226',
        model: 'Fabia',
        year: ['2014', '2021'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3309-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 2014 - 2021 III покоління EU Hatchback',
        titleKey: 'skoda10',
      },

      {
        id: '2227',
        model: 'Fabia',
        year: ['2014', '2021'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3310-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia 2014 - 2021 III покоління EU Universal',
        titleKey: 'skoda11',
      },
      {
        id: '2228',
        model: 'Fabia',
        year: ['2021'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/5969-250x150.jpg',
        title: 'Килимки в салон Skoda Fabia IV 2021 - ... IV покоління EU Hatchback',
        titleKey: 'skoda12',
      },
      {
        id: '2229',
        model: 'Felicia',
        year: ['1994', '2001'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3311-250x150.jpg',
        title: 'Килимки в салон Skoda Felicia 1994 - 2001 I покоління EU Hatchback',
        titleKey: 'skoda13',
      },
      {
        id: '2230',
        model: 'Felicia',
        year: ['1994', '2001'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3312-250x150.jpg',
        title: 'Килимки в салон Skoda Felicia 1994 - 2001 I покоління EU Universal',
        titleKey: 'skoda14',
      },
      {
        id: '2231',
        model: 'Forman',
        year: ['1987', '1995'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/skoda/3313-250x150.jpg',
        title: 'Килимки в салон Skoda Forman 1987 - 1995 I покоління EU Sedan',
        titleKey: 'skoda15',
      },
      {
        id: '2232',
        model: 'Kamiq',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/skoda/3314-250x150.jpg',
        title: 'Килимки в салон Skoda Kamiq 2019 - ... I покоління EU Crossover',
        titleKey: 'skoda16',
      },
      {
        id: '2233',
        model: 'Karoq',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/skoda/3315-250x150.jpg',
        title: 'Килимки в салон Skoda Karoq 2018 - ... I покоління EU Crossover',
        titleKey: 'skoda17',
      },
      {
        id: '2234',
        model: 'Kodiaq',
        year: ['2016', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/skoda/3316-250x150.jpg',
        title: 'Килимки в салон Skoda Kodiaq 2016 - 2021 I покоління EU Crossover 5-ти місна',
        titleKey: 'skoda18',
      },
      {
        id: '2235',
        model: 'Kodiaq',
        year: ['2016', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/skoda/3317-250x150.jpg',
        title: 'Килимки в салон Skoda Kodiaq 2016 - 2021 I покоління EU Crossover 7-ми місна',
        titleKey: 'skoda19',
      },
      {
        id: '2236',
        model: 'Kodiaq',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/skoda/3318-250x150.jpg',
        title: 'Килимки в салон Skoda Kodiaq 2021 - ... I покоління EU Crossover рест 5-ти місна',
        titleKey: 'skoda20',
      },

      {
        id: '2237',
        model: 'Kodiaq',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/skoda/3319-250x150.jpg',
        title: 'Килимки в салон Skoda Kodiaq 2021 - ... I покоління EU Crossover рест 7-ми місна',
        titleKey: 'skoda21',
      },
      {
        id: '2238',
        model: 'Octavia',
        year: ['1996', '2004'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3320-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 1996 - 2004 I покоління EU Liftback дорест',
        titleKey: 'skoda22',
      },
      {
        id: '2239',
        model: 'Octavia',
        year: ['1996', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3321-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 1996 - 2010 I покоління EU Universal',
        titleKey: 'skoda23',
      },
      {
        id: '2240',
        model: 'Octavia',
        year: ['2004', '2009'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3323-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2004 - 2009 II покоління EU Liftback дорест',
        titleKey: 'skoda24',
      },
      {
        id: '2241',
        model: 'Octavia',
        year: ['2004', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3326-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2004 - 2009 EU Universal дорест',
        titleKey: 'skoda25',
      },
      {
        id: '2242',
        model: 'Octavia',
        year: ['2004', '2010'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3322-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2004 - 2010 EU Liftback рест',
        titleKey: 'skoda26',
      },
      {
        id: '2243',
        model: 'Octavia',
        year: ['2009', '2013'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3325-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2009 - 2013 EU Liftback рест',
        titleKey: 'skoda27',
      },
      {
        id: '2244',
        model: 'Octavia',
        year: ['2009', '2013'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3324-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2009 - 2013 EU Universal рест',
        titleKey: 'skoda28',
      },
      {
        id: '2245',
        model: 'Octavia',
        year: ['2013', '2017'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3328-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2013 - 2017 EU Liftback дорест',
        titleKey: 'skoda29',
      },
      {
        id: '2246',
        model: 'Octavia',
        year: ['2013', '2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3329-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2013 - 2017 EU Universal дорест',
        titleKey: 'skoda30',
      },

      {
        id: '2247',
        model: 'Octavia',
        year: ['2013', '2020'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3333-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2013 - 2020 EU Liftback',
        titleKey: 'skoda31',
      },
      {
        id: '2248',
        model: 'Octavia',
        year: ['2013', '2020'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3332-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2013 - 2020 EU Universal',
        titleKey: 'skoda32',
      },
      {
        id: '2249',
        model: 'Octavia',
        year: ['2017', '2020'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3331-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2017 - 2020 EU Liftback рест',
        titleKey: 'skoda33',
      },
      {
        id: '2250',
        model: 'Octavia',
        year: ['2017', '2020'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3330-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2017 - 2020 EU Universal рест',
        titleKey: 'skoda34',
      },
      {
        id: '2251',
        model: 'Octavia',
        year: ['2020'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3334-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2020 - … EU Liftback',
        titleKey: 'skoda35',
      },
      {
        id: '2252',
        model: 'Octavia',
        year: ['2020'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3335-250x150.jpg',
        title: 'Килимки в салон Skoda Octavia 2020-... IV покоління EU Universal',
        titleKey: 'skoda36',
      },
      {
        id: '2253',
        model: 'Rapid',
        year: ['2012', '2022'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3337-250x150.jpg',
        title: 'Килимки в салон Skoda Rapid 2012-2022 I покоління EU Hatchback',
        titleKey: 'skoda37',
      },
      {
        id: '2254',
        model: 'Rapid',
        year: ['2012'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3336-250x150.jpg',
        title: 'Килимки в салон Skoda Rapid 2012-... I покоління EU Liftback',
        titleKey: 'skoda38',
      },
      {
        id: '2255',
        model: 'Roomster',
        year: ['2006', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/skoda/3338-250x150.jpg',
        title: 'Килимки в салон Skoda Roomster 2006-2015 I покоління EU Minivan',
        titleKey: 'skoda39',
      },
      {
        id: '2256',
        model: 'Scala',
        year: ['2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3339-250x150.jpg',
        title: 'Килимки в салон Skoda Scala 2018-... I покоління EU Hatchback',
        titleKey: 'skoda40',
      },

      {
        id: '2257',
        model: 'Superb',
        year: ['2001', '2008'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/skoda/3340-250x150.jpg',
        title: 'Килимки в салон Skoda Superb 2001-2008 I покоління EU Sedan',
        titleKey: 'skoda41',
      },
      {
        id: '2258',
        model: 'Superb',
        year: ['2008', '2013'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3342-250x150.jpg',
        title: 'Килимки в салон Skoda Superb 2008-2013 II покоління EU Liftback дорест',
        titleKey: 'skoda42',
      },
      {
        id: '2259',
        model: 'Superb',
        year: ['2008', '2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3343-250x150.jpg',
        title: 'Килимки в салон Skoda Superb 2008-2015 II покоління EU Universal',
        titleKey: 'skoda43',
      },
      {
        id: '2260',
        model: 'Superb',
        year: ['2013', '2015'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3341-250x150.jpg',
        title: 'Килимки в салон Skoda Superb 2013-2015 II покоління EU Liftback рест',
        titleKey: 'skoda44',
      },
      {
        id: '2261',
        model: 'Superb',
        year: ['2015'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/skoda/3344-250x150.jpg',
        title: 'Килимки в салон Skoda Superb 2015-... III покоління EU Liftback',
        titleKey: 'skoda45',
      },
      {
        id: '2262',
        model: 'Superb',
        year: ['2015'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/skoda/3345-250x150.jpg',
        title: 'Килимки в салон Skoda Superb 2015-... III покоління EU Universal',
        titleKey: 'skoda46',
      },
      {
        id: '2263',
        model: 'Yeti',
        year: ['2009', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/skoda/3346-250x150.jpg',
        title: 'Килимки в салон Skoda Yeti 2009-2017 I покоління EU Crossover',
        titleKey: 'skoda47',
      },
    ],
  },
  /* ---------------------------------- Smart --------------------------------- */
  Smart: {
    logo: '/images/catalog/logo/smart-100x100.png',
    name: 'Smart',
    models: [
      {
        id: '2264',
        model: 'Forfour',
        year: ['2004', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/smart/3294-250x150.jpg',
        title: 'Килимки в салон Smart Forfour 2004 - 2006 I покоління EU Hatchback',
        titleKey: 'smart1',
      },
      {
        id: '2265',
        model: 'Forfour',
        year: ['2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/smart/1723-250x150.jpg',
        title: 'Килимки в салон Smart Forfour 2014 - … II покоління EU Hatchback',
        titleKey: 'smart2',
      },
      {
        id: '2266',
        model: 'Fortwo',
        year: ['1999', '2003'],
        body: 'Cabriolet дорест',
        market: 'EU',
        img: '/images/catalog/models/smart/3297-250x150.jpg',
        title: 'Килимки в салон Smart Fortwo (A450) 1999 - 2003 I покоління EU Cabriolet дорест',
        titleKey: 'smart3',
      },
      {
        id: '2267',
        model: 'Fortwo',
        year: ['2007', '2014'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/smart/3295-250x150.jpg',
        title: 'Килимки в салон Smart Fortwo (A451) 2007 - 2014 II покоління EU Cabriolet',
        titleKey: 'smart4',
      },
      {
        id: '2268',
        model: 'Fortwo',
        year: ['1998', '2007'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/smart/3296-250x150.jpg',
        title: 'Килимки в салон Smart Fortwo (C450) 1998 - 2007 I покоління EU Coupe',
        titleKey: 'smart5',
      },
      {
        id: '2269',
        model: 'Fortwo',
        year: ['2007', '2014'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/smart/3298-250x150.jpg',
        title: 'Килимки в салон Smart Fortwo (C451) 2007 - 2014 II покоління EU Coupe',
        titleKey: 'smart6',
      },
      {
        id: '2270',
        model: 'Fortwo',
        year: ['2014'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/smart/3299-250x150.jpg',
        title: 'Килимки в салон Smart Fortwo (C453) 2014 - … III покоління EU Coupe',
        titleKey: 'smart7',
      },
      {
        id: '2271',
        model: 'Roadster',
        year: ['2003', '2005'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/smart/3300-250x150.jpg',
        title: 'Килимки в салон Smart Roadster 2003 - 2005 I покоління EU Cabriolet',
        titleKey: 'smart8',
      },
    ],
  },
  /* --------------------------------- SouEast -------------------------------- */
  Soueast: {
    logo: '/images/catalog/logo/SouEast-100x100.png',
    name: 'SouEast',
    models: [
      {
        id: '2272',
        model: 'Lioncel',
        year: ['2000', '2009'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/soueast/5044-250x150.jpg',
        title: 'Килимки в салон SouEast Lioncel 2000-2009 IX покоління China Sedan',
        titleKey: 'soueast',
      },
    ],
  },
  /* -------------------------------- SsangYong ------------------------------- */
  Ssangyong: {
    logo: '/images/catalog/logo/ssang_yong-100x100.png',
    name: 'Ssang Yong',
    models: [
      {
        id: '2273',
        model: 'Actyon',
        year: ['2005', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3280-250x150.jpg',
        title: 'Килимки в салон SsangYong Actyon 2005-2013 I покоління EU Crossover дорест',
        titleKey: 'ssangyong1',
      },
      {
        id: '2274',
        model: 'Actyon',
        year: ['2006', '2018'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3291-250x150.jpg',
        title: 'Килимки в салон SsangYong Actyon Sports 2006-2018 I покоління EU Pickup',
        titleKey: 'ssangyong2',
      },
      {
        id: '2275',
        model: 'Korando',
        year: ['1997', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3282-250x150.jpg',
        title: 'Килимки в салон SsangYong Korando 1997-2006 II покоління EU Crossover',
        titleKey: 'ssangyong3',
      },
      {
        id: '2276',
        model: 'Korando',
        year: ['2010', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3283-250x150.jpg',
        title: 'Килимки в салон SsangYong Korando 2010-2012 III покоління EU Crossover дорест',
        titleKey: 'ssangyong4',
      },
      {
        id: '2277',
        model: 'Korando',
        year: ['2012', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/6018-250x150.jpg',
        title: 'Килимки в салон SsangYong Korando 2012-2019 III покоління EU Crossover рест',
        titleKey: 'ssangyong5',
      },
      {
        id: '2278',
        model: 'Kyron',
        year: ['2005', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3284-250x150.jpg',
        title: 'Килимки в салон SsangYong Kyron 2005-2014 I покоління EU Crossover',
        titleKey: 'ssangyong6',
      },
      {
        id: '2279',
        model: 'Actyon',
        year: ['2013', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3281-250x150.jpg',
        title: 'Килимки в салон SsangYong New Actyon 2013-2018 I покоління EU Crossover рест',
        titleKey: 'ssangyong7',
      },
      {
        id: '2280',
        model: 'Rexton',
        year: ['2001', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3285-250x150.jpg',
        title: 'Килимки в салон SsangYong Rexton 2001-2012 I покоління EU Crossover',
        titleKey: 'ssangyong8',
      },
      {
        id: '2281',
        model: 'Rexton',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3286-250x150.jpg',
        title: 'Килимки в салон SsangYong Rexton 2017-... II покоління EU Crossover',
        titleKey: 'ssangyong9',
      },
      {
        id: '2282',
        model: 'Rexton',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3292-250x150.jpg',
        title: 'Килимки в салон SsangYong Rexton W 2012-2017 I покоління EU Crossover рест 5-ти місна',
        titleKey: 'ssangyong10',
      },

      {
        id: '2283',
        model: 'Rexton',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3287-250x150.jpg',
        title: 'Килимки в салон SsangYong Rexton W 2012-2017 I покоління EU Crossover рест 7-ми місна',
        titleKey: 'ssangyong11',
      },
      {
        id: '2284',
        model: 'Rodius',
        year: ['2004', '2008'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3288-250x150.jpg',
        title: 'Килимки в салон SsangYong Rodius 2004-2008 I покоління EU Minivan дорест 7-ми місна',
        titleKey: 'ssangyong12',
      },
      {
        id: '2285',
        model: 'Rodius',
        year: ['2004', '2013'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3293-250x150.jpg',
        title: 'Килимки в салон SsangYong Rodius 2004-2013 I покоління EU Minivan 9-ти місна',
        titleKey: 'ssangyong13',
      },
      {
        id: '2286',
        model: 'Tivoli',
        year: ['2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3290-250x150.jpg',
        title: 'Килимки в салон SsangYong Tivoli 2015-... I покоління EU Crossover',
        titleKey: 'ssangyong14',
      },
      {
        id: '2287',
        model: 'Tivoli',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/ssangyong/3289-250x150.jpg',
        title: 'Килимки в салон SsangYong Tivoli XLV 2016-... I покоління EU Crossover',
        titleKey: 'ssangyong15',
      },
    ],
  },
  /* --------------------------------- Subaru --------------------------------- */
  Subaru: {
    logo: '/images/catalog/logo/subaru-100x100.png',
    name: 'Subaru',
    models: [
      {
        id: '2288',
        model: 'Ascent',
        year: ['2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/subaru/3228-250x150.jpg',
        title: 'Килимки в салон Subaru Ascent 2018 - ... I покоління USA Crossover 7-ми місна',
        titleKey: 'subaru1',
      },
      {
        id: '2289',
        model: 'BRZ',
        year: ['2012'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/subaru/3229-250x150.jpg',
        title: 'Килимки в салон Subaru BRZ 2012 - ... I покоління EU Coupe',
        titleKey: 'subaru2',
      },
      {
        id: '2290',
        model: 'Crosstrek',
        year: ['2011', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3267-250x150.jpg',
        title: 'Килимки в салон Subaru Crosstrek GP 2011 - 2017 I покоління EU Crossover',
        titleKey: 'subaru3',
      },
      {
        id: '2291',
        model: 'Crosstrek',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/subaru/3230-250x150.jpg',
        title: 'Килимки в салон Subaru Crosstrek GT 2017 - ... II покоління USA Crossover',
        titleKey: 'subaru4',
      },
      {
        id: '2292',
        model: 'Forester',
        year: ['1997', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3231-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SF 1997 - 2002 I покоління EU Crossover',
        titleKey: 'subaru5',
      },
      {
        id: '2293',
        model: 'Forester',
        year: ['1997', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3232-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SF 1997 - 2002 I покоління EU Crossover праве кермо',
        titleKey: 'subaru6',
      },
      {
        id: '2294',
        model: 'Forester',
        year: ['2002', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3233-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SG 2002 - 2005 II покоління EU Crossover дорест',
        titleKey: 'subaru7',
      },
      {
        id: '2295',
        model: 'Forester',
        year: ['2005', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3234-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SG 2005 - 2008 II покоління EU Crossover рест',
        titleKey: 'subaru8',
      },
      {
        id: '2296',
        model: 'Forester',
        year: ['2008', '2012'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/subaru/3235-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SH 2008 - 2012 III покоління EU/USA Crossover',
        titleKey: 'subaru9',
      },
      {
        id: '2297',
        model: 'Forester',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3237-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SJ 2012 - 2018 IV покоління EU Crossover',
        titleKey: 'subaru10',
      },

      {
        id: '2298',
        model: 'Forester',
        year: ['2012', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/subaru/3236-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SJ 2012 - 2018 IV покоління USA Crossover',
        titleKey: 'subaru11',
      },
      {
        id: '2299',
        model: 'Forester',
        year: ['2018', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3263-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SK 2018 - 2021 V покоління EU Crossover дорест',
        titleKey: 'subaru12',
      },
      {
        id: '2300',
        model: 'Forester',
        year: ['2018', '2021'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/subaru/3238-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SK 2018 - 2021 V покоління USA Crossover',
        titleKey: 'subaru13',
      },
      {
        id: '2301',
        model: 'Forester',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3264-250x150.jpg',
        title: 'Килимки в салон Subaru Forester SK 2021 - ... V покоління EU Crossover рест',
        titleKey: 'subaru14',
      },
      {
        id: '2302',
        model: 'Impreza',
        year: ['1992', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3239-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GC, GF, GM 1992 - 2000 I покоління EU Sedan',
        titleKey: 'subaru15',
      },
      {
        id: '2303',
        model: 'Impreza',
        year: ['2000', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3240-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GD 2000 - 2007 II покоління EU Sedan',
        titleKey: 'subaru16',
      },
      {
        id: '2304',
        model: 'Impreza',
        year: ['2000', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/subaru/3241-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GD 2000 - 2007 II покоління EU Universal',
        titleKey: 'subaru17',
      },
      {
        id: '2305',
        model: 'Impreza',
        year: ['2016', '2022'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/subaru/3266-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GK 2016 - 2022 V покоління USA Sedan',
        titleKey: 'subaru18',
      },
      {
        id: '2306',
        model: 'Impreza',
        year: ['2011', '2015'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/subaru/3243-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GP/GJ 2011 - 2015 IV покоління EU Hatchback',
        titleKey: 'subaru19',
      },
      {
        id: '2307',
        model: 'Impreza',
        year: ['2011', '2015'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3242-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GP/GJ 2011 - 2015 IV покоління EU Sedan',
        titleKey: 'subaru20',
      },

      {
        id: '2308',
        model: 'Impreza',
        year: ['2007', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/subaru/3245-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GR 2007 - 2011 III покоління EU Hatchback',
        titleKey: 'subaru21',
      },
      {
        id: '2309',
        model: 'Impreza',
        year: ['2007', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3244-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GR 2007 - 2011 III покоління EU Sedan',
        titleKey: 'subaru22',
      },
      {
        id: '2310',
        model: 'Impreza',
        year: ['2016', '2022'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/subaru/3265-250x150.jpg',
        title: 'Килимки в салон Subaru Impreza GT 2016 - 2022 V покоління USA Hatchback',
        titleKey: 'subaru23',
      },
      {
        id: '2311',
        model: 'Legacy',
        year: ['1994', '1999'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3246-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BD 1994 - 1999 II покоління EU Sedan',
        titleKey: 'subaru24',
      },
      {
        id: '2312',
        model: 'Legacy',
        year: ['1999', '2003'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3251-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BE 1999 - 2003 III покоління EU Sedan',
        titleKey: 'subaru25',
      },
      {
        id: '2313',
        model: 'Legacy',
        year: ['1999', '2003'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/subaru/3252-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BH 1999 - 2003 III покоління EU Universal',
        titleKey: 'subaru26',
      },
      {
        id: '2314',
        model: 'Legacy',
        year: ['2003', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3247-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BL 2003 - 2009 IV покоління EU Sedan',
        titleKey: 'subaru27',
      },
      {
        id: '2315',
        model: 'Legacy',
        year: ['2009', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3249-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BM 2009 - 2014 V покоління EU Sedan',
        titleKey: 'subaru28',
      },
      {
        id: '2316',
        model: 'Legacy',
        year: ['2009', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/subaru/5963-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BM 2009 - 2014 V покоління EU Universal',
        titleKey: 'subaru29',
      },
      {
        id: '2317',
        model: 'Legacy',
        year: ['2009', '2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/subaru/3248-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BM 2009 - 2014 V покоління USA Sedan',
        titleKey: 'subaru30',
      },

      {
        id: '2318',
        model: 'Legacy',
        year: ['2014', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/subaru/3250-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BN 2014 - 2019 VI покоління USA Sedan',
        titleKey: 'subaru31',
      },
      {
        id: '2319',
        model: 'Legacy',
        year: ['2003', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/subaru/3268-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BP 2003 - 2009 IV покоління EU Universal',
        titleKey: 'subaru32',
      },
      {
        id: '2320',
        model: 'Legacy',
        year: ['2019'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/subaru/3269-250x150.jpg',
        title: 'Килимки в салон Subaru Legacy BW 2019 - ... VII покоління EU Universal',
        titleKey: 'subaru33',
      },
      {
        id: '2321',
        model: 'Leone',
        year: ['1984', '1994'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/subaru/3271-250x150.jpg',
        title: 'Килимки в салон Subaru Leone 1984 - 1994 III покоління EU Coupe рест',
        titleKey: 'subaru34',
      },
      {
        id: '2322',
        model: 'Leone',
        year: ['1984', '1994'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/subaru/3272-250x150.jpg',
        title: 'Килимки в салон Subaru Leone 1984 - 1994 III покоління EU Sedan рест',
        titleKey: 'subaru35',
      },
      {
        id: '2323',
        model: 'Leone',
        year: ['1984', '1994'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/subaru/3270-250x150.jpg',
        title: 'Килимки в салон Subaru Leone 1984 - 1994 III покоління EU Universal рест',
        titleKey: 'subaru36',
      },
      {
        id: '2324',
        model: 'Outback',
        year: ['1999', '2003'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/subaru/3253-250x150.jpg',
        title: 'Килимки в салон Subaru Outback BH 1999 - 2003 II покоління EU Universal',
        titleKey: 'subaru37',
      },
      {
        id: '2325',
        model: 'Outback',
        year: ['2009', '2014'],
        body: 'Universal',
        market: 'EU/USA',
        img: '/images/catalog/models/subaru/3255-250x150.jpg',
        title: 'Килимки в салон Subaru Outback BM 2009 - 2014 IV покоління EU/USA Universal',
        titleKey: 'subaru38',
      },
      {
        id: '2326',
        model: 'Outback',
        year: ['2003', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/subaru/3254-250x150.jpg',
        title: 'Килимки в салон Subaru Outback BP 2003 - 2009 III покоління EU Universal',
        titleKey: 'subaru39',
      },
      {
        id: '2327',
        model: 'Outback',
        year: ['2014', '2019'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/subaru/3256-250x150.jpg',
        title: 'Килимки в салон Subaru Outback BS 2014 - 2019 V покоління USA Universal',
        titleKey: 'subaru40',
      },

      {
        id: '2328',
        model: 'Outback',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3257-250x150.jpg',
        title: 'Килимки в салон Subaru Outback BT 2019 - ... VI покоління EU Crossover',
        titleKey: 'subaru41',
      },
      {
        id: '2329',
        model: 'Tribeca',
        year: ['2005', '2014'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/subaru/3258-250x150.jpg',
        title: 'Килимки в салон Subaru Tribeca B-9 2005 - 2014 I покоління EU/USA Crossover',
        titleKey: 'subaru42',
      },
      {
        id: '2330',
        model: 'WRX',
        year: ['2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/subaru/3259-250x150.jpg',
        title: 'Килимки в салон Subaru WRX (VA) 2014 - ... IV покоління USA Sedan',
        titleKey: 'subaru43',
      },
      {
        id: '2331',
        model: 'WRX',
        year: ['2007', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/subaru/3273-250x150.jpg',
        title: 'Килимки в салон Subaru WRX STI (GR) 2007 - 2014 III покоління EU Hatchback',
        titleKey: 'subaru44',
      },
      {
        id: '2332',
        model: 'XV',
        year: ['2011', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3260-250x150.jpg',
        title: 'Килимки в салон Subaru XV (GP) 2011 - 2016 I покоління EU Crossover дорест',
        titleKey: 'subaru45',
      },
      {
        id: '2333',
        model: 'XV',
        year: ['2016', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3261-250x150.jpg',
        title: 'Килимки в салон Subaru XV (GP) 2016 - 2017 I покоління EU Crossover рест',
        titleKey: 'subaru46',
      },
      {
        id: '2334',
        model: 'XV',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/subaru/3262-250x150.jpg',
        title: 'Килимки в салон Subaru XV (GT) 2017 - ... II покоління EU Crossover',
        titleKey: 'subaru47',
      },
    ],
  },
  /* --------------------------------- Suzuki --------------------------------- */
  Suzuki: {
    logo: '/images/catalog/logo/suzuki-100x100.png',
    name: 'Suzuki',
    models: [
      {
        id: '2335',
        model: 'Alto',
        year: ['2002', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3222-250x150.jpg',
        title: 'Килимки в салон Suzuki Alto 2002-2006 IV покоління EU Hatchback',
        titleKey: 'suzuki1',
      },
      {
        id: '2336',
        model: 'Alto',
        year: ['2009', '2014'],
        body: 'Hatchback',
        market: 'Japan',
        img: '/images/catalog/models/suzuki/1880-250x150.jpg',
        title: 'Килимки в салон Suzuki Alto 2009-2014 VII покоління Japan Hatchback',
        titleKey: 'suzuki2',
      },
      {
        id: '2337',
        model: 'Celerio',
        year: ['2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3220-250x150.jpg',
        title: 'Килимки в салон Suzuki Celerio 2014-… II покоління EU Hatchback',
        titleKey: 'suzuki3',
      },
      {
        id: '2338',
        model: 'Grand Vitara',
        year: ['1998', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3223-250x150.jpg',
        title: 'Килимки в салон Suzuki Grand Vitara 1998-2005 I покоління EU Crossover Long',
        titleKey: 'suzuki4',
      },
      {
        id: '2339',
        model: 'Grand Vitara',
        year: ['1998', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3199-250x150.jpg',
        title: 'Килимки в салон Suzuki Grand Vitara 1998-2005 I покоління EU Crossover 3-х дверна',
        titleKey: 'suzuki5',
      },
      {
        id: '2340',
        model: 'Grand Vitara',
        year: ['1998', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3200-250x150.jpg',
        title: 'Килимки в салон Suzuki Grand Vitara 1998-2005 I покоління EU Crossover 5-ти дверна',
        titleKey: 'suzuki6',
      },
      {
        id: '2341',
        model: 'Grand Vitara',
        year: ['2005', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3196-250x150.jpg',
        title: 'Килимки в салон Suzuki Grand Vitara 2005-2012 II покоління EU Crossover дорест 5-ти дверна',
        titleKey: 'suzuki7',
      },
      {
        id: '2342',
        model: 'Grand Vitara',
        year: ['2005', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3197-250x150.jpg',
        title: 'Килимки в салон Suzuki Grand Vitara 2005-2017 II покоління EU Crossover 3-х дверна',
        titleKey: 'suzuki8',
      },
      {
        id: '2343',
        model: 'Grand Vitara',
        year: ['2012', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3198-250x150.jpg',
        title: 'Килимки в салон Suzuki Grand Vitara 2012-2017 II покоління EU Crossover рест 5-ти дверна',
        titleKey: 'suzuki9',
      },
      {
        id: '2344',
        model: 'Ignis',
        year: ['2000', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3202-250x150.jpg',
        title: 'Килимки в салон Suzuki Ignis 2000-2006 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'suzuki10',
      },

      {
        id: '2345',
        model: 'Ignis',
        year: ['2003', '2008'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3224-250x150.jpg',
        title: 'Килимки в салон Suzuki Ignis 2003-2008 II покоління EU Hatchback',
        titleKey: 'suzuki11',
      },
      {
        id: '2346',
        model: 'Ignis',
        year: ['2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3201-250x150.jpg',
        title: 'Килимки в салон Suzuki Ignis 2016-… III покоління EU Hatchback',
        titleKey: 'suzuki12',
      },
      {
        id: '2347',
        model: 'Ignis',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Suzuki Ignis 2020-… III покоління EU Crossover hybrid',
        titleKey: 'suzuki13',
      },
      {
        id: '2348',
        model: 'Jimny',
        year: ['1998', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3203-250x150.jpg',
        title: 'Килимки в салон Suzuki Jimny 1998-2018 III покоління EU Crossover',
        titleKey: 'suzuki14',
      },
      {
        id: '2349',
        model: 'Jimny',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3204-250x150.jpg',
        title: 'Килимки в салон Suzuki Jimny 2018-… IV покоління EU Crossover',
        titleKey: 'suzuki15',
      },
      {
        id: '2350',
        model: 'Kizashi',
        year: ['2009', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3205-250x150.jpg',
        title: 'Килимки в салон Suzuki Kizashi 2009-2016 I покоління EU Sedan',
        titleKey: 'suzuki16',
      },
      {
        id: '2351',
        model: 'Liana',
        year: ['2001', '2007'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3206-250x150.jpg',
        title: 'Килимки в салон Suzuki Liana 2001-2007 I покоління EU Hatchback',
        titleKey: 'suzuki17',
      },
      {
        id: '2352',
        model: 'Liana',
        year: ['2001', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3208-250x150.jpg',
        title: 'Килимки в салон Suzuki Liana 2001-2007 I покоління EU Sedan',
        titleKey: 'suzuki18',
      },
      {
        id: '2353',
        model: 'Liana',
        year: ['2001', '2007'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3207-250x150.jpg',
        title: 'Килимки в салон Suzuki Liana 2001-2007 I покоління EU Sedan повний привід',
        titleKey: 'suzuki19',
      },
      {
        id: '2354',
        model: 'Liana',
        year: ['2001', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3207-250x150.jpg',
        title: 'Килимки в салон Suzuki Liana 2001-2007 I покоління EU Universal повний привід',
        titleKey: 'suzuki20',
      },

      {
        id: '2355',
        model: 'Sidekick/Escudo',
        year: ['1988', '1997'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3209-250x150.jpg',
        title: 'Килимки в салон Suzuki Sidekick/Escudo 1988-1997 I покоління EU Crossover',
        titleKey: 'suzuki21',
      },
      {
        id: '2356',
        model: 'Splash',
        year: ['2008', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3210-250x150.jpg',
        title: 'Килимки в салон Suzuki Splash 2008-2018 I покоління EU Hatchback',
        titleKey: 'suzuki22',
      },
      {
        id: '2357',
        model: 'Swift',
        year: ['1988', '1995'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3211-250x150.jpg',
        title: 'Килимки в салон Suzuki Swift 1988-1995 II покоління EU Sedan',
        titleKey: 'suzuki23',
      },
      {
        id: '2358',
        model: 'Swift',
        year: ['1995', '2004'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3226-250x150.jpg',
        title: 'Килимки в салон Suzuki Swift 1995-2004 III покоління EU Hatchback',
        titleKey: 'suzuki24',
      },
      {
        id: '2359',
        model: 'Swift',
        year: ['2005', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3212-250x150.jpg',
        title: 'Килимки в салон Suzuki Swift 2005-2010 IV покоління EU Hatchback 5-ти дверна',
        titleKey: 'suzuki25',
      },
      {
        id: '2360',
        model: 'Swift',
        year: ['2010', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3225-250x150.jpg',
        title: 'Килимки в салон Suzuki Swift 2010-2017 V покоління EU Hatchback 3-х дверна',
        titleKey: 'suzuki26',
      },
      {
        id: '2361',
        model: 'Swift',
        year: ['2010', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3213-250x150.jpg',
        title: 'Килимки в салон Suzuki Swift 2010-2017 V покоління EU Hatchback 5-ти дверна',
        titleKey: 'suzuki27',
      },
      {
        id: '2362',
        model: 'SX4',
        year: ['2006', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3214-250x150.jpg',
        title: 'Килимки в салон Suzuki SX4 2006-2009 I покоління EU Hatchback дорест',
        titleKey: 'suzuki28',
      },
      {
        id: '2363',
        model: 'SX4',
        year: ['2006', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3227-250x150.jpg',
        title: 'Килимки в салон Suzuki SX4 2006-2014 I покоління EU Sedan',
        titleKey: 'suzuki29',
      },
      {
        id: '2364',
        model: 'SX4',
        year: ['2009', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3215-250x150.jpg',
        title: 'Килимки в салон Suzuki SX4 2009-2014 I покоління EU Hatchback рест',
        titleKey: 'suzuki30',
      },

      {
        id: '2365',
        model: 'SX4',
        year: ['2013', '2021'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3216-250x150.jpg',
        title: 'Килимки в салон Suzuki SX4 2013-2021 II покоління EU Hatchback',
        titleKey: 'suzuki31',
      },
      {
        id: '2366',
        model: 'SX4',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3217-250x150.jpg',
        title: 'Килимки в салон Suzuki SX4 2021-... III покоління EU Crossover',
        titleKey: 'suzuki32',
      },
      {
        id: '2367',
        model: 'SX4',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3217-250x150.jpg',
        title: 'Килимки в салон Suzuki SX4 S-Cross 2021-... III покоління EU Crossover hybrid',
        titleKey: 'suzuki33',
      },
      {
        id: '2368',
        model: 'Vitara',
        year: ['2015', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3219-250x150.jpg',
        title: 'Килимки в салон Suzuki Vitara 2015-2018 II покоління EU Crossover дорест',
        titleKey: 'suzuki34',
      },
      {
        id: '2369',
        model: 'Vitara',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/suzuki/3218-250x150.jpg',
        title: 'Килимки в салон Suzuki Vitara 2018-... II покоління EU Crossover рест',
        titleKey: 'suzuki35',
      },
      {
        id: '2370',
        model: 'XL 7',
        year: ['1998', '2006'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/suzuki/3221-250x150.jpg',
        title: 'Килимки в салон Suzuki XL 7 1998-2006 I покоління USA Crossover',
        titleKey: 'suzuki36',
      },
    ],
  },
  /* ---------------------------------- Tesla --------------------------------- */
  Tesla: {
    logo: '/images/catalog/logo/tesla-100x100.png',
    name: 'Tesla',
    models: [
      {
        id: '2371',
        model: 'Model 3',
        year: ['2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/tesla/1898-250x150.jpg',
        title: 'Килимки в салон Tesla Model 3 2017-... I покоління USA Sedan',
        titleKey: 'tesla1',
      },
      {
        id: '2372',
        model: 'Model S',
        year: ['2012'],
        body: 'Liftback',
        market: 'USA',
        img: '/images/catalog/models/tesla/3189-250x150.jpg',
        title: 'Килимки в салон Tesla Model S 2012-... I покоління USA Liftback',
        titleKey: 'tesla2',
      },
      {
        id: '2373',
        model: 'Model X',
        year: ['2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/tesla/3190-250x150.jpg',
        title: 'Килимки в салон Tesla Model X 2015-... I покоління USA Crossover 5-ти місна',
        titleKey: 'tesla3',
      },
      {
        id: '2374',
        model: 'Model X',
        year: ['2015'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/tesla/3191-250x150.jpg',
        title: 'Килимки в салон Tesla Model X 2015-... I покоління USA Crossover 6-ти місна',
        titleKey: 'tesla4',
      },
      {
        id: '2375',
        model: 'Model X',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/tesla/3192-250x150.jpg',
        title: 'Килимки в салон Tesla Model X 2017-... I покоління USA Crossover рест 7-ми місна',
        titleKey: 'tesla5',
      },
      {
        id: '2376',
        model: 'Model X',
        year: ['2015', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/tesla/3193-250x150.jpg',
        title: 'Килимки в салон Tesla Model X 2015-2017 I покоління USA Crossover 7-ми місна',
        titleKey: 'tesla6',
      },
      {
        id: '2377',
        model: 'Model S',
        year: ['2021'],
        body: 'Liftback',
        market: 'USA',
        img: '/images/catalog/models/tesla/5978-250x150.jpg',
        title: 'Килимки в салон Tesla Model S Plaid 2021-... I покоління USA Liftback',
        titleKey: 'tesla7',
      },
      {
        id: '2378',
        model: 'Model Y',
        year: ['2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/tesla/3194-250x150.jpg',
        title: 'Килимки в салон Tesla Model Y 2020-... I покоління USA Crossover 5-ти місна',
        titleKey: 'tesla8',
      },
      {
        id: '2379',
        model: 'Model Y',
        year: ['2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/tesla/3195-250x150.jpg',
        title: 'Килимки в салон Tesla Model Y 2020-... I покоління USA Crossover 7-ми місна',
        titleKey: 'tesla9',
      },
    ],
  },
  /* --------------------------------- Toyota --------------------------------- */
  Toyota: {
    logo: '/images/catalog/logo/toyota-100x100.png',
    name: 'Toyota',
    models: [
      {
        id: '2380',
        model: '4Runner',
        year: ['1989', '1995'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/2999-250x150.jpg',
        title: 'Килимки в салон Toyota 4Runner (N120) 1989 - 1995 II покоління EU Crossover',
        titleKey: 'toyota1',
      },
      {
        id: '2381',
        model: '4Runner',
        year: ['1995', '2003'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title: 'Килимки в салон Toyota 4Runner (N180) 1995 - 2003 III покоління Crossover',
        titleKey: 'toyota2',
      },
      {
        id: '2382',
        model: '4Runner',
        year: ['2009', '2014'],
        body: 'Crossover дорест 7-ми місна',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/2996-250x150.jpg',
        title: 'Килимки в салон Toyota 4Runner (N280) 2009 - 2014 V покоління EU/USA Crossover дорест 7-ми місна',
        titleKey: 'toyota3',
      },
      {
        id: '2383',
        model: '4Runner',
        year: ['2014'],
        body: 'Crossover рест. 7-ми місна',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/2997-250x150.jpg',
        title: 'Килимки в салон Toyota 4Runner (N280) 2014 - … V покоління EU/USA Crossover рест. 7-ми місна',
        titleKey: 'toyota4',
      },
      {
        id: '2384',
        model: '4Runner',
        year: ['2003', '2009'],
        body: 'Crossover',
        market: '',
        img: '/images/catalog/models/toyota/2998-250x150.jpg',
        title: 'Килимки в салон Toyota 4Runner 2003 - 2009 IV покоління Crossover',
        titleKey: 'toyota5',
      },
      {
        id: '2385',
        model: 'Alphard',
        year: ['2002', '2008'],
        body: 'Minivan',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3000-250x150.jpg',
        title: 'Килимки в салон Toyota Alphard 2002 - 2008 I покоління Japan Minivan',
        titleKey: 'toyota6',
      },
      {
        id: '2386',
        model: 'Alphard',
        year: ['2015', '2018'],
        body: 'Minivan',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3001-250x150.jpg',
        title: 'Килимки в салон Toyota Alphard AH30 2015 - 2018 III покоління Japan Minivan дорест 7-ми місна',
        titleKey: 'toyota7',
      },
      {
        id: '2387',
        model: 'Alphard',
        year: ['2018'],
        body: 'Minivan',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3002-250x150.jpg',
        title: 'Килимки в салон Toyota Alphard AH30 2018 - … III покоління Japan Minivan рест 7-ми місна праве кермо',
        titleKey: 'toyota8',
      },
      {
        id: '2388',
        model: 'Auris',
        year: ['2009', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3005-250x150.jpg',
        title: 'Килимки в салон Toyota Auris E150 (Turkish Assembly) 2009 - 2012 I покоління EU Hatchback рест.',
        titleKey: 'toyota9',
      },
      {
        id: '2389',
        model: 'Auris',
        year: ['2006', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3004-250x150.jpg',
        title: 'Килимки в салон Toyota Auris E150 2006 - 2012 I покоління EU Hatchback 3-х дверна передній привіт',
        titleKey: 'toyota10',
      },

      {
        id: '2390',
        model: 'Auris',
        year: ['2006', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3003-250x150.jpg',
        title: 'Килимки в салон Toyota Auris E150 2006 - 2012 I покоління EU Hatchback 5-ти дверна передній привіт',
        titleKey: 'toyota11',
      },
      {
        id: '2391',
        model: 'Auris',
        year: ['2012', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3006-250x150.jpg',
        title: 'Килимки в салон Toyota Auris E180 (Turkish Assembly) 2012 - 2018 II покоління EU Hatchback',
        titleKey: 'toyota12',
      },
      {
        id: '2392',
        model: 'Auris',
        year: ['2012', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3007-250x150.jpg',
        title: 'Килимки в салон Toyota Auris E180 2012 - 2018 II покоління EU Hatchback',
        titleKey: 'toyota13',
      },
      {
        id: '2393',
        model: 'Auris',
        year: ['2012', '2018'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3008-250x150.jpg',
        title: 'Килимки в салон Toyota Auris E180 2012 - 2018 II покоління EU Hatchback Hybrid',
        titleKey: 'toyota14',
      },
      {
        id: '2394',
        model: 'Auris',
        year: ['2012', '2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/toyota/3009-250x150.jpg',
        title: 'Килимки в салон Toyota Auris E180 2012 - 2018 II покоління EU Universal',
        titleKey: 'toyota15',
      },
      {
        id: '2395',
        model: 'Avalon',
        year: ['2005', '2012'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3010-250x150.jpg',
        title: 'Килимки в салон Toyota Avalon XX30 2005 - 2012 III покоління USA Sedan',
        titleKey: 'toyota16',
      },
      {
        id: '2396',
        model: 'Avalon',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3012-250x150.jpg',
        title: 'Килимки в салон Toyota Avalon XX40 XLE 2012 - 2018 IV покоління USA Sedan',
        titleKey: 'toyota17',
      },
      {
        id: '2397',
        model: 'Avalon',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3011-250x150.jpg',
        title: 'Килимки в салон Toyota Avalon XX40 XLE 2012 - 2018 IV покоління USA Sedan Hybrid',
        titleKey: 'toyota18',
      },
      {
        id: '2398',
        model: 'Avensis',
        year: ['1998', '2003'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3013-250x150.jpg',
        title: 'Килимки в салон Toyota Avensis T22 1998 - 2003 I покоління EU Sedan',
        titleKey: 'toyota19',
      },
      {
        id: '2399',
        model: 'Avensis',
        year: ['2003', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3016-250x150.jpg',
        title: 'Килимки в салон Toyota Avensis T25 2003 - 2009 II покоління EU Hatchback',
        titleKey: 'toyota20',
      },

      {
        id: '2400',
        model: 'Avensis',
        year: ['2003', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3014-250x150.jpg',
        title: 'Килимки в салон Toyota Avensis T25 2003 - 2009 II покоління EU Sedan',
        titleKey: 'toyota21',
      },
      {
        id: '2401',
        model: 'Avensis',
        year: ['2003', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/toyota/3015-250x150.jpg',
        title: 'Килимки в салон Toyota Avensis T25 2003 - 2009 II покоління EU Universal',
        titleKey: 'toyota22',
      },
      {
        id: '2402',
        model: 'Avensis',
        year: ['2009', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3017-250x150.jpg',
        title: 'Килимки в салон Toyota Avensis T27 2009 - 2018 III покоління EU Sedan',
        titleKey: 'toyota23',
      },
      {
        id: '2403',
        model: 'Avensis',
        year: ['2009', '2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/toyota/3019-250x150.jpg',
        title: 'Килимки в салон Toyota Avensis T27 2009 - 2018 III покоління EU Universal',
        titleKey: 'toyota24',
      },
      {
        id: '2404',
        model: 'Avensis',
        year: ['2009', '2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/toyota/3019-250x150.jpg',
        title: 'Килимки в салон Toyota Avensis T27 2009 - 2018 III покоління EU Universal праве кермо',
        titleKey: 'toyota25',
      },
      {
        id: '2405',
        model: 'Avensis',
        year: ['2001', '2009'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3018-250x150.jpg',
        title: 'Килимки в салон Toyota Avensis Verso 2001 - 2009 I покоління EU Minivan 7-ми місна',
        titleKey: 'toyota26',
      },
      {
        id: '2406',
        model: 'Aygo',
        year: ['2005', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3020-250x150.jpg',
        title: 'Килимки в салон Toyota Aygo 2005 - 2014 I покоління EU Hatchback 3-х дверна',
        titleKey: 'toyota27',
      },
      {
        id: '2407',
        model: 'Aygo',
        year: ['2005', '2014'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3021-250x150.jpg',
        title: 'Килимки в салон Toyota Aygo 2005 - 2014 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'toyota28',
      },
      {
        id: '2408',
        model: 'Aygo',
        year: ['2014', '2021'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3022-250x150.jpg',
        title: 'Килимки в салон Toyota Aygo AB40 2014 - 2021 II покоління EU Hatchback',
        titleKey: 'toyota29',
      },
      {
        id: '2409',
        model: 'BZ4X',
        year: ['2022'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/5966-250x150.jpg',
        title: 'Килимки в салон Toyota BZ4X 2022 - … I покоління EU Crossover Electro',
        titleKey: 'toyota30',
      },

      {
        id: '2410',
        model: 'C-HR',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3048-250x150.jpg',
        title: 'Килимки в салон Toyota C-HR 2016 - 2020 I покоління EU Crossover Hybrid',
        titleKey: 'toyota31',
      },
      {
        id: '2411',
        model: 'C-HR',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3047-250x150.jpg',
        title: 'Килимки в салон Toyota C-HR 2016 - … I покоління EU Crossover',
        titleKey: 'toyota32',
      },
      {
        id: '2412',
        model: 'C-HR',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3050-250x150.jpg',
        title: 'Килимки в салон Toyota C-HR 2020 - … I покоління EU Crossover Hybrid',
        titleKey: 'toyota33',
      },
      {
        id: '2413',
        model: 'C-HR',
        year: ['2016'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/toyota/3051-250x150.jpg',
        title: 'Килимки в салон Toyota C-HR EV 2016 - … I покоління China Crossover',
        titleKey: 'toyota34',
      },
      {
        id: '2414',
        model: 'C-HR',
        year: ['2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3049-250x150.jpg',
        title: 'Килимки в салон Toyota C-HR Turkish Assembly 2016 - … I покоління EU Crossover',
        titleKey: 'toyota35',
      },
      {
        id: '2415',
        model: 'Camry',
        year: ['2003', '2009'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/toyota/3028-250x150.jpg',
        title: 'Килимки в салон Toyota Camry Solara (XV30) 2003 - 2009 II покоління USA Coupe',
        titleKey: 'toyota36',
      },
      {
        id: '2416',
        model: 'Camry',
        year: ['1982', '1986'],
        body: 'Sedan',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3029-250x150.jpg',
        title: 'Килимки в салон Toyota Camry V10 (Vista) 1982 - 1986 I покоління Japan Sedan',
        titleKey: 'toyota37',
      },
      {
        id: '2417',
        model: 'Camry',
        year: ['1986', '1991'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3038-250x150.jpg',
        title: 'Килимки в салон Toyota Camry V20 1986 - 1991 II покоління EU Sedan',
        titleKey: 'toyota38',
      },
      {
        id: '2418',
        model: 'Camry',
        year: ['1996', '2001'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3032-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV20 1996 - 2001 IV покоління EU Sedan',
        titleKey: 'toyota39',
      },
      {
        id: '2419',
        model: 'Camry',
        year: ['1996', '2001'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/toyota/3030-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV20 1996 - 2001 IV покоління EU Universal',
        titleKey: 'toyota40',
      },

      {
        id: '2420',
        model: 'Camry',
        year: ['1996', '2001'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3031-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV20 1996 - 2001 IV покоління USA Sedan',
        titleKey: 'toyota41',
      },
      {
        id: '2421',
        model: 'Camry',
        year: ['2001', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3033-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV30 2001 - 2006 V покоління EU Sedan',
        titleKey: 'toyota42',
      },
      {
        id: '2422',
        model: 'Camry',
        year: ['2006', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3023-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV40 (3.5L) 2006 - 2011 VI покоління EU Sedan',
        titleKey: 'toyota43',
      },
      {
        id: '2423',
        model: 'Camry',
        year: ['2006', '2011'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3023-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV40 (3.5L) 2006 - 2011 VI покоління USA Sedan',
        titleKey: 'toyota44',
      },
      {
        id: '2424',
        model: 'Camry',
        year: ['2011', '2014'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3026-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV50 2011 - 2014 VII покоління USA Sedan Hybrid',
        titleKey: 'toyota45',
      },
      {
        id: '2425',
        model: 'Camry',
        year: ['2011', '2014'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3034-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV50 2011 - 2014 VII покоління EU/USA Sedan',
        titleKey: 'toyota46',
      },
      {
        id: '2426',
        model: 'Camry',
        year: ['2014', '2017'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3027-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV55 2014 - 2017 VII покоління USA Sedan Hybrid',
        titleKey: 'toyota47',
      },
      {
        id: '2427',
        model: 'Camry',
        year: ['2014', '2017'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3035-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV55 2014 - 2017 VII покоління EU/USA Sedan',
        titleKey: 'toyota48',
      },
      {
        id: '2428',
        model: 'Camry',
        year: ['2017', '2020'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3036-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV70 2017 - 2020 VIII покоління EU/USA Sedan Hybrid',
        titleKey: 'toyota49',
      },
      {
        id: '2429',
        model: 'Camry',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3024-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV70 2017 - … VIII покоління EU/USA Sedan',
        titleKey: 'toyota50',
      },

      {
        id: '2430',
        model: 'Camry',
        year: ['2020'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3037-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV70 2020 - … VIII покоління EU Sedan Hybrid',
        titleKey: 'toyota51',
      },
      {
        id: '2431',
        model: 'Carina',
        year: ['1989', '1993'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3039-250x150.jpg',
        title: 'Килимки в салон Toyota Carina 1989 - 1993 V покоління EU Sedan',
        titleKey: 'toyota52',
      },
      {
        id: '2432',
        model: 'Carina',
        year: ['1993', '1998'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3043-250x150.jpg',
        title: 'Килимки в салон Toyota Carina E (T190) 1993 - 1998 VI покоління EU Liftback',
        titleKey: 'toyota53',
      },
      {
        id: '2433',
        model: 'Carina',
        year: ['1993', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3040-250x150.jpg',
        title: 'Килимки в салон Toyota Carina E (T190) 1993 - 1998 VI покоління EU Sedan',
        titleKey: 'toyota54',
      },
      {
        id: '2434',
        model: 'Carina',
        year: ['1993', '1998'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/toyota/3042-250x150.jpg',
        title: 'Килимки в салон Toyota Carina E (T190) 1993 - 1998 VI покоління EU Universal',
        titleKey: 'toyota55',
      },
      {
        id: '2435',
        model: 'Carina',
        year: ['1984', '1992'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3041-250x150.jpg',
        title: 'Килимки в салон Toyota Carina II 1984 - 1992 II покоління EU Sedan',
        titleKey: 'toyota56',
      },
      {
        id: '2436',
        model: 'Celica',
        year: ['1993', '1999'],
        body: 'Coupe',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3044-250x150.jpg',
        title: 'Килимки в салон Toyota Celica 1993 - 1999 VI покоління Japan Coupe',
        titleKey: 'toyota57',
      },
      {
        id: '2437',
        model: 'Celica',
        year: ['1999', '2006'],
        body: 'Coupe',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3045-250x150.jpg',
        title: 'Килимки в салон Toyota Celica 1999 - 2006 VII покоління Japan Coupe',
        titleKey: 'toyota58',
      },
      {
        id: '2438',
        model: 'Celica',
        year: ['1999', '2006'],
        body: 'Coupe',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3046-250x150.jpg',
        title: 'Килимки в салон Toyota Celica 1999 - 2006 VII покоління Japan Coupe',
        titleKey: 'toyota59',
      },
      {
        id: '2439',
        model: 'Corolla Cross',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3069-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla Cross XG10 2020 - … I покоління EU Crossover Hybrid',
        titleKey: 'toyota60',
      },

      {
        id: '2440',
        model: 'Corolla',
        year: ['1991', '1997'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3053-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E10 1991 - 1997 VII покоління EU Hatchback',
        titleKey: 'toyota61',
      },
      {
        id: '2441',
        model: 'Corolla',
        year: ['1991', '1997'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3054-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E10 1991 - 1997 VII покоління EU Liftback',
        titleKey: 'toyota62',
      },
      {
        id: '2442',
        model: 'Corolla',
        year: ['1991', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3052-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E10 1991 - 1997 VII покоління EU Sedan',
        titleKey: 'toyota63',
      },
      {
        id: '2443',
        model: 'Corolla',
        year: ['1995', '1999'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3064-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E11 1995 - 1999 VIII покоління EU Sedan',
        titleKey: 'toyota64',
      },
      {
        id: '2444',
        model: 'Corolla',
        year: ['1995', '1999'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/toyota/3067-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E11 1995 - 1999 VIII покоління EU Universal',
        titleKey: 'toyota65',
      },
      {
        id: '2445',
        model: 'Corolla',
        year: ['1995', '2002'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3056-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E11 1995 - 2002 VIII покоління EU Liftback',
        titleKey: 'toyota66',
      },
      {
        id: '2446',
        model: 'Corolla',
        year: ['1999', '2002'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3055-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E11 1999 - 2002 VIII покоління EU Sedan',
        titleKey: 'toyota67',
      },
      {
        id: '2447',
        model: 'Corolla',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3066-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E12 2000 - 2006 IX покоління Sedan',
        titleKey: 'toyota68',
      },
      {
        id: '2448',
        model: 'Corolla',
        year: ['2000', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3058-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E12 2000 - 2006 IX покоління EU Hatchback',
        titleKey: 'toyota69',
      },
      {
        id: '2449',
        model: 'Corolla',
        year: ['2000', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3057-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E12 2000 - 2006 IX покоління EU Sedan',
        titleKey: 'toyota70',
      },

      {
        id: '2450',
        model: 'Corolla',
        year: ['2000', '2006'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/toyota/3065-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E12 2000 - 2006 IX покоління EU Universal',
        titleKey: 'toyota71',
      },
      {
        id: '2451',
        model: 'Corolla',
        year: ['2006', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3059-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E14/E15 2006 - 2012 X покоління EU Sedan',
        titleKey: 'toyota72',
      },
      {
        id: '2452',
        model: 'Corolla',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3060-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E16/E17 2012 - 2018 XI покоління EU Sedan',
        titleKey: 'toyota73',
      },
      {
        id: '2453',
        model: 'Corolla',
        year: ['2012', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3061-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E16/E17 2012 - 2018 XI покоління USA Sedan',
        titleKey: 'toyota74',
      },
      {
        id: '2454',
        model: 'Corolla',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3062-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E21 2018 - … XII покоління EU Sedan',
        titleKey: 'toyota75',
      },
      {
        id: '2455',
        model: 'Corolla',
        year: ['2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3068-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E21 2018 - … XII покоління EU Sedan Hybrid',
        titleKey: 'toyota76',
      },
      {
        id: '2456',
        model: 'Corolla',
        year: ['1987', '1992'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3063-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla E9 1987 - 1992 VI покоління EU Liftback',
        titleKey: 'toyota77',
      },
      {
        id: '2457',
        model: 'Corolla Verso',
        year: ['2001', '2007'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3070-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla Verso (Spacio) E120 2001 - 2007 II покоління EU Minivan',
        titleKey: 'toyota78',
      },
      {
        id: '2458',
        model: 'Corolla Verso',
        year: ['2004', '2009'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3071-250x150.jpg',
        title: 'Килимки в салон Toyota Corolla Verso AR10 2004 - 2009 III покоління EU Minivan 7-ми місна',
        titleKey: 'toyota79',
      },
      {
        id: '2459',
        model: 'Echo',
        year: ['1999', '2002'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3072-250x150.jpg',
        title: 'Килимки в салон Toyota Echo 1999 - 2002 I покоління USA Sedan',
        titleKey: 'toyota80',
      },

      {
        id: '2460',
        model: 'FJ Cruiser',
        year: ['2006', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3073-250x150.jpg',
        title: 'Килимки в салон Toyota FJ Cruiser 2006 - 2018 I покоління USA Crossover',
        titleKey: 'toyota81',
      },
      {
        id: '2461',
        model: 'Fortuner',
        year: ['2004', '2015'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3074-250x150.jpg',
        title: 'Килимки в салон Toyota Fortuner 2004 - 2015 I покоління EU Crossover',
        titleKey: 'toyota82',
      },
      {
        id: '2462',
        model: 'GT86',
        year: ['2012', '2021'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/toyota/3075-250x150.jpg',
        title: 'Килимки в салон Toyota GT86 2012 - 2021 I покоління EU Coupe',
        titleKey: 'toyota83',
      },
      {
        id: '2463',
        model: 'Hiace',
        year: ['1989', '2004'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/toyota/3076-250x150.jpg',
        title: 'Килимки в салон Toyota Hiace H100 1989 - 2004 IV покоління EU VAN',
        titleKey: 'toyota84',
      },
      {
        id: '2464',
        model: 'Hiace',
        year: ['2004', '2019'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/toyota/3078-250x150.jpg',
        title: 'Килимки в салон Toyota Hiace H200 2004 - 2019 V покоління EU VAN',
        titleKey: 'toyota85',
      },
      {
        id: '2465',
        model: 'Highlander',
        year: ['2000', '2008'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3079-250x150.jpg',
        title: 'Килимки в салон Toyota Highlander XU20 2000 - 2008 I покоління EU Crossover',
        titleKey: 'toyota86',
      },
      {
        id: '2466',
        model: 'Highlander',
        year: ['2008', '2013'],
        body: 'Crossover 7-ми місна',
        market: 'EU',
        img: '/images/catalog/models/toyota/3081-250x150.jpg',
        title: 'Килимки в салон Toyota Highlander XU40 2008 - 2013 II покоління EU Crossover 7-ми місна',
        titleKey: 'toyota87',
      },
      {
        id: '2467',
        model: 'Highlander',
        year: ['2008', '2013'],
        body: 'Crossover 7-ми місна Hybrid',
        market: 'EU',
        img: '/images/catalog/models/toyota/3082-250x150.jpg',
        title: 'Килимки в салон Toyota Highlander XU40 2008 - 2013 II покоління EU Crossover 7-ми місна Hybrid',
        titleKey: 'toyota88',
      },
      {
        id: '2468',
        model: 'Highlander',
        year: ['2008', '2013'],
        body: 'Crossover 7-ми місна',
        market: 'USA',
        img: '/images/catalog/models/toyota/3080-250x150.jpg',
        title: 'Килимки в салон Toyota Highlander XU40 2008 - 2013 II покоління USA Crossover 7- ми місна',
        titleKey: 'toyota89',
      },
      {
        id: '2469',
        model: 'Highlander',
        year: ['2013', '2019'],
        body: 'Crossover 7-ми місна',
        market: 'EU',
        img: '/images/catalog/models/toyota/3083-250x150.jpg',
        title: 'Килимки в салон Toyota Highlander XU50 2013 - 2019 III покоління EU Crossover 7-ми місна',
        titleKey: 'toyota90',
      },

      {
        id: '2470',
        model: 'Highlander',
        year: ['2013', '2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3084-250x150.jpg',
        title: 'Килимки в салон Toyota Highlander XU50 2013 - 2019 III покоління EU Crossover 8-ми місна',
        titleKey: 'toyota91',
      },
      {
        id: '2471',
        model: 'Highlander',
        year: ['2019', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3086-250x150.jpg',
        title: 'Килимки в салон Toyota Highlander XU70 2019 - … IV покоління EU Crossover 7-ми місна Hybrid',
        titleKey: 'toyota92',
      },
      {
        id: '2472',
        model: 'Highlander',
        year: ['2019', '2021'],
        body: 'Crossover 7-ми місна',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3085-250x150.jpg',
        title: 'Килимки в салон Toyota Highlander XU70 2019 - … IV покоління EU/USA Crossover 7-ми місна',
        titleKey: 'toyota93',
      },
      {
        id: '2473',
        model: 'Hilux',
        year: ['2015', '2021'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/toyota/3088-250x150.jpg',
        title: 'Килимки в салон Toyota Hilux AN120 2015 - … VIII покоління EU Pickup 4-х дверна',
        titleKey: 'toyota94',
      },
      {
        id: '2474',
        model: 'Hilux',
        year: ['2004', '2015'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/toyota/3087-250x150.jpg',
        title: 'Килимки в салон Toyota Hilux AN20/AN30 2004 - 2015 VII покоління EU Pickup 4-х дверна',
        titleKey: 'toyota95',
      },
      {
        id: '2475',
        model: 'IQ',
        year: ['2008', '2015'],
        body: 'Hatchback',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3089-250x150.jpg',
        title: 'Килимки в салон Toyota IQ 2008 - 2015 I покоління Japan Hatchback',
        titleKey: 'toyota96',
      },
      {
        id: '2476',
        model: 'Land Cruiser',
        year: ['1998', '2003'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3099-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 100 1998 - 2003 VIII покоління EU Crossover 7-ми місна',
        titleKey: 'toyota97',
      },
      {
        id: '2477',
        model: 'Land Cruiser',
        year: ['1998', '2003'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/6019-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 100 1998 - 2003 VIII покоління EU Crossover дорест 5-ти місна',
        titleKey: 'toyota98',
      },
      {
        id: '2478',
        model: 'Land Cruiser',
        year: ['2003', '2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3091-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 100 2003 - 2007 VIII покоління EU Crossover 7-ми місна',
        titleKey: 'toyota99',
      },
      {
        id: '2479',
        model: 'Land Cruiser',
        year: ['2003', '2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/6020-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 100 2003 - 2007 VIII покоління EU Crossover рест 5-ти місна',
        titleKey: 'toyota100',
      },

      {
        id: '2480',
        model: 'Land Cruiser',
        year: ['2007', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3092-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 200 2007 - 2012 IX покоління EU Crossover 5-ти місна',
        titleKey: 'toyota101',
      },
      {
        id: '2481',
        model: 'Land Cruiser',
        year: ['2007', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3093-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 200 2007 - 2012 IX покоління EU Crossover 7-ми місна',
        titleKey: 'toyota102',
      },
      {
        id: '2482',
        model: 'Land Cruiser',
        year: ['2012', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3095-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 200 2012 - 2021 IX покоління EU Crossover 5-ти місна',
        titleKey: 'toyota103',
      },
      {
        id: '2483',
        model: 'Land Cruiser',
        year: ['2012', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3094-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 200 2012 - 2021 IX покоління EU Crossover 7-ми місна',
        titleKey: 'toyota104',
      },
      {
        id: '2484',
        model: 'Land Cruiser',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3090-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 300 2021 - … X покоління EU Crossover 5-ти місна',
        titleKey: 'toyota105',
      },
      {
        id: '2485',
        model: 'Land Cruiser',
        year: ['1984', '1993'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3096-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 73 1984 - 1993 VI покоління EU Crossover',
        titleKey: 'toyota106',
      },
      {
        id: '2486',
        model: 'Land Cruiser',
        year: ['1990', '1997'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3098-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser 80 1990 - 1997 VII покоління EU Crossover',
        titleKey: 'toyota107',
      },
      {
        id: '2487',
        model: 'Land Cruiser',
        year: ['2007'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3097-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser J76 2007 - … VII покоління EU Crossover 5-ти дверна',
        titleKey: 'toyota108',
      },
      {
        id: '2488',
        model: 'Land Cruiser Prado',
        year: ['2002', '2009'],
        body: 'Crossover',
        market: 'Arabic',
        img: '/images/catalog/models/toyota/3103-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J120 2002 - 2009 III покоління Arabic Crossover',
        titleKey: 'toyota109',
      },
      {
        id: '2489',
        model: 'Land Cruiser Prado',
        year: ['2002', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3102-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J120 2002 - 2009 III покоління EU Crossover 5-ти місна',
        titleKey: 'toyota110',
      },

      {
        id: '2490',
        model: 'Land Cruiser Prado',
        year: ['2002', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3109-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J120 2002 - 2009 III покоління EU Crossover 7-ми місна',
        titleKey: 'toyota111',
      },
      {
        id: '2491',
        model: 'Land Cruiser Prado',
        year: ['2002', '2009'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3101-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J125 2002 - 2009 III покоління EU Crossover 3-х дверна',
        titleKey: 'toyota112',
      },
      {
        id: '2492',
        model: 'Land Cruiser Prado',
        year: ['2009', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3106-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J150 2009 - 2013 IV покоління EU Crossover 5-ти місна',
        titleKey: 'toyota113',
      },
      {
        id: '2493',
        model: 'Land Cruiser Prado',
        year: ['2009', '2013'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3105-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J150 2009 - 2013 IV покоління EU Crossover 7-ми місна',
        titleKey: 'toyota114',
      },
      {
        id: '2494',
        model: 'Land Cruiser Prado',
        year: ['2013', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3107-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J150 2013 - 2017 IV покоління EU Crossover 5-ти місна',
        titleKey: 'toyota115',
      },
      {
        id: '2495',
        model: 'Land Cruiser Prado',
        year: ['2013', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3111-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J150 2013 - 2017 IV покоління EU Crossover 7-ми місна',
        titleKey: 'toyota116',
      },
      {
        id: '2496',
        model: 'Land Cruiser Prado',
        year: ['2017', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3100-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J150 2017 - … IV покоління EU Crossover 5-ти місна',
        titleKey: 'toyota117',
      },
      {
        id: '2497',
        model: 'Land Cruiser Prado',
        year: ['2017', '2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3104-250x150.jpg',
        title: 'Килимки в салон Toyota Land Cruiser Prado J150 2017 - … IV покоління EU Crossover 7-ми місна',
        titleKey: 'toyota118',
      },
      {
        id: '2498',
        model: 'Land Cruiser Prado',
        year: ['1996', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/placeholder-250x150.png',
        title:
          'Килимки в салон Toyota Land Cruiser Prado J90 1996 - 2002 II покоління EU Crossover 3-х дверна 5-ти місна',
        titleKey: 'toyota119',
      },
      {
        id: '2499',
        model: 'Land Cruiser Prado',
        year: ['1996', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3108-250x150.jpg',
        title:
          'Килимки в салон Toyota Land Cruiser Prado J90 1996 - 2002 II покоління EU Crossover 5-ти дверна 5-ти місна',
        titleKey: 'toyota120',
      },

      {
        id: '2500',
        model: 'Land Cruiser Prado',
        year: ['1996', '2002'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3110-250x150.jpg',
        title:
          'Килимки в салон Toyota Land Cruiser Prado J90 1996 - 2002 II покоління EU Crossover 5-ти дверна 7-ми місна',
        titleKey: 'toyota121',
      },
      {
        id: '2501',
        model: 'Mark',
        year: ['1992', '1996'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3112-250x150.jpg',
        title: 'Килимки в салон Toyota Mark II (90 кузов) 1992 - 1996 VII покоління EU Sedan',
        titleKey: 'toyota122',
      },
      {
        id: '2502',
        model: 'Mark',
        year: ['2004', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3113-250x150.jpg',
        title: 'Килимки в салон Toyota Mark X (120 кузов) 2004 - 2009 I покоління EU Sedan',
        titleKey: 'toyota123',
      },
      {
        id: '2503',
        model: 'Matrix',
        year: ['2002', '2008'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/toyota/3114-250x150.jpg',
        title: 'Килимки в салон Toyota Matrix E130 2002 - 2008 I покоління USA Hatchback',
        titleKey: 'toyota124',
      },
      {
        id: '2504',
        model: 'Matrix',
        year: ['2011', '2014'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/toyota/3115-250x150.jpg',
        title: 'Килимки в салон Toyota Matrix E140 2011 - 2014 II покоління USA Hatchback',
        titleKey: 'toyota125',
      },
      {
        id: '2505',
        model: 'Picnic',
        year: ['1996', '2001'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3116-250x150.jpg',
        title: 'Килимки в салон Toyota Picnic 1996 - 2001 I покоління Minivan',
        titleKey: 'toyota126',
      },
      {
        id: '2506',
        model: 'Premio',
        year: ['2001'],
        body: 'Sedan',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3117-250x150.jpg',
        title: 'Килимки в салон Toyota Premio (260 кузов) 2001 - ... I покоління Japan Sedan',
        titleKey: 'toyota127',
      },
      {
        id: '2507',
        model: 'Previa',
        year: ['2006', '2019'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3120-250x150.jpg',
        title: 'Килимки в салон Toyota Previa 2006 - 2019 III покоління EU Minivan 6-ти місна',
        titleKey: 'toyota128',
      },
      {
        id: '2508',
        model: 'Previa',
        year: ['1990', '2000'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3118-250x150.jpg',
        title: 'Килимки в салон Toyota Previa XR10/XR20 1990 - 2000 I покоління EU Minivan 7-ми місна',
        titleKey: 'toyota129',
      },
      {
        id: '2509',
        model: 'Previa',
        year: ['2000', '2006'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3119-250x150.jpg',
        title: 'Килимки в салон Toyota Previa XR30/XR40 2000 - 2006 II покоління EU Minivan',
        titleKey: 'toyota130',
      },

      {
        id: '2510',
        model: 'Prius',
        year: ['2015', '2022'],
        body: 'Liftback',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3126-250x150.jpg',
        title: 'Килимки в салон Toyota Prius (XW50) 2015 - 2022 IV покоління EU/USA Liftback hybrid',
        titleKey: 'toyota131',
      },
      {
        id: '2511',
        model: 'Prius',
        year: ['1997', '2003'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3121-250x150.jpg',
        title: 'Килимки в салон Toyota Prius 1997 - 2003 I покоління EU Sedan',
        titleKey: 'toyota132',
      },
      {
        id: '2512',
        model: 'Prius',
        year: ['2003', '2009'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3122-250x150.jpg',
        title: 'Килимки в салон Toyota Prius NHW20 2003 - 2009 II покоління EU Liftback',
        titleKey: 'toyota133',
      },
      {
        id: '2513',
        model: 'Prius',
        year: ['2015', '2022'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3130-250x150.jpg',
        title: 'Килимки в салон Toyota Prius Prime (XW50) 2015 - 2022 IV покоління EU Liftback Plug-in-hybrid',
        titleKey: 'toyota134',
      },
      {
        id: '2514',
        model: 'Prius',
        year: ['2011', '2021'],
        body: 'Minivan',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3129-250x150.jpg',
        title: 'Килимки в салон Toyota Prius V 2011 - 2021 I покоління EU/USA Minivan Hybrid',
        titleKey: 'toyota135',
      },
      {
        id: '2515',
        model: 'Prius',
        year: ['2009', '2015'],
        body: 'Liftback',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3123-250x150.jpg',
        title: 'Килимки в салон Toyota Prius ZVW30 2009 - 2015 III покоління EU/USA Liftback Hybrid/Plug-in-hybrid',
        titleKey: 'toyota136',
      },
      {
        id: '2516',
        model: 'Prius',
        year: ['2012', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3125-250x150.jpg',
        title: 'Килимки в салон Toyota Prius C Aqua ZVW30 2012 - 2019 III покоління EU Hatchback',
        titleKey: 'toyota137',
      },
      {
        id: '2517',
        model: 'Prius',
        year: ['2012', '2019'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/toyota/3124-250x150.jpg',
        title: 'Килимки в салон Toyota Prius C Aqua ZVW30 2012 - 2019 III покоління USA Hatchback',
        titleKey: 'toyota138',
      },
      {
        id: '2518',
        model: 'Prius+',
        year: ['2011', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3128-250x150.jpg',
        title: 'Килимки в салон Toyota Prius+ 2011 - 2015 III покоління EU Minivan 7-ми місна',
        titleKey: 'toyota139',
      },
      {
        id: '2519',
        model: 'ProAce',
        year: ['2020'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/toyota/3131-250x150.jpg',
        title: 'Килимки в салон Toyota ProAce city 2020 - … I покоління EU VAN',
        titleKey: 'toyota140',
      },

      {
        id: '2520',
        model: 'ProAce',
        year: ['2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/toyota/3133-250x150.jpg',
        title: 'Килимки в салон Toyota ProAce L2 2016 - … II покоління EU VAN 8-ми місна',
        titleKey: 'toyota141',
      },
      {
        id: '2521',
        model: 'ProAce',
        year: ['2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/toyota/3132-250x150.jpg',
        title: 'Килимки в салон Toyota ProAce Verso 2016 - … II покоління EU VAN 7-ми місна',
        titleKey: 'toyota142',
      },
      {
        id: '2522',
        model: 'Rav 4',
        year: ['1994', '2000'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3138-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 1994 - 2000 I покоління EU Crossover 3-х дверна',
        titleKey: 'toyota143',
      },
      {
        id: '2523',
        model: 'Rav 4 ',
        year: ['2000', '2003'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3139-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 CA20W 2000 - 2003 II покоління EU Crossover 5-ти дверна повний привіт',
        titleKey: 'toyota144',
      },
      {
        id: '2524',
        model: 'Rav 4',
        year: ['2003', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3140-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 CA20W 2003 - 2005 II покоління EU Crossover 5-ти дверна',
        titleKey: 'toyota145',
      },
      {
        id: '2525',
        model: 'Rav 4',
        year: ['2005', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3134-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 CA30W 2005 - 2010 III покоління EU Crossover Short',
        titleKey: 'toyota146',
      },
      {
        id: '2526',
        model: 'Rav 4',
        year: ['2005', '2012'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3142-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 CA30W 2005 - 2012 III покоління USA Crossover Long',
        titleKey: 'toyota147',
      },
      {
        id: '2527',
        model: 'Rav 4',
        year: ['2010', '2012'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3141-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 CA30W 2010 - 2012 III покоління EU Crossover Short',
        titleKey: 'toyota148',
      },
      {
        id: '2528',
        model: 'Rav 4',
        year: ['1994', '2000'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3136-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 XA10G 1994 - 2000 I покоління EU Crossover 5-ти дверна',
        titleKey: 'toyota149',
      },
      {
        id: '2529',
        model: 'Rav 4',
        year: ['1994', '2000'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3137-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 XA10G 1994 - 2000 I покоління USA Crossover 5-ти дверна',
        titleKey: 'toyota150',
      },

      {
        id: '2530',
        model: 'Rav 4',
        year: ['2013', '2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3143-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 XA40 2013 - 2018 IV покоління EU/USA Crossover',
        titleKey: 'toyota151',
      },
      {
        id: '2531',
        model: 'Rav 4',
        year: ['2013', '2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3135-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 XA40 2013 - 2018 IV покоління EU/USA Crossover Hybrid',
        titleKey: 'toyota152',
      },
      {
        id: '2532',
        model: 'Rav 4',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3144-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 XA50 2018 - … V покоління EU/USA Crossover',
        titleKey: 'toyota153',
      },
      {
        id: '2533',
        model: 'Rav 4',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3145-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 XA50 2018 - … V покоління EU Crossover Hybrid',
        titleKey: 'toyota154',
      },
      {
        id: '2534',
        model: 'Rav 4',
        year: ['2000', '2005'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3146-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 CA20W 2000 - 2005 II покоління EU Crossover 3-х дверна',
        titleKey: 'toyota155',
      },
      {
        id: '2535',
        model: 'Camry',
        year: ['2006', '2011'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/toyota/3025-250x150.jpg',
        title: 'Килимки в салон Toyota Camry XV40 (2.5L) 2006 - 2011 VI покоління EU/USA Sedan',
        titleKey: 'toyota156',
      },
      {
        id: '2536',
        model: 'Rav 4',
        year: ['2012', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3147-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 EV 2012 - 2014 III покоління EU Crossover',
        titleKey: 'toyota157',
      },
      {
        id: '2537',
        model: 'Rav 4',
        year: ['2005', '2012'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3148-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 CA30W (Arab assembly) 2005 - 2012 III покоління USA Crossover',
        titleKey: 'toyota158',
      },
      {
        id: '2538',
        model: 'Rav 4',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3149-250x150.jpg',
        title: 'Килимки в салон Toyota Rav 4 XA50 2018 - … V покоління EU Crossover Plug in Hybrid (PHEV)',
        titleKey: 'toyota159',
      },
      {
        id: '2539',
        model: 'Scion',
        year: ['2004', '2007'],
        body: 'Coupe',
        market: 'USA',
        img: '/images/catalog/models/toyota/3150-250x150.jpg',
        title: 'Килимки в салон Toyota Scion tC (AT10) 2004 - 2007 I покоління USA Coupe',
        titleKey: 'toyota160',
      },

      {
        id: '2540',
        model: 'Sequoia',
        year: ['2001', '2007'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3151-250x150.jpg',
        title: 'Килимки в салон Toyota Sequoia 2001 - 2007 I покоління USA Crossover',
        titleKey: 'toyota161',
      },
      {
        id: '2541',
        model: 'Sequoia',
        year: ['2008'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3152-250x150.jpg',
        title: 'Килимки в салон Toyota Sequoia Platinum 2008 - … II покоління USA Crossover 7-ми місна',
        titleKey: 'toyota162',
      },
      {
        id: '2542',
        model: 'Sequoia',
        year: ['2007', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3153-250x150.jpg',
        title: 'Килимки в салон Toyota Sequoia SR5 2007 - 2017 II покоління USA Crossover 8-ми місна',
        titleKey: 'toyota163',
      },
      {
        id: '2543',
        model: 'Sequoia',
        year: ['2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3154-250x150.jpg',
        title: 'Килимки в салон Toyota Sequoia SR5 2017 - … II покоління USA Crossover 8-ми місна',
        titleKey: 'toyota164',
      },
      {
        id: '2544',
        model: 'Sienna',
        year: ['2003', '2009'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3155-250x150.jpg',
        title: 'Килимки в салон Toyota Sienna XL20 2003 - 2009 II покоління USA Minivan 7-ми місна',
        titleKey: 'toyota165',
      },
      {
        id: '2545',
        model: 'Sienna',
        year: ['2010', '2020'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3156-250x150.jpg',
        title: 'Килимки в салон Toyota Sienna XL30 2010 - 2020 III покоління USA Minivan 7-ми місна',
        titleKey: 'toyota166',
      },
      {
        id: '2546',
        model: 'Sienna',
        year: ['2010'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3157-250x150.jpg',
        title: 'Килимки в салон Toyota Sienna XL30 2010 - … III покоління USA Minivan 8-ми місна',
        titleKey: 'toyota167',
      },
      {
        id: '2547',
        model: 'Sienna',
        year: ['2020'],
        body: 'Minivan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3158-250x150.jpg',
        title: 'Килимки в салон Toyota Sienna XL30 2020 - … III покоління USA Minivan 6-ти місна',
        titleKey: 'toyota168',
      },
      {
        id: '2548',
        model: 'Starlet',
        year: ['1989', '1995'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3159-250x150.jpg',
        title: 'Килимки в салон Toyota Starlet P80 1989 - 1995 IV покоління EU Hatchback',
        titleKey: 'toyota169',
      },
      {
        id: '2549',
        model: 'Supra',
        year: ['1993', '2002'],
        body: 'Coupe',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3160-250x150.jpg',
        title: 'Килимки в салон Toyota Supra A80 1993 - 2002 IV покоління Japan Coupe',
        titleKey: 'toyota170',
      },

      {
        id: '2550',
        model: 'Tacoma',
        year: ['2004', '2015'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3161-250x150.jpg',
        title: 'Килимки в салон Toyota Tacoma 2004 - 2015 II покоління USA Pickup 4-х дверна',
        titleKey: 'toyota171',
      },
      {
        id: '2551',
        model: 'Tacoma',
        year: ['2004', '2015'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3162-250x150.jpg',
        title: 'Килимки в салон Toyota Tacoma 2004 - 2015 II покоління USA Pickup 2-х дверна',
        titleKey: 'toyota172',
      },
      {
        id: '2552',
        model: 'Tacoma',
        year: ['2015'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3163-250x150.jpg',
        title: 'Килимки в салон Toyota Tacoma 2015 - … III покоління USA Pickup',
        titleKey: 'toyota173',
      },
      {
        id: '2553',
        model: 'Tundra',
        year: ['2007', '2013'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3164-250x150.jpg',
        title: 'Килимки в салон Toyota Tundra 2007 - 2013 II покоління USA Pickup 4-х дверна Double Cub',
        titleKey: 'toyota174',
      },
      {
        id: '2554',
        model: 'Tundra',
        year: ['2007', '2013'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3165-250x150.jpg',
        title: 'Килимки в салон Toyota Tundra 2007 - 2013 II покоління USA Pickup',
        titleKey: 'toyota175',
      },
      {
        id: '2555',
        model: 'Tundra',
        year: ['2014', '2021'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3166-250x150.jpg',
        title: 'Килимки в салон Toyota Tundra XK50 2014 - 2021 III покоління USA Pickup 4-х дверна Double Cab',
        titleKey: 'toyota176',
      },
      {
        id: '2556',
        model: 'Tundra',
        year: ['2014', '2021'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3167-250x150.jpg',
        title: 'Килимки в салон Toyota Tundra XK50 2014 - 2021 III покоління USA Pickup 4-х дверна Crew Max Cab',
        titleKey: 'toyota177',
      },
      {
        id: '2557',
        model: 'Tundra',
        year: ['2000', '2006'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3168-250x150.jpg',
        title: 'Килимки в салон Toyota Tundra 2000 - 2006 I покоління USA Pickup 4-х дверна Access Cab Pickup',
        titleKey: 'toyota178',
      },
      {
        id: '2558',
        model: 'Tundra',
        year: ['2007', '2013'],
        body: 'Pickup',
        market: 'USA',
        img: '/images/catalog/models/toyota/3169-250x150.jpg',
        title: 'Килимки в салон Toyota Tundra 2007 - 2013 II покоління USA Pickup 4-х дверна Crew Max',
        titleKey: 'toyota179',
      },
      {
        id: '2559',
        model: 'Vellfire',
        year: ['2008', '2015'],
        body: 'Minivan',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3170-250x150.jpg',
        title: 'Килимки в салон Toyota Vellfire AH20 2008 - 2015 II покоління Japan Minivan 7-ми місна праве кермо',
        titleKey: 'toyota180',
      },

      {
        id: '2560',
        model: 'Venza',
        year: ['2008', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/toyota/3171-250x150.jpg',
        title: 'Килимки в салон Toyota Venza AV10 2008 - 2017 I покоління USA Crossover',
        titleKey: 'toyota181',
      },
      {
        id: '2561',
        model: 'Venza',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3172-250x150.jpg',
        title: 'Килимки в салон Toyota Venza XU80 2020 - … II покоління EU Crossover',
        titleKey: 'toyota182',
      },
      {
        id: '2562',
        model: 'Verso',
        year: ['2009', '2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3173-250x150.jpg',
        title: 'Килимки в салон Toyota Verso 2009 - 2018 I покоління EU Minivan 7-ми місна',
        titleKey: 'toyota183',
      },
      {
        id: '2563',
        model: 'Verso',
        year: ['2009', '2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/6021-250x150.jpg',
        title: 'Килимки в салон Toyota Verso 2009 - 2018 I покоління EU Minivan 5-ти місна',
        titleKey: 'toyota184',
      },
      {
        id: '2564',
        model: 'Verso',
        year: ['2009', '2018'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3174-250x150.jpg',
        title: 'Килимки в салон Toyota Verso S 2009 - 2018 I покоління EU Minivan 5-ти місна',
        titleKey: 'toyota185',
      },
      {
        id: '2565',
        model: 'Windom',
        year: ['1991', '1996'],
        body: 'Sedan',
        market: 'Japan',
        img: '/images/catalog/models/toyota/3175-250x150.jpg',
        title: 'Килимки в салон Toyota Windom V10 1991 - 1996 I покоління Japan Sedan',
        titleKey: 'toyota186',
      },
      {
        id: '2566',
        model: 'Yaris',
        year: ['1999', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3176-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris 1999 - 2006 I покоління EU Hatchback 5-ти дверна',
        titleKey: 'toyota187',
      },
      {
        id: '2567',
        model: 'Yaris',
        year: ['1999', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3177-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris 1999 - 2006 I покоління EU Hatchback 3-х дверна',
        titleKey: 'toyota188',
      },
      {
        id: '2568',
        model: 'Yaris',
        year: ['2005', '2011'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3178-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris XP9 2005 - 2011 II покоління USA Sedan',
        titleKey: 'toyota189',
      },
      {
        id: '2569',
        model: 'Yaris',
        year: ['2006', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3179-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris XP9 2006 - 2011 II покоління EU Hatchback 5-ти дверна',
        titleKey: 'toyota190',
      },

      {
        id: '2570',
        model: 'Yaris',
        year: ['2010', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3180-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris XP13 2010 - 2020 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'toyota191',
      },
      {
        id: '2571',
        model: 'Yaris',
        year: ['2010', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/toyota/3181-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris XP13 2010 - 2020 III покоління EU Hatchback 5-ти дверна Hybrid',
        titleKey: 'toyota192',
      },
      {
        id: '2572',
        model: 'Yaris',
        year: ['1999', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3182-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris (Echo) 1999 - 2006 I покоління EU Sedan',
        titleKey: 'toyota193',
      },
      {
        id: '2573',
        model: 'Yaris',
        year: ['2017', '2020'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/toyota/3183-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris iA 2017 - 2020 I покоління USA Sedan',
        titleKey: 'toyota194',
      },
      {
        id: '2574',
        model: 'Yaris Cross',
        year: ['2020'],
        body: 'Crossover Hibrid',
        market: 'EU',
        img: '/images/catalog/models/toyota/3184-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris Cross XP210 2020 - … IV покоління EU Crossover Hibrid',
        titleKey: 'toyota195',
      },
      {
        id: '2575',
        model: 'Yaris Cross',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/toyota/3185-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris Cross XP210 2020 - … IV покоління EU Crossover',
        titleKey: 'toyota196',
      },
      {
        id: '2576',
        model: 'Yaris Verso',
        year: ['2000', '2005'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/toyota/3186-250x150.jpg',
        title: 'Килимки в салон Toyota Yaris Verso 2000 - 2005 I покоління EU Minivan',
        titleKey: 'toyota197',
      },
    ],
  },
  /* ----------------------------------- UAZ ---------------------------------- */
  Uaz: {
    logo: '/images/catalog/logo/uaz-100x100.png',
    name: 'УАЗ',
    models: [
      {
        id: '2577',
        model: 'Patriot',
        year: ['2005', '2014'],
        body: 'Crossover',
        market: 'RU',
        img: '/images/catalog/models/uaz/5930-250x150.jpg',
        title: 'Килимки в салон УАЗ Patriot (3163) 2005-2014 I покоління RU Crossover дорест',
        titleKey: 'uaz1',
      },
      {
        id: '2578',
        model: 'Patriot',
        year: ['2014', '2016'],
        body: 'Crossover',
        market: 'RU',
        img: '/images/catalog/models/uaz/5931-250x150.jpg',
        title: 'Килимки в салон УАЗ Patriot (3163) 2014-2016 I покоління RU Crossover рест',
        titleKey: 'uaz2',
      },
    ],
  },
  /* ----------------------------------- VAZ ---------------------------------- */
  Vaz: {
    logo: '/images/catalog/logo/vaz-100x100.png',
    name: 'ВАЗ',
    models: [
      {
        id: '2579',
        model: '2101',
        year: ['1970', '1984'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/vaz/5914-250x150.jpg',
        title: 'Килимки в салон VAZ 2101 1970-1984 I покоління EU Sedan',
        titleKey: 'vaz1',
      },
      {
        id: '2580',
        model: '2102',
        year: ['1971', '1985'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/vaz/5915-250x150.jpg',
        title: 'Килимки в салон VAZ 2102 1971-1985 I покоління EU Universal',
        titleKey: 'vaz2',
      },
      {
        id: '2581',
        model: '2103',
        year: ['1972', '1984'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/vaz/5916-250x150.jpg',
        title: 'Килимки в салон VAZ 2103 1972-1984 I покоління EU Sedan',
        titleKey: 'vaz3',
      },
      {
        id: '2582',
        model: '2104',
        year: ['1984', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/vaz/5917-250x150.jpg',
        title: 'Килимки в салон VAZ 2104 1984-2012 I покоління EU Universal',
        titleKey: 'vaz4',
      },
      {
        id: '2583',
        model: '2105',
        year: ['1980', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/vaz/5918-250x150.jpg',
        title: 'Килимки в салон VAZ 2105 1980-2010 I покоління EU Sedan',
        titleKey: 'vaz5',
      },
      {
        id: '2584',
        model: '2106',
        year: ['1976', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/vaz/5919-250x150.jpg',
        title: 'Килимки в салон VAZ 2106 1976-2006 I покоління EU Sedan',
        titleKey: 'vaz6',
      },
      {
        id: '2585',
        model: '2107',
        year: ['1982', '1991'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/vaz/5920-250x150.jpg',
        title: 'Килимки в салон VAZ 2107 1982-1991 I покоління EU Sedan дорест',
        titleKey: 'vaz7',
      },
      {
        id: '2586',
        model: '2107',
        year: ['1991', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/vaz/5921-250x150.jpg',
        title: 'Килимки в салон VAZ 2107 1991-2011 I покоління EU Sedan рест',
        titleKey: 'vaz8',
      },
      {
        id: '2587',
        model: '2108',
        year: ['1984', '2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/vaz/5922-250x150.jpg',
        title: 'Килимки в салон VAZ 2108 1984-2005 I покоління EU Hatchback',
        titleKey: 'vaz9',
      },
      {
        id: '2588',
        model: '2109',
        year: ['1987', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/vaz/5923-250x150.jpg',
        title: 'Килимки в салон VAZ 2109 1987-2011 I покоління EU Hatchback',
        titleKey: 'vaz10',
      },

      {
        id: '2589',
        model: '21099',
        year: ['1990', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/vaz/5924-250x150.jpg',
        title: 'Килимки в салон VAZ 21099 1990-2011 I покоління EU Sedan',
        titleKey: 'vaz11',
      },
      {
        id: '2590',
        model: '2121 Niva',
        year: ['1977', '1994'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/vaz/5927-250x150.jpg',
        title: 'Килимки в салон VAZ 2121 Niva 1977-1994 I покоління EU Crossover 3-х дверна',
        titleKey: 'vaz12',
      },
      {
        id: '2591',
        model: '21213 Niva',
        year: ['1994', '2006'],
        body: 'Crossover 3-х дверна',
        market: 'EU',
        img: '/images/catalog/models/vaz/5925-250x150.jpg',
        title: 'Килимки в салон VAZ 21213 Niva 1994-2006 I покоління EU Crossover 3-х дверна',
        titleKey: 'vaz13',
      },
      {
        id: '2592',
        model: '21214 Niva',
        year: ['2006'],
        body: 'Crossover 3-х дверна',
        market: 'EU',
        img: '/images/catalog/models/vaz/5926-250x150.jpg',
        title: 'Килимки в салон VAZ 21214 Niva 2006-... I покоління EU Crossover 3-х дверна',
        titleKey: 'vaz14',
      },
      {
        id: '2593',
        model: '2141 Москвич',
        year: ['1986', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/vaz/5928-250x150.jpg',
        title: 'Килимки в салон VAZ 2141 Москвич 1986-2003 IV покоління EU Hatchback',
        titleKey: 'vaz15',
      },
      {
        id: '2594',
        model: 'ИЖ 2126',
        year: ['1995', '2003'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/vaz/5929-250x150.jpg',
        title: 'Килимки в салон VAZ ІЖ 2126 1995-2003 I покоління EU Hatchback',
        titleKey: 'vaz16',
      },
    ],
  },
  /* ------------------------------- Volkswagen ------------------------------- */
  Volkswagen: {
    logo: '/images/catalog/logo/volkswagen-100x100.png',
    name: 'Volkswagen',
    models: [
      {
        id: '2595',
        model: 'Amarok',
        year: ['2010', '2020'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2821-250x150.jpg',
        title: 'Килимки в салон Volkswagen Amarok 2010-2020 I покоління EU Pickup 2-х дверна',
        titleKey: 'volkswagen1',
      },
      {
        id: '2596',
        model: 'Amarok',
        year: ['2010', '2020'],
        body: 'Pickup',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2820-250x150.jpg',
        title: 'Килимки в салон Volkswagen Amarok 2010-2020 I покоління EU Pickup 4-х дверна',
        titleKey: 'volkswagen2',
      },
      {
        id: '2597',
        model: 'Arteon',
        year: ['2017'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2823-250x150.jpg',
        title: 'Килимки в салон Volkswagen Arteon 2017-… I покоління EU Liftback',
        titleKey: 'volkswagen3',
      },
      {
        id: '2598',
        model: 'Arteon',
        year: ['2017'],
        body: 'Liftback',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2822-250x150.jpg',
        title: 'Килимки в салон Volkswagen Arteon 2017-… I покоління USA Liftback',
        titleKey: 'volkswagen4',
      },
      {
        id: '2599',
        model: 'Atlas',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2824-250x150.jpg',
        title: 'Килимки в салон Volkswagen Atlas 2016-… I покоління USA Crossover 7-ми місна',
        titleKey: 'volkswagen5',
      },
      {
        id: '2600',
        model: 'Atlas',
        year: ['2016'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2825-250x150.jpg',
        title: 'Килимки в салон Volkswagen Atlas 2016-… I покоління USA Crossover дорест 6-ти місна',
        titleKey: 'volkswagen6',
      },
      {
        id: '2601',
        model: 'Beetle',
        year: ['2011', '2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2826-250x150.jpg',
        title: 'Килимки в салон Volkswagen Beetle 2011-2019 II покоління EU Hatchback',
        titleKey: 'volkswagen7',
      },
      {
        id: '2602',
        model: 'Beetle',
        year: ['2011', '2019'],
        body: 'Cabriolet',
        market: 'EU/USA',
        img: '/images/catalog/models/volkswagen/2828-250x150.jpg',
        title: 'Килимки в салон Volkswagen Beetle 2011-2019 II покоління EU/USA Cabriolet',
        titleKey: 'volkswagen8',
      },
      {
        id: '2603',
        model: 'Beetle',
        year: ['2011', '2019'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2827-250x150.jpg',
        title: 'Килимки в салон Volkswagen Beetle 2011-2019 II покоління USA Hatchback',
        titleKey: 'volkswagen9',
      },
      {
        id: '2604',
        model: 'Beetle',
        year: ['1997', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2829-250x150.jpg',
        title: 'Килимки в салон Volkswagen Beetle New A4 1997-2010 I покоління EU Hatchback',
        titleKey: 'volkswagen10',
      },

      {
        id: '2605',
        model: 'Bora',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2830-250x150.jpg',
        title: 'Килимки в салон Volkswagen Bora 1998-2005 I покоління EU Sedan',
        titleKey: 'volkswagen11',
      },
      {
        id: '2606',
        model: 'Bora',
        year: ['1998', '2005'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2831-250x150.jpg',
        title: 'Килимки в салон Volkswagen Bora 1998-2005 I покоління EU Universal',
        titleKey: 'volkswagen12',
      },
      {
        id: '2607',
        model: 'Caddy',
        year: ['2004', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2832-250x150.jpg',
        title: 'Килимки в салон Volkswagen Caddy (2K) 2004-2015 III покоління EU Minivan дорест 5-ти місна',
        titleKey: 'volkswagen13',
      },
      {
        id: '2608',
        model: 'Caddy',
        year: ['2015', '2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2833-250x150.jpg',
        title: 'Килимки в салон Volkswagen Caddy (2K) 2015-2020 III покоління EU Minivan рест 4-х дверна 5-ти місна',
        titleKey: 'volkswagen14',
      },
      {
        id: '2609',
        model: 'Caddy',
        year: ['2004', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2835-250x150.jpg',
        title: 'Килимки в салон Volkswagen Caddy (2K) MAXI 2004-2015 III покоління EU Minivan дорест 4-х дверна',
        titleKey: 'volkswagen15',
      },
      {
        id: '2610',
        model: 'Caddy',
        year: ['2015', '2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2834-250x150.jpg',
        title: 'Килимки в салон Volkswagen Caddy (2K) MAXI 2015-2020 III покоління EU Minivan рест 7-ми місна',
        titleKey: 'volkswagen16',
      },
      {
        id: '2611',
        model: 'Caddy',
        year: ['1995', '2004'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2836-250x150.jpg',
        title: 'Килимки в салон Volkswagen Caddy (9U/9KV) 1995-2004 II покоління EU Minivan',
        titleKey: 'volkswagen17',
      },
      {
        id: '2612',
        model: 'Caddy',
        year: ['2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2837-250x150.jpg',
        title: 'Килимки в салон Volkswagen Caddy (SB) 2020-… IV покоління EU Minivan 4-х дверна 7-ми місна',
        titleKey: 'volkswagen18',
      },
      {
        id: '2613',
        model: 'Caddy',
        year: ['2015', '2020'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2838-250x150.jpg',
        title: 'Килимки в салон Volkswagen Caddy MAXI 2015-2020 III покоління EU Minivan рест 5-ти місна Long',
        titleKey: 'volkswagen19',
      },
      {
        id: '2614',
        model: 'Crafter',
        year: ['2006', '2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2841-250x150.jpg',
        title: 'Килимки в салон Volkswagen Crafter 2006-2016 I покоління EU VAN',
        titleKey: 'volkswagen20',
      },

      {
        id: '2615',
        model: 'Crafter',
        year: ['2016'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2842-250x150.jpg',
        title: 'Килимки в салон Volkswagen Crafter 2016-… II покоління EU VAN',
        titleKey: 'volkswagen21',
      },
      {
        id: '2616',
        model: 'Cross Golf',
        year: ['2006', '2010'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2843-250x150.jpg',
        title: 'Килимки в салон Volkswagen Cross Golf 2006-2010 I покоління EU Hatchback',
        titleKey: 'volkswagen22',
      },
      {
        id: '2617',
        model: 'e-Bora',
        year: ['2019'],
        body: 'Sedan',
        market: 'RU',
        img: '/images/catalog/models/volkswagen/2844-250x150.jpg',
        title: 'Килимки в салон Volkswagen e-Bora 2019-… IV покоління RU Sedan Electric',
        titleKey: 'volkswagen23',
      },
      {
        id: '2618',
        model: 'e-Golf',
        year: ['2014', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2845-250x150.jpg',
        title: 'Килимки в салон Volkswagen e-Golf 2014-2020 VII покоління EU Hatchback 5-ти дверна Electric',
        titleKey: 'volkswagen24',
      },
      {
        id: '2619',
        model: 'E-Lavida',
        year: ['2018'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/volkswagen/2846-250x150.jpg',
        title: 'Килимки в салон Volkswagen E-Lavida 2018-… III покоління China Sedan Electric',
        titleKey: 'volkswagen25',
      },
      {
        id: '2620',
        model: 'E-Tharu',
        year: ['2020'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/volkswagen/2848-250x150.jpg',
        title: 'Килимки в салон Volkswagen E-Tharu 2020-… I покоління China Crossover 5-ти місна Electric',
        titleKey: 'volkswagen26',
      },
      {
        id: '2621',
        model: 'Eos',
        year: ['2006', '2015'],
        body: 'Cabriolet',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2847-250x150.jpg',
        title: 'Килимки в салон Volkswagen Eos 2006-2015 I покоління USA Cabriolet',
        titleKey: 'volkswagen27',
      },
      {
        id: '2622',
        model: 'Fox',
        year: ['2005', '2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2849-250x150.jpg',
        title: 'Килимки в салон Volkswagen Fox 2005-2011 I покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen28',
      },
      {
        id: '2623',
        model: 'Fox',
        year: ['2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2850-250x150.jpg',
        title: 'Килимки в салон Volkswagen Fox 2005-… I покоління EU Hatchback 5-ти дверна',
        titleKey: 'volkswagen29',
      },
      {
        id: '2624',
        model: 'Golf',
        year: ['1983', '1992'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2851-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (II) 1983-1992 II покоління EU Hatchback',
        titleKey: 'volkswagen30',
      },

      {
        id: '2625',
        model: 'Golf',
        year: ['1991', '1998'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2852-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (III) 1991-1998 III покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen31',
      },
      {
        id: '2626',
        model: 'Golf',
        year: ['1991', '1998'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2853-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (III) 1991-1998 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'volkswagen32',
      },
      {
        id: '2627',
        model: 'Golf',
        year: ['1997', '2006'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2854-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (IV) 1997-2006 IV покоління EU Hatchback',
        titleKey: 'volkswagen33',
      },
      {
        id: '2628',
        model: 'Golf',
        year: ['1997', '2006'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2855-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (IV) 1997-2006 IV покоління EU Universal',
        titleKey: 'volkswagen34',
      },
      {
        id: '2629',
        model: 'Golf',
        year: ['2003', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2857-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (V) 2003-2009 V покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen35',
      },
      {
        id: '2630',
        model: 'Golf',
        year: ['2003', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2856-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (V) 2003-2009 V покоління EU Hatchback 5-ти дверна',
        titleKey: 'volkswagen36',
      },
      {
        id: '2631',
        model: 'Golf',
        year: ['2003', '2009'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2858-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (V) 2003-2009 V покоління EU Universal',
        titleKey: 'volkswagen37',
      },
      {
        id: '2632',
        model: 'Golf',
        year: ['2008', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2860-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (VI) 2008-2012 VI покоління EU Hatchback',
        titleKey: 'volkswagen38',
      },
      {
        id: '2633',
        model: 'Golf',
        year: ['2008', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2861-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (VI) 2008-2012 VI покоління EU Universal',
        titleKey: 'volkswagen39',
      },
      {
        id: '2634',
        model: 'Golf',
        year: ['2008', '2012'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2859-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (VI) 2008-2012 VI покоління USA Hatchback',
        titleKey: 'volkswagen40',
      },

      {
        id: '2635',
        model: 'Golf',
        year: ['2012', '2016'],
        body: 'Hatchback',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2862-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (VII) 2012-2016 VII покоління USA Hatchback дорест 5-ти дверна',
        titleKey: 'volkswagen41',
      },
      {
        id: '2636',
        model: 'Golf',
        year: ['2012', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2863-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (VII) 2012-2020 VII покоління EU Hatchback 5-ти дверна',
        titleKey: 'volkswagen42',
      },
      {
        id: '2637',
        model: 'Golf',
        year: ['2012', '2020'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2864-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf (VII) 2012-2020 VII покоління EU Universal',
        titleKey: 'volkswagen43',
      },
      {
        id: '2638',
        model: 'Golf',
        year: ['2012', '2021'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2865-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf Alltrack (Mk7) 2012-2021 VII покоління USA Universal',
        titleKey: 'volkswagen44',
      },
      {
        id: '2639',
        model: 'Golf',
        year: ['2012', '2020'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2866-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf GTI 2012-2020 VII покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen45',
      },
      {
        id: '2640',
        model: 'Golf',
        year: ['2019'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2867-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf Mk8 2019-… VIII покоління EU Hatchback',
        titleKey: 'volkswagen46',
      },
      {
        id: '2641',
        model: 'Golf',
        year: ['2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2964-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf Mk8 2019-… VIII покоління EU Sedan',
        titleKey: 'volkswagen47',
      },
      {
        id: '2642',
        model: 'Golf',
        year: ['2005', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2868-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf Plus 2005-2014 I покоління EU Minivan',
        titleKey: 'volkswagen48',
      },
      {
        id: '2643',
        model: 'Golf ',
        year: ['2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2869-250x150.jpg',
        title: 'Килимки в салон Volkswagen Golf Sportsvan 2014-… VII покоління EU Minivan',
        titleKey: 'volkswagen49',
      },
      {
        id: '2644',
        model: 'ID.3',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2870-250x150.jpg',
        title: 'Килимки в салон Volkswagen ID.3 2019-… I покоління EU Crossover Electric',
        titleKey: 'volkswagen50',
      },

      {
        id: '2645',
        model: 'ID.4',
        year: ['2020'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/volkswagen/2871-250x150.jpg',
        title: 'Килимки в салон Volkswagen ID.4 Crozz 2020-… I покоління China Crossover Electric',
        titleKey: 'volkswagen51',
      },
      {
        id: '2646',
        model: 'ID.4',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2872-250x150.jpg',
        title: 'Килимки в салон Volkswagen ID.4 GTX 2021-… I покоління EU Crossover Electric',
        titleKey: 'volkswagen52',
      },
      {
        id: '2647',
        model: 'ID.4',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2873-250x150.jpg',
        title: 'Килимки в салон Volkswagen ID.4 Pro 2021-… I покоління EU Crossover Electric',
        titleKey: 'volkswagen53',
      },
      {
        id: '2648',
        model: 'ID.4',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2874-250x150.jpg',
        title: 'Килимки в салон Volkswagen ID.4 Pure 2021-… I покоління EU Crossover Electric',
        titleKey: 'volkswagen54',
      },
      {
        id: '2649',
        model: 'ID.4',
        year: ['2021'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2875-250x150.jpg',
        title: 'Килимки в салон Volkswagen ID.4 Pure Performance 2021-… I покоління EU Crossover Electric',
        titleKey: 'volkswagen55',
      },
      {
        id: '2650',
        model: 'ID.6',
        year: ['2021'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/volkswagen/2876-250x150.jpg',
        title: 'Килимки в салон Volkswagen ID.6 Crozz 2021-… I покоління China Crossover 6-ти місна',
        titleKey: 'volkswagen56',
      },
      {
        id: '2651',
        model: 'ID.6',
        year: ['2021'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/volkswagen/2877-250x150.jpg',
        title: 'Килимки в салон Volkswagen ID.6 Crozz 2021-… I покоління China Crossover 7-ми місна',
        titleKey: 'volkswagen57',
      },
      {
        id: '2652',
        model: 'Jetta',
        year: ['2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2878-250x150.jpg',
        title: 'Килимки в салон Volkswagen Jetta (A7) 2018-… VII покоління USA Sedan',
        titleKey: 'volkswagen58',
      },
      {
        id: '2653',
        model: 'Jetta',
        year: ['1984', '1992'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2879-250x150.jpg',
        title: 'Килимки в салон Volkswagen Jetta (II) 1984-1992 II покоління EU Sedan',
        titleKey: 'volkswagen59',
      },
      {
        id: '2654',
        model: 'Jetta',
        year: ['1998', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2880-250x150.jpg',
        title: 'Килимки в салон Volkswagen Jetta (IV) 1998-2005 IV покоління EU Sedan',
        titleKey: 'volkswagen60',
      },

      {
        id: '2655',
        model: 'Jetta',
        year: ['2005', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2881-250x150.jpg',
        title: 'Килимки в салон Volkswagen Jetta (V) 2005-2010 V покоління EU Sedan',
        titleKey: 'volkswagen61',
      },
      {
        id: '2656',
        model: 'Jetta',
        year: ['2005', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2882-250x150.jpg',
        title: 'Килимки в салон Volkswagen Jetta (V) 2005-2010 V покоління EU Universal',
        titleKey: 'volkswagen62',
      },
      {
        id: '2657',
        model: 'Jetta',
        year: ['2010', '2015'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2885-250x150.jpg',
        title: 'Килимки в салон Volkswagen Jetta (VI) 2010-2015 VI покоління USA Universal',
        titleKey: 'volkswagen63',
      },
      {
        id: '2658',
        model: 'Jetta',
        year: ['2010', '2018'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2884-250x150.jpg',
        title: 'Килимки в салон Volkswagen Jetta (VI) 2010-2018 VI покоління EU Sedan',
        titleKey: 'volkswagen64',
      },
      {
        id: '2659',
        model: 'Jetta',
        year: ['2010', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2883-250x150.jpg',
        title: 'Килимки в салон Volkswagen Jetta (VI) 2010-2018 VI покоління USA Sedan',
        titleKey: 'volkswagen65',
      },
      {
        id: '2660',
        model: 'Lavida',
        year: ['2018'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/volkswagen/2886-250x150.jpg',
        title: 'Килимки в салон Volkswagen Lavida 2018-… III покоління China Sedan',
        titleKey: 'volkswagen66',
      },
      {
        id: '2661',
        model: 'LT',
        year: ['1996', '2006'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2887-250x150.jpg',
        title: 'Килимки в салон Volkswagen LT 1996-2006 II покоління EU VAN',
        titleKey: 'volkswagen67',
      },
      {
        id: '2662',
        model: 'Lupo',
        year: ['1998', '2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2888-250x150.jpg',
        title: 'Килимки в салон Volkswagen Lupo 1998-2005 I покоління EU Hatchback',
        titleKey: 'volkswagen68',
      },
      {
        id: '2663',
        model: 'Passat',
        year: ['1980', '1988'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2889-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B2 1980-1988 II покоління EU Liftback',
        titleKey: 'volkswagen69',
      },
      {
        id: '2664',
        model: 'Passat',
        year: ['1988', '1993'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2890-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B3 1988-1993 III покоління EU Sedan',
        titleKey: 'volkswagen70',
      },

      {
        id: '2665',
        model: 'Passat',
        year: ['1988', '1993'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2891-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B3 1988-1993 III покоління EU Universal',
        titleKey: 'volkswagen71',
      },
      {
        id: '2666',
        model: 'Passat',
        year: ['1993', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2893-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B4 1993-1997 IV покоління EU Sedan',
        titleKey: 'volkswagen72',
      },
      {
        id: '2667',
        model: 'Passat',
        year: ['1993', '1997'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2892-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B4 1993-1997 IV покоління EU Universal',
        titleKey: 'volkswagen73',
      },
      {
        id: '2668',
        model: 'Passat',
        year: ['1996', '2000'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2894-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B5 1996-2000 V покоління EU Sedan дорест',
        titleKey: 'volkswagen74',
      },
      {
        id: '2669',
        model: 'Passat',
        year: ['1996', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2895-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B5 1996-2000 V покоління EU Universal дорест',
        titleKey: 'volkswagen75',
      },
      {
        id: '2670',
        model: 'Passat',
        year: ['2000', '2005'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2896-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B5+ 2000-2005 V покоління EU Sedan рест',
        titleKey: 'volkswagen76',
      },
      {
        id: '2671',
        model: 'Passat',
        year: ['2000', '2005'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2897-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B5+ 2000-2005 V покоління EU Universal рест',
        titleKey: 'volkswagen77',
      },
      {
        id: '2672',
        model: 'Passat',
        year: ['2005', '2010'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2899-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B6 2005-2010 VI покоління EU Sedan',
        titleKey: 'volkswagen78',
      },
      {
        id: '2673',
        model: 'Passat',
        year: ['2005', '2010'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2898-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B6 2005-2010 VI покоління EU Universal',
        titleKey: 'volkswagen79',
      },
      {
        id: '2674',
        model: 'Passat',
        year: ['2010', '2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2900-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B7 2010-2014 VII покоління EU Sedan',
        titleKey: 'volkswagen80',
      },

      {
        id: '2675',
        model: 'Passat',
        year: ['2010', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2901-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B7 2010-2014 VII покоління EU Universal',
        titleKey: 'volkswagen81',
      },
      {
        id: '2676',
        model: 'Passat',
        year: ['2010', '2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2902-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B7 Alltrack 2010-2014 VII покоління EU Universal',
        titleKey: 'volkswagen82',
      },
      {
        id: '2677',
        model: 'Passat',
        year: ['2014'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2903-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B8 2014-… VIII покоління EU Sedan',
        titleKey: 'volkswagen83',
      },
      {
        id: '2678',
        model: 'Passat',
        year: ['2014'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2904-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat B8 Alltrack 2014-… VIII покоління EU Universal дорест',
        titleKey: 'volkswagen84',
      },
      {
        id: '2679',
        model: 'Passat CC',
        year: ['2008', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2840-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat CC 2008-2012 I покоління EU Sedan дорест',
        titleKey: 'volkswagen85',
      },
      {
        id: '2680',
        model: 'Passat CC',
        year: ['2012', '2017'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/volkswagen/2839-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat CC 2012-2017 I покоління EU/USA Sedan рест',
        titleKey: 'volkswagen86',
      },
      {
        id: '2681',
        model: 'Passat',
        year: ['2011', '2015'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2906-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat NMS 2011-2015 I покоління USA Sedan дорест',
        titleKey: 'volkswagen87',
      },
      {
        id: '2682',
        model: 'Passat',
        year: ['2015', '2019'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2907-250x150.jpg',
        title: 'Килимки в салон Volkswagen Passat NMS 2015-2019 I покоління USA Sedan рест',
        titleKey: 'volkswagen88',
      },
      {
        id: '2683',
        model: 'Phaeton',
        year: ['2002', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2909-250x150.jpg',
        title: 'Килимки в салон Volkswagen Phaeton 2002-2016 I покоління EU Sedan Long',
        titleKey: 'volkswagen89',
      },
      {
        id: '2684',
        model: 'Phaeton',
        year: ['2002', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2908-250x150.jpg',
        title: 'Килимки в салон Volkswagen Phaeton 2002-2016 I покоління EU Sedan Short',
        titleKey: 'volkswagen90',
      },

      {
        id: '2685',
        model: 'Pointer/Gol',
        year: ['1999', '2005'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2910-250x150.jpg',
        title: 'Килимки в салон Volkswagen Pointer/Gol 1999-2005 III покоління EU Hatchback',
        titleKey: 'volkswagen91',
      },
      {
        id: '2686',
        model: 'Polo',
        year: ['1981', '1994'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2911-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (II) 1981-1994 II покоління EU Hatchback',
        titleKey: 'volkswagen92',
      },
      {
        id: '2687',
        model: 'Polo',
        year: ['1994', '2001'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2914-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (III) 1994-2001 III покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen93',
      },
      {
        id: '2688',
        model: 'Polo',
        year: ['1994', '2001'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2913-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (III) 1994-2001 III покоління EU Hatchback 5-ти дверна',
        titleKey: 'volkswagen94',
      },
      {
        id: '2689',
        model: 'Polo',
        year: ['1994', '2001'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2912-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (III) 1994-2001 III покоління EU Sedan',
        titleKey: 'volkswagen95',
      },
      {
        id: '2690',
        model: 'Polo',
        year: ['2001', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2916-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (IV) 2001-2009 IV покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen96',
      },
      {
        id: '2691',
        model: 'Polo',
        year: ['2001', '2009'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2915-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (IV) 2001-2009 IV покоління EU Hatchback 5-ти дверна',
        titleKey: 'volkswagen97',
      },
      {
        id: '2692',
        model: 'Polo',
        year: ['2009', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2918-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (V) 2009-2017 V покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen98',
      },
      {
        id: '2693',
        model: 'Polo',
        year: ['2009', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2919-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (V) 2009-2017 V покоління EU Hatchback 5-ти дверна',
        titleKey: 'volkswagen99',
      },
      {
        id: '2694',
        model: 'Polo',
        year: ['2009', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2917-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (V) 2009-2017 V покоління EU Sedan',
        titleKey: 'volkswagen100',
      },

      {
        id: '2695',
        model: 'Polo',
        year: ['2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2920-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (VI) 2017-… VI покоління EU Hatchback',
        titleKey: 'volkswagen101',
      },
      {
        id: '2696',
        model: 'Polo',
        year: ['2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2921-250x150.jpg',
        title: 'Килимки в салон Volkswagen Polo (VI) 2017-… VI покоління EU Sedan',
        titleKey: 'volkswagen102',
      },
      {
        id: '2697',
        model: 'Routan',
        year: ['2009', '2014'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2922-250x150.jpg',
        title: 'Килимки в салон Volkswagen Routan 2009-2014 I покоління EU Minivan 7-ми місна',
        titleKey: 'volkswagen103',
      },
      {
        id: '2698',
        model: 'Scirocco',
        year: ['1981', '1992'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2923-250x150.jpg',
        title: 'Килимки в салон Volkswagen Scirocco Mk3 1981-1992 II покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen104',
      },
      {
        id: '2699',
        model: 'Scirocco',
        year: ['2008', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2924-250x150.jpg',
        title: 'Килимки в салон Volkswagen Scirocco Mk3 2008-2017 III покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen105',
      },
      {
        id: '2700',
        model: 'Sharan',
        year: ['1995', '2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2925-250x150.jpg',
        title: 'Килимки в салон Volkswagen Sharan (7M) 1995-2010 I покоління EU Minivan 7-ми місна',
        titleKey: 'volkswagen106',
      },
      {
        id: '2701',
        model: 'Sharan',
        year: ['2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2926-250x150.jpg',
        title: 'Килимки в салон Volkswagen Sharan (7N) 2010-… II покоління EU Minivan 6-ти місна',
        titleKey: 'volkswagen107',
      },
      {
        id: '2702',
        model: 'Sharan',
        year: ['2010'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2927-250x150.jpg',
        title: 'Килимки в салон Volkswagen Sharan (7N) 2010-… II покоління EU Minivan 7-ми місна',
        titleKey: 'volkswagen108',
      },
      {
        id: '2703',
        model: 'T-Cross',
        year: ['2019'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2945-250x150.jpg',
        title: 'Килимки в салон Volkswagen T-Cross 2019-… EU Crossover',
        titleKey: 'volkswagen109',
      },
      {
        id: '2704',
        model: 'T-Roc',
        year: ['2017'],
        body: 'Crossover 5-ти дверна',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2963-250x150.jpg',
        title: 'Килимки в салон Volkswagen T-Roc 2017-… I покоління EU Crossover 5-ти дверна',
        titleKey: 'volkswagen110',
      },

      {
        id: '2705',
        model: 'T3',
        year: ['1979', '1992'],
        body: 'VAN висока підлога',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2928-250x150.jpg',
        title: 'Килимки в салон Volkswagen T3 Transporter 1979-1992 III покоління EU VAN висока підлога',
        titleKey: 'volkswagen111',
      },
      {
        id: '2706',
        model: 'T3',
        year: ['1979', '1992'],
        body: 'VAN низька підлога',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2929-250x150.jpg',
        title: 'Килимки в салон Volkswagen T3 Transporter 1979-1992 III покоління EU VAN низька підлога',
        titleKey: 'volkswagen112',
      },
      {
        id: '2707',
        model: 'T4',
        year: ['1990', '2003'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2930-250x150.jpg',
        title: 'Килимки в салон Volkswagen T4 Caravelle 1990-2003 IV покоління EU VAN',
        titleKey: 'volkswagen113',
      },
      {
        id: '2708',
        model: 'T4',
        year: ['1990', '2003'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2931-250x150.jpg',
        title: 'Килимки в салон Volkswagen T4 Transporter 1990-2003 IV покоління EU VAN',
        titleKey: 'volkswagen114',
      },
      {
        id: '2709',
        model: 'T5',
        year: ['2011', '2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2932-250x150.jpg',
        title: 'Килимки в салон Volkswagen T5 California 2011-2015 V покоління EU VAN',
        titleKey: 'volkswagen115',
      },
      {
        id: '2710',
        model: 'T5',
        year: ['2003', '2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2933-250x150.jpg',
        title: 'Килимки в салон Volkswagen T5 Caravelle 2003-2015 V покоління EU VAN',
        titleKey: 'volkswagen116',
      },
      {
        id: '2711',
        model: 'T5',
        year: ['2003', '2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2817-250x150.jpg',
        title: 'Килимки в салон Volkswagen T5 Multivan 2003-2015 V покоління EU VAN пасажир',
        titleKey: 'volkswagen117',
      },
      {
        id: '2712',
        model: 'T5',
        year: ['2003', '2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2818-250x150.jpg',
        title: 'Килимки в салон Volkswagen T5 Multivan 2003-2015 V покоління EU VAN позаду - 1 двері',
        titleKey: 'volkswagen118',
      },
      {
        id: '2713',
        model: 'T5',
        year: ['2003', '2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2819-250x150.jpg',
        title: 'Килимки в салон Volkswagen T5 Multivan 2003-2015 V покоління EU VAN позаду - 2 двері',
        titleKey: 'volkswagen119',
      },
      {
        id: '2714',
        model: 'T5',
        year: ['2003', '2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2935-250x150.jpg',
        title: 'Килимки в салон Volkswagen T5 Transporter 2003-2015 V покоління EU VAN груз-пас.',
        titleKey: 'volkswagen120',
      },

      {
        id: '2715',
        model: 'T6',
        year: ['2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2937-250x150.jpg',
        title: 'Килимки в салон Volkswagen T6 Caravelle 2015-… VI покоління EU VAN 1 двері',
        titleKey: 'volkswagen121',
      },
      {
        id: '2716',
        model: 'T6',
        year: ['2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2938-250x150.jpg',
        title: 'Килимки в салон Volkswagen T6 Caravelle 2015-… VI покоління EU VAN 2 двері',
        titleKey: 'volkswagen122',
      },
      {
        id: '2717',
        model: 'T6',
        year: ['2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2940-250x150.jpg',
        title: 'Килимки в салон Volkswagen T6 Multivan 2015-… VI покоління EU VAN без стола',
        titleKey: 'volkswagen123',
      },
      {
        id: '2718',
        model: 'T6',
        year: ['2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2939-250x150.jpg',
        title: 'Килимки в салон Volkswagen T6 Multivan 2015-… VI покоління EU VAN зі столом',
        titleKey: 'volkswagen124',
      },
      {
        id: '2719',
        model: 'T6',
        year: ['2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2943-250x150.jpg',
        title: 'Килимки в салон Volkswagen T6 Transporter 2015-… VI покоління EU VAN Long',
        titleKey: 'volkswagen125',
      },
      {
        id: '2720',
        model: 'T6',
        year: ['2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2941-250x150.jpg',
        title: 'Килимки в салон Volkswagen T6 Transporter 2015-… VI покоління EU VAN груз-пас.',
        titleKey: 'volkswagen126',
      },
      {
        id: '2721',
        model: 'T6',
        year: ['2015'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2942-250x150.jpg',
        title: 'Килимки в салон Volkswagen T6 Transporter 2015-… VI покоління EU VAN пасажир',
        titleKey: 'volkswagen127',
      },
      {
        id: '2722',
        model: 'T7',
        year: ['2021'],
        body: 'VAN',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2944-250x150.jpg',
        title: 'Килимки в салон Volkswagen T7 Multivan 2021-… VII покоління EU VAN Long',
        titleKey: 'volkswagen128',
      },
      {
        id: '2723',
        model: 'Tiguan',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2946-250x150.jpg',
        title: 'Килимки в салон Volkswagen Tiguan Allspace 2016-2020 II покоління USA Crossover 7-ми місна',
        titleKey: 'volkswagen129',
      },
      {
        id: '2724',
        model: 'Tiguan',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2949-250x150.jpg',
        title: 'Килимки в салон Volkswagen Tiguan Mk2 2016-2020 II покоління EU Crossover дорест 5-ти місна',
        titleKey: 'volkswagen130',
      },

      {
        id: '2725',
        model: 'Tiguan',
        year: ['2016', '2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2948-250x150.jpg',
        title: 'Килимки в салон Volkswagen Tiguan Mk2 2016-2020 II покоління USA Crossover дорест',
        titleKey: 'volkswagen131',
      },
      {
        id: '2726',
        model: 'Tiguan',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2950-250x150.jpg',
        title: 'Килимки в салон Volkswagen Tiguan Mk2 2020-… II покоління EU Crossover рест 5-ти місна',
        titleKey: 'volkswagen132',
      },
      {
        id: '2727',
        model: 'Tiguan',
        year: ['2020'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/5983-250x150.jpg',
        title: 'Килимки в салон Volkswagen Tiguan Mk2 2020-… II покоління USA Crossover рест 5-ти місна',
        titleKey: 'volkswagen133',
      },
      {
        id: '2728',
        model: 'Tiguan',
        year: ['2007', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2951-250x150.jpg',
        title: 'Килимки в салон Volkswagen Tiguan NF 2007-2018 I покоління EU Crossover',
        titleKey: 'volkswagen134',
      },
      {
        id: '2729',
        model: 'Tiguan',
        year: ['2007', '2018'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/volkswagen/2952-250x150.jpg',
        title: 'Килимки в салон Volkswagen Tiguan NF 2007-2018 I покоління USA Crossover',
        titleKey: 'volkswagen135',
      },
      {
        id: '2730',
        model: 'Touareg',
        year: ['2002', '2006'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2954-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touareg (7L) 2002-2006 I покоління EU Crossover дорест',
        titleKey: 'volkswagen136',
      },
      {
        id: '2731',
        model: 'Touareg',
        year: ['2006', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2955-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touareg (7L) 2006-2010 I покоління EU Crossover дорест праве кермо',
        titleKey: 'volkswagen137',
      },
      {
        id: '2732',
        model: 'Touareg',
        year: ['2006', '2010'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2953-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touareg (7L) 2006-2010 I покоління EU Crossover рест',
        titleKey: 'volkswagen138',
      },
      {
        id: '2733',
        model: 'Touareg',
        year: ['2010', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2957-250x150.jpg',
        title:
          'Килимки в салон Volkswagen Touareg (7P) 2010-2014 II покоління EU Crossover дорест 2-zone climate control',
        titleKey: 'volkswagen139',
      },
      {
        id: '2734',
        model: 'Touareg',
        year: ['2010', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2958-250x150.jpg',
        title:
          'Килимки в салон Volkswagen Touareg (7P) 2010-2014 II покоління EU Crossover дорест 4-zone climate control',
        titleKey: 'volkswagen140',
      },

      {
        id: '2735',
        model: 'Touareg',
        year: ['2014', '2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2956-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touareg (7P) 2014-2018 II покоління EU Crossover рест',
        titleKey: 'volkswagen141',
      },
      {
        id: '2736',
        model: 'Touareg',
        year: ['2018'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2959-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touareg (CR) 2018-… III покоління EU Crossover',
        titleKey: 'volkswagen142',
      },
      {
        id: '2737',
        model: 'Touran',
        year: ['2003', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2961-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touran 1T 2003-2015 I покоління EU Minivan 5-ти місна',
        titleKey: 'volkswagen143',
      },
      {
        id: '2738',
        model: 'Touran',
        year: ['2003', '2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2960-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touran 1T 2003-2015 I покоління EU Minivan 7-ми місна',
        titleKey: 'volkswagen144',
      },
      {
        id: '2739',
        model: 'Touran',
        year: ['2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2960-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touran 5T 2015-… II покоління EU Minivan 7-ми місна',
        titleKey: 'volkswagen145',
      },
      {
        id: '2740',
        model: 'Touran',
        year: ['2015'],
        body: 'Minivan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2962-250x150.jpg',
        title: 'Килимки в салон Volkswagen Touran 5T 2015-… II покоління EU Minivan 5-ти місна',
        titleKey: 'volkswagen146',
      },
      {
        id: '2741',
        model: 'Up!',
        year: ['2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/5984-250x150.jpg',
        title: 'Килимки в салон Volkswagen Up! 2011-… I покоління EU Hatchback 3-х дверна',
        titleKey: 'volkswagen147',
      },
      {
        id: '2742',
        model: 'Up!',
        year: ['2011'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2964-250x150.jpg',
        title: 'Килимки в салон Volkswagen Up! 2011-… I покоління EU Hatchback 5-ти дверна',
        titleKey: 'volkswagen148',
      },
      {
        id: '2743',
        model: 'Vento',
        year: ['1992', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volkswagen/2965-250x150.jpg',
        title: 'Килимки в салон Volkswagen Vento 1992-1998 I покоління EU Sedan',
        titleKey: 'volkswagen149',
      },
    ],
  },
  /* ---------------------------------- Volvo --------------------------------- */
  Volvo: {
    logo: '/images/catalog/logo/volvo-100x100.png',
    name: 'Volvo',
    models: [
      {
        id: '2744',
        model: '440',
        year: ['1988', '1996'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volvo/1901-250x150.jpg',
        title: 'Килимки в салон Volvo 440 1988-1996 Hatchback I покоління EU',
        titleKey: 'volvo1',
      },
      {
        id: '2745',
        model: '460',
        year: ['1988', '1996'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1902-250x150.jpg',
        title: 'Килимки в салон Volvo 460 1988-1996 Sedan I покоління EU',
        titleKey: 'volvo2',
      },
      {
        id: '2746',
        model: '850',
        year: ['1991', '1997'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1903-250x150.jpg',
        title: 'Килимки в салон Volvo 850 1991-1997 Sedan I покоління EU',
        titleKey: 'volvo3',
      },
      {
        id: '2747',
        model: '850',
        year: ['1991', '1997'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1904-250x150.jpg',
        title: 'Килимки в салон Volvo 850 1991-1997 Universal I покоління EU',
        titleKey: 'volvo4',
      },
      {
        id: '2748',
        model: '940',
        year: ['1990', '1998'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/2070-250x150.jpg',
        title: 'Килимки в салон Volvo 940 1990-1998 Universal I покоління EU',
        titleKey: 'volvo5',
      },
      {
        id: '2749',
        model: '960',
        year: ['1990', '1998'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1905-250x150.jpg',
        title: 'Килимки в салон Volvo 960 1990-1998 Sedan I покоління EU',
        titleKey: 'volvo6',
      },
      {
        id: '2750',
        model: '960',
        year: ['1990', '1998'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1906-250x150.jpg',
        title: 'Килимки в салон Volvo 960 1990-1998 Universal I покоління EU',
        titleKey: 'volvo7',
      },
      {
        id: '2751',
        model: 'C30',
        year: ['2006', '2012'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volvo/1907-250x150.jpg',
        title: 'Килимки в салон Volvo C30 2006-2012 Hatchback I покоління EU 3-х дверная',
        titleKey: 'volvo8',
      },
      {
        id: '2752',
        model: 'C40',
        year: ['2022'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/volvo/1908-250x150.jpg',
        title: 'Килимки в салон Volvo C40 2022-... Crossover 0 покоління China',
        titleKey: 'volvo9',
      },
      {
        id: '2753',
        model: 'C70',
        year: ['2005', '2013'],
        body: 'Cabriolet',
        market: 'EU',
        img: '/images/catalog/models/volvo/1909-250x150.jpg',
        title: 'Килимки в салон Volvo C70 2005-2013 Cabriolet II покоління EU',
        titleKey: 'volvo10',
      },

      {
        id: '2754',
        model: 'S40',
        year: ['1996', '2001'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1911-250x150.jpg',
        title: 'Килимки в салон Volvo S40 1996-2001 Sedan I покоління EU до рестайлинг',
        titleKey: 'volvo11',
      },
      {
        id: '2755',
        model: 'S40',
        year: ['2001', '2004'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1912-250x150.jpg',
        title: 'Килимки в салон Volvo S40 2001-2004 Sedan I покоління EU рестайлинг',
        titleKey: 'volvo12',
      },
      {
        id: '2756',
        model: 'S40',
        year: ['2004', '2012'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1912-250x150.jpg',
        title: 'Килимки в салон Volvo S40 2004-2012 Sedan II покоління EU',
        titleKey: 'volvo13',
      },
      {
        id: '2757',
        model: 'S60',
        year: ['2000', '2009'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1913-250x150.jpg',
        title: 'Килимки в салон Volvo S60 2000-2009 Sedan I покоління EU',
        titleKey: 'volvo14',
      },
      {
        id: '2758',
        model: 'S60',
        year: ['2010', '2018'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/volvo/1915-250x150.jpg',
        title: 'Килимки в салон Volvo S60 2010-2018 Sedan II покоління EU/USA',
        titleKey: 'volvo15',
      },
      {
        id: '2759',
        model: 'S60',
        year: ['2010', '2018'],
        body: 'Sedan',
        market: 'USA',
        img: '/images/catalog/models/volvo/1917-250x150.jpg',
        title: 'Килимки в салон Volvo S60 2010-2018 Sedan II покоління USA',
        titleKey: 'volvo16',
      },
      {
        id: '2760',
        model: 'S60',
        year: ['2019'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1916-250x150.jpg',
        title: 'Килимки в салон Volvo S60 2019-... Sedan III покоління EU',
        titleKey: 'volvo17',
      },
      {
        id: '2761',
        model: 'S60',
        year: ['2013', '2018'],
        body: 'Sedan',
        market: 'China',
        img: '/images/catalog/models/volvo/1914-250x150.jpg',
        title: 'Килимки в салон Volvo S60 L 2013-2018 Sedan II покоління China',
        titleKey: 'volvo18',
      },
      {
        id: '2762',
        model: 'S80',
        year: ['2006', '2016'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1918-250x150.jpg',
        title: 'Килимки в салон Volvo S80 AS 2006-2016 Sedan II покоління EU',
        titleKey: 'volvo19',
      },
      {
        id: '2763',
        model: 'S80',
        year: ['1998', '2006'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/volvo/1919-250x150.jpg',
        title: 'Килимки в салон Volvo S80 TS 1998-2006 Sedan I покоління EU',
        titleKey: 'volvo20',
      },

      {
        id: '2764',
        model: 'S90',
        year: ['2016'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/volvo/1920-250x150.jpg',
        title: 'Килимки в салон Volvo S90 2016-... Sedan II покоління EU/USA Short',
        titleKey: 'volvo21',
      },
      {
        id: '2765',
        model: 'S90L',
        year: ['2016'],
        body: 'Sedan',
        market: 'EU/USA',
        img: '/images/catalog/models/volvo/2075-250x150.jpg',
        title: 'Килимки в салон Volvo S90L 2016-... Sedan II покоління EU/USA Long',
        titleKey: 'volvo22',
      },
      {
        id: '2766',
        model: 'V40',
        year: ['2012', '2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volvo/1921-250x150.jpg',
        title: 'Килимки в салон Volvo V40 (Cross Country) 2012-2016 Hatchback I покоління EU до рестайлинг',
        titleKey: 'volvo23',
      },
      {
        id: '2767',
        model: 'V40',
        year: ['2016'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/volvo/1922-250x150.jpg',
        title: 'Килимки в салон Volvo V40 (Cross Country) 2016-... Hatchback I покоління EU рестайлинг',
        titleKey: 'volvo24',
      },
      {
        id: '2768',
        model: 'V40',
        year: ['1996', '2004'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1923-250x150.jpg',
        title: 'Килимки в салон Volvo V40 1996-2004 Universal I покоління EU',
        titleKey: 'volvo25',
      },
      {
        id: '2769',
        model: 'V50',
        year: ['2004', '2012'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1924-250x150.jpg',
        title: 'Килимки в салон Volvo V50 2004-2012 Universal II покоління EU рестайлинг',
        titleKey: 'volvo26',
      },
      {
        id: '2770',
        model: 'V60',
        year: ['2015', '2018'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/volvo/1927-250x150.jpg',
        title: 'Килимки в салон Volvo V60 (Cross Country) 2015-2018 Universal I покоління USA',
        titleKey: 'volvo27',
      },
      {
        id: '2771',
        model: 'V60',
        year: ['2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1928-250x150.jpg',
        title: 'Килимки в салон Volvo V60 (Cross Country) 2018-… Universal II покоління EU',
        titleKey: 'volvo28',
      },
      {
        id: '2772',
        model: 'V60',
        year: ['2010', '2017'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1925-250x150.jpg',
        title: 'Килимки в салон Volvo V60 2010-2017 Universal I покоління EU',
        titleKey: 'volvo29',
      },
      {
        id: '2773',
        model: 'V60',
        year: ['2010', '2017'],
        body: 'Universal',
        market: 'USA',
        img: '/images/catalog/models/volvo/1926-250x150.jpg',
        title: 'Килимки в салон Volvo V60 2010-2017 Universal I покоління USA',
        titleKey: 'volvo30',
      },

      {
        id: '2774',
        model: 'V60',
        year: ['2018'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/2079-250x150.jpg',
        title: 'Килимки в салон Volvo V60 2018 - ... II покоління EU Universal',
        titleKey: 'volvo31',
      },
      {
        id: '2775',
        model: 'V70',
        year: ['1996', '2000'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/2080-250x150.jpg',
        title: 'Килимки в салон Volvo V70 1996 - 2000 I покоління EU Universal',
        titleKey: 'volvo32',
      },
      {
        id: '2776',
        model: 'V70 P2',
        year: ['2000', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1930-250x150.jpg',
        title: 'Килимки в салон Volvo V70 P2 2000 - 2007 II покоління EU Universal',
        titleKey: 'volvo33',
      },
      {
        id: '2777',
        model: 'V70 P24',
        year: ['2007', '2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1929-250x150.jpg',
        title: 'Килимки в салон Volvo V70 P24 2007 - 2016 III покоління EU Universal',
        titleKey: 'volvo34',
      },
      {
        id: '2778',
        model: 'V90',
        year: ['2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1931-250x150.jpg',
        title: 'Килимки в салон Volvo V90 2016 - ... II покоління EU Universal',
        titleKey: 'volvo35',
      },
      {
        id: '2779',
        model: 'V90 Cross Country',
        year: ['2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/2081-250x150.jpg',
        title: 'Килимки в салон Volvo V90 Cross Country 2016 - ... II покоління EU Universal',
        titleKey: 'volvo36',
      },
      {
        id: '2780',
        model: 'V90',
        year: ['2016'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1932-250x150.jpg',
        title: 'Килимки в салон Volvo V90 Cross Country 2016 - ... II покоління EU Universal',
        titleKey: 'volvo37',
      },
      {
        id: '2781',
        model: 'XC40',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volvo/1933-250x150.jpg',
        title: 'Килимки в салон Volvo XC40 2017 - ... I покоління EU Crossover',
        titleKey: 'volvo38',
      },
      {
        id: '2782',
        model: 'XC60',
        year: ['2008', '2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volvo/1934-250x150.jpg',
        title: 'Килимки в салон Volvo XC60 2008 - 2017 I покоління EU Crossover',
        titleKey: 'volvo39',
      },
      {
        id: '2783',
        model: 'XC60',
        year: ['2008', '2017'],
        body: 'Crossover',
        market: 'USA',
        img: '/images/catalog/models/volvo/1935-250x150.jpg',
        title: 'Килимки в салон Volvo XC60 2008 - 2017 I покоління USA Crossover',
        titleKey: 'volvo40',
      },

      {
        id: '2784',
        model: 'XC60',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volvo/1936-250x150.jpg',
        title: 'Килимки в салон Volvo XC60 2017 - ... II покоління EU Crossover',
        titleKey: 'volvo41',
      },
      {
        id: '2785',
        model: 'XC60',
        year: ['2017'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volvo/1936-250x150.jpg',
        title: 'Килимки в салон Volvo XC60 2017 - ... II покоління EU Hybrid Crossover',
        titleKey: 'volvo42',
      },
      {
        id: '2786',
        model: 'XC70',
        year: ['2000', '2007'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/volvo/1937-250x150.jpg',
        title: 'Килимки в салон Volvo XC70 2000 - 2007 I покоління EU Universal',
        titleKey: 'volvo43',
      },
      {
        id: '2787',
        model: 'XC70',
        year: ['2007', '2016'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volvo/1938-250x150.jpg',
        title: 'Килимки в салон Volvo XC70 2007 - 2016 II покоління EU до рестайлинг Crossover',
        titleKey: 'volvo44',
      },
      {
        id: '2788',
        model: 'XC90',
        year: ['2002', '2014'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volvo/2069-250x150.jpg',
        title: 'Килимки в салон Volvo XC90 2002 - 2014 I покоління EU 7-ми місна Crossover',
        titleKey: 'volvo45',
      },
      {
        id: '2789',
        model: 'XC90',
        year: ['2014', '2020'],
        body: 'Crossover',
        market: 'EU/USA',
        img: '/images/catalog/models/volvo/2070-250x150.jpg',
        title: 'Килимки в салон Volvo XC90 2014 - 2020 II покоління EU/USA дорест 7-ми місна Crossover',
        titleKey: 'volvo46',
      },
      {
        id: '2790',
        model: 'XC90',
        year: ['2020'],
        body: 'Crossover',
        market: 'EU',
        img: '/images/catalog/models/volvo/2071-250x150.jpg',
        title: 'Килимки в салон Volvo XC90 2020 - ... II покоління EU рестайлинг 7-ми місна Crossover',
        titleKey: 'volvo47',
      },
    ],
  },
  /* ------------------------------- Weltmeister ------------------------------ */
  Weltmeister: {
    logo: '/images/catalog/logo/Weltmeister-100x100.png',
    name: 'Weltmeister',
    models: [
      {
        id: '2791',
        model: 'EX5-Z',
        year: ['2018'],
        body: 'Crossover',
        market: '',
        img: '/images/catalog/models/weltmeister/2808-250x150.jpg',
        title: 'Килимки в салон Weltmeister EX5-Z 2018-… Crossover I покоління',
        titleKey: 'weltmeister1',
      },
      {
        id: '2792',
        model: 'W6',
        year: ['2021'],
        body: 'Crossover',
        market: '',
        img: '/images/catalog/models/weltmeister/2809-250x150.jpg',
        title: 'Килимки в салон Weltmeister W6 2021-… Crossover I покоління',
        titleKey: 'weltmeister2',
      },
    ],
  },
  /* ---------------------------------- Wolv ---------------------------------- */
  Wolv: {
    logo: '/images/catalog/logo/Wolv-100x100.png',
    name: 'Wolv',
    models: [
      {
        id: '2793',
        model: 'FC2200',
        year: ['2020'],
        body: 'VAN',
        market: '',
        img: '/images/catalog/models/wolv/2810-250x150.jpg',
        title: 'Килимки в салон Wolv FC2200 2020-… VAN I покоління',
        titleKey: 'wolv',
      },
    ],
  },
  /* ---------------------------------- Xpeng --------------------------------- */
  Xpeng: {
    logo: '/images/catalog/logo/Xpeng-100x100.png',
    name: 'Xpeng',
    models: [
      {
        id: '2794',
        model: 'P7',
        year: ['2021'],
        body: 'Sedan',
        market: '',
        img: '/images/catalog/models/xpeng/2811-250x150.jpg',
        title: 'Килимки в салон Xpeng P7 2021-… Sedan I покоління',
        titleKey: 'xpeng',
      },
    ],
  },
  /* ----------------------------------- ZAZ ---------------------------------- */
  Zaz: {
    logo: '/images/catalog/logo/zaz-100x100.png',
    name: 'ЗАЗ',
    models: [
      {
        id: '2795',
        model: 'Славута',
        year: ['1999', '2011'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/zaz/5944-250x150.jpg',
        title: 'Килимки в салон ZAZ 1103 "Славута" 1999-2011 I покоління EU Sedan',
        titleKey: 'zaz1',
      },
      {
        id: '2796',
        model: 'Дана',
        year: ['1994', '2011'],
        body: 'Universal',
        market: 'EU',
        img: '/images/catalog/models/zaz/5941-250x150.jpg',
        title: 'Килимки в салон ZAZ 1105 "Дана" 1994-2011 I покоління EU Universal',
        titleKey: 'zaz2',
      },
      {
        id: '2797',
        model: 'Запорожець',
        year: ['1960', '1969'],
        body: 'Coupe',
        market: 'EU',
        img: '/images/catalog/models/zaz/5942-250x150.jpg',
        title: 'Килимки в салон ZAZ 965 "Запорожець" 1960-1969 I покоління EU Coupe',
        titleKey: 'zaz3',
      },
      {
        id: '2798',
        model: 'Запорожець',
        year: ['1980', '1994'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/zaz/5943-250x150.jpg',
        title: 'Килимки в салон ZAZ 968M "Запорожець" 1980-1994 I покоління EU Sedan',
        titleKey: 'zaz4',
      },
      {
        id: '2799',
        model: 'Forza',
        year: ['2011', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/zaz/5937-250x150.jpg',
        title: 'Килимки в салон ZAZ Forza 2011-2017 I покоління EU Hatchback',
        titleKey: 'zaz5',
      },
      {
        id: '2800',
        model: 'Forza',
        year: ['2011', '2017'],
        body: 'Liftback',
        market: 'EU',
        img: '/images/catalog/models/zaz/5938-250x150.jpg',
        title: 'Килимки в салон ZAZ Forza 2011-2017 I покоління EU Liftback',
        titleKey: 'zaz6',
      },
      {
        id: '2801',
        model: 'Vida',
        year: ['2012', '2017'],
        body: 'Hatchback',
        market: 'EU',
        img: '/images/catalog/models/zaz/5940-250x150.jpg',
        title: 'Килимки в салон ZAZ Vida 2012-2017 I покоління EU Hatchback',
        titleKey: 'zaz7',
      },
      {
        id: '2802',
        model: 'Vida',
        year: ['2012', '2017'],
        body: 'Sedan',
        market: 'EU',
        img: '/images/catalog/models/zaz/5939-250x150.jpg',
        title: 'Килимки в салон ZAZ Vida 2012-2017 I покоління EU Sedan',
        titleKey: 'zaz8',
      },
      {
        id: '2803',
        model: 'Таврія',
        year: ['1987', '1998'],
        body: 'Hatchback',
        market: 'UK',
        img: '/images/catalog/models/zaz/5945-250x150.jpg',
        title: 'Килимки в салон ZAZ Таврія 1102 1987-1998 I покоління UK Hatchback',
        titleKey: 'zaz9',
      },
      {
        id: '2804',
        model: 'Таврія',
        year: ['1999', '2011'],
        body: 'Pickup',
        market: 'UK',
        img: '/images/catalog/models/zaz/5946-250x150.jpg',
        title: 'Килимки в салон ZAZ Таврія 110557 1999-2011 I покоління UK Pickup',
        titleKey: 'zaz10',
      },
      {
        id: '2805',
        model: 'Таврія Нова 1102',
        year: ['1998', '2007'],
        body: 'Hatchback',
        market: 'UK',
        img: '/images/catalog/models/zaz/5947-250x150.jpg',
        title: 'Килимки в салон ZAZ Таврія Нова 1102 1998-2007 II покоління UK Hatchback',
        titleKey: 'zaz11',
      },
    ],
  },
  /* ---------------------------------- Zeekr --------------------------------- */
  Zeekr: {
    logo: '/images/catalog/logo/Zeekr-100x100.png',
    name: 'Zeekr',
    models: [
      {
        id: '2806',
        model: 'Zeekr',
        year: ['2023'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/zeekr/6023-250x150.jpg',
        title: 'Килимки в салон Zeekr X 2023-... I покоління China Crossover 4-х місна',
        titleKey: 'zeekr1',
      },
      {
        id: '2807',
        model: 'Zeekr',
        year: ['2023'],
        body: 'Crossover',
        market: 'China',
        img: '/images/catalog/models/zeekr/6022-250x150.jpg',
        title: 'Килимки в салон Zeekr X 2023-... I покоління China Crossover 5-ти місна',
        titleKey: 'zeekr2',
      },
    ],
  },
  /* --------------------------------- Zhidou --------------------------------- */
  Zhidou: {
    logo: '/images/catalog/logo/Zhidou-100x100.png',
    name: 'Zhidou',
    models: [
      {
        id: '2808',
        model: 'D2',
        year: ['2014'],
        body: 'Hatchback',
        market: '',
        img: '/images/catalog/models/zhidou/2812-250x150.jpg',
        title: 'Килимки в салон Zhidou D2 2014-… Hatchback I покоління',
        titleKey: 'zhidou',
      },
    ],
  },
  /* ----------------------------------- ZX ----------------------------------- */
  Zx: {
    logo: '/images/catalog/logo/ZX-100x100.png',
    name: 'ZX',
    models: [
      {
        id: '2809',
        model: 'Admiral',
        year: ['2001', '2009'],
        body: 'Crossover',
        market: '',
        img: '/images/catalog/models/zx/2813-250x150.jpg',
        title: 'Килимки в салон ZX Admiral 2001-2009 Crossover I покоління',
        titleKey: 'zx1',
      },
      {
        id: '2810',
        model: 'LandMаrk',
        year: ['2005', '2013'],
        body: 'Crossover',
        market: '',
        img: '/images/catalog/models/zx/6000-250x150.jpg',
        title: 'Килимки в салон ZX LandMаrk 2005-2013 Crossover I покоління',
        titleKey: 'zx2',
      },
    ],
  },
};
