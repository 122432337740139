import { createSlice } from '@reduxjs/toolkit';
import { fetchProductsBySearch } from './searchProductsApi';
import { transliterateToEnglish } from '../../utils/formatURL';

const searchProductsSlice = createSlice({
  name: 'searchProducts',
  initialState: {
    searchQuery: '',
    filteredProducts: [],
  },
  reducers: {
    setSearchQuery: (state, { payload }) => {
      state.searchQuery = payload;

      if (state.searchQuery === '') {
        state.filteredProducts = [];
      }
    },

    resetState: (state) => {
      state.searchQuery = '';
      state.filteredProducts = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsBySearch.fulfilled, (state, { payload }) => {
        state.filteredProducts = payload;
      })
      .addCase(fetchProductsBySearch.rejected, (state) => {
        state.filteredProducts = [];
      });
  },
});

export const { setSearchQuery, resetState } = searchProductsSlice.actions;
export const searchProductsReducer = searchProductsSlice.reducer;
