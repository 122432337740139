// Объект брендов (русские и английские названия)
// сопоставляется с введённым словом в инпут если на русском отправляется на транслитерацию если на англ остаётся как есть
export const keyWords = {
  мазд: 'Mazda',
  маз: 'Mazda',
  мазда: 'Mazda',
  той: 'Toyota',
  тойо: 'Toyota',
  тойота: 'Toyota',
  фор: 'Ford',
  форд: 'Ford',
  мерс: 'Mercedes',
  мерсе: 'Mercedes',
  мерседес: 'Mercedes',
  бмв: 'BMW',
  ауд: 'Audi',
  ауди: 'Audi',
  ауді: 'Audi',
  опе: 'Opel',
  опел: 'Opel',
  опель: 'Opel',
  пэж: 'Peugeot',
  пеж: 'Peugeot',
  пежо: 'Peugeot',
  нис: 'Nissan',
  нисан: 'Nissan',
  нісан: 'Nissan',
  ниссан: 'Nissan',
  ніссан: 'Nissan',
  хон: 'Honda',
  хонд: 'Honda',
  хонда: 'Honda',
  шко: 'Skoda',
  шкод: 'Skoda',
  шкода: 'Skoda',
  суб: 'Subaru',
  суба: 'Subaru',
  субару: 'Subaru',
  суз: 'Suzuki',
  сузу: 'Suzuki',
  сузуки: 'Suzuki',
  сузукі: 'Suzuki',
  лек: 'Lexus',
  лекс: 'Lexus',
  лексус: 'Lexus',
  чер: 'Chery',
  чери: 'Chery',
  чері: 'Chery',
  черри: 'Chery',
  черрі: 'Chery',
  джыл: 'Geely',
  джіл: 'Geely',
  джыли: 'Geely',
  джилі: 'Geely',
  джы: 'Jeep',
  джи: 'Jeep',
  джып: 'Jeep',
  джип: 'Jeep',
  інф: 'Infiniti',
  инф: 'Infiniti',
  инфи: 'Infiniti',
  інфі: 'Infiniti',
  инфинити: 'Infiniti',
  інфініті: 'Infiniti',
  вол: 'Volvo',
  воль: 'Volvo',
  вольво: 'Volvo',
  хюн: 'Hyundai',
  хун: 'Hyundai',
  хунд: 'Hyundai',
  хундай: 'Hyundai',
  хюнд: 'Hyundai',
  хюндай: 'Hyundai',
  фол: 'Volkswagen',
  фоль: 'Volkswagen',
  фольс: 'Volkswagen',
  фольц: 'Volkswagen',
  фольк: 'Volkswagen',
  фолькс: 'Volkswagen',
  фольксваген: 'Volkswagen',
  фиат: 'Fiat',
  фіат: 'Fiat',
  кад: 'Cadillac',
  каді: 'Cadillac',
  кади: 'Cadillac',
  кадилак: 'Cadillac',
  кадиллак: 'Cadillac',
  каділак: 'Cadillac',
  каділлак: 'Cadillac',
  мазе: 'Maserati',
  мазер: 'Maserati',
  мазераті: 'Maserati',
  ламб: 'Lamborghini',
  ламба: 'Lamborghini',
  ламбо: 'Lamborghini',
  ламборгіні: 'Lamborghini',
  саб: 'Saab',
  сааб: 'Saab',
  ора: 'Ora',
  нэта: 'Neta',
  нєта: 'Neta',
  нета: 'Neta',
  аку: 'Acura',
  акура: 'Acura',
  альф: 'Alfa Romeo',
  альфа: 'Alfa Romeo',
  альфаромео: 'Alfa Romeo',
  бей: 'Beijing',
  бейд: 'Beijing',
  бейдж: 'Beijing',
  бейджинг: 'Beijing',
  буик: 'Buick',
  бьюик: 'Buick',
  бьюїк: 'Buick',
  бид: 'BYD',
  бід: 'BYD',
  чана: 'Chana',
  чевр: 'Chevrolet',
  шевр: 'Chevrolet',
  шевроле: 'Chevrolet',
  край: 'Chrysler',
  крайс: 'Chrysler',
  крайслер: 'Chrysler',
  ситр: 'Citroen',
  ситроен: 'Citroen',
  сітр: 'Citroen',
  сітроен: 'Citroen',
  купра: 'Cupra',
  дача: 'Dacia',
  дачия: 'Dacia',
  дачія: 'Dacia',
  дади: 'Dadi',
  даді: 'Dadi',
  деу: 'Daewoo',
  дайх: 'Daihatsu',
  дайхатсу: 'Daihatsu',
  додж: 'Dodge',
  фиск: 'Fisker',
  фискер: 'Fisker',
  фіск: 'Fisker',
  фіскер: 'Fisker',
  гз: 'GAZ',
  газ: 'GAZ',
  гмс: 'GMC',
  грейт: 'Great Wall',
  грей: 'Great Wall',
  грейтвол: 'Great Wall',
  хавал: 'Haval',
  хум: 'Hummer',
  хумм: 'Hummer',
  хамм: 'Hummer',
  хам: 'Hummer',
  хаммер: 'Hummer',
  ису: 'Isuzu',
  исузу: 'Isuzu',
  ісу: 'Isuzu',
  ісузу: 'Isuzu',
  ивеко: 'Iveco',
  івеко: 'Iveco',
  джак: 'JAC',
  ягуар: 'Jaguar',
  джетур: 'Jetour',
  кия: 'Kia',
  кія: 'Kia',
  лада: 'LADA',
  ланча: 'Lancia',
  лансия: 'Lancia',
  лансія: 'Lancia',
  ланчія: 'Lancia',
  лендр: 'Land Rover',
  ленд: 'Land Rover',
  лендровер: 'Land Rover',
  лео: 'Leopaard',
  леоп: 'Leopaard',
  леопард: 'Leopaard',
  лифан: 'Lifan',
  ліфан: 'Lifan',
  линк: 'Lincoln',
  линкольн: 'Lincoln',
  лінк: 'Lincoln',
  лінкольн: 'Lincoln',
  макс: 'Maxus',
  максус: 'Maxus',
  мерк: 'Mercury',
  мерку: 'Mercury',
  меркури: 'Mercury',
  меркурі: 'Mercury',
  мг: 'MG',
  мини: 'Mini Cooper',
  миникупер: 'Mini Cooper',
  міні: 'Mini Cooper',
  мінікупер: 'Mini Cooper',
  мицык: 'Mitsubishi',
  мицу: 'Mitsubishi',
  мицубиси: 'Mitsubishi',
  міцик: 'Mitsubishi',
  міцу: 'Mitsubishi',
  міцубісі: 'Mitsubishi',
  понт: 'Pontiac',
  понтиак: 'Pontiac',
  понтіак: 'Pontiac',
  понтяк: 'Pontiac',
  порш: 'Porsche',
  порше: 'Porsche',
  раво: 'Ravon',
  равон: 'Ravon',
  рено: 'Renault',
  сайпа: 'Saipa',
  саманд: 'Samand',
  самсунг: 'Samsung',
  сеат: 'Seat',
  сиат: 'Seat',
  сіат: 'Seat',
  сехол: 'Sehol',
  смарт: 'Smart',
  сауіст: 'SouEast',
  санг: 'SsangYong',
  сангйонг: 'SsangYong',
  тесла: 'Tesla',
  уаз: 'UAZ',
  ваз: 'VAZ',
  велтмайстер: 'Weltmeister',
  волв: 'Wolv',
  икспенг: 'Xpeng',
  ікспенг: 'Xpeng',
  заз: 'ZAZ',
  зеек: 'Zeekr',
  зеекр: 'Zeekr',
  зык: 'Zeekr',
  зик: 'Zeekr',
  зикр: 'Zeekr',
  зыкр: 'Zeekr',
  зік: 'Zeekr',
  зікр: 'Zeekr',
  жыдоу: 'Zhidou',
  жидоу: 'Zhidou',
  зх: 'ZX',
  eva: 'EVA матеріал',
  ева: 'EVA матеріал',
  eco: 'ECO матеріал',
  эко: 'ECO матеріал',
};
