/* eslint-disable no-undef */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CloseBtn, MessageWrapper, StyledBtn, StyledModal, Title } from './ModalThanks.styled';
import ModalBackdrop from '../../../components/common/ModalBackdrop/ModalBackdrop';
import { FlexBox } from '../ModalBasket/ModalBasket.styled';
import { deleteName } from '../../../redux/basket/basket';

const ModalThanks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name, totalPrice } = useSelector((state) => state.basket);
  const { t, i18n } = useTranslation();

  const goBack = () => navigate('/');

  useEffect(() => {
    // Викликаємо подію пікселя Facebook при відображенні сторінки подяки
    fbq('track', 'Purchase', { value: totalPrice, currency: t('accessories.money') }); // Сума покупки та валюта
  }, [totalPrice, t, i18n.language]);

  useEffect(() => {
    return () => {
      dispatch(deleteName());
    };
  }, [dispatch]);

  return (
    <ModalBackdrop>
      <StyledModal
        onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <CloseBtn src="/images/icons/close.svg" alt="Close" onClick={goBack} />
        <Title>
          {name} {t('thanksModal.title2')}
        </Title>
        <MessageWrapper>
          <p>{t('thanksModal.text2')}</p>
          <p>{t('thanksModal.text3')}</p>
          <p>
            {totalPrice} {t('accessories.money')}
          </p>
        </MessageWrapper>
        <FlexBox>
          <StyledBtn to="/accessories">{t('modalBasket.btn2')}</StyledBtn>
          <StyledBtn to="/">{t('thanksModal.btn')}</StyledBtn>
        </FlexBox>
      </StyledModal>
    </ModalBackdrop>
  );
};

export default ModalThanks;
