/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { COLOR_MAP, TYPE_MATERIAL_KEYS } from '../../data/allKeys';

const initialState = {
  genCardId: '',
  imgGen: '',
  title: '',
  typeM: '', //тип материала ева\эко
  slug: '',
  sku: '',
  color: '',
  size: '',
  colorEdge: '',
  selectedSideСolor: '', //цвет боковин
  selectedCenterColor: '', //цвет цента
  selectedInsertColor: '', //цвет вставки
  selectedThreadColor: '', //цвет нитки

  price: 0,
  salePrice: 0,
};

// достаём нужные атрибуты
const findAttributFunction = (attributes) => {
  let material = '';
  let value = '';
  let colorMaterial = [];

  attributes.forEach(({ id, options }) => {
    switch (id) {
      case 30:
        material = options || [];
        break;
      case 11:
        value = options?.[0] || '';
        break;
      case 15:
        colorMaterial = options || [];
        break;

      default:
        break;
    }
  });
  return { material, value, colorMaterial };
};

// получаем масив текстовых значени из WP и добавляем к ним ключи
// [{label:"",value:""}]
const getKeyFromAttributes = (keyAttribut, keyMap) => {
  if (keyAttribut.length === 0) return '';

  const newOptions = keyAttribut.map((option) => ({
    value: keyMap[option] || null,
    label: option,
  }));

  return newOptions;
};

const generalProductCardSlice = createSlice({
  name: 'generalProductCard',
  initialState,

  reducers: {
    popelateInitialState: (state, { payload }) => {
      const { id, name: title, images, attributes, slug, sku, price, regular_price, sale_price } = payload;
      const img = images[0]?.src;
      const { material, value, colorMaterial } = findAttributFunction(attributes);

      const numberPrice = !sale_price ? Number(regular_price) : Number(sale_price);
      const numberSalePrice = Number(sale_price);

      const currentColor =
        (getKeyFromAttributes(colorMaterial, COLOR_MAP) || []).find(({ value }) => value === 'bk')?.value || '';

      const currentMaterial = getKeyFromAttributes(material, TYPE_MATERIAL_KEYS)?.[0]?.value || '';

      state.genCardId = id;
      state.slug = slug;
      state.sku = sku || '';
      state.imgGen = img || '';
      state.title = title || '';
      state.typeM = currentMaterial;
      state.price = numberPrice;
      state.salePrice = numberSalePrice;
      // state.price = t(`accessories.${slug}.${id}.price`);
      state.size = value || ''; //размер
      state.color = '';
      // state.color = currentColor || '';
      state.colorEdge = ''; //цвет канта
      state.selectedSideСolor = ''; //цвет боковин
      state.selectedCenterColor = ''; //цвет цента
      state.selectedInsertColor = ''; //цвет вставки
      state.selectedThreadColor = ''; //цвет нитки
    },

    cleanState: (state) => {
      state.img = '';
      state.title = '';
      state.typeM = '';
      state.size = '';
      state.color = '';
      state.colorEdge = ''; //цвет канта
      state.selectedSideСolor = ''; //цвет боковин
      state.selectedCenterColor = ''; //цвет цента
      state.selectedInsertColor = ''; //цвет вставки
      state.selectedThreadColor = ''; //цвет нитки
      state.price = 0;
      state.salePrice = 0;
    },
  },
});

export const { popelateInitialState, cleanState } = generalProductCardSlice.actions;

export const generalProductCardReducer = generalProductCardSlice.reducer;
/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
