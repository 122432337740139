/* eslint-disable no-dupe-keys */
// транслитерация для ссылок c русского на английский
export function transliterate(text) {
  const ukrainianToLatin = {
    А: 'A',
    Б: 'B',
    В: 'V',
    Г: 'H',
    Ґ: 'G',
    Д: 'D',
    Е: 'E',
    Є: 'Ye',
    Ж: 'Zh',
    З: 'Z',
    И: 'Y',
    І: 'I',
    Ї: 'Yi',
    Й: 'Y',
    К: 'K',
    Л: 'L',
    М: 'M',
    Н: 'N',
    О: 'O',
    П: 'P',
    Р: 'R',
    С: 'S',
    Т: 'T',
    У: 'U',
    Ф: 'F',
    Х: 'Kh',
    Ц: 'Ts',
    Ч: 'Ch',
    Ш: 'Sh',
    Щ: 'Shch',
    Ь: '',
    Ю: 'Yu',
    Я: 'Ya',
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'h',
    ґ: 'g',
    д: 'd',
    е: 'e',
    є: 'ye',
    ж: 'zh',
    з: 'z',
    и: 'y',
    і: 'i',
    ї: 'yi',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'kh',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ь: '',
    ю: 'yu',
    я: 'ya',
    ' ': '-',
    '—': '-',
    '–': '-',
    '’': '',
    "'": '',
  };

  return text
    .split('')
    .map((char) => ukrainianToLatin[char] || char)
    .join('');
}

// export default function formatURL(text) {
//   return transliterate(text)
//     .toLowerCase()
//     .replace(/[^a-z0-9]+/g, "-") // заменяем все символы, кроме букв и цифр, на дефисы
//     .replace(/^-+|-+$/g, ""); // удаляем ведущие и замыкающие дефисы
// }

// export default function transliterateToUkrainian(text) {
//   if (!text) return null;
//   const translitMap = {
//     a: 'а',
//     b: 'б',
//     c: 'ц',
//     d: 'д',
//     e: 'е',
//     f: 'ф',
//     g: 'г',
//     h: 'х',
//     i: 'і',
//     i: 'и',
//     j: 'й',
//     k: 'к',
//     l: 'л',
//     m: 'м',
//     n: 'н',
//     o: 'о',
//     p: 'п',
//     r: 'р',
//     s: 'с',
//     t: 'т',
//     u: 'у',
//     v: 'в',
//     w: 'в',
//     x: 'кс',
//     y: 'и',
//     z: 'з',
//     zh: 'ж',
//     ch: 'ч',
//     sh: 'ш',
//     y: 'ї',
//     yu: 'ю',
//     ya: 'я',
//   };

//   return text
//     .toLowerCase()
//     .replace(/sh/g, 'ш')
//     .replace(/ch/g, 'ч')
//     .replace(/zh/g, 'ж')
//     .replace(/y/g, 'ї')
//     .replace(/yu/g, 'ю')
//     .replace(/ya/g, 'я')
//     .replace(/[a-z]/g, (match) => translitMap[match] || match) // заменяем на украинские буквы
//     .replace(/-/g, ' '); // убираем дефисы
// }

// function transliterateToPolish(text) {
//   const polMap = {
//       'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j',
//       'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'r': 'r', 's': 's', 't': 't', 'u': 'u',
//       'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z', 'ó': 'ó', 'ż': 'ż', 'ć': 'ć', 'ę': 'ę', 'ł': 'ł',
//       'ń': 'ń', 'ś': 'ś', 'ź': 'ź'
//   };
//   return text
//       .toLowerCase()
//       .replace(/[a-zóżćęłńśź]+/g, match => polMap[match] || match)
//       .replace(/-/g, ''); // удаляем дефисы
// }
// =====================ТРАНСЛИТЕРАЦИЯ ВКЛЮЧАЕТ РУСКИЙ УКРАИНСКИЙ АЛФАВИТ, И ПЕРЕВОДИТ ТЕКСТ С руского или украинского=============================
const transliterationMap = {
  // Русский алфавит
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'kh',
  ц: 'ts',
  ч: 'ch',
  ш: 'sh',
  щ: 'shch',
  ъ: '',
  ы: 'y',
  ь: '',
  э: 'e',
  ю: 'yu',
  я: 'ya',

  // Украинский алфавит
  ґ: 'g',
  є: 'ye',
  ї: 'yi',
  і: 'i',

  // Заглавные буквы
  А: 'A',
  Б: 'B',
  В: 'V',
  Г: 'G',
  Д: 'D',
  Е: 'E',
  Ё: 'Yo',
  Ж: 'Zh',
  З: 'Z',
  И: 'I',
  Й: 'Y',
  К: 'K',
  Л: 'L',
  М: 'M',
  Н: 'N',
  О: 'O',
  П: 'P',
  Р: 'R',
  С: 'S',
  Т: 'T',
  У: 'U',
  Ф: 'F',
  Х: 'Kh',
  Ц: 'Ts',
  Ч: 'Ch',
  Ш: 'Sh',
  Щ: 'Shch',
  Ъ: '',
  Ы: 'Y',
  Ь: '',
  Э: 'E',
  Ю: 'Yu',
  Я: 'Ya',

  Ґ: 'G',
  Є: 'Ye',
  Ї: 'Yi',
  І: 'I',
};

const brandSpecificMap = {
  ак: 'acura',
  аку: 'acura',
  акура: 'acura',
  беи: 'beijing',
  беид: 'beijing',
  беиджинг: 'beijing',
  пек: 'beijing',
  пекино: 'beijing',
  би: 'byd',
  бид: 'byd',
  ка: 'cadillac',
  кад: 'cadillac',
  кади: 'cadillac',
  кадиллак: 'cadillac',
  ше: 'chevrolet',
  шев: 'chevrolet',
  шевроле: 'chevrolet',
  кра: 'chrysler',
  край: 'chrysler',
  крайслер: 'chrysler',
  сит: 'citroen',
  ситроен: 'citroen',
  куп: 'cupra',
  купра: 'cupra',
  део: 'daewoo',
  дэо: 'daewoo',
  деу: 'daewoo',
  дэу: 'daewoo',
  дай: 'daihatsu',
  дайхатсу: 'daihatsu',
  джи: 'geely',
  джили: 'geely',
  хав: 'haval',
  хавал: 'haval',
  хон: 'honda',
  хонда: 'honda',
  ху: 'hummer',
  ха: 'hummer',
  хум: 'hummer',
  хам: 'hummer',
  хуммер: 'hummer',
  хаммер: 'hummer',
  хун: 'hyundai',
  хюн: 'hyundai',
  хён: 'hyundai',
  хунде: 'hyundai',
  хюнде: 'hyundai',
  хёнде: 'hyundai',
  хёндэ: 'hyundai',
  жак: 'jac',
  джак: 'jac',
  ягу: 'jaguar',
  ягуар: 'jaguar',
  джип: 'jeep',
  дже: 'jetour',
  джетур: 'jetour',
  мер: 'mercedesbenz',
  мерц: 'mercedesbenz',
  мерце: 'mercedesbenz',
  мерс: 'mercedesbenz',
  мерсе: 'mercedesbenz',
  мерседес: 'mercedesbenz',
  мазе: 'maserati',
  мазерати: 'maserati',
  мак: 'maxus',
  максус: 'maxus',
  сай: 'saipa',
  сайпа: 'saipa',
  шко: 'skoda',
  шкода: 'skoda',
  сау: 'soueast',
  сауст: 'soueast',
  фоль: 'volkswagen',
  фольксваген: 'volkswagen',
  вел: 'weltmeister',
  вель: 'weltmeister',
  вельтмайстер: 'weltmeister',
  волв: 'wolv',
  ек: 'xpeng',
  эк: 'xpeng',
  екс: 'xpeng',
  экс: 'xpeng',
  экспенг: 'xpeng',
  зік: 'zeekr',
  зик: 'zeekr',
  зикр: 'zeekr',
};

export const transliterateToEnglish = (text) => {
  const normalizedText = text.toLowerCase().trim();

  // Проверяем специальные правила
  if (brandSpecificMap[normalizedText]) {
    return brandSpecificMap[normalizedText];
  }

  // Применяем общую транслитерацию
  return normalizedText
    .split('')
    .map((char) => transliterationMap[char] || char)
    .join('');
};
// =============================// Карты транслитерации с английского на русский=========================================================
export const transliterateToUa = (text, toLanguage) => {
  // Карты транслитерации
  const transliterationMapRU = {
    a: 'а',
    b: 'б',
    c: 'ц',
    d: 'д',
    e: 'е',
    f: 'ф',
    g: 'г',
    h: 'х',
    i: 'и',
    j: 'й',
    k: 'к',
    l: 'л',
    m: 'м',
    n: 'н',
    o: 'о',
    p: 'п',
    q: 'к',
    r: 'р',
    s: 'с',
    t: 'т',
    u: 'у',
    v: 'в',
    w: 'в',
    x: 'кс',
    y: 'ы',
    z: 'з',
    ch: 'ч',
    sh: 'ш',
    ya: 'я',
    yu: 'ю',
    yo: 'ё',
    zh: 'ж',
    ' ': ' ',
  };

  const transliterationMapUA = {
    a: 'а',
    b: 'б',
    c: 'ц',
    d: 'д',
    e: 'е',
    f: 'ф',
    g: 'г',
    h: 'х',
    i: 'і',
    j: 'й',
    k: 'к',
    l: 'л',
    m: 'м',
    n: 'н',
    o: 'о',
    p: 'п',
    q: 'к',
    r: 'р',
    s: 'с',
    t: 'т',
    u: 'у',
    v: 'в',
    w: 'в',
    x: 'кс',
    y: 'ї',
    z: 'з',
    ch: 'ч',
    sh: 'ш',
    ya: 'я',
    yu: 'ю',
    yi: 'ї',
    zh: 'ж',
    ' ': ' ',
  };

  // Выбор карты в зависимости от целевого языка
  const map = toLanguage === 'ru' ? transliterationMapRU : transliterationMapUA;

  // Транслитерация текста
  let result = '';
  for (let i = 0; i < text.length; i++) {
    const doubleChar = text[i] + (text[i + 1] || ''); // Проверяем на составные символы
    if (map[doubleChar]) {
      result += map[doubleChar];
      i++; // Пропускаем следующий символ
    } else if (map[text[i]]) {
      result += map[text[i]];
    } else {
      result += text[i]; // Если символ не найден в карте, добавляем его как есть
    }
  }

  return result;
};
