import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getRequestParams, resourceUrl } from '../../api/getRequestParams';

/* -------------------------- достаём продукт по ID ------------------------- */
export const fetchProduct = createAsyncThunk('product/fetchProduct', async (id, { getState, rejectWithValue }) => {
  // console.log('fetchProduct', id);
  try {
    const params = getRequestParams();
    const currentLeng = getState().language.language;
    // localStorage.setItem('id', id);

    // const getId = currentLeng === 'uk' && localStorage.getItem('id');
    // console.log('currId', getId);

    const response = await axios.get(
      `${resourceUrl}/wc/v3/products/${id}?lang=${currentLeng}&_fields=id,tags,name,images,attributes,description,short_description,slug,sku,has_options,price,regular_price,sale_price`,
      { params }
    );

    const extractFields = (product) => {
      const {
        id,
        name,
        tags,
        images = [],
        attributes = [],
        description,
        short_description,
        slug,
        sku,
        has_options,
        price,
        regular_price,
        sale_price,
      } = product;

      const processedImages = images.map(({ id, src }) => ({ id, src }));
      const processedAttributes = attributes.map(({ id, name, options }) => ({ id, name, options }));

      return {
        id,
        name,
        tags,
        images: processedImages,
        attributes: processedAttributes,
        description,
        short_description,
        slug,
        sku,
        has_options,
        price,
        regular_price,
        sale_price,
      };
    };

    return extractFields(response.data);
  } catch (error) {
    console.error('Ошибка при загрузке товаров:', error);
    return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке товаров');
  }
});

/* -------------------------- достаём вариации продукта с фотками ------------------------- */
export const fetchVariations = createAsyncThunk(
  'product/fetchVariations',
  async (id, { getState, rejectWithValue }) => {
    try {
      const params = getRequestParams();
      const currentLeng = getState().language.language;

      const response = await axios.get(
        `${resourceUrl}/wc/v3/products/${id}/variations?lang=${currentLeng}&_fields=id,name,image`,
        {
          params,
        }
      );

      const data = response.data.map(({ id, name, image }) => ({ id, name, src: image?.src || '' }));
      return data;
    } catch (error) {
      console.error('Ошибка при загрузке товаров:', error);
      return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке товаров');
    }
  }
);
